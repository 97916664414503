import { useEffect, useState } from "react";
import { Navigate, redirect, Route, Routes, useNavigate, useOutletContext, useParams } from "react-router"
import { NavLink } from "react-router-dom";
import API from "../../../service/api";
import { PageNavigation } from "../../../utils/PageNavigation";
import { TabsViewer } from "../../../utils/TabsViewer";
import { HeaderBar } from "../HeaderNav";
import { MachineViewer } from "../machine/MachineViewer";
import { MachinesList } from "./MachinesList";
import { faker } from '@faker-js/faker';
import { LogViewer } from "../../other/LogViewer";
import * as mdb from 'mdb-ui-kit'; // lib
import WS from "../../../service/ws";
import { useTranslation } from "react-i18next";
import { ReportsSelectorPopup } from "../ReportsSelectorPopup";


function getWindowSize() {
    const {innerWidth, innerHeight} = window;
    return {innerWidth, innerHeight};
}




export const LocationViewer = ({ groupID, groupName, rights }) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [fetching, setFetching] = useState(false);
    const [needsToFetch, setNeedsToFetch] = useState(true);
    const [location, setLocation] = useState(null);
    const [updateMachine, setUpdateMachine] = useState(null);
    const [updateLocation, setUpdateLocation] = useState(null);
    const [windowSize, setWindowSize] = useState(getWindowSize());
    const [error, setError] = useState('');
    const { t } = useTranslation();


    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }
        window.addEventListener('resize', handleWindowResize);
        handleWindowResize();

		//WS.listen('machineUpdate', machineUpdateCallback);

        const machineUpdate = ev => {
            setUpdateMachine(ev);
        }
        const locationUpdate = ev => {
            setUpdateLocation(ev[id]);
        }
        WS.listen('location_machine_update', machineUpdate);
        WS.listen('location_update', locationUpdate);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
            //WS.unlisten(machineUpdateCallback);
            WS.unlisten('location_machine_update', machineUpdate);
            WS.unlisten('location_update', locationUpdate);
            //WS.unlisten('location_update', locationUpdate);
            WS.emit('location_leave', {id});
        };
    }, [id]);

    const fetch = async () => {
        setFetching(true);
        setNeedsToFetch(false);
        try {
            let data = await API.getMachinesByLocationID(groupID||0, id, 30);
            setLocation(data);
        }catch(err){
            setError(err.message);
        }
        finally{
            setFetching(false);
        }

    }

    useEffect(() => {
        if (location && updateMachine) {
            let id = -1;
            Object.keys(updateMachine).forEach(key => id = Number(key));
            if (typeof id !== 'number' || id < 0) return console.error(`Invalid machine update in LocationViewer!`);

            const prevState = location;
            const updatedMachine = location.machines.find(m => m.id == id);

            Object.assign(updatedMachine, updateMachine[id]);
            setUpdateMachine(null);
            setLocation({...prevState, machines: [...prevState.machines]});

        }
    }, [location, updateMachine]);

    useEffect(() => {
		if (location && updateLocation) {
			setUpdateLocation(null);
			setLocation({...location, ...updateLocation});
		}
	}, [location, updateLocation]);

    useEffect(() => {
        if (needsToFetch && !fetching) fetch();
    }, [needsToFetch, fetching]);

    useEffect(() => {
        document.querySelectorAll(".my-tooltip").forEach(el => {
            new mdb.Tooltip(el);
        });
    }, [location]);

    function onMachineSelect(id) {
        navigate('machines/'+id);
    }

    if (!location && !error) return (
        <div className="d-flex justify-content-center pt-2 w-100 h-100">
            <div className="spinner-border" role="status">
                <span className="visually-hidden">{t('loading...')}</span>
            </div>
        </div>
    );

    if (error) return (
        <div className="d-flex flex-row justify-content-center align-items-center w-100" style={{height: 'fit-content'}}>
            <div className="alert alert-danger alert-dismissible fade show m-3" role="alert">
                { error }
            </div>
        </div>
        
    );

    return (
        <Routes>
            <Route path="" element={
                <div className="d-flex flex-fill flex-column justify-content-center align-items-center w-100" style={{}}>
                    <nav className="navbar navbar-expand-lg navbar-light p-1 w-100 border-top" style={{background: '#fc8c0342'}}>
                        <div className="justify-content-between d-flex flex-row w-100 overflow-hidden">
                            <div style={{position: ''}}>
                                <NavLink to=".." className="nav-link d-flex align-items-center p-1 user-select-none cursor-pointer" style={{cursor: 'pointer'}}>
                                    <div className="d-flex align-items-center pe-1">
                                        <i className="fas fa-arrow-left nav-link" style={{fontSize: '1.8rem'}}/>
                                    </div>
                                </NavLink>
                            </div>
                            {windowSize.innerWidth >= 600 &&
                                <div
                                    className="d-flex flex-row flex-fill align-items-center text-center"
                                    style={{ overflow: "hidden" }}
                                >
                                    <div
                                        className="d-flex flex-row flex-fill align-items-center justify-content-end overflow-hidden"
                                        onClick={() => navigate(`../g/${groupID}`)}
                                        style={{ whiteSpace: "nowrap" }}
                                    >
                                        <i className="fas fa-layer-group text-primary me-1 fa-sm" />
                                        <span
                                            style={{
                                                fontSize: "0.9rem",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                cursor: 'pointer',
                                            }}
                                        >
                                            {groupName}
                                        </span>
                                        <span
                                            style={{
                                                fontSize: "0.9rem",
                                            }}
                                        >
                                            &nbsp;/
                                        </span>
                                    </div>
                                    <div className="d-flex flex-row flex-fill align-items-center">
                                        <i className="fas fa-map-marker-alt text-primary me-1 ms-1" />
                                        <span
                                            style={{
                                                fontSize: "1rem",
                                                fontWeight: 'bold',
                                                whiteSpace: "nowrap",
                                            }}
                                        >
                                            {location.Name}
                                        </span>
                                    </div>
                                    <div className="d-flex flex-row position-absolute align-items-center" style={{right: 5}}>
                                        {location.value !== undefined &&
                                            <ReportsSelectorPopup gid={groupID} lid={id} disabled={!rights?.Events?.SalesEvents || !rights?.Locations?.ViewMachines} mobile={windowSize.innerWidth<600}
                                                trigger={<span className="badge badge-success align-items-center"><i className="fas fa-hand-holding-dollar fa-lg fa-fw text-primary me-1"/>{location.value}</span>}/> 
                                        }
                                        {/* {location.value !== undefined && <span className="badge badge-success align-items-center"><i className="fas fa-hand-holding-dollar fa-lg fa-fw text-primary me-1"/>{location.value}</span>} */}
                                    </div>
                                </div>
                            }
                            {windowSize.innerWidth < 600 &&
                            <div
                                className="d-flex flex-column flex-fill align-items-start justify-content-center text-center"
                                style={{ overflow: "hidden" }}
                            >
                                {/* <div className="flex-fill"></div> */}
                                <div className="d-flex flex-row flex-fill align-items-center" style={{textOverflow: 'ellipsis'}}>
                                    <i className="fas fa-map-marker-alt text-primary fa-fw me-1" />
                                    <span className="d-flex flex-fill"
                                        style={{
                                            fontSize: "1rem",
                                            fontWeight: 'bold',
                                            whiteSpace: "nowrap",
                                            lineHeight: '1em',
                                        }}
                                    >
                                        {location.Name}
                                    </span>
                                </div>
                                <div
                                    className="d-flex flex-row flex-fill align-items-center justify-content-end overflow-hidden"
                                    onClick={() => navigate(`../g/${groupID}`)}
                                    style={{ whiteSpace: "nowrap" }}
                                >
                                    <i className="fas fa-layer-group text-primary fa-fw me-1 fa-sm" />
                                    <span
                                        style={{
                                            fontSize: "0.9rem",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                        }}
                                    >
                                        {groupName}
                                    </span>
                                </div>
                                <div className="d-flex flex-row position-absolute align-items-center" style={{right: 5}}>
                                    {location.value !== undefined &&
                                        <ReportsSelectorPopup gid={groupID} lid={id} disabled={!rights?.Events?.SalesEvents || !rights?.Locations?.ViewMachines} mobile={windowSize.innerWidth<600}
                                            trigger={<span className="badge badge-success align-items-center"><i className="fas fa-hand-holding-dollar fa-lg fa-fw text-primary me-1"/>{location.value}</span>}/> 
                                    }
                                </div>
                            </div>
                            }
                            
                        </div>
                    </nav>

                    <div className="d-flex flex-column flex-fill justify-content-center align-items-center w-100 pt-1" style={{overflowX: 'hidden'}}>
                        {windowSize.innerWidth < 700 &&
                            <TabsViewer
                                id={'location'}
                                tabs={[
                                    {
                                        name: 'machines', title: t('machines'), icon: 'fas fa-server',
                                        default: true,
                                        component: <MachinesList machines={location.machines || []} onMachineSelect={(id) => onMachineSelect(id)} />
                                    },
                                    {
                                        name: 'logs', title: t('logs'), icon: 'fas fa-bell',
                                        component: (
                                            <LogViewer groupId={groupID} locationId={id}/>
                                        )
                                    }
                                ]}
                            />
                        }
                        {windowSize.innerWidth >= 700 && (
                            <div className="d-flex flex-row w-100 flex-fill overflow-hidden">
                                <div className="d-flex flex-column align-items-center col-8 overflow-hidden flex-fill">
                                    <h5 className="w-100" style={{textAlignLast: 'center'}}>{t('machines')}</h5>
                                    <MachinesList machines={location.machines || []} onMachineSelect={(id) => onMachineSelect(id)} />
                                </div>
                                <div className="d-flex flex-column flex-fill col-4">
                                    <h5 className="w-100 pe-2" style={{textAlignLast: 'center'}}>{t('logs')}</h5>
                                    <div className="d-flex flex-column flex-fill overflow-auto pe-2">
                                        <LogViewer groupId={groupID} locationId={id}/>
                                    </div>
                                    
                                </div>
                            </div>
                        )}
            
                        
                        
                        
            
                    </div>
        
                    
                </div>
            }/>
            { location.machines && <Route path="machines/:id/*" element={<MachineViewer groupID={groupID} groupName={groupName} rights={rights} locationID={id} standalone={location.machines.length === 1}/>}/> }
            { location.machines && <Route path="next" element={location.machines.length > 0 ? <Navigate to={"../machines/"+location.machines[0].id}/> : <Navigate to=""/>}/> }
            <Route path="*" element={<Navigate to=".."/>}/>
        </Routes>
        
    )
}