import { useEffect, useRef, useState } from "react";


export const CodeInput = ({onCodeChange}) => {
    const [code, setCode] = useState(['', '', '', '', '', '']);
    const inputRefs = useRef([]);
    
    const handleChange = (index, event) => {
        const newCode = [...code];
        if (!/^[a-zA-Z0-9\s]+$|^$/.test(event.target.value)) return false;
        newCode[index] = event.target.value.toLocaleUpperCase();
        setCode(newCode);
        if (event.target.value && inputRefs.current[index + 1]) {
            inputRefs.current[index + 1].focus();
        }
    };
    
    const handleKeyDown = (index, event) => {
        if (event.key === 'Backspace' && !event.target.value && inputRefs.current[index - 1]) {
        inputRefs.current[index - 1].focus();
        }
    };

    const handlePaste = (event) => {
        event.preventDefault();
        const text = event.clipboardData.getData('text/plain');
        const textLength = text.length;
        const newCode = [...code];
        let currentIndex = 0;
        for (let i = 0; i < textLength && currentIndex < 6; i++) {
          newCode[currentIndex] = text.charAt(i);
          currentIndex++;
        }
        setCode(newCode);
        if (currentIndex < 6 && inputRefs.current[currentIndex]) {
          inputRefs.current[currentIndex].focus();
        }
      };

    useEffect(() => {
        if (onCodeChange) onCodeChange(code.join(''));
    }, [code, onCodeChange]);
    
    return (
        <>
        {code.map((char, index) => (
            <input
                key={index}
                ref={(el) => inputRefs.current[index] = el}
                value={char}
                maxLength={1}
                onChange={(event) => handleChange(index, event)}
                onKeyDown={(event) => handleKeyDown(index, event)}
                className="input-code-field text-success fw-bold"
                onPaste={(index === 0) ? handlePaste : null}
            />
        ))}
        </>
    );
      
}