import { useEffect, useState } from "react"
import * as mdb from 'mdb-ui-kit'; // lib
import { CustomSelect } from "../../../utils/CustomSelect";
import API from "../../../service/api";
import { useTranslation } from "react-i18next";
import { ChangePasswordModal } from "./modal/ChangePasswordModal";


function getWindowSize() {
    const {innerWidth, innerHeight} = window;
    return {innerWidth, innerHeight};
}

export const ProfilePage = ({userData, onUserUpdate, rights}) => {
    const [windowSize, setWindowSize] = useState(getWindowSize());
    const [data, setData] = useState(null);
    const [myCompany, setMyCompany] = useState(userData.company);
    const [inheritedCompany, setInheritedCompany] = useState('');
    const [countries, setCountries] = useState([]);
    const [compCountry, setCompCountry] = useState(null);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [changedUser, setChangedUser] = useState(false);
    const [changedCompany, setChangedCompany] = useState(false);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [resetPassword, setResetPassword] = useState(false);
    const [minimized, setMinimized] = useState(false);
    const { t } = useTranslation();

    const fetch = () => {
        API.me().then(d => { setData(d); setSelectedCompany(d.company?.id || -1); });
    }

    useEffect(() => {
        setMyCompany(userData.company);
        setInheritedCompany(userData.rightsCompany !== userData.company.name ? userData.rightsCompany : '');
    }, [userData]);

    useEffect(() => {
        document.querySelectorAll(".form-outline").forEach((formOutline) => {
			new mdb.Input(formOutline).init();
		});
        
        
        API.configCountries()
            .then(c => {
                if (Array.isArray(c)) setCountries(c);
            })
            .catch(err => console.error(err))
            .finally(() => {
                fetch();
            });

        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }
        window.addEventListener('resize', handleWindowResize);

        API.unregisterLogs();
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    useEffect(() => {
        setMinimized(windowSize.innerWidth < 700);
    }, [windowSize]);

    useEffect(() => {
        if (Array.isArray(countries) && myCompany){
            const country = countries.find(c => c.code === myCompany.country);
            if (country) setCompCountry(country);
        }
    }, [myCompany, countries]);

    useEffect(() => {
        document.querySelectorAll(".form-outline").forEach((formOutline) => {
			new mdb.Input(formOutline).init();
		});
    }, [myCompany]);

    const onChange = (ev) => {
        setData(prev => ({
            ...prev,
            [ev.nativeEvent.target.name]: ev.nativeEvent.target.value
        }));
        setChangedUser(true);
        setMessage(''); setError('');
        
    }

    const onChangeCompany = ev => {
        setMyCompany(prev => ({
            ...prev,
            [ev.nativeEvent.target.name]: ev.nativeEvent.target.value
        }));
        setChangedCompany(true);
    }

    const submit = async () => {
        setLoading(true);
        try {
            await API.updateUser(data.name, data.phone, data.language, selectedCompany);
            setChangedUser(false);
            setMessage(t('user_updated_success'));
            localStorage.setItem('lastopcomp', selectedCompany);
            fetch();
            onUserUpdate();
        }catch(err){
            setChangedUser(false);
            setError(err.message);
        }finally{
            setLoading(false);
            
        }
    }

    const submitCompany = async () => {
        setLoading(true);
        API.updateMyCompany(myCompany.name, myCompany.CIF, myCompany.country)
            .then(c => {
                setLoading(false);
                onUserUpdate();
                //fetchMyCompany();
            })
            .catch(err => {
                setError(err.message);
            })
            .finally(() => setChangedCompany(false));
    }

    if (!data || !myCompany) return null;

    return (
        <section className="d-flex flex-column flex-fill">
            {resetPassword && <div className={"d-flex flex-column " + (minimized ? "w-100 mb-2" : "col-6 me-2")} style={{maxWidth: 450}}>
                <ChangePasswordModal open={true} onClose={() => setResetPassword(false)}/>
            </div>}
            <div className="d-flex flex-row w-100">
                <div className={"d-flex me-2 w-100 " + (minimized ? "flex-column" : "flex-row")}>
                    {<div className={"d-flex flex-column " + (minimized ? "w-100 mb-2" : "col-6 me-2")} style={{maxWidth: 450}}>
                        <div className={"card overflow-auto "}>
                            <div className="card-body">
                                <h5 className="ms-2 mb-3">{t('your_account')}</h5>
                                <div className="d-flex mb-2 flex-row align-items-center">
                                    <i className="fas fa-id-card fa-fw"/>
                                    <div className="col ms-2">
                                        <div className="form-outline">
                                            <input type="text" name="name" id="userName" className="form-control" value={data.name} onInput={onChange}/>
                                            <label className="form-label" htmlFor="userName">{t('name')}</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex mb-2 flex-row align-items-center">
                                    <i className="fas fa-envelope fa-fw"/>
                                    <div className="col ms-2">
                                        <div className="form-outline">
                                            <input type="text" name="mail" id="userMail" className="form-control" value={data.mail} disabled onInput={onChange}/>
                                            <label className="form-label" htmlFor="userMail">{t('email')}</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex mb-2 flex-row align-items-center">
                                    <i className="fas fa-mobile-alt fa-fw"/>
                                    <div className="col ms-2">
                                        <div className="form-outline">
                                            <input type="text" name="phone" id="userPhone" className="form-control" value={data.phone} onInput={onChange}/>
                                            <label className="form-label" htmlFor="userPhone">{t('phone')}</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex mb-2 flex-row align-items-center">
                                    <i className="fas fa-user-tag fa-fw"/>
                                    <div className="col ms-2">
                                        <div className="form-outline">
                                            <input type="text" id="userParent" className="form-control" value={data.parent||'-'} disabled/>
                                            <label className="form-label" htmlFor="userParent">{t('invited_by_full')}</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex mb flex-row align-items-center">
                                    <i className="fas fa-building fa-fw"/>
                                    <div className="col ms-2">
                                        <div className="w-100">
                                            <CustomSelect
                                                title={t('company')}
                                                items={data.companies.map(c => ({
                                                    val: c.id, title: c.name
                                                }))}
                                                selected={selectedCompany}
                                                onSelect={id => { setSelectedCompany(id); setChangedUser(true); setMessage(''); setError(''); }}
                                            />
                                        </div>
                                    </div>
                                </div>

                                { inheritedCompany && <span className="d-block w-100 text-muted text-end fst-italic" style={{fontSize: '85%'}}>{t('inherited_permissions_from')} <span className="text-warning">{inheritedCompany}</span></span> }

                                { message && <div className="alert alert-success alert-dismissible fade show mt-2" role="alert">
                                    { message }
                                </div> }
                                { error && <div className="alert alert-danger alert-dismissible fade show mt-2" role="alert">
                                    { error }
                                </div> }

                                <div className="d-flex flex-row mt-2">
                                    <button className="btn btn-primary" disabled={!changedUser || loading} onClick={submit}>{t('save')}</button>
                                    <div className="d-flex flex-row flex-fill justify-content-end">
                                        <button className="btn btn-link text-primary p-2" onClick={() => setResetPassword(true)}>{t('change_password')}</button>

                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>}
                    
                    <div className={"d-flex flex-column " + (minimized ? "w-100" : 'col-6')} style={{maxWidth: 450}}>
                        <div className={"card overflow-auto "}>
                            <div className="card-body">
                                <h5 className="ms-2 mb-3">{t('your_company')}</h5>
                                {rights?.Companies?.Own?.Change && <>
                                    <div className="d-flex mb-3 flex-row align-items-center">
                                        <i className="fas fa-pencil fa-fw"/>
                                        <div className="col ms-2">
                                            <div className="form-outline">
                                                <input type="text" name="name" id="name" className="form-control" value={myCompany.name} onInput={onChangeCompany}/>
                                                <label className="form-label" htmlFor="name">{t('name')}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex mb-2 flex-row align-items-center">
                                        <i className="fas fa-briefcase fa-fw"/>
                                        <div className="col ms-2">
                                            <div className="form-outline">
                                                <input type="text" name="CIF" id="CIF" className="form-control" value={myCompany.CIF} onInput={onChangeCompany}/>
                                                <label className="form-label" htmlFor="CIF">{t('cif')}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex mb-2 flex-row align-items-center">
                                        <i className="fas fa-building fa-fw"/>
                                        <div className="col ms-2">
                                            <div className="form-outline">
                                                <input type="text" name="parent" id="parent" className="form-control" value={myCompany.parent ? myCompany.parent.name : '-'} disabled/>
                                                <label className="form-label" htmlFor="parent">{t('parent')}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex mb-2 flex-row align-items-center">
                                        <i className="fas fa-earth-europe fa-fw"/>
                                        <div className="col ms-2">
                                            <div className="w-100">
                                                <CustomSelect expandWidth={"245px"} title={t('country')} search={true}
                                                    items={countries.map(c => ({ val: c.code, title: `${c.country}`,
                                                        icon: `flag-icon flag-icon-${c.code?.toLocaleLowerCase()}`}))}
                                                    selected={myCompany.country}
                                                    onSelect={code => {setMyCompany(comp => ({...comp, country: code})); setChangedCompany(true); }} />
                                            </div>
                                        </div>
                                        
                                    </div>
                                    
                                    <button className="btn btn-primary" disabled={!changedCompany || loading} onClick={submitCompany}>{t('save')}</button>
                                </>}
                                {!rights?.Companies?.Own?.Change && <>
                                    <ul className="list-group list-group-light p-1">
                                        <li className="list-group-item p-1"><i className="fas fa-pencil fa-fw text-primary me-1"/><span className="fw-bold me-1">{t('name')}:</span>{myCompany.name}</li>
                                        <li className="list-group-item p-1"><i className="fas fa-briefcase fa-fw text-primary me-1"/><span className="fw-bold me-1">{t('cif')}:</span>{myCompany.CIF}</li>
                                        <li className="list-group-item p-1"><i className="fas fa-building fa-fw text-primary me-1"/><span className="fw-bold me-1">{t('parent')}:</span>{myCompany.parent?.name || '-'}</li>
                                        <li className="list-group-item p-1"><i className="fas fa-earth-europe fa-fw text-primary me-1"/><span className="fw-bold me-1">{t('country')}:</span>{compCountry ? compCountry.country : '?'}</li>
                                    </ul>
                                </>}
                                
                                { error && <div className="alert alert-danger alert-dismissible fade show mt-2" role="alert">
                                    { error }
                                </div> }
                            </div>
                        </div>
                    </div>
                    
                </div>
                
            </div>
            
            
        </section>
        
    )
}

const ResetPassword = ({onClose}) => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const { t } = useTranslation();

    useEffect(() => {
        document.querySelectorAll(".form-outline").forEach((formOutline) => {
			new mdb.Input(formOutline).init();
		});
    }, []);

    const submit = () => {
        API.updateUser(undefined, undefined, undefined, undefined, password)
            .then(() => { onClose(); API.logout(); })
            .catch(err => setError(err.message));
    }

    return (
        <div className="card overflow-hidden d-flex flex-column">
            <button type="button" className="btn-close position-absolute" style={{right: 5, top: 5}} onClick={() => onClose ? onClose() : {}}></button>
            <div className="card-body">
                <h5 className="ms-2 mb-3">{t('change_password')}</h5>

                <div className="d-flex mb-2 flex-row align-items-center">
                    <i className="fas fa-key fa-fw"/>
                    <div className="col ms-2">
                        <div className="form-outline">
                            <input type="password" name="password" id="password" className="form-control" value={password} onChange={ev => setPassword(ev.currentTarget.value)}/>
                            <label className="form-label" htmlFor="password">{t('setpassword')}</label>
                        </div>
                    </div>
                </div>

                <div className="d-flex mb-2 flex-row align-items-center">
                    <i className="fas fa-key fa-fw"/>
                    <div className="col ms-2">
                        <div className="form-outline">
                            <input type="password" name="confirmPassword" id="confirmPassword" className="form-control" value={confirmPassword} onChange={ev => setConfirmPassword(ev.currentTarget.value)}/>
                            <label className="form-label" htmlFor="confirmPassword">{t('confirmpassword')}</label>
                        </div>
                    </div>
                </div>

                {error && <div className="alert alert-danger alert-dismissible fade show mt-2 mb-1 p-3 w-100" role="alert">
                    { error }
                </div>}

                <button className="btn btn-primary" disabled={password.length == 0 || password !== confirmPassword} onClick={submit}>{t('change')}</button>
            </div>
        </div>
    )
}