import React, { useEffect, useState } from 'react';

const CameraList = () => {
  const [cameras, setCameras] = useState([]);

  useEffect(() => {
    const getCameras = async () => {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const cameras = devices.filter(device => device.kind === 'videoinput');
        setCameras(cameras);
      } catch (error) {
        console.error('Error getting cameras:', error);
      }
    };

    getCameras();
  }, []);

  return (
    <div>
      <h2>Available Cameras:</h2>
      <ul>
        {cameras.map((camera) => (
          <li key={camera.deviceId}>
            <p>Device ID: {camera.deviceId}</p>
            <p>Label: {camera.label}</p>
            <p>Capabilities:</p>
            <ul>
              {camera.getCapabilities && Object.entries(camera.getCapabilities()).map(([key, value]) => (
                <li key={key}>
                  {key}: {JSON.stringify(value)}
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CameraList;