import { useEffect, useState } from "react";
import API from "../../../service/api";
import CustomTable from "../../other/CustomTable";
import * as mdb from 'mdb-ui-kit'; // lib
import { CustomModal } from "../../other/CustomModal";
import { DeleteConfirm } from "../../other/DeleteConfirm";
import { isValidNumber } from "../../../service/utils";
import { useTranslation } from "react-i18next";


export const ProductsPage = ({rights}) => {
    const [selectedId, setSelectedId] = useState(null);
    const [productsLoading, setProductsLoading] = useState(false);
    const [data, setData] = useState([]);
    const { t } = useTranslation();

    const fetchProducts = () => {
        if (!rights?.Products?.View) return;
        setProductsLoading(true);
        API.getProductsAdm()
            .then(data => setData(data))
            .finally(() => setProductsLoading(false));
    }

    useEffect(() => {
        fetchProducts();
    }, [])
    

    const ProductsTable = (
        <div className="d-flex flex-fill flex-column overflow-hidden ms-3">
            <div className="d-flex flex-column overflow-hidden card">
                <div className="d-flex flex-column card-body overflow-hidden">
                    { rights?.Products?.Add && <span className="btn text-white btn-floating bg-primary position-absolute text-center" role="button" style={{top: 15, right: 15}} onClick={() => { setSelectedId(-1); }}>
                        <span className="d-flex justify-content-center align-items-center w-100 h-100"><i className="fas fa-plus fa-lg"/></span>
                    </span> }
                    <div className="d-flex flex-row ms-2 mb-2 align-items-center">
                        <h5 className="me-1 px-0 py-1 m-0 flex-fill">{t('products')}</h5>
                    </div>
                    <CustomTable columns={[t('name'), t('description'), t('aqprice_short')]}
                        columnWidths={[50,30,20]}
                        columnRenderers={[
                            p => <span>{p.name}</span>,
                            p => <span style={{maxHeight: 75}}>{p.description}</span>,
                            p => <span>{p.aqPrice}</span>,
                        ]}
                        search={(p, text) => p.name.toLocaleUpperCase().includes(text.toLocaleUpperCase())}
                        searchTitle={t('name')}
                        onClick={p => { setSelectedId(p.id); }}
                        activeRows={p => p.id === selectedId}
                        loading={productsLoading}
                        data={data}/>
                </div>
            </div>
        </div>
    )

    return (
        <section className="d-flex flex-column flex-fill w-100 h-100" style={{minWidth: 800}}>
            <div className="d-flex flex-row h-100">
                {isValidNumber(selectedId) && <>
                    <div className="d-flex flex-column col-8">{ProductsTable}</div>
                <div className="d-flex flex-column col-4 ms-3 pe-3">{<ProductEdit productId={selectedId} rights={rights} onAdd={() => { fetchProducts(); setSelectedId(null);}} onEdit={() => fetchProducts()} onClose={() => setSelectedId(null)}/>}</div>
                </>}
                {!isValidNumber(selectedId) && ProductsTable}
            </div>
        </section>
    )
}

const ProductEdit = ({productId, rights, onAdd, onEdit, onClose}) => {
    const [product, setProduct] = useState(null);
    const [name, setName] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [canEditProduct, setCanEditProduct] = useState(false);
    const [canDeleteProduct, setCanDeleteProduct] = useState(false);
    const [changed, setChanged] = useState(false);
    const [deleteProductId, setDeleteProductId] = useState(null);
    const { t } = useTranslation();

    const fetch = () => {
        setError('');
        setChanged(false);
        if (productId >= 0) {
            setLoading(true);
            API.getProductAdm(productId)
                .then(p => { setProduct(p); setName(p.name); })
                .catch(err => setError(err.message))
                .finally(() => setLoading(false));
        }else {
            setProduct({
                name: '', description: '', aqPrice: undefined, deleted: null
            });
        }
    }

    const update = () => {
        setLoading(true);
        API.updateProductAdm(productId, product.name, product.description, product.aqPrice)
            .then(() => { fetch(); onEdit(); })
            .catch(err => setError(err.message))
            .finally(() => setLoading(false));
    }

    const insert = () => {
        setLoading(true);
        API.addProductAdm(product.name, product.description, product.aqPrice)
            .then(() => onAdd())
            .catch(err => setError(err.message))
            .finally(() => setLoading(false));
    }


    useEffect(() => {
        fetch();
        setCanEditProduct(rights?.Products?.Change && productId > 0);
        setCanDeleteProduct(rights?.Products?.Delete && productId > 0);
    }, [productId]);

    useEffect(() => {
        document.querySelectorAll(".form-outline").forEach((formOutline) => {
			new mdb.Input(formOutline).init();
		});

    }, [productId, product, loading]);

    if (!product) return null;

    return (
        <div className="d-flex flex-column overflow-hidden card">
            <button type="button" className="btn-close position-absolute" style={{right: 5, top: 5}} onClick={() => onClose ? onClose() : {}}></button>
            <CustomModal open={deleteProductId !== null} onClose={() => setDeleteProductId(null)}>
                <DeleteConfirm title={t('remove_product')} message={t('remove_product_prompt')} onConfirm={() => {
                    API.deleteProduct(deleteProductId)
                        .then(() => { onEdit(); onClose(); })
                    setDeleteProductId(null);
                }} onCancel={() => setDeleteProductId(null)}/>
            </CustomModal>
            <div className="d-flex flex-column overflow-hidden card-body">
                <div className="d-flex flex-column w-100 justify-content-center overflow-hidden">
                    <i className="fas fa-shopping-cart fa-4x mb-2"/>
                    
                    
                    {loading && (
                        <div className="d-flex w-100 justify-content-center">
                            <div className="spinner-border" role="status">
                                <span className="visually-hidden">{t('loading...')}</span>
                            </div>
                        </div>
                        
                    )}
                    {!loading && <>
                        <h5 className="text-center">{productId == -1 ? t('new_product') : name}</h5>
                        
                        <div className="d-flex flex-column overflow-auto pe-1 pt-2">
                            { (productId !== -1 && !canEditProduct) && <>
                                <ul className="list-group list-group-light p-0">
                                    <li className="list-group-item p-1"><i className="fas fa-circle-info fa-fw text-primary me-1"/><span className="fw-bold me-1">{t('description')}:</span>{product.description}</li>
                                    <li className="list-group-item p-1"><i className="fas fa-coins fa-fw text-primary me-1"/><span className="fw-bold me-1">{t('aqprice')}:</span>{product.aqPrice}</li>
                                </ul>
                            </> }

                            { (productId == -1 || canEditProduct) && <>
                                <div className="d-flex mb-3 flex-row align-items-center">
                                    <i className="fas fa-pen-to-square fa-fw text-primary"/>
                                    <div className="col ms-2">
                                        <div className="form-outline">
                                            <input type="text" name="name" id="name" className="form-control" value={product.name} onInput={ev => { setProduct(p => ({...p, name: ev.target.value})); setChanged(true); }}/>
                                            <label className="form-label" htmlFor="name">{t('name')}</label>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="d-flex mb-3 flex-row align-items-center">
                                    <i className="fas fa-circle-info fa-fw text-primary"/>
                                    <div className="col ms-2">
                                        <div className="form-outline">
                                            <textarea rows={3} type="text" name="address" id="address" className="form-control" value={product.description} onInput={ev => { setProduct(p => ({...p, description: ev.target.value})); setChanged(true); }}/>
                                            <label className="form-label" htmlFor="address">{t('description')}</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex mb-3 flex-row align-items-center">
                                    <i className="fas fa-coins fa-fw text-primary"/>
                                    <div className="col ms-2">
                                        <div className="form-outline">
                                            <input type="number" name="info" id="info" className="form-control" value={product.aqPrice} onInput={ev => { setProduct(p => ({...p, aqPrice: ev.target.value})); setChanged(true); }}/>
                                            <label className="form-label" htmlFor="info">{t('aqprice')}</label>
                                        </div>
                                    </div>
                                </div>
                            </> }

                            

                            { error && <div className="alert alert-danger alert-dismissible fade show mt-2" role="alert">
                                { error }
                            </div> }

                            {productId !== -1 && <>
                                { canEditProduct && <button className="btn btn-primary mb-2" disabled={!changed || loading} onClick={() => update()}>{t('save')}</button> }
                                { canDeleteProduct && <button className="btn btn-danger" disabled={loading} onClick={() => setDeleteProductId(productId)}>{t('delete')}</button> }
                            </>}
                            {productId === -1 && <>
                                <button className="btn btn-primary mt-2" disabled={!changed || loading} onClick={() => insert()}>{t('add')} <i className="far fa-square-plus fa-lg"/></button>
                            </>}
                        </div>
                        

                    </>}
                </div>
            </div>
        </div>
    )

}