import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router"
import API from "../../service/api";
import { AgGridReact } from 'ag-grid-react';
import { Trans, useTranslation } from "react-i18next";
import * as fns from "date-fns"; 
import Lang from "../../service/lang";
import styles from './reports.css';
import Popup from "reactjs-popup";
import WS from "../../service/ws";
import { NavLink } from "react-router-dom";
import { utils, writeFile } from 'xlsx';

const NamesTooltip = (props) => {
    const data = useMemo(
        () => props.api.getDisplayedRowAtIndex(props.rowIndex).data,
        []
      );

    if (props.node.rowPinned) return null;
    
    if (!data.prevNames) return null;
  
    return (
      <div
        className="custom-tooltip"
        style={{ backgroundColor: props.color || '#ececec' }}
      >
        <p><Trans i18nKey="previous_names"/></p>
        {data.prevNames.map((n,idx) => (
            <p key={idx}><span>{n}</span></p>
        ))}
      </div>
    );
  };

const RowFromValue = data => {
    let row = {};
    row.name = data.names[0];
    row.total = {cash: 0, card: 0, total: 0};
    row.idx = data.idx;
    row.isExtra = data.isExtra;
    data.sales.forEach((sales, idx) => {
        row[`i${idx}`] = {cash: sales[0], card: sales[1], total: sales[0]+sales[1]};
        row.total.cash += row[`i${idx}`].cash;
        row.total.card += row[`i${idx}`].card;
        row.total.total += row[`i${idx}`].total;
    });

    if (data.names.length > 1) row.prevNames = data.names.slice(1);
    return row;
}

const GetTotalsRow = (rows, intervalsCount, t) => {
    let totalsRow = {};
    totalsRow.name = `${t('total')} (${rows.length})`;
    totalsRow.total = {cash: 0, card: 0, total: 0};
    totalsRow.hidePercent = true;
    for(let i=0; i<intervalsCount; i++) totalsRow[`i${i}`] = {cash: 0, card: 0, total: 0};

    rows.forEach(r => {
        totalsRow.total.cash += r.total.cash;
        totalsRow.total.card += r.total.card;
        totalsRow.total.total += r.total.total;
        for(let i=0; i<intervalsCount; i++){
            totalsRow[`i${i}`].cash += r[`i${i}`].cash;
            totalsRow[`i${i}`].card += r[`i${i}`].card;
            totalsRow[`i${i}`].total += r[`i${i}`].total;
        }
    });
    return totalsRow;
}

const IntervalColumns = (intervals, resolution, filter, colorScheme) => {
    return intervals.map((int, idx) => {
        let name = '?';
        const d = new Date(int);
        if (resolution == 'day') name = `${d.getDate().toString().padStart(2,'0')}/${(d.getMonth()+1).toString().padStart(2,'0')}`;
        if (resolution == 'month') name = fns.format(d, 'MMMM', { locale: Lang.instance.getCurrentLocale() });
        if (resolution == 'hour') name =  fns.format(d, 'HH:mm', { locale: Lang.instance.getCurrentLocale() });
        return {
            field: `i${idx}`, headerName: name, width: resolution == 'month' ? 130 : 100, resizable: true, sortable: true,
            cellRenderer: ({value, node}) => {
                const cashColor = colorScheme.find(pair => value.cash <= pair[0]);
                const cardColor = colorScheme.find(pair => value.card <= pair[0]);
                const totalColor = colorScheme.find(pair => value.total <= pair[0]);
                return (
                    <div className="d-flex flex-column justify-content-center h-100" style={{fontWeight: node.rowPinned ? 'bold' : ''}}>
                        {(filter.cash || filter.card) && <div className="d-flex flex-row w-100">
                            {filter.cash && <span style={{lineHeight: '1.1em', background: cashColor ? cashColor[1]: ""}} className="flex-fill d-flex justify-content-center">{value.cash}</span>}
                            {filter.card && <span style={{lineHeight: '1.1em', background: cardColor ? cardColor[1]: ""}} className="flex-fill d-flex justify-content-center">{value.card}</span>}
                        </div>}
                        {filter.total && <span style={{lineHeight: '1.1em', background: totalColor ? totalColor[1]: ""}} className={"d-flex flex-row justify-content-center  w-100 " + ((filter.cash || filter.card) ? "text-success":"")}>{value.total}</span>}
                    </div>
                )
            },
            cellStyle: ({value, data}) => {
                return {
                    padding: 0
                }
                // let val = null;
                // if (filter.total) val = value.total;
                // else if (filter.cash && !filter.card) val = value.cash;
                // else if (!filter.cash && filter.card) val = value.card;
                // else if (filter.cash && filter.card) val = value.total;
                // if (val == null) return {};
                // const color = colorScheme.find(pair => val <= pair[0]);

                // return {
                //     backgroundColor: color ? color[1] : ""
                // };
            },
            valueFormatter: ({value}) => `${value.cash}|${value.card}|${value.total}`,
            comparator: (a,b) => a.total - b.total
        }
    });
}

export const SalesReportViewer = ({report, mobile, onNewReportRequest}) => {
    const [rdata] = useState(Object.keys(report.result.values).map(key => report.result.values[key]).map((rr,idx) => RowFromValue({...rr, idx})));
    const { t } = useTranslation();

    const [source] = useState(report.machine ? 'machine' : (report.location ? 'location' : 'group'));
    const [totalsRow] = useState(GetTotalsRow(rdata, report.result.intervals.length, t));
    
    const [gridApi, setGridApi] = useState(null);
    const [filter, setFilter] = useState({
        cash: true, card: true, total: true
    });
    const [context, setContext] = useState(null);
    const grid = useRef();

    const [rowData] = useState(rdata);


    useEffect(() => {
        if (report && gridApi){

            if (mobile) gridApi.setColumnDefs([]);

            gridApi.setColumnDefs([
                { field: 'elementname', valueGetter: ({data}) => data.name, headerName: t('name'), width: 250, resizable: true, sortable: true, pinned: mobile?undefined:'left', tooltipField: 'name', tooltipComponent: NamesTooltip,
                    cellRenderer: ({value, data, node}) => 
                        <span onContextMenu={ev => {ev.preventDefault(); ev.stopPropagation(); onRowElementNameContext({dom: ev.target.parentElement.parentElement.parentElement})} }>
                            {!node.rowPinned && <span className="me-1 text-primary"><i className={"fas fa-"+(source=='group'?'location-dot':'server')}/></span>}
                            <span style={{fontWeight: node.rowPinned ? 'bold' : '',
                                ...(data.isExtra ? {textDecoration: 'line-through', fontStyle: 'italic'} : {})
                            }}>{value}</span>
                            {data.prevNames && <span className="ms-1 text-warning"><i className="fas fa-asterisk fa-xs"/></span>}
                        </span>
                },
                { field: 'total', headerName: t('total'), width: report.resolution == 'month' ? 200 : 170, resizable: true, sortable: true, pinned: mobile?undefined:'right',
                    cellRenderer: ({value, node}) => {
                        const ratio = (filter.total || (filter.cash && filter.card)) ? ( value.total / (totalsRow.total.total / rowData.length ) ) :
                            (filter.cash ? ( value.cash / (totalsRow.total.cash) / rowData.length ) :
                                (filter.card ? ( value.card / ( totalsRow.total.card / rowData.length )) : null)
                            );
                        let color = '';
                        if (ratio < 1){
                            color = `rgba(255,0,0,${0.5 * (1-ratio)})`
                        }else{
                            color = `rgba(0,255,0,${Math.min(0.8, 0.5 * (ratio-1))})`;
                        }

                        return (
                            <div className="d-flex flex-row justify-content-center h-100 w-100 align-items-center px-2" style={{background: node.rowPinned ? '' : color}}>
                                <div className="d-flex flex-column justify-content-center h-100 flex-fill" style={{fontWeight: node.rowPinned ? 'bold' : ''}}>
                                    {(filter.cash || filter.card) && <div className="d-flex flex-row w-100">
                                        {filter.cash && <span style={{lineHeight: '1.1em'}} className="flex-fill d-flex justify-content-center align-items-center"><i className="fas fa-coins text-primary me-1 fa-sm"/>{value.cash}</span>}
                                        {filter.card && <span style={{lineHeight: '1.1em'}} className="flex-fill d-flex justify-content-center align-items-center"><i className="fas fa-credit-card text-primary me-1 fa-sm"/>{value.card}</span>}
                                    </div>}
                                    {filter.total && <span style={{lineHeight: '1.1em'}} className={"d-flex flex-row justify-content-center fw-bold  w-100 " + ((filter.cash || filter.card) ? "text-success":"")}>{value.total}</span>}
                                </div>
                                {(!node.rowPinned && ratio !== null && !value.hidePercent) && <span className="ms-2 text-muted" style={{fontSize: '0.65rem'}}>{(ratio*100).toFixed(1)}%</span>}
                            </div>
                        )
                    },
                    cellStyle: () => ({padding: 0}),
                    valueFormatter: ({value}) => `${value.cash}|${value.card}|${value.total}`,
                    comparator: (a,b) => ((filter.total || (filter.cash && filter.card)) ? a.total : (filter.cash ? a.cash : a.card)) -
                        ((filter.total || (filter.cash && filter.card)) ? b.total : (filter.cash ? b.cash : b.card))
                },
                {
                    headerName: {'month': t('monthly_breakdown'), 'day': t('daily_breakdown'), 'hour': t('hourly_breakdown')}[report.resolution] || '?',
                    children: IntervalColumns(report.result.intervals, report.resolution, filter, report.colorScheme)
                },
            ]);
        }

        if (gridApi){
            const root = document.querySelector('.ag-root-wrapper');
            root.oncontextmenu = ev => {
                setContext(null);
                const header = ev.target.closest('.ag-header-cell');
                if (header){
                    const id = header.getAttribute('col-id');
                    if (id[0] == 'i'){
                        onColumnHeaderContext({dom: header});
                        ev.stopPropagation();
                        ev.preventDefault();
                    }
                }
            }
        }
    }, [gridApi, report, mobile, filter]);

    useEffect(() => {
        if (report && gridApi) {
            gridApi.setRowData(rowData);
        }
    }, [gridApi, report, rowData]);

    const onColumnHeaderContext = ({dom}) => {
        const id = dom.getAttribute('col-id'),
            rect = dom.getBoundingClientRect();

        const startDate = report.result.intervals[Number(id.slice(1))];
        const endDate = report.result.intervals[Number(id.slice(1))+1] || report.endDate;
        const gid = report.group ? report.group.id : undefined,
            lid = report.location ? report.location.id : undefined,
            mid = report.machine ? report.machine.id : undefined;


        setContext({
            x: rect.x + rect.width/2, y: rect.y + rect.height/2 + 10, position: "bottom center",
            onReportRequest: () => { onReportRequest(startDate, endDate, gid, lid, mid); }
        });
    }

    const onRowElementNameContext = ({dom}) => {
        const id = Number(dom.getAttribute('row-id')),
            rect = dom.getBoundingClientRect();
        const elId = Object.keys(report.result.values)[id];
        let gid = report.group ? report.group.id : undefined;
        let lid = report.location ? report.location.id : undefined;
        let mid = report.machine ? report.machine.id : undefined;

        if (!gid) gid = elId;
        else if (!lid) lid = elId;
        else mid = elId;
        setContext({
            x: rect.x + rect.width/2, y: rect.y + rect.height/2 + 10, position: "right center",
            onReportRequest: () => { onReportRequest(report.startDate, report.endDate, gid, lid, mid); }
        })
    }

    const onReportRequest = (startDate, endDate, gid, lid, mid) => {
        API.reportsRequest(startDate, endDate, (new Date()).getTimezoneOffset(), gid, lid, mid)
            .then(({id}) => {
                onNewReportRequest(id);
            });
    }

    const exportToFile = (type) => {
        const sd = new Date(report.startDate);
        const ed = new Date(report.endDate);

        const sourceName = (report.machine ? report.machine.name : (report.location ? report.location.Name : report.group.Name)).replace(/\s+/g, '_').replace(/[^a-zA-Z0-9_\-\.]/g, ''),
            startDate = `${sd.getFullYear()}_${(sd.getMonth()+1).toString().padStart(2, '0')}_${(sd.getDate()).toString().padStart(2, '0')}`,
            endDate = `${ed.getFullYear()}_${(ed.getMonth()+1).toString().padStart(2, '0')}_${(ed.getDate()).toString().padStart(2, '0')}`

        const filename = `${sourceName}_${report.type}_${startDate}-${endDate}`;
        const intervals = report.result.intervals.map(interval => {
            const d = new Date(interval);
            if (report.resolution == 'day') return `${d.getDate().toString().padStart(2,'0')}/${(d.getMonth()+1).toString().padStart(2,'0')}`;
            if (report.resolution == 'month') return fns.format(d, 'MMMM', { locale: Lang.instance.getCurrentLocale() });
            if (report.resolution == 'hour') return fns.format(d, 'HH:mm', { locale: Lang.instance.getCurrentLocale() });
            return '?'
        });
        let data = rowData.map(r => {
            const row = [];
            row.push(r.name);

            intervals.forEach((_, idx) => {
                let vals = [];
                if (filter.cash) vals.push(r[`i${idx}`].cash);
                if (filter.card) vals.push(r[`i${idx}`].card);
                if (filter.total) vals.push(r[`i${idx}`].total);
                row.push(vals.length > 1 ? vals.join('|') : Number(vals[0]))
            });
            let valsTotal = [];
            if (filter.cash) valsTotal.push(r.total.cash);
            if (filter.card) valsTotal.push(r.total.card);
            if (filter.total) valsTotal.push(r.total.total);
            row.push(valsTotal.length > 1 ? valsTotal.join('|') : Number(valsTotal[0]))
            return row;
        });

        const ws = utils.aoa_to_sheet([
            [t('name'), ...intervals, t('total')],
            ...data
        ]);
        const wb = utils.book_new();

        utils.book_append_sheet(wb, ws, "Sheet1");

        if (type == 'excel'){
            writeFile(wb, `${filename}.xlsx`, {type: 'xlsx'});
        }else if (type == 'csv'){
            const csv = utils.sheet_to_csv(ws);
            const blob = new Blob([csv], { type: 'text/plain' });
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = `${filename}.csv`;
            link.click();
            URL.revokeObjectURL(link.href);
        }
    }

    return (
        <div className="d-flex flex-column w-100 h-100 overflow-hidden" style={{background: '#eeeeee'}}>
            {context &&
                <Popup open={true} trigger={<span style={{position: 'fixed', left: context.x, top: context.y}}></span>} position={context.position} onClose={() => setContext(null)}>
                    { close => <div className="d-flex flex-column">
                        <div className="custom-select-item" onClick={() => {context.onReportRequest();close()}}>
                            <i className="fas fa-calendar-days text-primary me-1 fa-fw"/>
                            <span style={{overflow: 'hidden', textOverflow: 'ellipsis'}}>{t('new_report')}</span>
                        </div>
                    </div> }
                    
                </Popup>
            }


            <div className={"d-flex flex-column w-100 px-2 py-1 " + (rowData.length >= (mobile ? -1 : 20) ? 'flex-fill' : '')}>
                
                    

                <span className="d-flex flex-fill align-items-center justify-content-end">
                    <Popup position="bottom right" trigger={
                        <button className="btn btn-link text-primary p-1">
                            <i className="fas fa-download me-2"/>
                            <Trans i18nKey="export"/>
                        </button>
                    }>
                        {close => <div className="d-flex flex-column">
                            <div className="custom-select-item" onClick={() => { close(); exportToFile("excel"); }}>
                                <i className="fas fa-file-excel text-success me-1"/>
                                <span style={{overflow: 'hidden', textOverflow: 'ellipsis'}}>{t('export_to_excel')}</span>
                            </div>
                            <div className="custom-select-item" onClick={() => { close(); exportToFile("csv"); }}>
                                <i className="fas fa-file-csv text-success me-1"/>
                                <span style={{overflow: 'hidden', textOverflow: 'ellipsis'}}>{t('export_to_csv')}</span>
                            </div>
                        </div>
                        }
                    </Popup>
                    <Popup position="bottom right" trigger={
                        <button className="btn btn-link text-primary p-1">
                            <i className="fas fa-filter me-2"/>
                            <Trans i18nKey="filter"/>
                        </button>
                    }>
                        {close => <div className="d-flex flex-column">
                            <div className="form-check align-items-center d-flex">
                                <input className="form-check-input" type="checkbox" checked={filter.cash} id="cash" onChange={_ => setFilter(f => ({...f, cash: !f.cash}))} />
                                <label className="form-check-label" htmlFor="cash">{t('cash_value')}</label>
                            </div>
                            <div className="form-check align-items-center d-flex">
                                <input className="form-check-input" type="checkbox" checked={filter.card} id="card" onChange={_ => setFilter(f => ({...f, card: !f.card}))} />
                                <label className="form-check-label" htmlFor="card">{t('card_value')}</label>
                            </div>
                            <div className="form-check align-items-center d-flex">
                                <input className="form-check-input" type="checkbox" checked={filter.total} id="total" onChange={_ => setFilter(f => ({...f, total: !f.total}))} />
                                <label className="form-check-label" htmlFor="total">{t('total_value')}</label>
                            </div>
                        </div>
                        }
                    </Popup>
                </span>
                <div className={"ag-theme-alpine w-100 h-100"  } style={{}}>
                    <AgGridReact ref={grid}
                        suppressMovableColumns
                        suppressColumnVirtualisation
                        suppressRowClickSelection
                        scrollbarWidth={10}
                        tooltipShowDelay={0}
                        tooltipHideDelay={2000}
                        pinnedBottomRowData={totalsRow ? [totalsRow] : undefined}
                        domLayout={rowData.length >= (mobile ? -1 : 20) ? undefined : 'autoHeight'}
                        rowBuffer={20}
                        onGridReady={event => {setGridApi(event.api); }}
                        >
                    </AgGridReact>
                </div>
                
                
            </div>
            
        </div>
    )

}