import { useEffect } from "react";
import * as mdb from 'mdb-ui-kit'; // lib
import { Tooltip } from 'react-tooltip';
import { useTranslation } from "react-i18next";

export const BoxesList = ({boxes, onBoxSelect}) => {
    const { t } = useTranslation();

    useEffect(() => {
        document.querySelectorAll(".my-tooltip").forEach(el => {
            new mdb.Tooltip(el);
        });
    }, [boxes]);

    if (!boxes || boxes.length === 0) return <span className="text-muted fst-italic w-100 text-center">{t('no_boxes')}</span>;

    

    return (
        <section className="w-100 row m-0 overflow-auto">
            <div className="d-inline-block">
                {boxes.map((d, index) => (
                    <div
                        key={index}
                        className="d-inline-block pt-3 pb-3 ps-1 pe-1 col-12 col-sm-6 col-md-6"
                        onClick={() => onBoxSelect(d)}
                    >
                        <div className={"card d-inline-block w-100 card-hover2 " + 
                                ((d.status?.Offline || d.status?.SensorTrouble || d.status?.Empty) ? "list-group-item-danger" :
                                ((d.status?.LowQuantity) ? "list-group-item-warning" : ""))}>
                            <div
                                className="position-absolute d-flex justify-content-center w-100"
                                style={{ top: 0, marginTop: -10 }}
                            >
                                <span className="badge rounded-pill badge-primary">
                                    {index+1}
                                </span>
                            </div>

                            
                            <div className="card-body p-2">
                                <div className="d-flex justify-content-between px-md-1">
                                    <div
                                        className="text-start flex-fill"
                                        style={{
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                        }}
                                    >
                                        <span className="d-flex flex-row w-100 "
                                            style={{
                                                fontWeight: "bold",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                whiteSpace: "nowrap",
                                            }}
                                        >
                                            {d.productName && <>
                                                <span><i className="fas fa-shopping-basket text-primary me-1" /></span>
                                                {d.productName}
                                            </>}
                                            {!d.productName && <>
                                                <span><i className="fas fa-circle-question text-warning me-1" /></span>
                                                <span className="text-warning">{t('unknown_product')}</span>
                                            </>}
                                            
                                        </span>
                                        <div
                                            className="d-flex flex-row flex-fill"
                                            style={{ whiteSpace: "nowrap" }}
                                        >
                                            { d.price !== undefined && <span className="fw-normal text-success me-2 my-tooltip">
                                                {d.price}  <i className="fas fa-money-bill"/>
                                            </span> }
                                            { d.qnty !== undefined && <span className="fw-normal text-muted my-tooltip">
                                                {d.qnty}  <i className="fas fa-shopping-cart" />
                                            </span> }

                                            <div className="d-flex flex-row justify-content-end flex-fill">
                                                {d.status?.Offline && <>
                                                    <span className="ms-1" data-tooltip-id="offline-tooltip" data-tooltip-content={t('status.offline')}><i className="fas fa-power-off text-danger"/></span>
                                                    <Tooltip id="offline-tooltip" />
                                                </>}
                                                {d.status?.SensorTrouble && <>
                                                    <span className="ms-1" data-tooltip-id="sensor-tooltip" data-tooltip-content={t('status.sensor_issue')}><i className="fas fa-rss text-danger"/></span>
                                                    <Tooltip id="sensor-tooltip" />
                                                </>}
                                                {d.status?.Empty && <>
                                                    <span className="ms-1" data-tooltip-id="empty-tooltip" data-tooltip-content={t('status.empty')}><i className="fas fa-store text-danger"/></span>
                                                    <Tooltip id="empty-tooltip" />
                                                </>}
                                                {d.status?.LowQuantity && <>
                                                    <span className="ms-1" data-tooltip-id="lowqnty-tooltip" data-tooltip-content={t('status.low_quantity')}><i className="fas fa-store text-warning"/></span>
                                                    <Tooltip id="lowqnty-tooltip" />
                                                </>}
                                            </div>
                                        </div>
                                        {/* <p className="mb-0">New Posts</p> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );

}