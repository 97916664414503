import i18n from "i18next";
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';


i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
        lng: 'en',
        fallbackLng: 'en',
        debug: true,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        backend: {
            loadPath: '/locales/{{lng}}/{{ns}}.json'
        },
        supportedLngs: ['en', 'fr', 'ro', 'de', 'es', 'nl'],
        ns: ['common'],
        defaultNS: 'common'
    });


export default i18n;