import { useEffect, useState } from "react";

function getWindowSize() {
    const {innerWidth, innerHeight} = window;
    return {innerWidth, innerHeight};
}

export const TabsViewer = ({id, tabs, fullSize, autoDefault}) => {
    const [touchStart, setTouchStart] = useState(0);
    const [touchEnd, setTouchEnd] = useState(0);
    const [windowSize, setWindowSize] = useState(getWindowSize());
    const [selectedIdx, setSelectedIdx] = useState(Math.max(tabs.findIndex(t => t.default), 0));

    // useEffect(() => {
    //     const idx = tabs.findIndex(t => t.default);
    //     if (idx > -1) setSelectedIdx(idx);
    // }, [tabs]);

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }
        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    function handleTouchStart(e) {
        if (fullSize || windowSize.innerHeight >= 1000) return;
        setTouchStart(e.targetTouches[0].clientX);
        setTouchEnd(e.targetTouches[0].clientX);
    }
    
    function handleTouchMove(e) {
        if (fullSize || windowSize.innerHeight >= 1000) return;
        setTouchEnd(e.targetTouches[0].clientX);
    }
    
    function handleTouchEnd() {
        if (fullSize || windowSize.innerHeight >= 1000) return;
        const links = tabs.map(tab => Array.from(document.querySelectorAll(`#${tab.name}-link.nav-link`))[0]);
        const selectedIndex = links.findIndex(l => l.classList.contains('active'));
        let nextTabIndex = selectedIndex;

        if (touchStart - touchEnd > 100 && selectedIndex + 1 < links.length && !tabs[selectedIndex].disableSwipe) nextTabIndex = selectedIndex+1;
        if (touchStart - touchEnd < -100 && selectedIndex > 0 && !tabs[selectedIndex].disableSwipe) nextTabIndex = selectedIndex-1;

        if (nextTabIndex !== selectedIndex){
            links[nextTabIndex].click();
            
        }

        setTouchStart(0);
        setTouchEnd(0);
    }

    return (
        <>
            <ul className={"nav nav-tabs"+(windowSize.innerWidth < 1000 ? ' ':'')} id={id} role="tablist">
                {tabs.map((tab, index) => (
                    <li key={tab.name} className="nav-item" role="presentation">
                        {(windowSize.innerWidth>= 1000 || fullSize) &&
                            <span
                                className={"nav-link p-2" + (index == selectedIdx ? ' active' : '')}
                                id={tab.name + '-link'}
                                onClick={() => setSelectedIdx(index)}
                            >
                                {tab.icon &&<i className={`${tab.icon} fa-fw ${tab.title ? ' me-2' : ''}`}/> }
                                {tab.title}
                            </span>
                        }
                        {(windowSize.innerWidth < 1000 && !fullSize) && 
                            <span
                                className={"nav-link border-0" + (index == selectedIdx ? ' active' : '')}
                                style={{opacity: 0.5, padding: '2px', backgroundColor: 'transparent'}}
                                id={tab.name + '-link'}
                                onClick={() => setSelectedIdx(index)}
                            >
                                <i className="fas fa-circle fa-xs" style={{fontSize: '0.5em'}}/>
                                
                                
                            </span>
                        }
                        
                    </li>
                ))}
            </ul>

            <div className={"d-flex flex-fill tab-content overflow-auto flex-fill w-100 h-100"+(windowSize.innerWidth >= 1000 ? ' mt-1':'')}
                onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} onTouchEnd={handleTouchEnd}
                id="grouptabs-content">
                
                {tabs.map((tab, index) => (
                    <div
                        key={tab.name}
                        className={"tab-pane fade w-100 h-100 " +  (index == selectedIdx ? ' show active' : '')}
                        id={tab.name}
                    >
                        { tab.component }
                    </div>
                ))}
            </div>
        </>
    )
}