import { useCallback, useEffect, useState } from "react"
import { Navigate, Route, Routes, useNavigate, useOutletContext, useParams } from "react-router";
import API from "../../../service/api";
import { NavLink } from "react-router-dom";
import { CustomSelect } from "../../../utils/CustomSelect";
import { Input } from 'mdb-ui-kit';
import { PageNavigation } from "../../../utils/PageNavigation";
import { LocationsList } from "./LocationsList";
import { TabsViewer } from "../../../utils/TabsViewer";
import { LocationViewer } from "../location/LocationViewer";
import { LogViewer } from "../../other/LogViewer";
import { LocationsGIS } from "./LocationsGIS";
import WS from "../../../service/ws";
import Popup from 'reactjs-popup';
import { useTranslation } from "react-i18next";
import { ReportsSelectorPopup } from "../ReportsSelectorPopup";


function getWindowSize() {
    const {innerWidth, innerHeight} = window;
    return {innerWidth, innerHeight};
}

export const GroupViewer = ({onLoad, group, groups, onGroupSelect}) => {
    const navigate = useNavigate();
    const [windowSize, setWindowSize] = useState(getWindowSize());

    const [updateGroup, setUpdateGroup] = useState(null);
    const [updateLocation, setUpdateLocation] = useState(null);
    const { id } = useParams();
    const [groupListener, setGroupListener] = useState(null);
    const [fetching, setFetching] = useState(false);
    const [needsToFetch, setNeedsToFetch] = useState(true);
    const [data, setData] = useState([]);
    const [value, setValue] = useState(null);
    const [error, setError] = useState('');
    const [rights, setRights] = useState({});
    const { t } = useTranslation();

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }
        window.addEventListener('resize', handleWindowResize);
        handleWindowResize();

        const groupUpdate = ev => {
            setUpdateGroup(ev[id]);
        }

        const locationUpdate = ev => {
            setUpdateLocation(ev);
        }

        WS.listen('group_update', groupUpdate);
        WS.listen('group_location_update', locationUpdate);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
            WS.unlisten('group_update', groupUpdate);
            WS.unlisten('group_location_update', locationUpdate);
            if (groupListener) WS.emit('group_leave', {id: groupListener});
        };
    }, [groupListener]);

    useEffect(() => {
        onLoad(id);
        setNeedsToFetch(true);
    }, [id]);

    const fetch = async () => {
        setNeedsToFetch(false);
        setFetching(true);
        API.getLocationsByGroupID(id)
            .then(data => {
                setRights(data.userRights);
                setData(data.locations || []);
                setValue(data.value);
                if (groupListener) WS.emit('group_leave', {id: groupListener});
                setGroupListener(id);
            })
            .catch(err => {
                setError(err.message);
            })
            .finally(() => {
                setFetching(false);
            });

    }

    useEffect(() => {
        if (data && updateLocation) {
            let id = -1;
            Object.keys(updateLocation).forEach(key => id = Number(key));
            if (typeof id !== 'number' || id < 0) return console.error(`Invalid location update in GroupViewer!`);

            const updatedLocation = data.find(l => l.id == id);

            Object.assign(updatedLocation, updateLocation[id]);
            setUpdateLocation(null);
            setData([...data]);
        }
    }, [data, updateLocation]);

    useEffect(() => {
		if (updateGroup) {
            const value = updateGroup.value;
			setUpdateGroup(null);
            if (value == undefined) return;
            setValue(value);
		}
	}, [updateGroup]);

    useEffect(() => {
        if (needsToFetch && !fetching) fetch();
    }, [needsToFetch, fetching]);

    const onLocationSelect = useCallback(id => {
        const location = data.find(el => el.id == id);
        if (location.singleMachineId) navigate(`locations/${id}/machines/${location.singleMachineId}`);
        else navigate('locations/'+id);
    }, [data]);

    if (!group) return (
        <div className="d-flex justify-content-center pt-2 w-100 h-100">
            <div className="spinner-border" role="status">
                <span className="visually-hidden">{t('loading...')}</span>
            </div>
        </div>
        
    );

    if (error) return (
        <div className="d-flex flex-row justify-content-center align-items-center w-100" style={{height: 'fit-content'}}>
            <div className="alert alert-danger alert-dismissible fade show m-3" role="alert">
                { error }
            </div>
        </div>
        
    );
    return (
        <Routes>
            <Route path="" element={
                <div className="d-flex flex-column flex-fill justify-content-center align-items-center" style={{overflowX: 'hidden'}}>
                    <nav className="navbar navbar-expand-lg navbar-light p-1 w-100 border-top" style={{background: '#fc8c0342'}}>
                        <div className="container-fluid justify-content-between">
                            {windowSize.innerWidth >= 600 &&
                                <div className="d-flex flex-row flex-fill align-items-center text-center"
                                    style={{marginLeft: 20, marginRight: 0, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>
                                    <div className="flex-fill"></div>
                                    <Popup key={id} position="bottom center" trigger={
                                        <span role="button" className="d-flex flex-row align-items-center">
                                            <i className="fas fa-layer-group text-primary fa-lg me-1"/>
                                            <span style={{fontSize: '1rem', fontWeight: 'bold'}}>{group.gType == 1 && <span><i className="fas fa-bookmark text-warning me-1"/></span>}{group.Name}</span>
                                            <div className="" style={{}}>
                                                <span><i className="fas fa-bars fa-fw fa-sm"/></span>
                                            </div>
                                        </span>
                                    }>
                                        {close => <div className="d-flex flex-column">
                                            {(!groups || groups.length == 0) && <div className="custom-select-item">{t('no_groups')}</div>}
                                            {groups && groups.map(g => (
                                                <div key={g.id} className={"custom-select-item" + (g.gType == 1 ? ' list-group-item-warning fst-italic warning-row':'')} onClick={() => navigate('/g/'+g.id)}>
                                                    <i className="fas fa-layer-group text-primary me-1"/>
                                                    <span style={{overflow: 'hidden', textOverflow: 'ellipsis'}}>{g.gType == 1 && <span><i className="fas fa-bookmark text-warning me-1"/></span>}{g.Name}</span>
                                                </div>
                                            ))}
                                        </div>
                                        }
                                    </Popup>
                                    
                                    
                                    <div className="flex-fill"></div>
                                    <div className="d-flex flex-row position-absolute align-items-center" style={{right: 5}}>
                                        {value !== undefined &&
                                            <ReportsSelectorPopup gid={id} disabled={!rights?.Events?.SalesEvents || !rights?.Locations?.View} mobile={windowSize.innerWidth<600}
                                                trigger={<span className="badge badge-success align-items-center"><i className="fas fa-hand-holding-dollar fa-lg fa-fw text-primary me-1"/>{value}</span>}/> 
                                        }
                                    </div>
                                </div>
                            }
                            {windowSize.innerWidth < 600 &&
                                <div className="d-flex flex-row flex-fill align-items-center text-center"
                                    style={{overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>
                                    
                                    <Popup key={id} position="bottom left" trigger={
                                        <span role="button" className="d-flex flex-row align-items-center" style={{marginRight: 80}}>
                                            <i className="fas fa-layer-group text-primary fa-lg me-1"/>
                                        <span style={{fontSize: '1rem', fontWeight: 'bold'}}>{group.gType == 1 && <span><i className="fas fa-bookmark text-warning me-1"/></span>}{group.Name}</span>
                                            <div className="" style={{}}>
                                                <span><i className="fas fa-bars fa-fw fa-sm"/></span>
                                            </div>
                                        </span>
                                    }>
                                        {close => <div className="d-flex flex-column">
                                            {(!groups || groups.length == 0) && <div className="custom-select-item">{t('no_groups')}</div>}
                                            {groups && groups.map(g => (
                                                <div key={g.id} className={"custom-select-item" + (g.gType == 1 ? ' list-group-item-warning fst-italic warning-row':'')} onClick={() => navigate('../'+g.id)}>
                                                    <i className="fas fa-layer-group text-primary me-1"/>
                                                    <span style={{overflow: 'hidden', textOverflow: 'ellipsis'}}>{g.gType == 1 && <span><i className="fas fa-bookmark text-warning me-1"/></span>}{g.Name}</span>
                                                </div>
                                            ))}
                                        </div>
                                        }
                                    </Popup>
                                    
                                    
                                    <div className="flex-fill"></div>
                                    <div className="d-flex flex-row position-absolute align-items-center" style={{right: 5}}>
                                        {value !== undefined &&
                                            <ReportsSelectorPopup gid={id} disabled={!rights?.Events?.SalesEvents || !rights?.Locations?.View} mobile={windowSize.innerWidth<600}
                                                trigger={<span className="badge badge-success align-items-center"><i className="fas fa-hand-holding-dollar fa-lg fa-fw text-primary me-1"/>{value}</span>}/> 
                                        }
                                    </div>
                                </div>
                            }
                            
                            
                        </div>
                    </nav>

                    {windowSize.innerWidth < 1000 &&
                        <TabsViewer
                            id={'group'}
                            tabs={[
                                {
                                    name: 'geo', title: t('geo'), icon: 'fas fa-globe', disableSwipe: true,
                                    component: (
                                        <div className="d-flex flex-column w-100 h-100 align-items-center">
                                            <LocationsGIS locations={data} onLocationClick={location => onLocationSelect(location.id)}/>
                                            <button className="btn btn-primary mb-1 mt-1 position-absolute d-flex align-items-center justify-content-center" style={{width: 250}} onClick={() => {
                                                const el = document.querySelector('#locations-link.nav-link');
                                                if (el) el.click();
                                            }}><span>{t('back_to_locations_list')}</span><i className="fas fa-arrow-right-long fa-xl position-absolute" style={{right: 8}}/> </button>
                                        </div>
                                    )
                                },
                                {
                                    name: 'locations', title: t('locations'), icon: 'fas fa-map-marker-alt',
                                    default: true,
                                    component: <LocationsList
                                        locations={data}
                                        onLocationSelect={id => onLocationSelect(id)}
                                    />
                                },
                                {
                                    name: 'logs', title: t('logs'), icon: 'fas fa-bell',
                                    component: <LogViewer key={id} groupId={id}/>
                                }
                            ]}
                        />
                    }
                    {windowSize.innerWidth >= 1000 &&
                        <div className="d-flex flex-row w-100 flex-fill overflow-hidden">
                            <div className="d-flex flex-column align-items-center col-8">
                                <TabsViewer
                                    id={'group'}
                                    tabs={[
                                        {
                                            name: 'geo', title: t('geo'), icon: 'fas fa-globe', disableSwipe: true,
                                            component: <LocationsGIS locations={data} onLocationClick={location => { onLocationSelect(location.id); } }/>
                                        },
                                        {
                                            name: 'locations', title: t('locations'), icon: 'fas fa-map-marker-alt',
                                            default: true,
                                            component: (
                                                <LocationsList
                                                    locations={data}
                                                    onLocationSelect={id => onLocationSelect(id)}
                                                />
                                            )
                                        }
                                    ]}
                                />
                            </div>
                            <div className="d-flex flex-column flex-fill col-4">
                                <h5 className="w-100 pe-2" style={{textAlignLast: 'center'}}>{t('logs')}</h5>
                                <div className="d-flex flex-column flex-fill overflow-auto pe-2">
                                    <LogViewer key={id} groupId={id}/>
                                </div>
                                
                            </div>
                            
                        </div>
                        
                    }
                </div>
            }/>
            <Route path="locations/:id/*" element={<LocationViewer groupID={id} groupName={group.Name} rights={rights}/>}/>
            <Route path="*" element={<Navigate to=""/>}/>
        </Routes>
        
    )
}