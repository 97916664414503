
const IconRadioItem = ({name, icon, active, onSelect, last, small}) => {

    return (
        <div role="button" onClick={onSelect} className={"d-flex flex-column align-items-center " + (active ? "text-primary":"") + (last ? "":" me-3")}>
            <div className={"icon-radio-item " + (small ? "p-3":"p-4")  + (active ? " border-primary":"")} style={{}}>
                <span>
                    {small && <span><i className={icon + ' fa-fw fa-xl'}/></span> }
                    {!small && <span><i className={icon + ' fa-fw fa-2x'}/></span> }
                </span>
            </div>
            <h6>{name}</h6>
        </div>
        
    )
}


export const IconRadioSelector = ({items, selected, onSelect, vertical, small}) => {
    return (
        <div className="d-flex flex-row">
            {items.map((it,idx) => (
                <IconRadioItem key={it.id} {...it} small={small} active={it.id === selected} onSelect={() => onSelect(it.id)} last={idx===items.length-1}/>
            ))}
        </div>
    )
}