import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom"

function getWindowSize() {
    const {innerWidth, innerHeight} = window;
    return {innerWidth, innerHeight};
  }

export const ProfileHeader = () => {
    const { t } = useTranslation();

    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light p-0 w-100">
            <div className="container-fluid justify-content-between p-2">
                    <NavLink
                        to={'/'}
                        className="nav-link d-flex align-items-center user-select-none cursor-pointer position-absolute ps-2"
                        style={{ cursor: "pointer"}}
                    >
                        <div className="d-flex align-items-center pe-1">
                            <i
                                className="fas fa-arrow-left nav-link"
                                style={{fontSize: "1.8rem" }}
                            />
                        </div>
                    </NavLink>

                <span className="d-flex flex-fill justify-content-center align-items-center">
                    <i className="fas fa-tools fa-lg me-1"/>
                    <h5 style={{lineHeight: 1, padding: 0, margin: 0, fontWeight: ''}}>{t('settings')}</h5>

                </span>
            </div>
        </nav>
    )
}