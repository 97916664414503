import { useEffect, useRef, useState, } from "react"
import {useNavigate} from 'react-router';
import { NavLink } from "react-router-dom";
import * as mdb from 'mdb-ui-kit'; // lib
import API from "../../service/api";
import Popup from 'reactjs-popup';
import { useTranslation } from "react-i18next";
import Lang from "../../service/lang";



function getWindowSize() {
    const {innerWidth, innerHeight} = window;
    return {innerWidth, innerHeight};
  }

  const CountryByLanguage = {
    'en': 'gb',
    'ro': 'ro',
    'fr': 'fr',
    'de': 'de',
    'es': 'es',
    'nl': 'nl'
}

const MainHeader = ({windowSize, userName, onMobileSearch, onQR, onHome, onHelp}) => {
    const [searchText, setSearchText] = useState('');
    const [notFound, setNotFound] = useState(false);
    const [lang, setLang] = useState('');
    const [languages, setLanguages] = useState([]);
    const navigate = useNavigate();
    const { t } = useTranslation();

    function getUserName(name){
        const n = name.split(' ')[0];
        return n.length <= 8 ? n : (n.slice(0, 7)+'...');
    }

    useEffect(() => {
        setLang(Lang.instance.getCurrent());
        const langSetter = l => setLang(l);
        Lang.instance.addListener(langSetter);

        return () => {
            Lang.instance.removeListener(langSetter);
        }
        
    }, []);

    useEffect(() => {
        setLanguages(Lang.instance.getAll().filter(l => l !== lang));
    }, [lang]);

    useEffect(() => {
        if (windowSize.innerWidth >= 800) {
            document.querySelectorAll(".form-outline").forEach((formOutline) => {
                new mdb.Input(formOutline).init();
            });
        }
    }, [windowSize]);

    const search = () => {
        API.search(searchText)
            .then(data => {
                if (data.length == 0) setNotFound(true);
                if (data.length > 0) {
                    const p = data[0];
                    if (p.machineId) navigate(`../g/${p.groupId}/locations/${p.locationId}/machines/${p.machineId}`);
                    else if (p.locationId) navigate(`../g/${p.groupId}/locations/${p.locationId}`);
                    else if (p.groupId) navigate(`../g/${p.groupId}`);
                }
            })
            .catch(err => {
                console.error(err);
            });
    }

    return (
        <div className="container-fluid justify-content-between">
            <span role="button" onClick={() => onHome()} className="nav-link d-flex align-items-center p-1 user-select-none cursor-pointer" style={{cursor: 'pointer'}}>
                <div className="d-flex align-items-center pe-1">
                    <i className="fas fa-cubes" style={{color: "#fc8c03", fontSize: '1.8rem'}}/>
                    <span className="h5 fw-bold mb-0" style={{color: '#e89328', textShadow: '1px 1px 1px rgba(0,0,0,0.1)'}}>
                        {windowSize.innerWidth < 800 && 'SCM'}
                        {windowSize.innerWidth >= 800 && 'Smart Capsule Machine'}
                    </span>
                </div>
            </span>
            <span className="d-flex flex-fill"></span>
            {windowSize.innerWidth >= 800 &&
                <div className="container-fluid justify-content-between d-flex flex-row align-items-center" style={{width: 300}}>
                    {/* <span role="button" className="me-2" onClick={() => window.open(`//${window.location.host}/reports`)}><i className="fas fa-chart-line"/></span> */}
                    { (window.location.protocol === 'https:' || window.location.hostname === 'localhost') && <span role="button" className="me-2" onClick={() => onQR()}><i className="fas fa-camera"/></span> }
                    
                    <div className="input-group form-outline" style={{height: 30}}>
                        <input type="search" id="form1" className={`form-control${notFound ? ' is-invalid' : ''}`} style={{minHeight: 1, height: 30}} value={searchText}
                            onChange={ev => { setSearchText(ev.currentTarget.value); setNotFound(false); }} 
                            onKeyDown={ev => {
                                if (ev.key == 'Enter') { ev.preventDefault(); ev.stopPropagation(); search(); }
                            }} autoComplete="off"/>
                        <button type="button" className="btn btn-primary" onClick={() => search()}>
                            <i className="fas fa-search"></i>
                        </button>
                    </div>
                    
                </div>
            }
            {windowSize.innerWidth < 800 && <div>
                {/* <span role="button" className="me-3" onClick={() => window.open(`//${window.location.host}/reports`)}><i className="fas fa-chart-line fa-lg"/></span> */}
                { (window.location.protocol === 'https:' || window.location.hostname === 'localhost') && <span role="button" className="me-3" onClick={() => onQR()}><i className="fas fa-camera fa-lg"/></span> }
                    <span role="button" className="me-3" onClick={() => onMobileSearch()}><i className="fas fa-magnifying-glass fa-lg"/></span>
                </div>
            }

            <Popup key={lang} position="bottom right" trigger={
                <div role="button">
                    <span className={" flag-icon flag-icon-"+CountryByLanguage[lang]||'gb'}/>
                    <span className="me-1"><i className="fas fa-caret-down"/></span>
                </div>
            }>
                {close => languages.map(l => (
                    <div key={l} className="custom-select-item" onClick={() => {close(); Lang.instance.changeLanguage(l); }}>
                        <span className={"me-1 flag-icon flag-icon-"+(CountryByLanguage[l]||'gb')}/>
                        <span style={{overflow: 'hidden', textOverflow: 'ellipsis'}}>{t('language_title.'+l)}</span>
                    </div>
                ))}
            </Popup>

            <span role="button" className="me-2" onClick={() => onHelp()}><i className="fas fa-circle-question text-primary fa-lg"/></span>

            <NavLink to="/profile" className={({isActive}) => "nav-item ps-2 user-select-none  " + (isActive ? 'border-bottom border-2 border-primary rounded-top text-primary' : 'border-start border-1 text-dark')} style={{cursor: 'pointer'}}>
                <span className="nav-link d-flex align-items-center user-select-none">
                    <span><i className="fas fa-user-circle fa-xl"></i></span>
                    {windowSize.innerWidth > 1000 && <strong className="ms-1">{getUserName(userName)}</strong>}
                </span>
            </NavLink>
        </div>
    );
};



export const HeaderBar = ({userName, onQR, onHome, onHelp}) => {
    const [windowSize, setWindowSize] = useState(getWindowSize());
    const [mobileSearch, setMobileSearch] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [notFound, setNotFound] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    useEffect(() => {
        document.querySelectorAll(".form-outline").forEach((formOutline) => {
            new mdb.Input(formOutline).init();
        });
    }, [mobileSearch]);

    const search = () => {
        API.search(searchText)
            .then(data => {
                if (data.length == 0) setNotFound(true);
                if (data.length > 0) {
                    const p = data[0];
                    if (p.machineId) navigate(`../g/${p.groupId}/locations/${p.locationId}/machines/${p.machineId}`);
                    else if (p.locationId) navigate(`../g/${p.groupId}/locations/${p.locationId}`);
                    else if (p.groupId) navigate(`../g/${p.groupId}`);
                }
            })
            .catch(err => {
                console.error(err);
            });
    }

    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light p-0 w-100">
            {!mobileSearch &&
                <MainHeader windowSize={windowSize} userName={userName} onMobileSearch={() => setMobileSearch(true)} onQR={onQR} onHome={onHome} onHelp={onHelp}/>
            }
            {mobileSearch &&
                <div className="d-flex flex-row w-100 py-1">
                    <div className="input-group form-outline d-flex flex-fill w-auto ms-2" style={{height: 35}}>
                        <input type="search" id="form1" className={`form-control${notFound ? ' is-invalid' : ''}`} style={{}} value={searchText}
                            onChange={ev => { setSearchText(ev.currentTarget.value); setNotFound(false); }} 
                            onKeyDown={ev => {
                                if (ev.key == 'Enter') { ev.preventDefault(); ev.stopPropagation(); search(); }
                            }} autoComplete="off" />
                        <button type="button" className="btn btn-primary" onClick={() => search()}>
                            <i className="fas fa-search"></i>
                        </button>
                    </div>
                    <span role="button" className="px-3 d-flex justify-content-center align-items-center" onClick={() => setMobileSearch(false)}><i className="fas fa-x fa-lg"/></span>
                </div>
            }
			
		</nav>
    )
}