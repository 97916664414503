import { useEffect, useState } from "react"
import * as mdb from 'mdb-ui-kit'; // lib
import { useTranslation } from "react-i18next";
import { CustomModal } from "../../../other/CustomModal";
import API from "../../../../service/api";

export const ChangePasswordModal = ({open, onClose}) => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const { t } = useTranslation();

    useEffect(() => {
        document.querySelectorAll(".form-outline").forEach((formOutline) => {
			new mdb.Input(formOutline).init();
		});
    }, []);

    const submit = () => {
        API.updateUser(undefined, undefined, undefined, undefined, password)
            .then(() => { onClose(); API.logout(); })
            .catch(err => setError(err.message));
    }

    return (
        <CustomModal open={open} onClose={() => onClose()}>
            <div className="px-2 overflow-auto" style={{minWidth: 250, maxWidth: 400, width: '100vw'}}>
                <div className="card">
                    <div className="card-body overflow-auto" style={{maxHeight: 500}}>
                        <h5 className="ms-2 mb-3">{t('change_password')}</h5>

                        <div className="d-flex mb-2 flex-row align-items-center">
                            <i className="fas fa-key fa-fw"/>
                            <div className="col ms-2">
                                <div className="form-outline">
                                    <input type="password" name="password" id="password" className="form-control" value={password} onChange={ev => setPassword(ev.currentTarget.value)}/>
                                    <label className="form-label" htmlFor="password">{t('setpassword')}</label>
                                </div>
                            </div>
                        </div>

                        <div className="d-flex mb-2 flex-row align-items-center">
                            <i className="fas fa-key fa-fw"/>
                            <div className="col ms-2">
                                <div className="form-outline">
                                    <input type="password" name="confirmPassword" id="confirmPassword" className="form-control" value={confirmPassword} onChange={ev => setConfirmPassword(ev.currentTarget.value)}/>
                                    <label className="form-label" htmlFor="confirmPassword">{t('confirmpassword')}</label>
                                </div>
                            </div>
                        </div>

                        {error && <div className="alert alert-danger alert-dismissible fade show mt-2 mb-1 p-3 w-100" role="alert">
                            { error }
                        </div>}

                        <button className="btn btn-primary" disabled={password.length == 0 || password !== confirmPassword} onClick={submit}>{t('change')}</button>
                    </div>
                </div>
            </div>
        </CustomModal>
    )
}