

export const StatusIcon = ({count, statusId, title, icon, priority}) => {

    return (

        <span className={"position-relative " + (count > 1 ? 'me-1' : 'me-0')}
            data-tooltip-id={statusId+"-tooltip"} title={title}
            onClick={ev => {ev.preventDefault(); ev.stopPropagation(); return false;}}>
                <i className={icon+' me-1 text-'+priority}/>
                {count > 1 && 
                    <span className={`position-absolute bg-${priority} d-flex justify-content-center align-items-center text-white`}
                        style={{left: 7, top: 12, lineHeight: 1, borderRadius: 14, height: 14, width: 14, padding: 3, fontSize: '0.6em'}}>{count}</span> }
        </span>
    )
}