import { useEffect, useState } from "react"
import * as mdb from 'mdb-ui-kit'; // lib
import CustomTable from "../../other/CustomTable";
import API from "../../../service/api";
import { DeleteConfirm } from "../../other/DeleteConfirm";
import { CustomModal } from "../../other/CustomModal";
import { CustomSelect } from "../../../utils/CustomSelect";
import { useTranslation } from "react-i18next";
import { UserInviteModal } from "./modal/UserInviteModal";
import Lang from "../../../service/lang";

export const UsersPage = ({userData, rights, onUserUpdate}) => {

    const [users, setUsers] = useState(null);
    const [usersLoading, setUsersLoading] = useState(false);
    const [userArea, setUserArea] = useState(null); //null, editUser, addUser
    const [editUserId, setEditUserId] = useState(null);
    const [deleteUserId, setDeleteUserId] = useState(null);
    const [inviteUser, setInviteUser] = useState(false);
    const { t } = useTranslation();

    const UsersTableArea = rights?.Users?.View ? (
        <div className="d-flex flex-fill flex-column overflow-hidden">
            <div className="d-flex flex-column overflow-hidden card">
                <div className="d-flex flex-column card-body overflow-hidden">
                    <div className="d-flex flex-row ms-2 mb-2 align-items-center">
                        <h5 className="me-1 px-0 py-1 m-0 flex-fill">{t('users')}</h5>
                        { rights?.Users?.Add && <button type="button" className="btn btn-secondary " onClick={() => { setInviteUser(true); }}>{t('invite')}</button> }
                    </div>
                    <CustomTable columns={[].includes(userArea) ? [t('name')] : [t('name'), t('email'), t('permissions')]}
                        columnWidths={[].includes(userArea) ? [100] : [31, 41, 28]}
                        columnRenderers={[
                            u => <span>{u.pending && <i className="fas fa-hourglass text-primary me-1"/>}{u.Name || t('pending_invitation')}</span>,
                            u => <span>{u.mail}</span>,
                            u => <span>{u.rights?.Name || '?'}</span>
                        ]}
                        search={(u, text) => (u.Name + u.mail + (u.rights ? u.rights.Name : '')).toLocaleUpperCase().includes(text.toLocaleUpperCase())}
                        searchTitle={`${t('name')}, ${t('email')}, ${t('permissions')}`}
                        onClick={u => { setUserArea('editUser'); setEditUserId(u.id); }}
                        activeRows={u => u.id === editUserId}
                        loading={usersLoading}
                        rowClassName={u => u.pending ? 'list-group-item-warning fst-italic warning-row' : ''}
                        data={users||[]}/>
                </div>
            </div>
        </div>
    ) : null;



    const fetchUsers = () => {
        if (rights?.Users?.View){
            setUsersLoading(true);
            API.companyUsers()
                .then(u => setUsers(u))
                .finally(() => setUsersLoading(false));
        }
    }

    useEffect(() => {
        fetchUsers();
    }, []);

    return (
        <section className="d-flex flex-column flex-fill w-100" style={{minWidth: 800}}>
            <div className="d-flex flex-row mb-3">
                
                {userArea === 'editUser' && editUserId && (
                    <div className="d-flex flex-row flex-fill">
                        <div className="d-flex col-7">
                            { UsersTableArea }
                        </div>
                        <div className="d-flex ms-3 col-5">
                            <div className="d-flex flex-column flex-fill me-3">
                                <UserEdit userId={editUserId} companyId={userData.company.id} rights={rights} self={userData.id === editUserId} onClose={() => { setEditUserId(null); setUserArea(null) }} onEdit={() => fetchUsers()} onUserUpdate={onUserUpdate} onDelete={() => setDeleteUserId(editUserId)}/>
                            </div>
                        </div>
                    </div>
                )}
                {/* {userArea === 'inviteUser' && (
                    <div className="d-flex flex-row flex-fill">
                        <div className="d-flex col-7">
                            { UsersTableArea }
                        </div>
                        <div className="d-flex ms-3 col-5">
                            <div className="d-flex flex-column flex-fill me-3">
                                <UserInvite companyId={userData.company.id} onClose={() => setUserArea(null)} />
                            </div>
                        </div>
                    </div>
                )} */}
                {inviteUser &&
                    <UserInviteModal companyId={userData.company.id} open={true} onClose={() => setInviteUser(false)} onInviteDone={() => fetchUsers()}/>
                }
                {(userArea === null && users) && UsersTableArea}
            </div>

        </section>
    )
}

const UserEdit = ({userId, companyId, rights, self, onEdit, onUserUpdate, onClose}) => {
    const [user, setUser] = useState(null);
    const [userPermissionsId, setUserPermissionsId] = useState(-1);
    const [name, setName] = useState('');
    const [changed, setChanged] = useState(false);
    const [loading, setLoading] = useState(false);
    const [canEditPermissions, setCanEditPermissions] = useState(false);
    const [canDeleteUser, setCanDeleteUser] = useState(rights?.Users?.Delete);
    const [permissions, setPermissions] = useState([]);
    const [deleteUserId, setDeleteUserId] = useState(null);
    const [error, setError] = useState('');
    const { t } = useTranslation();

    const fetch = () => {
        setChanged(false);
        setUserPermissionsId(-1);

        API.getCompanyUser(userId)
            .then(u => {
                API.getAllRights().then(result => {
                    const r = result.rights;
                    setPermissions(r);
                    setUserPermissionsId(u.rights?.id || -1);
                    setUser(u);
                    setName(u.Name);
                });
            });
    }

    useEffect(() => {
        setCanEditPermissions(rights?.Users?.Change && rights?.Users?.ManageRights);
        setCanDeleteUser(rights?.Users?.Delete);
    }, [rights]);

    useEffect(() => {
        fetch();
        
    }, [userId]);

    useEffect(() => {
        document.querySelectorAll(".form-outline").forEach((formOutline) => {
			new mdb.Input(formOutline).init();
		});
    }, [user]);

    const update = async () => {
        setLoading(true);
        try {
            await API.updateOtherUser(userId, user.Name, user.phone, userPermissionsId);
            onEdit();
            fetch();
        }catch(err){
            setError(err.message);
        }finally{
            setLoading(false);
        };
    }

    if (!user) return <></>

    return (
        <div className={"d-flex flex-column card overflow-hidden "} style={{minWidth: 300}}>
            <CustomModal open={deleteUserId !== null} onClose={() => setDeleteUserId(null)}>
                <DeleteConfirm title={t('remove_user')} message={t('remove_user_from_company_prompt')} onConfirm={() => {
                    API.removeUserFromCompany(deleteUserId)
                        .then(() => {
                            if (self) {
                                onUserUpdate();
                            }else {
                                onEdit();
                            }
                            onClose();
                            
                        })
                        .catch(err => setError(err.message));
                    setDeleteUserId(null);
                }} onCancel={() => setDeleteUserId(null)}/>
            </CustomModal>
            <div className="d-flex flex-column card-body overflow-hidden">
                
                <div className="d-flex flex-column overflow-auto pe-1">
                    <div className="d-flex flex-column w-100 justify-content-center text-center">
                        <i className="fas fa-circle-user fa-4x mb-2"/>
                        {user.pending && <h5 className="fst-italic">{t('pending_invitation')}</h5>}
                        {!user.pending && <h5>{name}{self && <span style={{fontStyle: 'italic'}}>&nbsp;({t('me_lower')})</span> }</h5>}
                    </div>

                    {!user.pending && <>
                        <h5 className="ms-2 mb-3">{t('information')}</h5>
                        <ul className="list-group list-group-light p-0">
                            <li className="list-group-item p-1"><i className="fas fa-id-card fa-fw text-primary me-1"/><span className="fw-bold me-1">{t('name')}:</span>{user.Name}</li>
                            <li className="list-group-item p-1"><i className="fas fa-envelope fa-fw text-primary me-1"/><span className="fw-bold me-1">{t('email')}:</span>{user.mail}</li>
                            <li className="list-group-item p-1"><i className="fas fa-mobile-alt fa-fw text-primary me-1"/><span className="fw-bold me-1">{t('phone')}:</span>{user.phone}</li>
                        </ul>
                    </>}

                    {user.pending && <>
                        <span className="w-100 d-flex justify-content-center"><img src="/time.svg" width={256} height={256}/></span>
                        {/* <span className="d-flex flex-row w-100 justify-content-center"><i className="fas fa-hourglass text-primary fa-8x"/></span> */}
                        <ul className="list-group list-group-light p-0">
                            <li className="list-group-item p-1"><i className="fas fa-envelope fa-fw text-primary me-1"/><span className="fw-bold me-1">{t('email')}:</span>{user.mail}</li>
                            <li className="list-group-item p-1"><i className="fas fa-clock fa-fw text-primary me-1"/><span className="fw-bold me-1">{t('expire_at')}:</span>
                                {user.invitationExpireAt && <span>{new Date(user.invitationExpireAt).toLocaleString(Lang.instance.getCurrentLocale().code)}</span> }
                                {!user.invitationExpireAt && <>
                                    <span className="text-danger fst-italic">{t('expired')}</span>
                                    <button className="btn btn-link p-1 ms-2" onClick={() => {
                                        API.inviteUser(user.mail, companyId, userPermissionsId)
                                            .then(() => {
                                                onEdit(); onClose();
                                            })
                                            .catch(err => setError(err.message));
                                    }}>{t('resend')}</button>
                                </> }
                            </li>
                        </ul>
                    </>}
                    

                    {(canEditPermissions && canEditPermissions > 0) && (
                        <div className="d-flex mb-3 flex-row align-items-center w-100">
                            <i className="fas fa-briefcase fa-fw text-primary me-2"/>
                            <CustomSelect w100 title={t('permissions')} items={permissions.map(p => ({ val: p.id, title: p.Name }))} selected={userPermissionsId} onSelect={id => {setUserPermissionsId(id); setChanged(true); }} />
                        </div>
                    )}
                    {!canEditPermissions && (
                        <ul className="list-group list-group-light p-0"><li className="list-group-item p-1"><i className="fas fa-briefcase fa-fw text-primary me-1"/><span className="fw-bold me-1">{t('permissions')}:</span>{user.rights?.Name||''}</li></ul>
                    )}
                    <div className="d-flex flex-row w-100">
                        {(canEditPermissions) && (
                            <button className="btn btn-primary me-1 flex-fill" style={{minWidth: 0}} disabled={!changed || loading} onClick={update}>{t('save')}</button>
                        )}
                        {canDeleteUser && (
                            <button className="btn btn-danger flex-fill" style={{minWidth: 0}} disabled={loading} onClick={() => setDeleteUserId(userId)}>{t('delete')}</button>
                        )}
                    </div>
                    
                    { error && <div className="alert alert-danger alert-dismissible fade show mt-2" role="alert">
                        { error }
                    </div> }
                </div>
                
                
            </div>
            <button type="button" className="btn-close position-absolute" style={{right: 5, top: 5}} onClick={() => onClose ? onClose() : {}}></button>
        </div>
    ) 
}

const UserInvite = ({companyId, onClose}) => {
    const [inviteEmail, setInviteEmail] = useState('');
    const [selectedRightsId, setSelectedRightsId] = useState(null);
    const [rights, setRights] = useState([]);
    const [inviteError, setInviteError] = useState('');
    const [loading, setLoading] = useState(false);  
    const [invitationURL, setInvitationURL] = useState('');
    const { t } = useTranslation();

    useEffect(() => {
        document.querySelectorAll(".form-outline").forEach((formOutline) => {
			new mdb.Input(formOutline).init();
		});
    }, []);

    useEffect(() => {
        API.getAllRights()
            .then(res => {
                const rs = res.rights;
                setRights(rs.filter(r => r.private));
                const idx = rs.findIndex(v => v.Name == 'Admin');
                if (idx > 0) setSelectedRightsId(rs[idx].id);
                else if (rs.length > 0) setSelectedRightsId(rs[0].id);
            })
            .catch(err => setInviteError(err.message));
    }, [companyId]);

    const invite = () => {
        setLoading(true);
        setInviteError('');
        setInvitationURL('');
        API.inviteUser(inviteEmail, companyId, selectedRightsId)
            .then(({invitationId}) => {
                if (invitationId){
                    setInviteEmail('');
                    setInvitationURL(`${window.location.origin}/join?invitationId=${encodeURIComponent(invitationId)}`);
                }
            })
            .catch(err => setInviteError(err.message));
    }


    return (
        <div className="d-flex flex-column card overflow-hidden">
            <div className="d-flex flex-column card-body overflow-hidden">
                

                <div className="d-flex flex-column overflow-auto pe-1">
                    <div className="d-flex flex-column w-100 justify-content-center text-center">
                        <i className="fas fa-circle-user fa-4x mb-2"/>
                        <h5>{t('invite_user')}</h5>
                    </div>
                    <div className="form-outline mb-2">
                        <input type="text" name="invite_email" id="invite_email" className="form-control" value={inviteEmail} onChange={(ev) => setInviteEmail(ev.currentTarget.value)} />
                        <label className="form-label" htmlFor="invite_email">{t('email')}</label>
                    </div>

                    <CustomSelect title={t('permissions')}
                        items={rights.map(r => ({val: r.id, title: r.Name, icon: 'fas fa-lock'}))}
                        selected={selectedRightsId}
                        onSelect={id => setSelectedRightsId(id)}
                        w100
                    />

                    <button className="btn btn-primary mt-2" style={{minWidth: 0}} disabled={inviteEmail.length == 0 || selectedRightsId == null} onClick={invite}>{t('invite')} <i className="fas fa-envelope fa-lg"/></button>
                    { inviteError && <div className="alert alert-danger alert-dismissible fade show mt-2" role="alert">
                        { inviteError }
                    </div> }
                    { invitationURL && <div className="alert alert-success alert-dismissible fade show mt-2" role="alert">
                        {t('invite_success_link')} <a href={invitationURL} className="text-primary">{invitationURL}</a>
                    </div> }

                </div>

                
                
            </div>
            <button type="button" className="btn-close position-absolute" style={{right: 5, top: 5}} onClick={() => onClose ? onClose() : {}}></button>
        </div>
    )
}