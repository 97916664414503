import { useEffect, useState } from "react"
import * as mdb from 'mdb-ui-kit'; // lib
import CustomTable from "../../other/CustomTable";
import API from "../../../service/api";
import { DeleteConfirm } from "../../other/DeleteConfirm";
import { CustomModal } from "../../other/CustomModal";
import { CustomSelect } from "../../../utils/CustomSelect";
import { useTranslation } from "react-i18next";
import { UserInviteModal } from "./modal/UserInviteModal";

export const CompaniesPage = ({userData, rights, onUserUpdate}) => {
    const [myCompany, setMyCompany] = useState(userData.company);
    const [countries, setCountries] = useState([]);
    const [compCountry, setCompCountry] = useState(null);
    const [subcompanies, setSubcompanies] = useState(null);
    
    const [subcompaniesLoading, setSubcompaniesLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [changed, setChanged] = useState(false);
    const [editCompanyId, setEditCompanyId] = useState(null);
    const [addCompany, setAddCompany] = useState(false);
    
    const [deleteCompanyId, setDeleteCompanyId] = useState(null);
    
    const [error, setError] = useState('');
    const { t } = useTranslation();

    

    const fetchSubcompanies = () => {
        if (rights?.Companies?.Sub?.View) {
            setSubcompaniesLoading(true);
            API.subcompanies()
                .then(c => setSubcompanies(c))
                .finally(() => setSubcompaniesLoading(false));
        }
        
    }

    

    useEffect(() => {
        //fetchMyCompany();
        API.configCountries()
            .then(c => {
                if (Array.isArray(c)) setCountries(c);
            })
            .catch(err => console.error(err))
            .finally(() => {
                fetchSubcompanies();
                //fetchUsers();
            });
    }, []);


    useEffect(() => {
        document.querySelectorAll(".form-outline").forEach((formOutline) => {
			new mdb.Input(formOutline).init();
		});
    }, [myCompany]);

    useEffect(() => {
        if (Array.isArray(countries) && myCompany){
            const country = countries.find(c => c.code === myCompany.country);
            if (country) setCompCountry(country);
        }
    }, [myCompany, countries]);

    if (!myCompany) return <></>;
    const SubcompaniesTableArea = rights?.Companies?.Sub?.View ? (
        <div className="d-flex flex-fill flex-column overflow-hidden">
            <div className="d-flex flex-column overflow-hidden card">
                <div className="d-flex flex-column card-body overflow-hidden">
                    {rights?.Companies?.Sub?.AddUser && <span className="btn text-white btn-floating bg-primary position-absolute text-center" role="button" style={{top: 15, right: 15}} onClick={() => { setEditCompanyId(null); setAddCompany(true); }}>
                        <span className="d-flex justify-content-center align-items-center w-100 h-100"><i className="fas fa-plus fa-lg"/></span>
                    </span>}
                    <h5 className="ms-2 mb-3">{t('subcompanies')}</h5>
                    <CustomTable columns={[t('name'), t('cif'), t('permissions')]}
                        columnWidths={[40, 30, 30]}
                        columnRenderers={[
                            comp => <span style={{overflow: 'hidden', textOverflow: 'clip', whiteSpace: 'nowrap'}}>{comp.name}</span>,
                            comp => <span>{comp.CIF}</span>,
                            comp => <span>{comp.maxRightsId?.name}</span>
                        ]}
                        search={(comp, text) => (comp.name + comp.CIF).toLocaleUpperCase().includes(text.toLocaleUpperCase())}
                        searchTitle={`${t('name')}, ${t('cif')}`}
                        onClick={comp => { setAddCompany(false); setEditCompanyId(comp.id); }}
                        activeRows={comp => comp.id === editCompanyId}
                        loading={subcompaniesLoading}
                        data={subcompanies||[]}/>
                        
                </div>
                
            </div>
        </div>
    ) : null;

    


    return (
        <section className="d-flex flex-column flex-fill w-100" style={{minWidth: 800}}>
            <CustomModal open={deleteCompanyId !== null} onClose={() => setDeleteCompanyId(null)}>
                <DeleteConfirm title={t('remove_company')} message={t('remove_company_prompt')} onConfirm={() => {
                    API.deleteCompany(deleteCompanyId)
                        .then(() => { setEditCompanyId(null); fetchSubcompanies(); });
                    setDeleteCompanyId(null);
                }} onCancel={() => setDeleteCompanyId(null)}/>
            </CustomModal>
            
            
            
            {rights?.Companies?.Sub?.View && <>
                {editCompanyId && (
                    <div className="d-flex flex-row flex-fill overflow-hidden">
                        <div className="d-flex col-7">
                            { SubcompaniesTableArea }
                        </div>
                        <div className="d-flex col-5 ms-3">
                            <div className="d-flex flex-column flex-fill me-3">
                                <SubcompanyEdit companyId={editCompanyId} userRights={rights} onClose={() => {setEditCompanyId(null)}}
                                    onEditDone={() => {
                                        setEditCompanyId(null);
                                        onUserUpdate();
                                        fetchSubcompanies();
                                        }}
                                    onDelete={() => setDeleteCompanyId(editCompanyId)}
                                    />
                                    
                            </div>
                        </div>
                        
                    </div>
                )}
                {!editCompanyId && addCompany && (
                    <div className="d-flex flex-row flex-fill overflow-hidden">
                        <div className="d-flex col-7">
                            { SubcompaniesTableArea }
                        </div>
                        <div className="d-flex col-5 ms-3">
                            <div className="d-flex flex-column flex-fill me-3">
                                <SubcompanyAdd onClose={() => setAddCompany(false)} onAdd={() => {setAddCompany(false); fetchSubcompanies()}}/>
                            </div>
                        </div>
                    </div>
                )}
    
                {!editCompanyId && !addCompany && subcompanies && SubcompaniesTableArea }
            </>}
            
            
        </section>
        
    )
}

const SubcompanyEdit = ({companyId, userRights, onClose, onEditDone, onDelete}) => {
    const [company, setCompany] = useState(null);
    const [name, setName] = useState('');
    const [inviteEmail, setInviteEmail] = useState('');
    const [countries, setCountries] = useState([]);
    const [compCountry, setCompCountry] = useState(null);
    const [changed, setChanged] = useState(false);
    const [loading, setLoading] = useState(false);
    const [area, setArea] = useState('general'); // general, !!users - delete!!, addUser, viewUser
    const [viewUser, setViewUser] = useState(null);
    const [editError, setEditError] = useState('');
    const [inviteError, setInviteError] = useState('');
    const [selectedRightsId, setSelectedRightsId] = useState(null);
    const [rights, setRights] = useState([]);
    const [allRights, setAllRights] = useState([]);
    const [invitationURL, setInvitationURL] = useState('');
    const [userInheritChange, setUserInheritChange] = useState(false);
    const [inviteUser, setInviteUser] = useState(false);
    const { t } = useTranslation();

    const onChange = (ev) => {
        setCompany(prev => ({
            ...prev,
            [ev.nativeEvent.target.name]: ev.nativeEvent.target.value
        }));
        setChanged(true);
    }

    const fetch = () => {
        setChanged(false);
        setCompany(null);
        setCompCountry(null);
        setArea('general');
        setRights([]);
        setSelectedRightsId(null);
        setUserInheritChange(false);

        API.configCountries()
            .then(c => {
                setCountries(Array.isArray(c) ? c : []);
            })
            .catch(err => console.error(err))
            .finally(() => {
                API.getAllRights()
                    .then(result => {
                        const rs = result.rights;
                        setRights(rs.filter(r => r.private));
                        setAllRights(rs);
                        const idx = rs.findIndex(v => v.Name == 'Admin');
                        if (idx > 0) setSelectedRightsId(rs[idx].id);
                        else if (rs.length > 0) setSelectedRightsId(rs[0].id);
                    })
                    .catch(err => setInviteError(err.message))
                    .finally(() => {
                        API.subcompany(companyId)
                            .then(c => { setCompany(c); setName(c.name); })
                            .catch(err => {
                                setArea('error');
                                setCompany({});
                            });
                    });
            });
    }


    useEffect(() => {
        fetch();
    }, [companyId]);

    useEffect(() => {
        if (Array.isArray(countries) && company){
            const country = countries.find(c => c.code === company.country);
            if (country) setCompCountry(country);
        }
        
    }, [countries, company]);

    useEffect(() => {
        document.querySelectorAll(".form-outline").forEach((formOutline) => {
			new mdb.Input(formOutline).init();
		});
        setInviteEmail('');
        setInviteError('');
        setInvitationURL('');
        setEditError('');

    }, [company, area]);

    const submit = () => {
        setLoading(true);
        API.updateSubcompany(companyId, company.name, company.CIF, company.maxRightsId?.id, company.country)
            .then(c => {
                onEditDone(c);
            })
            .catch(err => setEditError(err.message))
            .finally(() => {
                setLoading(false);
            });
    }

    const invite = () => {
        setLoading(true);
        setInviteError('');
        setInvitationURL('');
        API.inviteUser(inviteEmail, companyId, selectedRightsId)
            .then(({invitationId}) => {
                if (invitationId){
                    setInviteEmail('');
                    setInvitationURL(`${window.location.origin}/join?invitationId=${encodeURIComponent(invitationId)}`);
                }
            })
            .catch(err => setInviteError(err.message));
    }

    const updateInheritRights = () => {
        setUserInheritChange(false);
        API.updateSubcompanyUserInheritRights(companyId, viewUser.id, viewUser.inheritRights)
            .finally(() => fetch());
    }

    if (!company) return <></>;

    return (
        <div className="d-flex flex-column overflow-hidden card">
            { inviteUser && <UserInviteModal onClose={() => setInviteUser(false)} open={true} companyId={companyId}/>}
            
            <div className="d-flex flex-column overflow-hidden card-body">
                
                <div className="d-flex flex-column overflow-auto pe-1">
                    <div className="d-flex flex-column w-100 justify-content-center text-center">
                        <i className="fas fa-building fa-4x mb-2"/>
                        <h5>{name}</h5>
                    </div>
                    {area === 'general' && (<>
                        <h5 className="ms-2 mb-3">{t('information')}</h5>
                        {userRights?.Companies?.Sub?.Modify && <>
                            <div className="d-flex mb-3 flex-row align-items-center">
                                <i className="fas fa-pencil fa-fw text-primary"/>
                                <div className="col ms-2">
                                    <div className="form-outline">
                                        <input type="text" name="name" id="name" className="form-control" value={company.name} onInput={onChange}/>
                                        <label className="form-label" htmlFor="name">{t('name')}</label>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex mb-2 flex-row align-items-center">
                                <i className="fas fa-briefcase fa-fw text-primary"/>
                                <div className="col ms-2">
                                    <div className="form-outline">
                                        <input type="text" name="CIF" id="CIF" className="form-control" value={company.CIF} onInput={onChange}/>
                                        <label className="form-label" htmlFor="CIF">{t('cif')}</label>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex mb-2 flex-row align-items-center w-100">
                                <i className="fas fa-earth-europe fa-fw text-primary me-2"/>
                                <CustomSelect w100 title={t('country')} search={true} items={countries.map(c => ({ val: c.code, title: `${c.country}`, icon: `flag-icon flag-icon-${c.code?.toLocaleLowerCase()}`}))} selected={company.country} onSelect={code => {setCompany(comp => ({...comp, country: code})); setChanged(true); }} />
                            </div>
                            <div className="d-flex mb-2 flex-row align-items-center w-100">
                                <i className="fas fa-briefcase fa-fw text-primary me-2"/>
                                <CustomSelect w100 title={t('permissions')} items={allRights.map(p => ({ val: p.id, title: p.Name }))} selected={company.maxRightsId?.id} onSelect={id => {setCompany(c => ({...c, maxRightsId: {...c.maxRightsId, id: id}})); setChanged(true); }} />
                            </div>
                        </>}
                        {!userRights?.Companies?.Sub?.Modify && <>
                            <ul className="list-group list-group-light p-1">
                                <li className="list-group-item p-1"><i className="fas fa-pencil fa-fw text-primary me-1"/><span className="fw-bold me-1">{t('name')}:</span>{company.name}</li>
                                <li className="list-group-item p-1"><i className="fas fa-briefcase fa-fw text-primary me-1"/><span className="fw-bold me-1">{t('cif')}:</span>{company.CIF}</li>
                                <li className="list-group-item p-1"><i className="fas fa-earth-europe fa-fw text-primary me-1"/><span className="fw-bold me-1">{t('country')}:</span>
                                    {compCountry ? <span>{compCountry.country}</span> : <span>?</span>}
                                </li>
                            </ul>
                        </>}
                        {userRights?.Users?.View && <>
                            <div className="d-flex flex-row ms-2 mb-2 align-items-center">
                                <h5 className="m-0 me-1 px-0 py-0 m-0">{t('users')}</h5>
                                <span className="d-flex flex-fill"></span>
                            </div>
                            
                            {(
                                <div className="d-flex justify-content-start align-items-center" style={{flexWrap: 'wrap'}}>
                                    {company.users.map((u, i) => (
                                        <div className="d-inline-block mb-1 " key={i} role="button" onClick={() => { setViewUser(u); setArea('viewUser'); }} title={u.inheritRights ? t('user_inherits_rights') : ''}>
                                            <div className={"chip-custom mb-0 " + (u.inheritRights ? 'list-group-item-warning' : '')} style={{background: u.inheritRights ? '' : '#f1f1f1'}}>
                                                <span className="me-1" style={{color: 'rgb(79, 79, 79)'}} ><i className="fas fa-circle-user fa-2x"/></span>
                                                <span className={"chip-text-custom" + (u.pending ? ' fst-italic':'')} style={{lineHeight: 1, whiteSpace: 'nowrap'}}>
                                                    {u.pending && <i className="fas fa-hourglass text-primary me-1"/>}
                                                    {u.Name || u.mail}
                                                </span>
                                            </div>
                                        </div>
                                    ))}
                                    {userRights?.Companies?.Sub?.AddUser &&
                                        <span className="btn text-white btn-floating btn-sm bg-primary text-center mb-1" role="button" onClick={() => { setInviteUser(true); }}>
                                            <span className="d-flex justify-content-center align-items-center w-100 h-100"><i className="fas fa-plus fa-lg"/></span>
                                        </span>
                                    }      
                                </div>
                            )}
                            {company.users.length === 0 && (
                                <span className="mb-3 fst-italic">{t('no_users')}</span>
                            )}
                        
                        </>}
                        
                        
                        <div className="d-flex flex-row w-100 mt-2">
                            {userRights?.Companies?.Sub?.Modify && <button className="btn btn-primary flex-fill me-1" disabled={!changed || loading} onClick={submit}>{t('save')}</button> }
                            {userRights?.Companies?.Sub?.Delete && <button className="btn btn-danger flex-fill" disabled={loading} onClick={onDelete}>{t('delete')}</button> }
                        </div>
                        

                        { editError && <div className="alert alert-danger alert-dismissible fade show mt-2" role="alert">
                            { editError }
                        </div> }
                    
                    </>)}

                    {area === 'viewUser' && (<>
                        <span type="button" className="position-absolute" style={{left: 15, top: 10}} onClick={() => setArea('general')}><i className="fas fa-arrow-left-long fa-lg"/></span>
                        {viewUser.pending && <span className="w-100 d-flex justify-content-center"><img src="/time.svg" width={256} height={256}/></span>}
                        <ul className="list-group list-group-light p-1">
                            {!viewUser.pending && <li className="list-group-item p-1"><i className="fas fa-id-card fa-fw text-primary me-1"/><span className="fw-bold me-1">{t('name')}:</span>{viewUser.Name}</li>}
                            <li className="list-group-item p-1"><i className="fas fa-envelope fa-fw text-primary me-1"/><span className="fw-bold me-1">{t('email')}:</span>{viewUser.mail}</li>
                            {!viewUser.pending && <li className="list-group-item p-1"><i className="fas fa-mobile-alt fa-fw text-primary me-1"/><span className="fw-bold me-1">{t('phone')}:</span>{viewUser.phone}</li>}
                            <li className="list-group-item p-1 d-flex flex-row align-items-center">
                                <i className="fas fa-briefcase fa-fw text-primary me-1"/>
                                <span className="fw-bold me-1">{t('permissions')}:</span>{viewUser.rights?.Name||''}
                                {(userRights?.Companies?.Sub?.Modify && userRights?.Companies?.Sub?.AddUser) &&
                                    <div className="form-check align-items-center ms-3 d-flex">
                                        <input className="form-check-input" type="checkbox" checked={viewUser.inheritRights} id="inherit"
                                            onChange={ev => {setViewUser(usr => ({...usr, inheritRights: ev.target?.checked || false})); setUserInheritChange(true);  }} />
                                        <label className="form-check-label" htmlFor="inherit">{t('inherit_rights')}</label>
                                    </div>
                                }
                                {userInheritChange &&
                                    <span className="btn btn-link ms-2 p-1" onClick={() => updateInheritRights()}>{t('save')}</span>
                                }
                            </li>
                        </ul>

                    </>)}

                    {area === 'addUser' && (<>
                        <span type="button" className="position-absolute" style={{left: 15, top: 10}} onClick={() => setArea('general')}><i className="fas fa-arrow-left-long fa-lg"/></span>
                        <div className="d-flex flex-row ms-2 mb-2 align-items-center">
                            <h5 className="me-1 px-0 py-1 m-0 flex-fill">{t('invite_user')}</h5>
                        </div>

                        <div className="form-outline mb-2">
                            <input type="text" name="invite_email" id="invite_email" className="form-control" value={inviteEmail} onChange={(ev) => setInviteEmail(ev.currentTarget.value)} />
                            <label className="form-label" htmlFor="invite_email">{t('email')}</label>
                        </div>
                        <CustomSelect title={t('permissions')}
                            items={rights.map(r => ({val: r.id, title: r.Name, icon: 'fas fa-lock'}))}
                            selected={selectedRightsId}
                            onSelect={id => setSelectedRightsId(id)}
                            w100
                        />

                        <button className="btn btn-primary mt-2" style={{minWidth: 0}} disabled={inviteEmail.length == 0} onClick={invite}>{t('invite')} <i className="fas fa-envelope fa-lg"/></button>
                        { inviteError && <div className="alert alert-danger alert-dismissible fade show mt-2" role="alert">
                            { inviteError }
                        </div> }
                        { invitationURL && <div className="alert alert-success alert-dismissible fade show mt-2" role="alert">
                            {t('invite_success_link')} <a href={invitationURL} className="text-primary">{invitationURL}</a>
                        </div> }
                    </>)}

                    {area === 'error' && (
                        <div className="alert alert-danger alert-dismissible fade show mt-2" role="alert">
                            {t('no_company')}
                        </div>
                    )}
                </div>
                
                
            </div>

            <button type="button" className="btn-close position-absolute" style={{right: 5, top: 5}} onClick={() => onClose ? onClose() : {}}></button>
        </div>
        
    )
}



const SubcompanyAdd = ({onClose, onAdd}) => {
    const [company, setCompany] = useState({
        name: '',
        CIF: '',
        maxRightsId: -1
    });
    const [countries, setCountries] = useState([]);
    const [permissions, setPermissions] = useState([]);
    const [changed, setChanged] = useState(false);
    const [addError, setAddError] = useState('');
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();

    const onChange = (ev) => {
        setCompany(prev => ({
            ...prev,
            [ev.nativeEvent.target.name]: ev.nativeEvent.target.value
        }));
        setChanged(true);
    }

    useEffect(() => {
        document.querySelectorAll(".form-outline").forEach((formOutline) => {
			new mdb.Input(formOutline).init();
		});
        setChanged(false);
        setAddError('');
        API.getAllRights().then(r => {
            setPermissions(r.rights);
        });
        API.configCountries().then(c => {
            if (Array.isArray(c)) setCountries(c);
        })

    }, []);

    const submit = () => {
        setLoading(true);
        API.addSubcompany(company.name, company.CIF, company.maxRightsId, company.country)
            .then(() => {
                onAdd();
            })
            .catch(err => {setAddError(err.message) })
            .finally(() => setLoading(false));
    }

    return (
        <div className="d-flex flex-column overflow-hidden card">
            <div className="d-flex flex-column overflow-auto card-body">
                <div className="d-flex flex-column w-100 justify-content-center text-center">
                    <i className="fas fa-building fa-4x mb-2"/>
                    <h5>{t('add_subcompany')}</h5>
                </div>

                <h5 className="ms-2 mb-3">{t('information')}</h5>
                <div className="d-flex mb-2 flex-row align-items-center">
                    <i className="fas fa-pencil fa-fw"/>
                    <div className="col ms-2">
                        <div className="form-outline">
                            <input type="text" name="name" id="name" className="form-control" value={company.name} onInput={onChange}/>
                            <label className="form-label" htmlFor="name">{t('name')}</label>
                        </div>
                    </div>
                </div>
                <div className="d-flex mb-2 flex-row align-items-center">
                    <i className="fas fa-briefcase fa-fw"/>
                    <div className="col ms-2">
                        <div className="form-outline">
                            <input type="text" name="CIF" id="CIF" className="form-control" value={company.CIF} onInput={onChange}/>
                            <label className="form-label" htmlFor="CIF">{t('cif')}</label>
                        </div>
                    </div>
                </div>
                <div className="d-flex mb-2 flex-row align-items-center w-100">
                    <i className="fas fa-briefcase fa-fw me-2"/>
                    <CustomSelect w100 title={t('permissions')} items={permissions.map(p => ({ val: p.id, title: p.Name }))} selected={company.maxRightsId} onSelect={id => {setCompany(c => ({...c, maxRightsId: id})); setChanged(true); }} />
                </div>
                <div className="d-flex mb-2 flex-row align-items-center w-100">
                    <i className="fas fa-earth-europe fa-fw me-2"/>
                    <CustomSelect w100 title={t('country')} search={true} items={countries.map(c => ({ val: c.code, title: `${c.country}`, icon: `flag-icon flag-icon-${c.code?.toLocaleLowerCase()}`}))} selected={company.country} onSelect={code => {setCompany(comp => ({...comp, country: code})); setChanged(true); }} />
                </div>
                
                <button className="btn btn-primary mt-2" disabled={!changed || loading} onClick={submit}>{t('add')} <i className="far fa-square-plus fa-lg"/></button>
                { addError && <div className="alert alert-danger alert-dismissible fade show mt-2" role="alert">
                    { addError }
                </div> }
                
            </div>

            <button type="button" className="btn-close position-absolute" style={{right: 5, top: 5}} onClick={() => onClose ? onClose() : {}}></button>
        </div>
        
    )
}

