import API from "../../../service/api"
import Terminal, { ColorMode, TerminalOutput } from 'react-terminal-ui';
import { useEffect, useState } from "react"
import WS from "../../../service/ws";
import { Trans } from "react-i18next";

const Log = ({message, level, timestamp}) => (
    <div className="" style={{fontSize: '0.8rem', lineHeight: 1}} >
        <span className="text-secondary" style={{whiteSpace: 'nowrap'}}>{new Date(timestamp).toLocaleString()}</span>
        <span className={"text-" + ({warn: 'warning', error: 'danger'}[level]||'primary')}>&nbsp;[{(level||"").toLocaleUpperCase()}]</span>
        <span className={"text-" + ({warn: 'warning', error: 'danger'}[level]||'default')}>:&nbsp;</span>
        <span className={"text-" + ({warn: 'warning', error: 'danger'}[level]||'default')}>
            {message}
        </span>
    </div>
)

const UserInput = ({cmd, bad, timestamp}) => (
    <div className="" style={{fontSize: '0.8rem', lineHeight: 1}}>
        <span className="text-secondary">{new Date(timestamp).toLocaleString()}</span>
        <span>:&nbsp;</span>
        {bad && <span><Trans i18nKey="unknown_command"/>&nbsp;</span>}
        {!bad && <span><Trans i18nKey="success_command"/>&nbsp;</span>}
        <span className="text-warning">{cmd}</span>
    </div>
)

function getWindowSize() {
    const {innerWidth, innerHeight} = window;
    return {innerWidth, innerHeight};
}

export const DevPage = ({}) => {
    const [logs, setLogs] = useState([]);
    const [windowSize, setWindowSize] = useState(getWindowSize());

    useEffect(() => {
        API.devJoinLogs()
            .then(() => {
                API.devGetLogs(3000)
                    .then(data => {
                        setLogs(data)
                    })
            });

        const newLogCallback = log => {
            setLogs(prev => prev.concat([log]));
        }

        WS.listen('dev_log', newLogCallback);

        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }
        window.addEventListener('resize', handleWindowResize);

        return () => {
            API.devLeaveLogs();
            WS.unlisten('dev_log', newLogCallback);
            window.removeEventListener('resize', handleWindowResize);
        }
    }, []);

    const command = (cmd) => {
        API.devCommand(cmd)
            .then(r => setLogs(prev => prev.concat([{cmd, timestamp: Date.now()}])))
            .catch(err => {
                setLogs(prev => prev.concat([{cmd, bad: true, timestamp: Date.now()}]));
            });
    }

    return (
        <section className="d-flex flex-column w-100 h-100">
            

            <Terminal name="Development terminal" height={(windowSize.innerHeight-240)+ 'px'} onInput={t => { if (t) command(t); }} redBtnCallback={null} greenBtnCallback={null} yellowBtnCallback={null}>
                {logs.map((l, idx) => {
                    if (l.cmd !== undefined) return <UserInput cmd={l.cmd} bad={l.bad} timestamp={l.timestamp} key={idx} />
                    else return <Log key={idx} message={l.message} level={l.level} timestamp={l.timestamp} />
                })}
            </Terminal>
            <span className="text-muted">
                <Trans i18nKey="available_commands"/>: 
                <span className="text-primary">reset, restart, conn, ss, room [group|location|machine] [id:number], mexp [id] [DD-MM-YYYY[-HH-mm]] (UTC)</span></span>
        </section>
    )
}