import { useEffect } from "react";
import { useState } from "react";
import { Navigate, Outlet, redirect, Route, Routes, useLocation, useNavigate, useParams, useResolvedPath } from "react-router";
import { createBrowserRouter, useSearchParams } from "react-router-dom";
import API from "../../service/api";
import { CustomSelect } from "../../utils/CustomSelect";
import { LoginPage } from "../login/LoginPage";
import { HeaderBar } from "./HeaderNav";
import { MachineViewer } from "./machine/MachineViewer";
import { GroupViewer } from "./group/GroupViewer";
import { LocationViewer } from "./location/LocationViewer";
import { MobileNav } from "./MobileNav";
import { QRScanner } from "../other/QRScanner";
import { useTranslation } from "react-i18next";
import { GroupSelector } from "./group/GroupSelector";
import App from "../../App";
import { NewUserPopup } from "./NewUserPopup";

function getWindowSize() {
    const {innerWidth, innerHeight} = window;
    return {innerWidth, innerHeight};
  }

export const MainPage = ({userData}) => {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(true);
	const [groups, setGroups] = useState([]);
	const [selectedGroup, setSelectedGroup] = useState(null);
	const [qr, setQr] = useState(false);
	const [newUser, setNewUser] = useState(localStorage.getItem('newUser') !== null);
	const { t } = useTranslation();

	const fetch = () => {
		setLoading(true);
		API.getUserGroups().then(g => {
			setGroups(g);
			if (!selectedGroup && g.length > 0){
				const lastOpGroup = localStorage.getItem('lastopgroup'),
					idx = g.findIndex(grp => grp.id == Number(lastOpGroup));
				if (idx >= 0) {
					setSelectedGroup(g[idx]);
					navigate('g/'+g[idx].id);
				}else{
					localStorage.removeItem('lastopgroup');
					setSelectedGroup(g[0]);
					navigate('g/'+g[0].id);
				}
			}
			setLoading(false);
		});
	}

	useEffect(() => {
		fetch();
	}, [userData]);

	useEffect(() => {
		if (selectedGroup && selectedGroup.id) localStorage.setItem('lastopgroup', selectedGroup.id);
	}, [selectedGroup]);

	if (loading) return <div>...</div>;
	return (
		<div className="d-flex flex-column h-100">

			<HeaderBar
                userName={userData.name}
                onQR={() => setQr(true)}
                onHome={() => {
                    if (selectedGroup) navigate('/g/'+selectedGroup.id);
                    else navigate('/');
                }}
                onHelp={() => setNewUser("tutorial")}
            />
			{qr && <QRScanner key={selectedGroup} currentGroupId={selectedGroup?.id || -1} onClose={() => setQr(false)}/>}

			{newUser && <NewUserPopup userData={userData} mobile={getWindowSize().innerWidth < 800} withoutWelcome={newUser == 'tutorial'}/>}

			<div className="d-flex flex-fill overflow-auto" style={{background: '#eee'}}>
				<Routes>
					<Route path="" element={<GroupSelector groups={groups} />}/>

					<Route path="g/:id/*" element={<GroupViewer group={selectedGroup} groups={groups} onLoad={group => {
						if (!selectedGroup || selectedGroup.id !== Number(group)) setSelectedGroup(groups.find(g => g.id === Number(group)));
					}}
						onGroupSelect={id => setSelectedGroup(groups.find(g => g.id == id))}/>
					}/>
					<Route path="*" element={<Navigate to="/"/>}/>
				</Routes>
			</div>



		</div>

	);
};
