import React, { useState, useRef, useEffect } from "react";

function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}

function CustomTable({
  columns,
  columnWidths,
  columnRenderers,
  data,
  search,
  searchTitle,
  filter,
  onClick,
  activeRows,
  loading,
  header,
  infiniteScrollMaxCount,
  rowClassName,
  rowStyle,
}) {
  const [tableHeight, setTableHeight] = useState(0);
  const tableRef = useRef(null);
  const bodyRef = useRef(null);
  const [searchText, setSearchText] = useState("");
  const [realColumnWidths, setRealColumnWidths] = useState(
    columnWidths.map((c) => `${c}%`)
  );
  const [realRowWidth, setRealRowWidth] = useState("100%");
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [pageSize, setPageSize] = useState(infiniteScrollMaxCount || 50000);
  const [shownCount, setShownCount] = useState(0);

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    setTableHeight(tableRef.current.clientHeight);
  }, []);

  useEffect(() => {
    // console.log(bodyRef.current, data.length);
    // if (bodyRef.current && data.length > 0 && bodyRef.current.children.length > 0) {
    //     setRealColumnWidths(Array.from(bodyRef.current.children[0].children).map(c => c.offsetWidth + 'px'));
    // }
    if (bodyRef.current) {
      const w =
        bodyRef.current.children.length > 0
          ? bodyRef.current.children[0].offsetWidth
          : bodyRef.current.offsetWidth;
      if (w > 0) setRealRowWidth(w);
    }

  }, [bodyRef, data, windowSize]);

  useEffect(() => {
    if (!data) return;
    setShownCount(Math.min(data.length, pageSize));
  }, [data, infiniteScrollMaxCount, pageSize]);

  const onScroll = (dom) => {
    const { scrollTop, clientHeight, scrollHeight } = dom;
    if (scrollTop + clientHeight > scrollHeight - 5) {
      setShownCount(Math.min(data.length, shownCount + pageSize))
    }
  };

  const debounce = (func, wait) => {
    let timeout;
    return function (...args) {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        timeout = null;
        func.apply(context, args);
      }, wait);
    };
  };

  const debouncedHandleScroll = (dom) => {
    return debounce(() => onScroll(dom), 100);
  };

  const shouldShow = (el, index, search, searchText, shownCount, filter) => {
    if (filter && typeof filter == 'function' && !filter(el)) return false;
    if (search && searchText) return search(el, searchText);
    return index < shownCount;
  }


  return (
    <div
      ref={tableRef}
      className="w-100 bg-light d-flex flex-column overflow-hidden"
    >
      <div className="d-flex flex-row w-100 align-content-center">
        {search && (
          <div className="input-group rounded mb-2" style={{ maxWidth: 400 }}>
            <input
              type="search"
              className="form-control rounded"
              placeholder={searchTitle || "Search"}
              aria-label="Search"
              aria-describedby="search-addon"
              value={searchText}
              onChange={(ev) => setSearchText(ev.currentTarget.value)}
            />
            <span className="input-group-text border-0" id="search-addon">
              <i className="fas fa-search"></i>
            </span>
          </div>
        )}
        <div className="d-flex flex-fill align-items-center justify-content-end">
          {header && header(data, activeRows, search)}
        </div>
      </div>

      <div
        className="d-flex flex-row px-3 py-0 w-100"
        style={{ background: "#F4F5F9", borderBottom: "1px solid #e0e0e0" }}
      >
        <div className="d-flex flex-row" style={{ width: realRowWidth }}>
          {columns.map((column, i) => (
            <div
              key={i}
              style={{ width: `${columnWidths[i]}%`, fontWeight: 500 }}
              className="d-flex justify-content-start align-items-center p-2"
            >
              {column}
            </div>
          ))}
        </div>
      </div>
      <div
        ref={bodyRef}
        className={"w-100 d-flex flex-column overflow-auto px-3 py-2"}
        onScroll={e => onScroll(e.currentTarget)}
        onTouchMove={(e) =>
          debouncedHandleScroll(e.currentTarget)
        }
      >
        {!loading &&
          data.map((row, i) => (
            <div
              key={i}
              className={
                "w-100 d-flex flex-row custom-table-row " + (rowClassName ? rowClassName(row) : "") +
                (activeRows && activeRows(row)
                  ? " custom-table-row-active"
                  : "") //+
                // (( (search && !search(row, searchText)) || i >= shownCount) ? " d-none" : "")
              }
              style={{ cursor: onClick ? "pointer" : "", ...(rowStyle ? rowStyle(row) : {})}}
              onClick={() => (onClick ? onClick(row) : {})}
            >
              { shouldShow(row, i, search, searchText, shownCount, filter) && columns.map((col, j) => (
                <div
                  key={j}
                  style={{
                    width: `${columnWidths[j]}%`,
                    borderBottom: "1px solid #e0e0e0",
                    overflow: "auto",
                    whiteSpace: "",
                  }}
                  className="d-flex justify-content-start align-items-center p-2"
                >
                  {columnRenderers[j](row)}
                </div>
              ))}
            </div>
          ))}
        {!loading && data.length === 0 && (
          <span className="d-flex flex-fill justify-content-center align-items-center">
            Empty
          </span>
        )}
        {loading && (
          <span className="d-flex flex-fill justify-content-center align-items-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </span>
        )}
      </div>
    </div>
  );
}

export default CustomTable;
