import { io } from "socket.io-client";
import API from "./api";

export default class WS {
    socket = null;
    static instance;

    constructor(){
        WS.instance = this;
    }

    connect() {
        this.socket = io(API.uri, {auth: {token: API.token}});
        return new Promise(resolve => {
            this.socket.on('connect', () => { console.log(`WS connected to server!`); resolve(); });
            this.socket.on('disconnect', reason => { console.log(`WS has diconnected: ${reason}`); })
            this.socket.on('reconnect', () => { console.log(`WS reconnected!`); });
            this.socket.on('reconnecting', () => { console.log(`WS reconnecting...`); });
        })
        
    }

    static listen(event, callback) {
        if (!WS.instance.socket) return;
        WS.instance.socket.on(event, callback);
    }

    static unlisten(event, callback) {
        if (!WS.instance.socket) return;
        WS.instance.socket.off(event, callback);
    }

    static emit(event, data){
        if (!WS.instance.socket) return;
        WS.instance.socket.emit(event, data);
    }

    static connected() {
        return WS.instance.socket && WS.instance.socket.connected;
    }
}