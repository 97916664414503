import { useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router"
import API from "../../../service/api";
import { CompaniesPage } from "./CompaniesPage";
import { Logout } from "./Logout";
import { ProfileBar } from "./ProfileBar"
import { ProfileHeader } from "./ProfileHeader";
import { ProfilePage } from "./ProfilePage";
import { PermissionsPage } from "./PermissionsPage";
import { GroupsPage } from "./GroupsPage";
import { LocationsPage } from "./LocationsPage";
import { ProductsPage } from "./ProductsPage";
import { MachinesPage } from "./MachinesPage";
import { DevPage } from "./Dev";
import { UsersPage } from "./UsersPage";
import CameraList from "../../other/CameraList";


function getWindowSize() {
    const {innerWidth, innerHeight} = window;
    return {innerWidth, innerHeight};
}

export const Profile = ({userData, rights, onUserUpdate}) => {
    const [windowSize, setWindowSize] = useState(getWindowSize());
    const [minimized, setMinimized] = useState(false);
    const [horizontalBar, setHorizontalBar] = useState(false);

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }
        window.addEventListener('resize', handleWindowResize);

        API.unregisterLogs();
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    useEffect(() => {
        setMinimized(windowSize.innerWidth < 1300);
        setHorizontalBar(windowSize.innerWidth < 700);
    }, [windowSize]);

    return (
        <div className="d-flex h-100 w-100 flex-column" style={{background: '#eee'}}>
            <ProfileHeader userData={userData}/>

            <div className={"d-flex flex-fill overflow-hidden " + (horizontalBar ? "flex-column" : "flex-row")}>
                <div className={"m-0" + (minimized ? '' : ' ms-3')}><ProfileBar userData={userData} horizontal={horizontalBar} rights={rights} minimized={minimized}/></div>
                <div className={"d-flex flex-fill overflow-auto " + (minimized ? "p-1" : "p-3")}>
                    <Routes>
                        <Route path="" element={<ProfilePage userData={userData} onUserUpdate={onUserUpdate} rights={rights}/>}/>
                        <Route path="companies" element={<CompaniesPage userData={userData} rights={rights} onUserUpdate={onUserUpdate}/>}/>
                        { rights?.Users?.View && <Route path="users" element={<UsersPage userData={userData} rights={rights} onUserUpdate={onUserUpdate}/>}/> }
                        { rights?.Users?.ManageRights && <Route path="permissions" element={<PermissionsPage myRightsId={userData.rightsId}/>}/> }
                        { rights?.Groups?.View && <Route path="groups" element={<GroupsPage rights={rights}/>}/> }
                        { rights?.Locations?.View && <Route path="locations" element={<LocationsPage userData={userData} rights={rights}/>}/> }
                        { rights?.Products?.View && <Route path="products" element={<ProductsPage rights={rights}/>}/> }
                        { rights?.Locations?.ViewMachines && <Route path="machines" element={<MachinesPage rights={rights}/>}/> }
                        <Route path="k" element={<CameraList/>} />
                        { userData.company.id == 1 && <Route path="dev" element={<DevPage/>}/> }
                        <Route path="logout" element={<Logout/>}/>
                        {/* <Route path="*" element={<Navigate to="me"/>}/> */}
                    </Routes>
                </div>
            </div>
            


        </div>
    )
}