import { useEffect, useState } from "react";
import * as mdb from 'mdb-ui-kit'; // lib
import API from "../../../service/api";
import WS from "../../../service/ws";
import { CustomModal } from "../../other/CustomModal";
import { CustomSelect } from "../../../utils/CustomSelect";
import TimePicker from 'react-time-picker';
import { DayPicker } from 'react-day-picker';
import { useTranslation } from "react-i18next";

export const AdvancedSettingsModal = ({groupID, locationID, machine, propParams, onClose, canPower}) => {
    const { id } = machine;
    const [params, setParams] = useState(null);
    const [selectedParamAddr, setSelectedParamAddr] = useState(-1);
    const [selectedParam, setSelectedParam] = useState(-1);
    const [rawAddr, setRawAddr] = useState(0);
    //const [currentValue, setcurrentValue] = useState(null);
    const [currentValue, setCurrentValue] = useState(null);
    const [selectedSchedule, setSelectedSchedule] = useState(-1);
    const [valuesWaiting, setValuesWaiting] = useState({});
    const [valuesWriting, setValuesWriting] = useState({});
    const [addrValues, setAddrValues] = useState({});
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [changed, setChanged] = useState(false);
    const [writeMessage, setWriteMessage] = useState('');
    const { t } = useTranslation();

    useEffect(() => {
        setParams(machine.advanced_params || []);
    }, [machine]);


    useEffect(() => {
        document.querySelectorAll(".form-outline").forEach((formOutline) => {
			new mdb.Input(formOutline).init();
		});

        setSelectedParamAddr(null);
        setSelectedParam(null);
        
        setValuesWaiting({});
        setValuesWriting({});
        setAddrValues({});


        //setLoading(true);

        (groupID ? API.getMachineAdvancedParamsOperator(groupID, locationID, id) : API.getMachineAdvancedParams(id))
            .then(r => {
                //setParams(r); just for register
            })
            .catch(err => { setError(err.message); })
            .finally(() => setLoading(false));
        
        const callback = ev => {
            console.log(ev);
            const {addr, value} = ev[id];

            setValuesWaiting(prev => ({...prev, [addr]: false}));
            setAddrValues(prev => ({...prev, [addr]: value}));
        }
        WS.listen('advanced_machine_update', callback);

        const onWritten = ev => {
            console.log('advanced_machine_update_written', ev);
            const {addr} = ev[id];
            if (addr) setValuesWriting(prev => ({...prev, [addr]: false}));
        }
        WS.listen('advanced_machine_update_written', onWritten);

        return () => {
            console.log('exit ' + id)
            WS.unlisten('advanced_machine_update', callback);
            WS.unlisten('advanced_machine_update_written', onWritten);
            WS.emit('advanced_machine_leave', {id});
        }
    }, []);

    useEffect(() => {
        //console.log(addrValues, selectedParamAddr, currentValue);
        const addrToUpdate = selectedParam?.type == 'vtRowMem' ? rawAddr : selectedParamAddr;
        if (addrValues[addrToUpdate] !== undefined && currentValue == null) setCurrentValue(addrValues[addrToUpdate]);

        if (selectedParam?.type == 'vtSchedule' && currentValue && currentValue.length > 0 && selectedSchedule < 0) setSelectedSchedule(0);
        if (selectedParam?.type == 'vtHolidays' && currentValue && currentValue.length > 0 && selectedSchedule < 0) setSelectedSchedule(0);


    }, [addrValues, currentValue]);

    const read = () => {

        setCurrentValue(null);
        setSelectedSchedule(-1);
        const addrToRead = selectedParam?.type == 'vtRowMem' ? rawAddr : selectedParamAddr;
        setAddrValues(prev => ({...prev, [addrToRead]: undefined}));
        
        (groupID ? API.readFRAMOperator(groupID, locationID, machine.id, addrToRead) : API.readFRAM(machine.id, addrToRead))
            .then(() => {
                const prevState = valuesWaiting;
                prevState[addrToRead] = true;
                setValuesWaiting({...prevState});
            })
            .catch(err => setError(err.message));
    }

    const write = () => {
        if (selectedParam?.type == 'vtPassword' && currentValue.length !== 4) return setError('Invalid password: must be 4 numeric characters.');

        setChanged(false);
        const addrToWrite = selectedParam?.type == 'vtRowMem' ? rawAddr : selectedParamAddr;
        (groupID ? API.writeFRAMOperator(groupID, locationID, machine.id, addrToWrite, currentValue) : API.writeFRAM(machine.id, addrToWrite, currentValue))
            .then(() => { setValuesWriting(prev => ({...prev, [addrToWrite]: true})) })
            .catch(err => setError(err.message))
            .finally(() => { })
    }

    useEffect(() => {
        setChanged(false);

        setError(''); setWriteMessage('');
        if (!params) return;
        if (selectedParamAddr === 'raw'){
            const param = params.find(p => p.type == 'vtRowMem');
            if (!param) return;
            setSelectedParam(param);
            setCurrentValue(addrValues[rawAddr]!== undefined ? addrValues[rawAddr] : null)
        }else {
            setCurrentValue(addrValues[selectedParamAddr]!== undefined ? addrValues[selectedParamAddr] : null);
            const param = params.find(p => p.addr == selectedParamAddr);
            if (!param) return;
            setSelectedParam(param);
        }
        
        //setCurrentValue(initValByType(param.type));
        
    }, [selectedParamAddr, params]);

    useEffect(() => {
        document.querySelectorAll(".form-outline").forEach((formOutline) => {
			new mdb.Input(formOutline).init();
		});
    }, [selectedParam]);

    const setNthBit = (bit, val) => {
        if (val){
            setCurrentValue(currentValue | (1 << bit));
        }else {
            setCurrentValue(currentValue & (~(1 << bit)));
        }
    }

    const passwordInput = val => {
        setCurrentValue(val.replace(/[^0-9]/g, '').substr(0, 4))
    }

    const scheduleTitle = (idx, val) => {
        const on = {
            0: 'OFF', 1: 'ON'
        }[scheduleOnOffValue(val)] || '?';
        
        
        const days = val >> 24;
        let daysStr = '';
        const daysStrDict = {
            0: 'Mo', 1: 'Tu', 2: 'We', 3: 'Th', 4: 'Fr', 5: 'Sa', 6: 'Su', 7: 'Ho' 
        }
        for (let i=0; i<8; i++) daysStr += ((days >> i) & 1) === 1 ? daysStrDict[i] : '';
        if (daysStr == '') return 'N/A';

        return `${on} - ${scheduleTimeString(val)} ${daysStr}`;
    }

    const holidayDate = val => {
        const now = new Date();
        const day = (val >> 24) & 0xFF,
            month = (val >> 16) & 0xFF,
            year = (val & 0xFFFF) || now.getFullYear();
        if (day == 0 || month == 0) return null;
        else return new Date(year, month-1, day);
    }

    const holidaySetDate = (idx, date) => {
        if (!date) return;
        const day = date.getDate(),
            month = date.getMonth()+1,
            year = date.getFullYear();
        
        setCurrentValue(prev => prev.map((el, i) => i !== idx ? el : (
            (day << 24) + (month << 16) + (year)
        )));
        setChanged(true);
    }

    const holidaySetRecurrent = idx => {
        setCurrentValue(prev => prev.map((el, i) => i !== idx ? el : (
            el & 0xFFFF0000
        )));
        setChanged(true);
    }

    const holidayTitle = (val) => {
        const day = (val >> 24) & 0xFF,
            month = (val >> 16) & 0xFF,
            year = val & 0xFFFF;
        if (day==0 || month == 0) return 'N/A';
        return `${day < 10 ? '0':''}${day}.${month < 10 ? '0':''}${month}${year > 0 ? `.${year}`:''}`;
    }

    const scheduleTimeString = val => {
        let clock = val & 0xFFFF;
        if (clock >= 1440) {
            console.error(`Invalid clock value: ` + clock);
            clock = 0;
        }
        const hours = Math.floor(clock/60),
        mins = clock - hours*60;
        const hoursString = hours < 10 ? `0${hours}` : hours,
            minsString = mins < 10 ? `0${mins}` : mins;
        return `${hoursString}:${minsString}`;
    }

    const scheduleOnOffValue = val => {
        return ((val >> 16) & 0x01) == 1 ? 1 : 0;
    }

    const setScheduleOnOffValue = (idx, on) => {
        if (on){
            setCurrentValue(prev => prev.map((el, i) => i !== idx ? el : (
                (el & 0xFF00FFFF) | 0x00010000
            )));
        }else{
            setCurrentValue(prev => prev.map((el, i) => i !== idx ? el : (
                el & 0xFF00FFFF
            )));
        }
        setChanged(true);
    }

    const timePickerHandler = (idx, str) => {
        if (!str) return;
        const time = Number(str.split(':')[0])*60 + Number(str.split(':')[1]);
        const clock = currentValue[idx];
        const newClock = (clock & 0xFFFF0000) | time;

        setCurrentValue(prev => prev.map((el, i) => i == idx ? newClock : el));
        setChanged(true);
    }

    const setNthBitOfSchedule = (idx, bit, val) => {
        if (val){
            setCurrentValue(prev => prev.map((el, i) => i !== idx ? el : (
                el | (1 << bit)
            )));
        }else{
            setCurrentValue(prev => prev.map((el, i) => i !== idx ? el : (
                el & (~(1 << bit))
            )));
        }
    }
    

    return (
        <CustomModal open={machine} onClose={onClose}>
            <div className="card" style={{minWidth: 0}}>
                <div className="card-body">
                    <h5 className="card-title">{t('advanced_settings')}</h5>

                    {(!machine || !params || loading) && <>
                        <div className="spinner-border " role="status">
                            <span className="visually-hidden">{t('loading...')}</span>
                        </div>
                    
                    </>}

                    {(machine && params && !loading) && <>
                        <div className="d-flex flex-column">
                            <CustomSelect title={t('parameter')}
                                items={params.map(p => ({
                                   val: p.addr ? p.addr : 'raw', title: p.name
                                }))}
                                selected={['number','string'].includes(typeof selectedParamAddr) ? selectedParamAddr : null}
                                onSelect={addr => setSelectedParamAddr(addr)}
                                search={true}
                            />
                            {selectedParamAddr && <>
                                {['vtByte', 'vtWORD', 'vtDWORD', 'vtString', 'vtPassword'].includes(selectedParam?.type) && <>
                                    <div className="d-flex flex-row align-items-center mt-2">
                                        <div className="form-outline">
                                            <input type={currentValue == null ? 'text' : (['vtString', 'vtPassword'].includes(selectedParam?.type) ? 'text' : 'number')} name="val" id="val" className="form-control"
                                                value={currentValue!== null ? currentValue :t('no_data')} disabled={currentValue == null || valuesWriting[selectedParamAddr]}
                                                onInput={ev => {
                                                    if (selectedParam?.type === 'vtPassword') passwordInput(ev.currentTarget.value);
                                                    else setCurrentValue(ev.currentTarget.value);
                                                    setChanged(true); setWriteMessage(''); setError('');
                                                }}/>
                                            <label className="form-label" htmlFor="val">{t('value')}</label>
                                        </div>
                                        {(!valuesWaiting[selectedParamAddr] && !valuesWriting[selectedParamAddr]) && <span role="button" onClick={() => read()} className="text-primary ms-1"><i className="fas fa-upload fa-lg"/></span> }
                                        {(valuesWaiting[selectedParamAddr] || valuesWriting[selectedParamAddr]) && <div className="spinner-border spinner-border-sm me-1 ms-1" role="status"><span className="visually-hidden">{t('loading...')}</span></div> }
                                        {(currentValue !== null && changed && !valuesWriting[selectedParamAddr] && !selectedParam?.readOnly) && <span role="button" onClick={() => write()} className="text-success ms-1"><i className="fas fa-download fa-lg"/></span> }
                                    </div>
                                    
                                </>}
                                {['vt8YN'].includes(selectedParam?.type) && <>
                                    <div className="d-flex flex-column">
                                        {selectedParam?.items?.map((it,idx) => (
                                            <div key={selectedParamAddr+idx} className="form-check ms-1">
                                                <input className="form-check-input" type="checkbox" name={'checkname.'+selectedParamAddr+idx} checked={currentValue!==null && ((currentValue & (1 << idx)))}
                                                    onChange={ev => { setNthBit(idx, ev.currentTarget.checked); setChanged(true); }} id={'check.'+selectedParamAddr+idx} disabled={currentValue == null || valuesWriting[selectedParamAddr]} />
                                                <label className="form-check-label" htmlFor={'check.'+selectedParamAddr+idx}>
                                                    <span className="d-flex flex-row">{it}</span>
                                                </label>
                                            </div>
                                        ))}
                                        <div className="d-flex flex-row justify-content-end">
                                            {(!valuesWaiting[selectedParamAddr] && !valuesWriting[selectedParamAddr]) && <span role="button" onClick={() => read()} className="text-primary ms-1"><i className="fas fa-upload fa-lg"/></span> }
                                            {(valuesWaiting[selectedParamAddr] || valuesWriting[selectedParamAddr]) && <div className="spinner-border spinner-border-sm me-1 ms-1" role="status"><span className="visually-hidden">{t('loading...')}</span></div> }
                                            {(currentValue !== null && changed && !valuesWriting[selectedParamAddr] && !selectedParam?.readOnly) && <span role="button" onClick={() => write()} className="text-success ms-1"><i className="fas fa-download fa-lg"/></span> }
                                        </div>
                                        

                                    </div>
                                </>}
                                {['vtColors'].includes(selectedParam?.type) && <>
                                    <div className="d-flex flex-row align-items-center">
                                        <CustomSelect
                                            items={Object.keys(selectedParam.colors).map(c => ({
                                                val: selectedParam.colors[c], title: c
                                            }))}
                                            defaultTitle={t('upload_from_machine')}
                                            selected={currentValue} disabled={currentValue == null || valuesWriting[selectedParamAddr]}
                                            onSelect={c => {setCurrentValue(c);setChanged(true);}}
                                        />
                                        {(!valuesWaiting[selectedParamAddr] && !valuesWriting[selectedParamAddr]) && <span role="button" onClick={() => read()} className="text-primary ms-1"><i className="fas fa-upload fa-lg"/></span> }
                                        {(valuesWaiting[selectedParamAddr] || valuesWriting[selectedParamAddr]) && <div className="spinner-border spinner-border-sm me-1 ms-1" role="status"><span className="visually-hidden">{t('loading...')}</span></div> }
                                        {(currentValue !== null && changed && !valuesWriting[selectedParamAddr] && !selectedParam?.readOnly) && <span role="button" onClick={() => write()} className="text-success ms-1"><i className="fas fa-download fa-lg"/></span> }
                                    </div>  
                                </>}
                                {['vtRowMem'].includes(selectedParam?.type) && <>
                                    <div className="d-flex flex-row mt-2 align-items-center">
                                        <div className="form-outline me-1" style={{maxWidth: 80}}>
                                            <input type="number" name="raw" id="raw" className="form-control"
                                                value={rawAddr} disabled={valuesWriting[rawAddr]}
                                                onInput={ev => {
                                                    setRawAddr(Number(ev.currentTarget.value));
                                                    setChanged(true); setWriteMessage(''); setError('');
                                                }}/>
                                            <label className="form-label" htmlFor="raw">{t('mem_address')}</label>
                                        </div>

                                        <div className="form-outline" style={{maxWidth: 100}}>
                                            <input type={currentValue == null ? 'text' : 'number'} name="val" id="val" className="form-control"
                                                value={currentValue!== null ? currentValue :t('no_data')} disabled={currentValue == null || valuesWriting[rawAddr]}
                                                onInput={ev => {
                                                    setCurrentValue(ev.currentTarget.value);
                                                    setChanged(true); setWriteMessage(''); setError('');
                                                }}/>
                                            <label className="form-label" htmlFor="val">{t('value')}</label>
                                        </div>
                                        {(!valuesWaiting[rawAddr] && !valuesWriting[rawAddr]) && <span role="button" onClick={() => read()} className="text-primary ms-1"><i className="fas fa-upload fa-lg"/></span> }
                                        {(valuesWaiting[rawAddr] || valuesWriting[rawAddr]) && <div className="spinner-border spinner-border-sm me-1 ms-1" role="status"><span className="visually-hidden">{t('loading...')}</span></div> }
                                        {(currentValue !== null && changed && !valuesWriting[rawAddr] && !selectedParam?.readOnly) && <span role="button" onClick={() => write()} className="text-success ms-1"><i className="fas fa-download fa-lg"/></span> }
                                    </div>
                                </>}
                                {['vtSchedule'].includes(selectedParam?.type) && <>
                                    <div className="d-flex flex-column">
                                        <div className="d-flex flex-row align-items-center">
                                            <CustomSelect
                                                items={!Array.isArray(currentValue) ? [] : currentValue.map((val, idx) => ({
                                                    val: idx, title: scheduleTitle(idx, val)
                                                }))}
                                                w100
                                                defaultTitle={t('upload_from_machine')}
                                                selected={selectedSchedule} onSelect={s => setSelectedSchedule(s)} disabled={currentValue == null || valuesWriting[selectedParamAddr]}
                                            />
                                            
                                            
                                        </div>
                                        {(selectedSchedule >= 0 && currentValue !== null) && <>
                                            <div className="d-flex flex-row">
                                                <div className="d-flex flex-column col-8">
                                                    {[t('monday'), t('tuesday'), t('wednesday'), t('thursday'), t('friday'), t('saturday'), t('sunday'), t('holidays')].map((day, idx) => (
                                                        <div key={selectedSchedule+'day'+idx} className="form-check ms-1">
                                                            <input className="form-check-input" type="checkbox" name={'checkname.'+selectedSchedule+'day'+idx} checked={currentValue!==null && ((currentValue[selectedSchedule] & (1 << (24+idx))))}
                                                                onChange={ev => { setNthBitOfSchedule(selectedSchedule, 24+idx, ev.currentTarget.checked); setChanged(true); }} id={'check.'+selectedSchedule+'day'+idx} disabled={currentValue == null || valuesWriting[selectedParamAddr]} />
                                                            <label className="form-check-label" htmlFor={'check.'+selectedSchedule+'day'+idx}>
                                                                <span className="d-flex flex-row">{day}</span>
                                                            </label>
                                                        </div>
                                                    ))}
                                                </div>
                                                <div className="d-flex flex-column col-4 py-2">
                                                    <TimePicker value={scheduleTimeString(currentValue[selectedSchedule])} format="HH:mm" clearIcon={null} clockIcon={null} disableClock={true} onChange={t => timePickerHandler(selectedSchedule, t)} />
                                                    <span>
                                                        <CustomSelect
                                                            items={[{val: 0, title: t('off')}, {val: 1, title: t('on')}]}
                                                            selected={scheduleOnOffValue(currentValue[selectedSchedule])}
                                                            onSelect={val => setScheduleOnOffValue(selectedSchedule, val)}
                                                        />
                                                    </span>
                                                </div>
                                            </div>
                                            
                                        </>}
                                        <div className="d-flex flex-row justify-content-end align-items-center mt-2">
                                            {(!valuesWaiting[selectedParamAddr] && !valuesWriting[selectedParamAddr]) && <span role="button" onClick={() => read()} className="text-primary ms-1"><i className="fas fa-upload fa-lg"/></span> }
                                            {(valuesWaiting[selectedParamAddr] || valuesWriting[selectedParamAddr]) && <div className="spinner-border spinner-border-sm me-1 ms-1" role="status"><span className="visually-hidden">{t('loading...')}</span></div> }
                                            {(currentValue !== null && changed && !valuesWriting[selectedParamAddr] && !selectedParam?.readOnly) && <span role="button" onClick={() => write()} className="text-success ms-1"><i className="fas fa-download fa-lg"/></span> }
                                        </div>
                                        

                                    </div>
                                </>}
                                {['vtHolidays'].includes(selectedParam?.type) && <>
                                    <div className="d-flex flex-column">
                                        <div className="d-flex flex-row align-items-center">
                                            <CustomSelect
                                                items={!Array.isArray(currentValue) ? [] : currentValue.map((val, idx) => ({
                                                    val: idx, title: holidayTitle(val)
                                                }))}
                                                defaultTitle={t('upload_from_machine')}
                                                selected={selectedSchedule} onSelect={s => setSelectedSchedule(s)} disabled={currentValue == null || valuesWriting[selectedParamAddr]}
                                            />
                                            {(!valuesWaiting[selectedParamAddr] && !valuesWriting[selectedParamAddr]) && <span role="button" onClick={() => read()} className="text-primary ms-1"><i className="fas fa-upload fa-lg"/></span> }
                                            {(valuesWaiting[selectedParamAddr] || valuesWriting[selectedParamAddr]) && <div className="spinner-border spinner-border-sm me-1 ms-1" role="status"><span className="visually-hidden">{t('loading...')}</span></div> }
                                            {(currentValue !== null && changed && !valuesWriting[selectedParamAddr] && !selectedParam?.readOnly) && <span role="button" onClick={() => write()} className="text-success ms-1"><i className="fas fa-download fa-lg"/></span> }
                                        </div>
                                    </div>
                                    {(selectedSchedule >= 0 && currentValue !== null) && <>
                                        <DayPicker key={selectedSchedule} mode="single" captionLayout="dropdown" selected={holidayDate(currentValue[selectedSchedule])} defaultMonth={holidayDate(currentValue[selectedSchedule])}
                                            fromYear={new Date().getFullYear()-5} toYear={new Date().getFullYear()+5}
                                            onSelect={date => holidaySetDate(selectedSchedule, date)} />
                                        <div className="form-check align-items-center d-flex">
                                            <input className="form-check-input" type="checkbox" checked={(currentValue[selectedSchedule]&0x0000FFFF) == 0} id="reccurent" onChange={ev => holidaySetRecurrent(selectedSchedule)} />
                                            <label className="form-check-label" htmlFor="reccurent">{t('every_year')}</label>
                                        </div>
                                    </>}
                                </>}
                                {['vtCoinChans'].includes(selectedParam?.type) && <>
                                    <div className="d-flex flex-column">
                                        {currentValue !== null &&
                                            <div className="d-flex flex-column align-items-center justify-content-center mt-2">
                                                <div className="d-flex flex-row mb-1 justify-content-center align-items-center w-100">
                                                    <span className="d-flex flex-fill me-2 "></span>
                                                    <span className="me-1" style={{width: 70}}>Pulses</span>
                                                    <span className="me-1" style={{width: 70}}>Value</span>
                                                </div>
                                                {[0,1,2,3,4,5].map(ch => (
                                                    <div key={ch} className="d-flex flex-row mb-1 justify-content-center align-items-center w-100">
                                                        <span className="me-2 d-flex flex-fill">
                                                            Ch{ch+1}
                                                            <span className="text-muted"> ({((currentValue.vals[ch] >> 16) & 0xFF)*currentValue.scale})</span>
                                                        </span>
                                                        <span className="me-1" style={{width: 70}}>
                                                            <input type="number" name={"pulse"+ch} id={"pulse"+ch} className="form-control"
                                                                value={(currentValue.vals[ch] >> 24) & 0xFF} disabled={valuesWriting[selectedParamAddr]}
                                                                onInput={ev => {
                                                                    const val = ev.target.value & 0xFF;
                                                                    const vals = currentValue.vals.map((el, idx) => idx !== ch ? el : (
                                                                        (el & 0x00FFFFFF) + (val << 24)
                                                                    ));
                                                                    setCurrentValue(prev => ({...prev, vals}));
                                                                    setChanged(true);
                                                                }}/>
                                                        </span>
                                                        <span className="me-1" style={{width: 70}}>
                                                            <input type="number" name={"val"+ch} id={"val"+ch} className="form-control"
                                                                value={(currentValue.vals[ch] >> 16) & 0xFF} disabled={valuesWriting[selectedParamAddr]}
                                                                onInput={ev => {
                                                                    const val = ev.target.value & 0xFF;
                                                                    const vals = currentValue.vals.map((el, idx) => idx !== ch ? el : (
                                                                        (el & 0xFF00FFFF) + (val << 16)
                                                                    ));
                                                                    setCurrentValue(prev => ({...prev, vals}));
                                                                    setChanged(true);
                                                                }}/>
                                                        </span>
                                                    </div>
                                                ))}
                                            </div>
                                        }
                                        <div className="d-flex flex-row justify-content-end align-items-center">
                                            {(!valuesWaiting[selectedParamAddr] && !valuesWriting[selectedParamAddr]) && <span role="button" onClick={() => read()} className="text-primary ms-1"><i className="fas fa-upload fa-lg"/></span> }
                                            {(valuesWaiting[selectedParamAddr] || valuesWriting[selectedParamAddr]) && <div className="spinner-border spinner-border-sm me-1 ms-1" role="status"><span className="visually-hidden">{t('loading...')}</span></div> }
                                            {(currentValue !== null && changed && !valuesWriting[selectedParamAddr] && !selectedParam?.readOnly) && <span role="button" onClick={() => write()} className="text-success ms-1"><i className="fas fa-download fa-lg"/></span> }
                                        </div>
                                    </div>
                                </>}
                                
                            </>
                            }

                        { error && <div className="alert alert-danger alert-dismissible fade show mt-2 w-100" role="alert">
                            { error }
                        </div> }
                        { writeMessage && <div className="alert alert-success alert-dismissible fade show mt-2 w-100" role="alert">
                            { writeMessage }
                        </div> }
                            
                        </div>
                    </>}
                    

                    
                </div>
            </div>
        </CustomModal>
    )
}