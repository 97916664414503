import { useTranslation } from "react-i18next"

export const DeleteConfirm = ({title, message, onConfirm, onCancel, confirmText}) => {
	const { t } = useTranslation();

    return (
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">{title}</h5>
            <p className="card-text">{message}</p>
            <button className="btn btn-danger me-1" onClick={onConfirm}>{confirmText || t('delete')}</button>
            <button className="btn btn-secondary" onClick={onCancel}>{t('cancel')}</button>
          </div>
        </div>
    )
}