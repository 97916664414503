import { useEffect, useState } from "react";
import API from "../../../service/api";
import CustomTable from "../../other/CustomTable";
import * as mdb from 'mdb-ui-kit'; // lib
import { CustomSelect } from "../../../utils/CustomSelect";
import { CustomModal } from "../../other/CustomModal";
import { CodeInput } from "../../other/CodeInput";
import ImageFallback from 'react-image-fallback';
import WS from "../../../service/ws";
import { AdvancedSettingsModal } from "./AdvancedSettingsModal";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

export const MachinesPage = ({rights}) => {
    const [selectedId, setSelectedId] = useState(null);
    const [machinesLoading, setMachinesLoading] = useState(false);
    const [data, setData] = useState([]);
    const [onlyUnassigned, setOnlyUnassigned] = useState(false);
    const [searchParams] = useSearchParams();
    const qmid = searchParams.get('mid');
    const { t } = useTranslation();

    const fetchMachines = () => {
        setMachinesLoading(true);
        API.getMachinesAdm()
            .then(data => {
                setData(data);
                if (qmid && data.find(m => m.id === Number(qmid))) setSelectedId(Number(qmid));
            })
            .finally(() => setMachinesLoading(false));
    }

    useEffect(() => {
        fetchMachines();
    }, []);

    
    const MachinesTable = (
        <div className="d-flex flex-fill flex-column overflow-hidden ms-3">
            <div className="d-flex flex-column overflow-hidden card">
                <div className="d-flex flex-column card-body overflow-hidden">
                    <div className="d-flex flex-row ms-2 mb-2 align-items-center">
                        <h5 className="me-1 px-0 py-1 m-0 flex-fill">{t('machines')}</h5>
                    </div>
                    { rights?.Machines?.Transfer && <span className="btn text-white btn-floating bg-primary position-absolute text-center" role="button" style={{top: 15, right: 15}} onClick={() => { setSelectedId(-2); }}>
                        <span className="d-flex justify-content-center align-items-center w-100 h-100"><i className="fas fa-right-to-bracket fa-lg"/></span>
                    </span> }
                    <CustomTable columns={[t('name'), t('location'), t('boxes')]}
                        columnWidths={[65,25,10]}
                        columnRenderers={[
                            m => <div className="d-flex flex-row align-items-center">
                                <span><ImageFallback src={`/mtypes/mt${m.type?.id}.png`} width={22} height={22} className="me-1" title={m.type?.name}  fallbackImage={<span></span>}/></span>
                                <div className="d-flex flex-column" style={{lineHeight: '1.1rem'}}>
                                    <span>{m.name}</span>
                                    <span className="text-muted" style={{fontSize: '80%'}}>{m.sn}</span>
                                </div>
                            </div>,
                            m => m.location ? <span className="d-flex flex-row align-items-center"><i className="fas fa-map-marker-alt text-primary me-1"/>{m.location.Name}</span> : <span className="fst-italic">{t('unassigned')}</span>,
                            m => <span>{m.nrOfBoxes}</span>,
                        ]}
                        search={(m, text) => (m.name+m.sn).toLocaleUpperCase().includes(text.toLocaleUpperCase())}
                        searchTitle={t('name')}
                        filter={m => onlyUnassigned ? !m.location : true}
                        onClick={p => { setSelectedId(p.id); }}
                        activeRows={p => p.id === selectedId}
                        loading={machinesLoading}
                        data={data}
                        header={() => (
                            <div className="d-flex flex-row me-2">
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value={onlyUnassigned} onChange={ev => setOnlyUnassigned(ev.currentTarget.checked)} id="unassigned" />
                                    <label className="form-check-label" htmlFor="unassigned">{t('show_only_unassigned')}</label>
                                </div>
                            </div>
                        )}/>
                </div>
            </div>
        </div>
    )

    return (
        <section className="d-flex flex-column flex-fill w-100 h-100" style={{minWidth: 800}}>
            <div className="d-flex flex-row h-100">
                {selectedId && <>
                    <div className="d-flex flex-column col-7">{MachinesTable}</div>
                    
                    <div className="d-flex flex-column col-5 ms-3 pe-3">
                        {selectedId !== -2 && <MachineEdit machineId={selectedId} rights={rights} onAdd={() => fetchMachines()} onEdit={() => fetchMachines()} onClose={() => setSelectedId(null)}/> }
                        {selectedId === -2 && <ImportMachineModal onJoin={id => { fetchMachines(); setSelectedId(id); }} onClose={() => setSelectedId(null)}/>}
                    </div>   
                </>}
                {!selectedId && MachinesTable}
            </div>
        </section>
    )
}

const MachineEdit = ({machineId, rights, onEdit, onClose}) => {
    const [machine, setMachine] = useState(null);
    const [name, setName] = useState('');
    const [locationId, setLocationId] = useState(-999);
    const [locations, setLocations] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [canEditMachine, setCanEditMachine] = useState(false);
    const [canAssignToLocation, setCanAssignToLocation] = useState(rights?.Locations?.View && rights?.Locations?.AssignMachines);
    const [canTransfer, setCanTransfer] = useState(false);
    const [canAdvancedSettings, setCanAdvancedSettings] = useState(false);
    const [changed, setChanged] = useState(false);
    const [transfer, setTransfer] = useState(false);
    const [advancedSettings, setAdvancedSettings] = useState(null);
    const { t } = useTranslation();

    const fetch = () => {
        setError('');
        setChanged(false);
        setLocations(null);
        setLocationId(-999);

        setLoading(true);
        API.getMachineAdm(machineId)
            .then(m => {
                setMachine(m);
                setName(m.name);
                setLocationId(m.location ? m.location.id : -999);
                if (canAssignToLocation) API.getLocationsAdmin()
                    .then(l => setLocations(l))
                    .catch(err => setError(err.message))
                    .finally(() => setLoading(false));
                else { setLoading(false); setLocations([]); }
            })
            .catch(err => { setError(err.message); setLoading(false); });
    }

    const update = () => {
        setLoading(true);
        API.updateMachineAdm(machineId, machine.name, machine.info, locationId === -999 ? null : locationId)
            .then(() => { fetch(); onEdit(); })
            .catch(err => setError(err.message))
            .finally(() => setLoading(false));
    }

    useEffect(() => {
        setCanEditMachine(rights?.Machines?.Actions?.ChangeName);
        setCanTransfer(rights?.Machines?.Transfer);
        setCanAdvancedSettings(machine && machine.advanced_params && machine.advanced_params.length > 0);
    }, [rights, machine]);


    useEffect(() => {
        fetch();
    }, [machineId, canAssignToLocation]);

    useEffect(() => {
        document.querySelectorAll(".form-outline").forEach((formOutline) => {
			new mdb.Input(formOutline).init();
		});

    }, [machineId, machine, loading]);

    if (!machine || !locations) return null;

    return (
        <div className="d-flex flex-column overflow-hidden card">
            <TransferModal machineId={machineId} open={transfer} onClose={() => setTransfer(false)} onShared={() => { setTransfer(false); onEdit(); onClose(); }}/>
            {advancedSettings && <AdvancedSettingsModal machine={advancedSettings} onClose={() => setAdvancedSettings(null)} /> }
            <button type="button" className="btn-close position-absolute" style={{right: 5, top: 5}} onClick={() => onClose ? onClose() : {}}></button>
            <div className="d-flex flex-column overflow-hidden card-body">
                <div className="d-flex flex-column w-100 justify-content-center overflow-hidden">
                    <i className="fas fa-server fa-4x mb-2"/>
                    
                    {loading && (
                        <div className="d-flex w-100 justify-content-center">
                            <div className="spinner-border" role="status">
                                <span className="visually-hidden">{t('loading...')}</span>
                            </div>
                        </div>
                        
                    )}
                    {!loading && <>
                        <h5 className="text-center">{name}</h5>
                        
                        <div className="d-flex flex-column overflow-auto pe-1 pt-2">
                            { !canEditMachine && <>
                                <ul className="list-group list-group-light p-0">
                                    <li className="list-group-item p-1"><i className="fas fa-circle-info fa-fw text-primary me-1"/><span className="fw-bold me-1">{t('info')}:</span>{machine.info}</li>
                                </ul>
                            </> }

                            { canEditMachine && <>
                                <div className="d-flex mb-3 flex-row align-items-center">
                                    <i className="fas fa-pen-to-square fa-fw text-primary"/>
                                    <div className="col ms-2">
                                        <div className="form-outline">
                                            <input type="text" name="name" id="name" className="form-control" value={machine.name} onInput={ev => { setMachine(m => ({...m, name: ev.target.value})); setChanged(true); }}/>
                                            <label className="form-label" htmlFor="name">{t('name')}</label>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="d-flex mb-3 flex-row align-items-center">
                                    <i className="fas fa-circle-info fa-fw text-primary"/>
                                    <div className="col ms-2">
                                        <div className="form-outline">
                                            <textarea rows={3} type="text" name="info" id="info" className="form-control" value={machine.info} onInput={ev => { setMachine(m => ({...m, info: ev.target.value})); setChanged(true); }}/>
                                            <label className="form-label" htmlFor="info">{t('info')}</label>
                                        </div>
                                    </div>
                                </div>
                            </> }

                            { canAssignToLocation && <div className="d-flex mb-3 flex-row align-items-center">
                                <i className="fas fa-map-marker-alt fa-fw text-primary"/>
                                <div className="col ms-2">
                                    <CustomSelect title={t('location')}
                                        items={[{val: -999, title: t('unassigned'), icon: 'fas fa-link-slash'}].concat(locations.filter(l => l.lType == 0).map(l => ({val: l.id, title: l.Name})))}
                                        selected={locationId}
                                        w100
                                        onSelect={id => { setLocationId(id); setChanged(true); } }
                                    />
                                </div>
                            </div> }

                            

                            { error && <div className="alert alert-danger alert-dismissible fade show mt-2" role="alert">
                                { error }
                            </div> }

                                { (canEditMachine || canAssignToLocation) && <button className="btn btn-primary mb-2" disabled={!changed || loading} onClick={() => update()}>{t('save')}</button> }
                                { canTransfer && <button className="btn btn-secondary mb-2" onClick={() => setTransfer(true)}>{t('transfer')}</button> }
                                { canAdvancedSettings && <button className="btn btn-secondary" onClick={() => setAdvancedSettings(machine)}>{t('advanced_settings')}</button>}
                        </div>
                        

                    </>}
                </div>
            </div>
        </div>
    )

}

const TransferModal = ({machineId, open, onShared, onClose}) => {
    const [selectedId, setSelectedId] = useState(null);
    const [companies, setCompanies] = useState(null);
    const [search, setSearch] = useState('');
    const [shareWithLink, setShareWithLink] = useState(false);
    const [shareLoading, setShareLoading] = useState(false);
    const [shareError, setShareError] = useState('');
    const [shareInvitation, setShareInvitation] = useState(null);
    const [shareSuccessMessage, setShareSuccessMessage] = useState('');
    const { t } = useTranslation();

    useEffect(() => {
        setSearch('');
        setSelectedId(null);
        setShareWithLink(false);
        setShareLoading(false);
        setShareSuccessMessage('');
        setShareError('');
        setShareInvitation(null);
        setCompanies(null);
        if (open){
            API.getMachineTransferVariants(machineId)
                .then(v => {
                    let c = [];
                    if (v.parent) c.push({isParent: true, ...v.parent});
                    if (v.subcompanies) c = c.concat(v.subcompanies);
                    setCompanies(c);
                });
        }
    }, [open, machineId]);

    useEffect(() => {
        document.querySelectorAll(".form-outline").forEach((formOutline) => {
			new mdb.Input(formOutline).init();
		});
    }, [companies]);

    const confirm = () => {
        if (!shareWithLink){
            setShareLoading(true);
            API.transferMachineToCompany(machineId, selectedId)
                .then(() => { setShareSuccessMessage(t('transfer_success')); onShared(); })
                .catch(err => { setShareError(err.message); })
                .finally(() => setShareLoading(false));
        }else{
            setShareLoading(true);
            API.transferMachineLink(machineId)
                .then(({invitationId}) => { setShareInvitation(invitationId); })
                .catch(err => { setShareError(err.message); })
                .finally(() => setShareLoading(false));
        }

    }

    return (
        <CustomModal open={open} onClose={onClose}>
            <div className="card">
                <div className="card-body">
                    <h5 className="card-title">Transfer Machine</h5>
                    <div className="d-flex flex-column justify-content-center align-items-center my-2">
                        {(companies == null || shareLoading) && (
                            <div className="spinner-border" role="status">
                                <span className="visually-hidden">{t('loading...')}</span>
                            </div>
                        )}
                        {companies !== null && (
                            <div className="d-flex flex-column">
                                
                                {shareError && (
                                    <div className="alert alert-danger alert-dismissible fade show mt-2" role="alert">
                                        { shareError }
                                    </div>
                                )}
                                {shareSuccessMessage && (
                                    <div className="alert alert-success alert-dismissible fade show mt-2" role="alert">
                                        { shareSuccessMessage }
                                    </div>
                                )}
                                {shareInvitation && (
                                    <div className="d-flex flex-column align-items-center justify-content-center">
                                        <span>{t('your_invitation_id')}:</span>
                                        <h3 className="text-success" style={{letterSpacing: '10px', marginLeft: 10, fontWeight: 'bold'}}>{shareInvitation}</h3>
                                    </div>
                                )}
                                <div className="d-flex flex-column w-100">
                                    {(!shareError && !shareSuccessMessage && !shareInvitation) && <>
                                        <div className="d-flex form-outline px-1 mb-1 mt-1">
                                            <input type="text" name="search" id="search" className="form-control" value={search} onInput={ev => { setSearch(ev.currentTarget.value);}} autoComplete="off"/>
                                            <label className="form-label" htmlFor="search">{t('search')}</label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value={shareWithLink} id="shareWithLink" onChange={ev => setShareWithLink(ev.currentTarget.checked)} />
                                            <label className="form-check-label" htmlFor="shareWithLink">{t('share_with_invitation')}</label>
                                        </div>
                                        <div className="d-flex flex-column overflow-auto px-1 pt-2 pb-2" style={{maxHeight: 500, minWidth: 300, userSelect: 'none'}}>
                                            {companies.map(l => (
                                                <div key={l.id} className={"flex-row align-items-center hover-shadow p-1"+(shareWithLink?' d-none':'')} style={{cursor: 'pointer', background: l.id == selectedId ? '#0000001a' :'', display: l.name.toLocaleUpperCase().indexOf(search.toLocaleUpperCase()) == '-1' ? 'none': 'flex'}} onClick={() => setSelectedId(l.id)}>
                                                    <i className="fas fa-building text-primary"/>
                                                    <span className="ms-1">{l.name}</span>
                                                    {l.isParent && <span className="fst-italic">&nbsp;(parent)</span>}
                                                </div>
                                            ))}
                                        </div>

                                        <div className="d-flex flex-row w-100">
                                            <button className="btn btn-danger me-1 flex-fill" disabled={!shareWithLink && !selectedId} onClick={() => confirm()}>{t('confirm')}</button>
                                            <button className="btn btn-secondary flex-fill" onClick={onClose}>{t('cancel')}</button>
                                        </div>
                                    </>}
                                    {(shareError || shareSuccessMessage || shareInvitation) && <button className="btn btn-secondary w-100" onClick={onClose}>{t('close')}</button>}
                                    
                                </div>
                                
                            </div>
                            
                        )}
                    </div>
                    
                    
                </div>
            </div>
        </CustomModal>
    )
}

const ImportMachineModal = ({onJoin, onClose}) => {
    const [code, setCode] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        document.querySelectorAll(".form-outline").forEach((formOutline) => {
			new mdb.Input(formOutline).init();
		});
    }, []);

    useEffect(() => {
        setError('');
    }, [code]);

    const transfer = () => {
        setLoading(true);
        API.transferMachineAccept(code)
            .then(({machineId}) => onJoin(machineId))
            .catch(err => {setError(err.message); })
            .finally(() => setLoading(false));

    }
    return (
        <div className="d-flex flex-column overflow-hidden card">
            <button type="button" className="btn-close position-absolute" style={{right: 5, top: 5}} onClick={() => onClose ? onClose() : {}}></button>
            <div className="d-flex flex-column card-body overflow-hidden">
                <div className="d-flex flex-column w-100 justify-content-center">
                    <i className="fas fa-server fa-4x mb-2"/>
                    <div className="d-flex flex-column justify-content-center align-items-center">
                        <h5 className="text-center">{t('add_machine')}</h5>
                        
                        <h6>{t('insert_invitation_code')}:</h6>

                        <div className="d-flex flex-row justify-content-center">
                            <CodeInput onCodeChange={c => setCode(c)}/>
                        </div>
                        
                        { error && <div className="alert alert-danger alert-dismissible fade show mt-2 w-100" role="alert">
                            { error }
                        </div> }
                        <button className="btn btn-primary mt-2 w-100" disabled={code.length < 6 || loading} onClick={() => transfer()}>{t('add')} <i className="far fa-square-plus fa-lg"/></button>
                    </div>
                </div>
            </div>
        </div>
    )
}

