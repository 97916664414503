import i18n from '../i18n'; // Your i18n instance
import * as locales from 'date-fns/locale';


export default class Lang {
    __current = "en";
    __languages = ['en', 'fr', 'ro', 'de', 'es', 'nl'];
    __listeners = [];
    static instance;

    constructor(){
        const lang = localStorage.getItem('lang');
        if (lang && this.__languages.includes(lang)) this.changeLanguage(lang);
        if (!Lang.instance) Lang.instance = this;
    }

    addListener(callback){
        this.__listeners.push(callback);
    }

    removeListener(callback){
        this.__listeners = this.__listeners.filter(c => c !== callback);
    }

    changeLanguage(lang){
        if (lang !== this.__current && this.__languages.includes(lang)){
            this.__current = lang;
            this.__listeners.forEach(c => c(lang));
            localStorage.setItem('lang', lang);
            i18n.changeLanguage(lang);
        }
    }

    getCurrent(){
        return this.__current;
    }

    getCurrentLocale(){
        return {
            'en': locales.enGB,
            'ro': locales.ro,
            'fr': locales.fr,
            'de': locales.de,
            'es': locales.es,
            'nl': locales.nl
        }[this.__current] || locales.enGB;
    }

    getAll(){
        return this.__languages;
    }
}