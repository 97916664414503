import { useEffect, useState } from "react"
import API from "../../../service/api";
import * as mdb from 'mdb-ui-kit'; // lib

import CustomTable from "../../other/CustomTable";
import { TabsViewer } from "../../../utils/TabsViewer";
import { LocationsGIS } from "../group/LocationsGIS";
import { CustomModal } from "../../other/CustomModal";
import { DeleteConfirm } from "../../other/DeleteConfirm";
import { CustomSelect } from "../../../utils/CustomSelect";
import { CodeInput } from "../../other/CodeInput";
import { useTranslation } from "react-i18next";

export const GroupsPage = ({rights}) => {
    const [groups, setGroups] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
    const [deleteId, setDeleteId] = useState(null);
    const { t } = useTranslation();

    const fetch = () => {
        setLoading(true);

        API.getGroupsAdmin()
            .then(g => { setGroups(g); setLoading(false);})
    }

    useEffect(() => {
        fetch();
    }, []);

    useEffect(() => {
        document.querySelectorAll(".tooltip").forEach(el => {
            el.remove();
        });
        document.querySelectorAll(".my-tooltip").forEach(el => {
            new mdb.Tooltip(el);
        });
    }, [groups, selectedId, loading]);

    const GroupsTable = (
        <div className="d-flex flex-fill flex-column overflow-hidden ms-3">
            <div className="d-flex flex-column overflow-hidden card">
                <div className="d-flex flex-column card-body overflow-hidden">
                    { rights?.Groups?.Add &&
                    <span className="btn text-white btn-floating bg-primary position-absolute text-center" role="button" style={{top: 15, right: 60}} onClick={() => { setSelectedId(-1); }}>
                        <span className="d-flex justify-content-center align-items-center w-100 h-100"><i className="fas fa-plus fa-lg"/></span>
                    </span> }
                    { rights?.Groups?.Add && <span className="btn text-white btn-floating bg-primary position-absolute text-center" role="button" style={{top: 15, right: 15}} onClick={() => { setSelectedId(-2); }}>
                        <span className="d-flex justify-content-center align-items-center w-100 h-100"><i className="fas fa-right-to-bracket fa-lg"/></span>
                    </span> }
                    <div className="d-flex flex-row ms-2 mb-2 align-items-center">
                        <h5 className="me-1 px-0 py-1 m-0 flex-fill">{t('groups')}</h5>
                    </div>
                    <CustomTable columns={[t('name'), t('count')]}
                        columnWidths={[65, 35]}
                        columnRenderers={[
                            g => <span>
                                    {g.shared && 
                                        <span className="my-tooltip me-1" data-mdb-toggle="tooltip" title={"Shared by " + g.groupCompany.name}>
                                            <i className="fas fa-retweet fa-lg text-warning"/>
                                        </span>
                                    }
                                    {g.gType == 1 && <span><i className="fas fa-bookmark text-warning me-1"/></span>}{g.Name}
                                    {g.nrOfShares>0 && 
                                        <span className="my-tooltip ms-1" data-mdb-toggle="tooltip" title={`Shared to ${g.nrOfShares} ${g.nrOfShares > 1 ? 'companies' : 'company'}`}>
                                            <i className="fas fa-share text-secondary"/>
                                        </span>
                                    }
                                </span>,
                            g => <div className="d-flex flex-row">
                                <span style={{minWidth: 40}} className="me-1"><i className="fas fa-map-marker-alt text-primary me-1"/>{g.nrOfLocations}</span>
                                <span><i className="fas fa-user text-primary me-1"/>{g.nrOfUsers}</span>
                            </div>
                        ]}
                        search={(g, text) => g.Name.toLocaleUpperCase().includes(text.toLocaleUpperCase())}
                        searchTitle={t('name')}
                        onClick={g => { setSelectedId(g.id); }}
                        activeRows={g => g.id === selectedId}
                        rowClassName={g => g.gType == 1 ? 'list-group-item-warning fst-italic warning-row' : ''}
                        loading={loading}
                        data={groups}/>
                </div>
            </div>
        </div>
    )


    return (
        <section className="d-flex flex-column flex-fill w-100 h-100" style={{minWidth: 800}}>
            <div className="d-flex flex-row h-100">
                {selectedId && <>
                    <div className="d-flex flex-column col-5">{GroupsTable}</div>
                    <div className="d-flex flex-column col-7 ms-3 pe-3">
                        {selectedId === -1 && <GroupAdd onAdd={() => { setSelectedId(null); fetch(); }} onClose={() => setSelectedId(null)}/>}
                        {selectedId === -2 && <GroupJoin onJoin={(id) => {fetch(); setSelectedId(id); }} onClose={() => setSelectedId(null)}/>}
                        {selectedId > 0 &&
                            <GroupEditor key={selectedId} groupId={selectedId} rights={rights} onClose={() => setSelectedId(null)}
                                onLocationAdd={() => setGroups(old => old.map(g => g.id !== selectedId ? g : ({...g, nrOfLocations: g.nrOfLocations+1})))}
                                onLocationRemove={() => setGroups(old => old.map(g => g.id !== selectedId ? g : ({...g, nrOfLocations: g.nrOfLocations-1})))}
                                onUserAdd={() => setGroups(old => old.map(g => g.id !== selectedId ? g : ({...g, nrOfUsers: g.nrOfUsers+1})))}
                                onUserRemove={() => setGroups(old => old.map(g => g.id !== selectedId ? g : ({...g, nrOfUsers: g.nrOfUsers-1})))}
                                onGroupDelete={() => setDeleteId(selectedId)}
                                onGroupEdit={() => fetch()}
                            />
                        }
                    </div>
                </>}
                {!selectedId && <div className="d-flex flex-column w-100">{GroupsTable}</div>}

                <CustomModal open={deleteId !== null} onClose={() => setDeleteId(null)}>
                    <DeleteConfirm title={t('remove_group')} message={t('remove_group_prompt')} onConfirm={() => {
                        API.deleteGroup(deleteId).then(() => { fetch(); setSelectedId(null);});
                        setDeleteId(null);
                    }} onCancel={() => setDeleteId(null)}/>
                </CustomModal>
                
            </div>
        </section>
    )

}

const GroupEditor = ({groupId, rights, onClose, onLocationAdd, onLocationRemove, onUserAdd, onUserRemove, onGroupDelete, onGroupEdit}) => {
    const [group, setGroup] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [canEditGroup, setCanEditGroup] = useState(false);
    const [canDeleteGroup, setCanDeleteGroup] = useState(false);
    const [canShareGroup, setCanShareGroup] = useState(false);
    const [canViewLocations, setCanViewLocations] = useState(false);
    const [canViewUsers, setCanViewUsers] = useState(false);
    const [canAssignUser, setCanAssignUser] = useState(false);
    const [canAssignLocations, setCanAssignLocations] = useState(false);
    const [changed, setChanged] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [editGroupName, setEditGroupName] = useState('');
    const { t } = useTranslation();

    const fetch = () => {
        setLoading(true);
        setError('');
        setEditMode(false);
        setEditGroupName('');
        setChanged(false);
        API.getGroupAdmin(groupId)
            .then(g => {
                setGroup(g);
                setCanEditGroup(rights?.Groups?.Change && !g.shared);
                setCanDeleteGroup(rights?.Groups?.Delete);

                setCanViewLocations(g.locations !== undefined);
                setCanViewUsers(g.users !== undefined);
                setCanShareGroup(g.Share);
                setCanAssignUser(g.UserAssociation);
                setCanAssignLocations(g.AssignLocations);
                setCanEditGroup(g.Change);
                setCanDeleteGroup(g.Delete);
            })
            .catch(err => {
                setError(err.message);
                setGroup({});
            })
            .finally(() => { setLoading(false); })
    }

    useEffect(() => {
        fetch();
    }, [groupId]);

    useEffect(() => {
        document.querySelectorAll(".form-outline").forEach((formOutline) => {
			new mdb.Input(formOutline).init();
		});
    }, [editMode]);


    if (!group) return null;

    return (
        <div className="d-flex flex-column overflow-hidden card">
            <button type="button" className="btn-close position-absolute" style={{right: 5, top: 5}} onClick={() => onClose ? onClose() : {}}></button>
            <div className="d-flex flex-column card-body overflow-hidden">
                <div className="d-flex flex-column w-100 justify-content-center text-start">
                    <i className="fas fa-layer-group fa-4x mb-2"/>
                    <div className="d-flex flex-row  justify-content-center">
                        {!editMode && <h5 className="text-center mb-1" style={{marginLeft: 40, marginRight: 40}}>{group.Name || ''}</h5> }
                        { editMode && (
                            <div className="d-flex flex-row justify-content-center align-items-center w-100">
                                <div className="d-flex form-outline" style={{width: 400}}>
                                    <input type="text" name="name" id="name" className="form-control" value={editGroupName} onInput={ev => { setEditGroupName(ev.currentTarget.value); setChanged(true); }}/>
                                    <label className="form-label" htmlFor="name">{t('name')}</label>
                                </div>
                                <span className={"position-absolute " + (changed ? 'text-success' : 'text-muted')} style={{marginLeft: 400+30, opacity: changed ? 1 : 0.25, cursor: changed ? 'pointer' : 'default'}} role="button" 
                                    onClick={() => {
                                        setChanged(false);
                                        API.updateGroupAdmin(groupId, editGroupName)
                                            .then(() => {
                                                fetch();
                                                onGroupEdit();
                                            })
                                            .finally(() => {
                                                setEditMode(false);
                                            });
                                    }}><i className="fas fa-check fa-lg"/></span>
                                <span className="text-danger position-absolute" style={{marginLeft: 400+75}} role="button" onClick={() => {
                                    setEditMode(false);
                                }}><i className="fas fa-xmark fa-lg"/></span>
                            </div>
                            
                        )}
                        {(canEditGroup && !editMode && !error) && <span role="button" className="position-absolute" style={{right: 35}} onClick={() => { setEditGroupName(group.Name); setEditMode(true); }}><i className="fas fa-fw fa-pen-to-square"/></span> }
                        {(canDeleteGroup && !editMode && !error) && <span role="button" className="position-absolute" style={{right: 15}} onClick={() => { onGroupDelete(); }}><i className="fas fa-fw fa-trash-can text-danger"/></span>}
                        
                    </div>
                    { group.shared && <h6 className="text-muted">Shared by {group.groupCompany?.name}</h6> }
                    { group?.gType == 1 && <div className="alert alert-warning alert-dismissible fade show mt-2" role="alert">
                        <i className="fas fa-bookmark text-warning me-1"/>{t('special_ungrouped_group_prompt')}
                    </div> }
                    
                </div>

                {loading && (
                    <div className="d-flex w-100 justify-content-center">
                        <div className="spinner-border" role="status">
                            <span className="visually-hidden">{t('loading...')}</span>
                        </div>
                    </div>
                    
                )}

                {(error && !loading) && (
                    <div className="alert alert-danger alert-dismissible fade show mt-2" role="alert">
                        { error }
                    </div>
                )}
                {(!error && !loading && (canViewLocations || canViewUsers || canShareGroup)) && (<div className="w-100 d-flex flex-column align-items-center overflow-hidden">
                        <TabsViewer
                            id="admgroups" autoDefault fullSize
                            tabs={[
                                ...(canViewLocations ? [{
                                    name: 'locations', title: t('locations'), icon: 'fas fa-map-marker-alt',
                                    component: <GroupEditorLocationsTab groupId={groupId} shared={group.shared} canAssignLocations={canAssignLocations} locations={group.locations} onLocationAdd={() => { onLocationAdd(); fetch(); }} onLocationRemove={() => { onLocationRemove(); fetch(); }}/>
                                }] : []),
                                ...(canViewUsers ? [{
                                    name: 'users', title: t('users'), icon: 'fas fa-user',
                                    component: <GroupEditorUsersTab users={group.users} shared={group.shared} canAssignUser={canAssignUser} groupId={groupId} onEdit={() => fetch()} onUserAdd={() => {onUserAdd(); fetch(); }} onUserRemove={() => {onUserRemove(); fetch();}}/>
                                }] : []),
                                ...(canShareGroup ? [{
                                    name: 'shared', title: t('shared'), icon: 'fas fa-retweet',
                                    component: <GroupEditorSharedTab groupId={groupId} canShare={canShareGroup} onDelete={() => { fetch(); onGroupEdit(); }} onShare={() => {fetch(); onGroupEdit(); }}/>
                                }] : [])
                            ]}
                        />
                </div>)}
            </div>
        </div>
    )
}

const GroupEditorLocationsTab = ({groupId, canAssignLocations, locations, onLocationAdd, onLocationRemove}) => {
    const [gLocations, setGLocations] = useState(null);
    const [selectedId, setSelectedId] = useState(null);
    const [deleteLocationId, setDeleteLocationId] = useState(null);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [locationError, setLocationError] = useState('');
    const [openAddLocation, setOpenAddLocation] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        setGLocations(locations);
        setSelectedId(null);
    }, [groupId, locations]);

    useEffect(() => {
        setLocationError('');

        if (selectedId > 0) {
            API.getLocationAdmin(selectedId)
                .then(l => setSelectedLocation(l))
                .catch(err => { setLocationError(err.message); setSelectedLocation(null);});
        }else {
            setSelectedLocation(null);
        }
    }, [selectedId]);

    return (
        <div className="w-100 d-flex flex-column overflow-auto mt-1">
            <CustomModal open={deleteLocationId !== null} onClose={() => setDeleteLocationId(null)}>
                <DeleteConfirm title={t('remove_location')} message={t('remove_location_from_group_prompt')} onConfirm={() => {
                    API.removeLocationFromGroupAdmin(groupId, deleteLocationId).then(() => {
                        if (deleteLocationId == selectedId) setSelectedId(null);
                        onLocationRemove();
                    });
                    setDeleteLocationId(null);
                    
                }} onCancel={() => setDeleteLocationId(null)}/>
            </CustomModal>

            <AddLocationModal groupId={groupId} open={openAddLocation} onClose={() => setOpenAddLocation(false)} onConfirm={id => {
                API.addLocationToGroupAdmin(groupId, id)
                    .then(() => {
                        onLocationAdd();
                    })
                setOpenAddLocation(null);

            }}/>
            {(!selectedId || !selectedLocation) &&
                <div>
                    <CustomTable columns={[t('name'), t('address'), t('machines')]}
                        columnWidths={[40, 40, 20]}
                        columnRenderers={[
                            l => <span>{l.lType == 1 && <span><i className="fas fa-bookmark text-warning me-1"/></span>}{l.Name}</span>,
                            l => <span>{l.address}</span>,
                            l => <span>{l.nrOfMachines}</span>,
                        ]}
                        search={(l, text) => (l.Name+l.address).toLocaleUpperCase().includes(text.toLocaleUpperCase())}
                        searchTitle={`${t('name')}, ${t('address')}`}
                        onClick={l => { setSelectedId(l.id); }}
                        activeRows={l => l.id === selectedId}
                        data={gLocations||[]}
                        loading={gLocations == null}
                        rowClassName={l => l.lType == 1 ? 'list-group-item-warning fst-italic warning-row' : ''}
                        header={() => (
                            <div className="d-flex flex-row me-2">
                                { canAssignLocations && <span role="button" onClick={() => setOpenAddLocation(true)}><i className="fas fa-xl fa-square-plus text-primary"/></span> }
                            </div>
                        )}/>
                </div>
            }

            {(selectedId && selectedLocation) && (
                <div className="d-flex flex-column mt-1 pt-2 overflow-auto px-4" style={{borderTop: '2px solid #d7d7d7'}}>
                    
                    <div className="d-flex flex-row align-items-center mb-1">
                        <div className="d-flex flex-row justify-content-start">
                            <span type="button" className="me-2" style={{width: 30}} onClick={() => setSelectedId(null)}><i className="fas fa-arrow-left-long fa-lg"/></span>
                            <i className="fas fa-map-marker-alt fa-xl text-primary"/><h5 className="m-0 ms-1">{selectedLocation?.Name || '?'}</h5>
                        </div>
                        <div className="d-flex flex-row justify-content-end flex-fill">
                            { canAssignLocations && <button type="button" className="btn btn-danger px-3 py-2" data-mdb-ripple-color="dark" onClick={() => setDeleteLocationId(selectedId)}>{t('delete')}</button> }
                        </div>
                    </div>

                    {locationError && (
                        <div className="alert alert-danger alert-dismissible fade show mt-2" role="alert">
                            { locationError }
                        </div>
                    )}

                    {(!locationError && selectedLocation) && (
                        <>
                            <div className="d-flex flex-column">
                                <div className="d-flex flex-column" style={{height: 200}}>
                                    <LocationsGIS locations={[selectedLocation]} center={selectedLocation.geo} zoom={12} noPopups />
                                </div>
                                <div className="d-flex flex-column mt-1 px-2 flex-fill">
                                    {selectedLocation.address && <>
                                        <h6 className="mb-0">{t('address')}</h6>
                                        <span className="mb-1">{selectedLocation.address}</span>
                                    </>}
                                    {selectedLocation.Info && <>
                                        <h6 className="mb-0">{t('info')}</h6>
                                        <span className="mb-1">{selectedLocation.Info}</span>
                                    </>}
                                    
                                    <h6 className="mb-0">{t('groups')}</h6>
                                    <div className="d-flex flex-column mb-1" style={{maxHeight: 120, overflow: 'auto'}}>
                                        { ( !selectedLocation.groups || selectedLocation.groups.length == 0) && <span className="text-muted">{t('none_lower')}</span> }
                                        { selectedLocation.groups?.map(g => (
                                            <div key={g.id} className="d-flex flex-row align-items-center ms-1"><i className="fas fa-layer-group text-primary me-1"/>{g.Name}</div>
                                        )) }
                                    </div>
                                    <h6 className="mb-0">{t('machines')}</h6>
                                    <div className="d-flex flex-column mb-1" style={{maxHeight: 120, overflow: 'auto'}}>
                                        { selectedLocation.machines.length == 0 && <span className="text-muted">{t('none_lower')}</span> }
                                        { selectedLocation.machines.map(g => (
                                            <div key={g.id} className="d-flex flex-row align-items-center ms-1"><i className="fas fa-server text-primary me-1"/>{g.name}</div>
                                        )) }
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            )}
        </div>
    )

}

const GroupEditorUsersTab = ({groupId, canAssignUser, users, onEdit, onUserAdd, onUserRemove}) => {
    const [gUsers, setGUsers] = useState(null);
    const [changed, setChanged] = useState(false);
    const [permissions, setPermissions] = useState([]);
    const [selectedId, setSelectedId] = useState(null);
    const [deleteUserId, setDeleteUserId] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedUserRightsId, setSelectedUserRightsId] = useState(-1);
    const [userError, setUserError] = useState('');
    const [openAddUser, setOpenAddUser] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        setGUsers(users);
        setSelectedId(null);
    }, [groupId, users]);

    useEffect(() => {
        setUserError('');

        if (selectedId > 0) {
            API.getUserOfGroupAdmin(groupId, selectedId)
                .then(l => {
                    API.getAllRights()
                        .then(r => {
                            setPermissions(r.rights);
                            setSelectedUserRightsId(l.groupRights.id);
                            setSelectedUser(l)
                        })
                        .catch(err => { setUserError(err.message); setSelectedUser(null);});
                               
                })
                .catch(err => { setUserError(err.message); setSelectedUser(null);});
        }else {
            setSelectedUser(null);
        }
    }, [selectedId]);

    return (
        <div className="w-100 d-flex flex-column overflow-hidden mt-1">
            <CustomModal open={deleteUserId !== null} onClose={() => setDeleteUserId(null)}>
                <DeleteConfirm title={t('remove_user')} message={t('remove_user_from_group_prompt')} onConfirm={() => {
                    API.removeUserFromGroupAdmin(groupId, deleteUserId).then(() => {
                        onUserRemove();
                        if (selectedId == deleteUserId) setSelectedId(null);
                    });
                    setDeleteUserId(null);
                }} onCancel={() => setDeleteUserId(null)}/>
            </CustomModal>

            <AddUserModal groupId={groupId} open={openAddUser} onClose={() => setOpenAddUser(false)} onConfirm={(userId, permissionId) => {
                //add
                API.addUserToGroupAdmin(groupId, userId, permissionId).then(() => {
                    onUserAdd();
                });
                setOpenAddUser(false);   
            }}/>
            

            {!selectedId &&
                <CustomTable columns={[t('name'), t('email'), t('permissions')]}
                    columnWidths={[31, 41, 28]}
                    columnRenderers={[
                        u => <span>{u.pending && <i className="fas fa-hourglass text-primary me-1"/>}{u.Name||t('pending_invitation')}</span>,
                        u => <span>{u.mail}</span>,
                        u => <span>{u.groupRights?.Name}</span>
                    ]}
                    search={(u, text) => (u.Name + u.mail + u.groupRights?.Name).toLocaleUpperCase().includes(text.toLocaleUpperCase())}
                    searchTitle={`${t('name')}, ${t('email')}`}
                    onClick={l => { setSelectedId(l.id); }}
                    activeRows={l => l.id === selectedId}
                    data={gUsers||[]}
                    loading={gUsers == null}
                    rowClassName={u => u.pending ? 'fst-italic list-group-item-warning warning-row':''}
                    header={() => (
                        <div className="d-flex flex-row me-2">
                            { canAssignUser && <span role="button" onClick={() => setOpenAddUser(true)}><i className="fas fa-xl fa-square-plus text-primary"/></span> }
                        </div>
                    )}/>
            }
            

            {(selectedId&&selectedUser) && (
                <div className="d-flex flex-column mt-4 mx-4 pt-2" style={{borderTop: '2px solid #d7d7d7'}}>
                    
                    <div className="d-flex flex-row align-items-center mb-1">
                        <div className="d-flex flex-row justify-content-start">
                            <span type="button" className="me-2" style={{width: 30}} onClick={() => setSelectedId(null)}><i className="fas fa-arrow-left-long fa-lg"/></span>
                            <i className="fas fa-user fa-xl text-primary"/>
                            {!selectedUser.pending && <h5 className="m-0 ms-1">{selectedUser?.Name || '?'}</h5>}
                            {selectedUser.pending && <h5 className="m-0 ms-1 fst-italic">{t('pending_invitation')}</h5>}
                        </div>
                        <div className="d-flex flex-row justify-content-end flex-fill">
                            {canAssignUser && <button type="button" className="btn btn-danger px-3 py-2" data-mdb-ripple-color="dark" onClick={() => setDeleteUserId(selectedId)}>{t('delete')}</button>}
                        </div>
                        
                    </div>

                    {userError && (
                        <div className="alert alert-danger alert-dismissible fade show mt-2" role="alert">
                            { userError }
                        </div>
                    )}

                    {(!userError && selectedUser) && (
                        <>
                            <div className="d-flex flex-column">
                                {selectedUser.pending && <>
                                    <ul className="list-group list-group-light p-0">
                                        <li className="list-group-item p-1"><i className="fas fa-envelope fa-fw text-primary me-1"/><span className="fw-bold me-1">{t('email')}:</span>{selectedUser.mail}</li>
                                    </ul>
                                </>}
                                {!selectedUser.pending && <>
                                    <ul className="list-group list-group-light p-0">
                                        <li className="list-group-item p-1"><i className="fas fa-id-card fa-fw text-primary me-1"/><span className="fw-bold me-1">{t('name')}:</span>{selectedUser.Name}</li>
                                        <li className="list-group-item p-1"><i className="fas fa-envelope fa-fw text-primary me-1"/><span className="fw-bold me-1">{t('email')}:</span>{selectedUser.mail}</li>
                                        <li className="list-group-item p-1"><i className="fas fa-mobile-alt fa-fw text-primary me-1"/><span className="fw-bold me-1">{t('phone')}:</span>{selectedUser.phone}</li>
                                    </ul>
                                </>}
                                
                                {(canAssignUser && permissions.length > 0) && (
                                    <div className="d-flex mb-3 flex-row align-items-center w-100">
                                        <i className="fas fa-briefcase fa-fw text-primary me-2"/>
                                        <CustomSelect w100 title="Group permissions" items={permissions.map(p => ({ val: p.id, title: p.Name }))} selected={selectedUserRightsId} onSelect={id => {setSelectedUserRightsId(id); setChanged(true); }} />
                                        <button className="btn btn-primary ms-1 py-2" disabled={!changed} onClick={() => {
                                            setChanged(false);
                                            API.updateUserGroupRights(groupId, selectedUser.id, selectedUserRightsId)
                                                .then(() => onEdit());
                                        }}>{t('save')}</button>
                                    </div>
                                )}
                                {!canAssignUser && (
                                    <ul className="list-group list-group-light p-0"><li className="list-group-item p-1"><i className="fas fa-briefcase fa-fw text-primary me-1"/><span className="fw-bold me-1">{t('permissions')}:</span>{selectedUser.userRights?.Name||''}</li></ul>
                                )}
                            </div>
                            
                        </>
                    )}
                </div>
            )}
        </div>
    )

}

const GroupEditorSharedTab = ({groupId, canShare, onDelete, onShare}) => {
    const [companies, setCompanies] = useState(null);
    const [deleteId, setDeleteId] = useState(null);
    const [openShareGroup, setOpenShareGroup] = useState(false);
    const { t } = useTranslation();

    const fetch = () => {
        setCompanies(null);
        API.getSharedCompanies(groupId)
            .then(c => { setCompanies(c); })
    }

    useEffect(() => {
        fetch();
    }, [groupId]);


    return (
        <div className="w-100 d-flex flex-column overflow-hidden mt-1">
            <CustomModal open={deleteId !== null} onClose={() => setDeleteId(null)}>
                <DeleteConfirm title={t('unshare_group')} message={t('unshare_group_prompt')} onConfirm={() => {
                    API.unshareGroup(groupId, deleteId).then(() => {
                        onDelete();
                        fetch();
                    });
                    setDeleteId(null);
                }} onCancel={() => setDeleteId(null)}/>
            </CustomModal>
            <ShareGroupModal groupId={groupId} open={openShareGroup} onClose={() => setOpenShareGroup(false)} onGroupShared={() => onShare()} />

            <CustomTable columns={[t('name'), t('cif'), t('permissions'), t('unshare')]}
                columnWidths={canShare ? [40, 20, 25, 15] : [40,20,40]}
                columnRenderers={[
                    c => <span>{c.name}</span>,
                    c => <span>{c.CIF}</span>,
                    c => <span>{c.rights?.Name||'?'}</span>,
                    ...(canShare ? [c => <span><span role="button" onClick={() => setDeleteId(c.id)}><i className="fas fa-trash-can text-danger"/></span></span>] : [])
                ]}
                search={(c, text) => (c.name + c.CIF).toLocaleUpperCase().includes(text.toLocaleUpperCase())}
                searchTitle={`${t('name')}, ${t('cif')}`}
                data={companies||[]}
                loading={companies == null}
                header={() => (
                    <div className="d-flex flex-row me-2">
                        { canShare &&<span role="button" onClick={() => setOpenShareGroup(true)}><i className="fas fa-xl fa-square-plus text-primary"/></span> }
                    </div>
                )}/>
        </div>
    )
}

const GroupAdd = ({onAdd, onClose}) => {
    const [name, setName] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        document.querySelectorAll(".form-outline").forEach((formOutline) => {
			new mdb.Input(formOutline).init();
		});
    }, []);

    const insert = () => {
        setLoading(true);
        API.addGroup(name)
            .then(() => {
                onAdd();
            })
            .catch(err => setError(err.message))
            .finally(() => setLoading(false));

    }

    return (
        <div className="d-flex flex-column overflow-hidden card">
            <button type="button" className="btn-close position-absolute" style={{right: 5, top: 5}} onClick={() => onClose ? onClose() : {}}></button>
            <div className="d-flex flex-column card-body overflow-hidden">
                <div className="d-flex flex-column w-100 justify-content-center">
                    <i className="fas fa-layer-group fa-4x mb-2"/>
                    <div className="d-flex flex-column justify-content-center">
                        <h5 className="text-center">{t('new_group')}</h5>
                        <div className="form-outline">
                            <input type="text" name="name" id="name" className="form-control" value={name} onInput={ev => { setName(ev.currentTarget.value); }}/>
                            <label className="form-label" htmlFor="name">{t('name')}</label>
                        </div>
                        
                        { error && <div className="alert alert-danger alert-dismissible fade show mt-2" role="alert">
                            { error }
                        </div> }
                        <button className="btn btn-primary mt-2" disabled={name.length==0 || loading} onClick={() => insert()}>{t('add')} <i className="far fa-square-plus fa-lg"/></button>
                    </div>
                </div>
            </div>
        </div>
    )
}

const GroupJoin = ({onJoin, onClose}) => {
    const [code, setCode] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        document.querySelectorAll(".form-outline").forEach((formOutline) => {
			new mdb.Input(formOutline).init();
		});
    }, []);

    useEffect(() => {
        setError('');
    }, [code]);

    const join = () => {
        setLoading(true);
        API.getSharedGroup(code)
            .then(({groupId}) => onJoin(groupId))
            .catch(err => {setError(err.message); })
            .finally(() => setLoading(false));

    }
    return (
        <div className="d-flex flex-column overflow-hidden card">
            <button type="button" className="btn-close position-absolute" style={{right: 5, top: 5}} onClick={() => onClose ? onClose() : {}}></button>
            <div className="d-flex flex-column card-body overflow-hidden">
                <div className="d-flex flex-column w-100 justify-content-center">
                    <i className="fas fa-layer-group fa-4x mb-2"/>
                    <div className="d-flex flex-column justify-content-center align-items-center">
                        <h5 className="text-center">{t('add_shared_group')}</h5>
                        
                        <h6>{t('insert_invitation_code')}:</h6>

                        <div className="d-flex flex-row justify-content-center">
                            <CodeInput onCodeChange={c => setCode(c)}/>
                        </div>
                        
                        { error && <div className="alert alert-danger alert-dismissible fade show mt-2 w-100" role="alert">
                            { error }
                        </div> }
                        <button className="btn btn-primary mt-2 w-100" disabled={code.length < 6 || loading} onClick={() => join()}>{t('add')} <i className="far fa-square-plus fa-lg"/></button>
                    </div>
                </div>
            </div>
        </div>
    )
}

const AddLocationModal = ({groupId, open, onConfirm, onClose}) => {
    const [selectedId, setSelectedId] = useState(null);
    const [locations, setLocations] = useState(null);
    const [search, setSearch] = useState('');
    const { t } = useTranslation();

    
    useEffect(() => {
        setSearch('');
        setSelectedId(null);
        setLocations(null);
        if (open){
            API.getLocationsNotPartOfGroupAdm(groupId)
                .then(l => setLocations(l));
        }
    }, [open, groupId])

    useEffect(() => {
        document.querySelectorAll(".form-outline").forEach((formOutline) => {
			new mdb.Input(formOutline).init();
		});

        
    }, [locations]);

    return (
        <CustomModal open={open}>
            <div className="card">
                <div className="card-body">
                    <h5 className="card-title">{t('add_location_to_group')}</h5>
                    <div className="d-flex flex-column justify-content-center align-items-center my-2">
                        {locations == null && (
                            <div className="spinner-border" role="status">
                                <span className="visually-hidden">{t('loading...')}</span>
                            </div>
                        )}
                        {locations !== null && (
                            <div className="d-flex flex-column">
                                <div className="d-flex form-outline px-1 mb-1 mt-1">
                                    <input type="text" name="search" id="search" className="form-control" value={search} onInput={ev => { setSearch(ev.currentTarget.value);}} autoComplete="off"/>
                                    <label className="form-label" htmlFor="search">{t('search')}</label>
                                </div>
                                <div className="d-flex flex-column overflow-auto px-1 pt-2 pb-2" style={{maxHeight: 500, minWidth: 300, userSelect: 'none'}}>
                                    {locations.map(l => (
                                        <div key={l.id} className="flex-row align-items-center hover-shadow p-1" style={{cursor: 'pointer', background: l.id == selectedId ? '#0000001a' :'', display: l.Name.toLocaleUpperCase().indexOf(search.toLocaleUpperCase()) == '-1' ? 'none': 'flex'}} onClick={() => setSelectedId(l.id)}>
                                            <i className="fas fa-map-marker-alt text-primary"/>
                                            <span className="ms-1">{l.Name} (<i className="fas fa-server text-primary fa-xs"/>{l.nrOfMachines})</span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            
                        )}
                    </div>
                    
                    <button className="btn btn-danger me-1" disabled={!selectedId} onClick={() => onConfirm(selectedId)}>{t('confirm')}</button>
                    <button className="btn btn-secondary" onClick={onClose}>{t('cancel')}</button>
                </div>
            </div>
        </CustomModal>
    )
}

const AddUserModal = ({groupId, open, onConfirm, onClose}) => {
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [selectedPermissionId, setSelectedPermissionid] = useState(null);
    const [users, setUsers] = useState(null);
    const [permissions, setPermissions] = useState(null);
    const [search, setSearch] = useState('');
    const { t } = useTranslation();

    useEffect(() => {
        setSearch('');
        setSelectedUserId(null);
        setSelectedPermissionid(null);
        setUsers(null);
        if (open){
            API.getUsersNotPartOfGroupAdm(groupId)
                .then(u => setUsers(u));
            API.getAllRights()
                .then(r => setPermissions(r.rights));
        }

    }, [open, groupId]);

    useEffect(() => {
        document.querySelectorAll(".form-outline").forEach((formOutline) => {
			new mdb.Input(formOutline).init();
		});
    }, [users, selectedUserId, groupId, permissions]);

    return (
        <CustomModal open={open}>
            <div className="card">
                <div className="card-body">
                    <h5 className="card-title">{t('add_user_to_group')}</h5>
                    <div className="d-flex flex-column justify-content-center align-items-center my-2">
                        {(users == null || permissions == null) && (
                            <div className="spinner-border" role="status">
                                <span className="visually-hidden">{t('loading...')}</span>
                            </div>
                        )}
                        {(users !== null && permissions !== null) && <>
                            <div className="d-flex flex-column">
                                <div className="d-flex form-outline px-1 mb-1 mt-1">
                                    <input type="text" name="search" id="search" className="form-control" value={search} onInput={ev => { setSearch(ev.currentTarget.value);}} autoComplete="off"/>
                                    <label className="form-label" htmlFor="search">{t('search')}</label>
                                </div>

                                <div className="d-flex flex-column overflow-auto px-1 pt-2 pb-2" style={{maxHeight: 500, minWidth: 300, userSelect: 'none'}}>
                                    {users.map(u => (
                                        <div key={u.id} className={"flex-row align-items-center hover-shadow p-1 "+(u.pending?' list-group-item-warning fst-italic':'')} style={{cursor: 'pointer', background: u.id == selectedUserId ? '#0000001a' :'', display: u.Name.toLocaleUpperCase().indexOf(search.toLocaleUpperCase()) == '-1' ? 'none': 'flex'}} onClick={() => { setSearch(''); setSelectedUserId(u.id); }}>
                                            <i className="fas fa-user text-primary"/>{u.pending&&<i className="fas fa-hourglass text-primary ms-1"/>}
                                            <span className="ms-1">{u.Name}</span>
                                        </div>
                                    ))}
                                </div>

                                <span className="w-100 text-center"><h6>{t('permissions')}</h6></span>

                                <div className="d-flex flex-column overflow-auto px-1 pt-2 pb-2" style={{maxHeight: 500, minWidth: 300, userSelect: 'none'}}>
                                    {permissions.map(p => (
                                        <div key={p.id} className="flex-row align-items-center hover-shadow p-1" style={{cursor: 'pointer', background: p.id == selectedPermissionId ? '#0000001a' :'', display: p.Name.toLocaleUpperCase().indexOf(search.toLocaleUpperCase()) == '-1' ? 'none': 'flex'}} onClick={() => setSelectedPermissionid(p.id)}>
                                            <i className="fas fa-briefcase text-primary"/>
                                            <span className="ms-1">{p.Name}</span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </>}
                            
                        
                    </div>
                    <button className="btn btn-danger me-1" disabled={!selectedUserId || !selectedPermissionId} onClick={() => onConfirm(selectedUserId, selectedPermissionId)}>{t('confirm')}</button>
                    <button className="btn btn-secondary" onClick={onClose}>{t('cancel')}</button>
                </div>
            </div>
        </CustomModal>
    )
}

const ShareGroupModal = ({groupId, open, onGroupShared, onClose}) => {
    const [selectedId, setSelectedId] = useState(null);
    const [selectedPermissionId, setSelectedPermissionid] = useState(null);
    const [companies, setCompanies] = useState([]);
    const [search, setSearch] = useState('');
    const [permissions, setPermissions] = useState([]);
    const [shareWithLink, setShareWithLink] = useState(false);
    const [shareLoading, setShareLoading] = useState(false);
    const [shareError, setShareError] = useState('');
    const [shareInvitation, setShareInvitation] = useState(null);
    const [shareSuccessMessage, setShareSuccessMessage] = useState('');
    const { t } = useTranslation();

    useEffect(() => {
        setSearch('');
        setSelectedId(null);
        setShareWithLink(false);
        setShareLoading(false);
        setShareSuccessMessage('');
        setShareError('');
        setShareInvitation(null);
        setCompanies(null);
        if (open){
            API.getShareVariants(groupId)
                .then(v => {
                    let c = [];
                    if (v.parent) c.push({isParent: true, ...v.parent});
                    if (v.subcompanies) c = c.concat(v.subcompanies);
                    setCompanies(c);
                });
            API.getAllRights()
                .then(r => setPermissions(r.rights));
        }
    }, [open, groupId]);

    useEffect(() => {
        document.querySelectorAll(".form-outline").forEach((formOutline) => {
			new mdb.Input(formOutline).init();
		});
    }, [companies]);

    const confirm = () => {
        if (!shareWithLink){
            setShareLoading(true);
            API.shareGroup(groupId, selectedId, selectedPermissionId)
                .then(() => { setShareSuccessMessage(t('group_share_success')); onGroupShared(); })
                .catch(err => { setShareError(err.message); })
                .finally(() => setShareLoading(false));
        }else{
            setShareLoading(true);
            API.shareWithLink(groupId, selectedPermissionId)
                .then(({invitationId}) => { setShareInvitation(invitationId); })
                .catch(err => { setShareError(err.message); })
                .finally(() => setShareLoading(false));
        }

    }

    return (
        <CustomModal open={open} onClose={onClose}>
            <div className="card">
                <div className="card-body">
                    <h5 className="card-title">{t('share_group')}</h5>
                    <div className="d-flex flex-column justify-content-center align-items-center my-2">
                        {(companies == null || shareLoading) && (
                            <div className="spinner-border" role="status">
                                <span className="visually-hidden">{t('loading...')}</span>
                            </div>
                        )}
                        {companies !== null && (
                            <div className="d-flex flex-column">
                                
                                {shareError && (
                                    <div className="alert alert-danger alert-dismissible fade show mt-2" role="alert">
                                        { shareError }
                                    </div>
                                )}
                                {shareSuccessMessage && (
                                    <div className="alert alert-success alert-dismissible fade show mt-2" role="alert">
                                        { shareSuccessMessage }
                                    </div>
                                )}
                                {shareInvitation && (
                                    <div className="d-flex flex-column align-items-center justify-content-center">
                                        <span>{t('your_invitation_id')}:</span>
                                        <h3 className="text-success" style={{letterSpacing: '10px', marginLeft: 10, fontWeight: 'bold'}}>{shareInvitation}</h3>
                                    </div>
                                )}
                                <div className="d-flex flex-column w-100">
                                    {(!shareError && !shareSuccessMessage && !shareInvitation) && <>
                                        <div className="d-flex form-outline px-1 mb-1 mt-1">
                                            <input type="text" name="search" id="search" className="form-control" value={search} onInput={ev => { setSearch(ev.currentTarget.value);}} autoComplete="off"/>
                                            <label className="form-label" htmlFor="search">{t('search')}</label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value={shareWithLink} id="shareWithLink" onChange={ev => setShareWithLink(ev.currentTarget.checked)} />
                                            <label className="form-check-label" htmlFor="shareWithLink">{t('share_with_invitation')}</label>
                                        </div>
                                        <div className="d-flex flex-column overflow-auto px-1 pt-2 pb-2" style={{maxHeight: 500, minWidth: 300, userSelect: 'none'}}>
                                            {companies.map(l => (
                                                <div key={l.id} className={"flex-row align-items-center hover-shadow p-1"+(shareWithLink?' d-none':'')} style={{cursor: 'pointer', background: l.id == selectedId ? '#0000001a' :'', display: l.name.toLocaleUpperCase().indexOf(search.toLocaleUpperCase()) == '-1' ? 'none': 'flex'}} onClick={() => setSelectedId(l.id)}>
                                                    <i className="fas fa-building text-primary"/>
                                                    <span className="ms-1">{l.name}</span>
                                                    {l.isParent && <span className="fst-italic">&nbsp;({t('parent_lower')})</span>}
                                                </div>
                                            ))}
                                        </div>

                                        <span className="w-100 text-center"><h6>{t('permissions')}</h6></span>

                                        <div className="d-flex flex-column overflow-auto px-1 pt-2 pb-2" style={{maxHeight: 500, minWidth: 300, userSelect: 'none'}}>
                                            {permissions.map(p => (
                                                <div key={p.id} className="flex-row align-items-center hover-shadow p-1" style={{cursor: 'pointer', background: p.id == selectedPermissionId ? '#0000001a' :'', display: p.Name.toLocaleUpperCase().indexOf(search.toLocaleUpperCase()) == '-1' ? 'none': 'flex'}} onClick={() => setSelectedPermissionid(p.id)}>
                                                    <i className="fas fa-briefcase text-primary"/>
                                                    <span className="ms-1">{p.Name}</span>
                                                </div>
                                            ))}
                                        </div>
                                        <div className="d-flex flex-row w-100">
                                            <button className="btn btn-danger me-1 flex-fill" disabled={(!shareWithLink && !selectedId) || !selectedPermissionId} onClick={() => confirm()}>{t('confirm')}</button>
                                            <button className="btn btn-secondary flex-fill" onClick={onClose}>{t('cancel')}</button>
                                        </div>
                                    </>}
                                    {(shareError || shareSuccessMessage || shareInvitation) && <button className="btn btn-secondary w-100" onClick={onClose}>{t('close')}</button>}
                                    
                                </div>
                                
                            </div>
                            
                        )}
                    </div>
                    
                    
                </div>
            </div>
        </CustomModal>
    )
}