import { useEffect, useState } from "react"
import CustomTable from "../../other/CustomTable";
import API from "../../../service/api";
import * as mdb from 'mdb-ui-kit'; // lib
import { CustomModal } from "../../other/CustomModal";
import { DeleteConfirm } from "../../other/DeleteConfirm";
import { useTranslation } from "react-i18next";


const PermissionsSorter = (a,b) => {
    if (a.company && !b.company) return 1;
    else if (!a.company && b.company) return -1;
    return 0;
}

export const PermissionsPage = ({myRightsId}) => {
    const [selectedId, setSelectedId] = useState(null);
    const [permissionsLoading, setPermissionsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [maximal, setMaximal] = useState(null);
    const [minimal, setMinimal] = useState(null);
    const { t } = useTranslation();

    const fetchPermissions = () => {
        setPermissionsLoading(true);
        API.getAllRights()
            .then(d => {
                setMaximal(d.maximal);
                setMinimal(d.minimal);
                setData(d.rights.sort(PermissionsSorter));
                if (selectedId == null) setSelectedId(d.rights.find(el => el.id == myRightsId) ? myRightsId : d.rights[0].id);
            })
            .finally(() => setPermissionsLoading(false));
    }

    useEffect(() => {
        fetchPermissions();
    }, [])
    

    const PermissionsTable = (
        <div className="d-flex flex-fill flex-column overflow-hidden ms-3">
            <div className="d-flex flex-column overflow-hidden card">
                <div className="d-flex flex-column card-body overflow-hidden">
                    <a className="btn text-white btn-floating bg-primary position-absolute text-center" role="button" style={{top: 15, right: 15}} onClick={() => { setSelectedId(-1); }}>
                        <span className="d-flex justify-content-center align-items-center w-100 h-100"><i className="fas fa-plus fa-lg"/></span>
                    </a>
                    <div className="d-flex flex-row ms-2 mb-2 align-items-center">
                        <h5 className="me-1 px-0 py-1 m-0 flex-fill">{t('permissions')}</h5>
                    </div>
                    <CustomTable columns={[t('name')]}
                        columnWidths={[100]}
                        columnRenderers={[
                            r => <span style={{overflow: 'hidden', textOverflow: 'clip', whiteSpace: 'nowrap'}}>
                                {r.private && <span className="me-1"><i className="fas fa-lock"/></span>}
                                {r.Name}
                            </span>,
                        ]}
                        search={(r, text) => r.Name.toLocaleUpperCase().includes(text.toLocaleUpperCase())}
                        searchTitle={t('name')}
                        onClick={r => { setSelectedId(r.id); }}
                        activeRows={r => r.id === selectedId}
                        loading={permissionsLoading}
                        data={data}/>
                </div>
            </div>
        </div>
    )

    if (!maximal || !minimal) return null;

    return (
        <section className="d-flex flex-column flex-fill w-100 h-100" style={{minWidth: 800}}>
            <div className="d-flex flex-row h-100">
                <div className="d-flex flex-column col-5">{PermissionsTable}</div>
                <div className="d-flex flex-column col-7 ms-3 pe-3">{<PermissionEdit rightsId={selectedId} maximal={maximal} minimal={minimal} onAdd={() => fetchPermissions()} onEdit={() => fetchPermissions()} onDelete={() => setSelectedId(null)}/>}</div>
            </div>
        </section>
    )
}


const PermissionEdit = ({rightsId, maximal, minimal, onAdd, onEdit, onDelete}) => {
    const [rights, setRights] = useState(null);
    const [def, setDef] = useState({});
    const [maximalDef, setMaximalDef] = useState(maximal);
    const [loading, setLoading] = useState(false);
    const [changed, setChanged] = useState(false);
    const [name, setName] = useState('');
    const [error, setError] = useState('');
    const [deleteId, setDeleteId] = useState(null);
    const [savePopup, setSavePopup] = useState(false);
    const [protectedRight, setProtectedRight] = useState(false);
    const { t } = useTranslation(); 

    const fetch = () => {
        setChanged(false);
        setName('');
        setError('');
        if (rightsId >= 0 && typeof rightsId == 'number'){
            setLoading(true);
            API.getRight(rightsId)
                .then(r => { setName(r.Name); setProtectedRight(r.private); setDef(r.def);  setRights(r); })
                .finally(() => setLoading(false));
        }
        else {
            setDef(minimal);
            setRights({});
            setProtectedRight(false);
        }
    }

    useEffect(() => { setMaximalDef(maximal); }, [maximal]);

    useEffect(() => {
        document.querySelectorAll(".form-outline").forEach((formOutline) => {
			new mdb.Input(formOutline).init();
		});
        document.querySelectorAll(".tooltip").forEach(el => {
            el.remove();
        });

        document.querySelectorAll(".my-tooltip").forEach(el => {
            new mdb.Tooltip(el);
        });
    }, [rights, def]);

    useEffect(() => {
        document.querySelectorAll(".form-outline").forEach((formOutline) => {
			new mdb.Input(formOutline).init();
		});
        fetch();
        
    }, [rightsId]);

    const insert = () => {
        setLoading(true);
        API.addRight(name, JSON.stringify(def))
            .then(() => { onAdd(); fetch(); })
            .catch(err => setError(err.message))
            .finally(() => setLoading(false));
    }

    const update = () => {
        setLoading(true);
        API.updateRight(rightsId, name, JSON.stringify(def))
            .then(() => { onEdit(); fetch(); })
            .catch(err => setError(err.message))
            .finally(() => setLoading(false));
    }

    const checkSum = right => {
        if (typeof right == 'boolean') return right ? 1 : -1;
        else {
            if (Object.values(right).map(v => checkSum(v)).every(v => v == true)) return 1;
            else if (Object.values(right).map(v => checkSum(v)).some(v => v == true)) return 0;
            else return -1;
        }
    }

    const checked = (def, name) => {
        const path = name.split('.');
        let root = JSON.parse(JSON.stringify(def));
        path.forEach((val, index) => { if (index < path.length - 1) root = root[val] });
        return typeof root[path[path.length-1]] == 'boolean' ? root[path[path.length-1]] : false;
    }
    
    const setChecked = (name, check) => {
        const path = name.split('.');
        let defcopy = JSON.parse(JSON.stringify(def)),
            root = defcopy;
        path.forEach((val, index) => { if (index < path.length - 1) root = root[val] });
        if (root[path[path.length-1]] !== undefined) root[path[path.length-1]] = check;
        setChanged(true);
        setDef(defcopy);
    }

    const checkObject = (root, maximal, check) => {
        Object.keys(root).forEach(key => {
            if (typeof root[key] == 'boolean' && maximal[key] === true) root[key] = check;
            else checkObject(root[key], maximal[key], check);
        })
    }

    const setCheckedCategory = (categoryName) => {
        if (protectedRight) return;
        const maximalCategory = maximal[categoryName];
        const maximalFields = FlatCategory(maximalCategory);
        if (Object.values(maximalFields).every(f => f === false)) return <></>;

        const category = def[categoryName];
        const fields = FlatCategory(category);

        let status = 0; //nrTrue == maxTrue ? 1 : ( (nrTrue == 0) ? -1 : 0 );
        if (Object.keys(maximalFields).filter(key => maximalFields[key]).every(key => fields[key])) status = 1;
        if (Object.keys(maximalFields).filter(key => maximalFields[key]).every(key => !fields[key])) status = -1;

        if (status == 1) checkObject(category, maximal[categoryName], false);
        else checkObject(category, maximal[categoryName], true);
        setChanged(true);
        setDef(prev => ({...def}));
    }

    const Category = ({title, categoryName}) => {
        const maximalCategory = maximal[categoryName];
        const maximalFields = FlatCategory(maximalCategory);
        if (Object.values(maximalFields).every(f => f === false)) return <></>;

        const category = def[categoryName];
        const fields = FlatCategory(category);

        let status = 0; //nrTrue == maxTrue ? 1 : ( (nrTrue == 0) ? -1 : 0 );
        if (Object.keys(maximalFields).filter(key => maximalFields[key]).every(key => fields[key])) status = 1;
        if (Object.keys(maximalFields).filter(key => maximalFields[key]).every(key => !fields[key])) status = -1;

        const className = status == 1 ? 'fas fa-square-check text-primary' : (status == 0 ? 'fas fa-square-minus text-primary' : 'far fa-square');
        const style = status == 1 ? {} : (status == 0 ? {} : {color: '#00000040'});
        return (
            <span className="d-flex flex-row align-items-center mb-1" style={{cursor: !protectedRight ? 'pointer' : '', opacity: !protectedRight ? 1 : 0.5}} onClick={() => setCheckedCategory(categoryName)}>
                <i className={"fa-lg me-1 " + className} style={{...style}} data-mdb-ripple-color="dark"/>
                <h6 className="mb-0">{title}</h6>
            </span>
        )

    }

    const FlatCategory = (category) => {
        let res = {};
        Object.keys(category).forEach(key => {
            if (typeof category[key] == 'boolean') res[key] = category[key];
            else {
                const rech = FlatCategory(category[key]);
                Object.keys(rech).forEach(k => res[k] = rech[k]);
            }
        });
        return res;

        //return Object.values(category).map(field => typeof field == 'boolean' ? field : FlatCategory(field)).flat();
    }

    const Item = ({name, children, admin, operator}) => {
        return checked(maximalDef, name) ? <div className="form-check ms-1">
            <input className="form-check-input" type="checkbox" name={name} checked={checked(def, name)} onChange={ev => setChecked(name, ev.target.checked)} id={'check.'+name} disabled={protectedRight} />
            <label className="form-check-label" htmlFor={'check.'+name}>
                {children}
                {admin && <span className="my-tooltip ms-1" data-mdb-toggle="tooltip" title={t('permission_admin')}><i className="fas fa-user-gear fa-xs text-primary"/></span>}
                {operator && <span className="my-tooltip ms-1" data-mdb-toggle="tooltip" title={t('permission_operator')}><i className="fas fa-desktop fa-xs text-warning" /></span>}
            </label>
        </div> : <></>;
    }

    

    if (!def || !rights) return <></>;

    return (
        <div className="d-flex flex-column overflow-hidden card">
            <CustomModal open={deleteId !== null} onClose={() => setDeleteId(null)}>
                <DeleteConfirm title={t('remove_permission')} message={t('remove_permission_prompt')} onConfirm={() => {
                    API.deleteRight(deleteId).then(() => { onDelete(); onEdit(); }).catch(err => setError(err.message));
                    setDeleteId(null);
                }} onCancel={() => setDeleteId(null)}/>
            </CustomModal>

            <CustomModal open={savePopup} onClose={() => setSavePopup(false)}>
                <DeleteConfirm title={t('save_permission')} confirmText={t('save')} message={t('save_permission_prompt')} onConfirm={() => {
                    update();
                    setSavePopup(false);
                }} onCancel={() => setSavePopup(false)}/>
            </CustomModal>
            <div className="d-flex flex-column overflow-hidden card-body">
                <div className="d-flex flex-column w-100 justify-content-center overflow-hidden">
                    <i className="fas fa-briefcase fa-4x mb-2"/>
                    
                    
                    {loading && (
                        <div className="d-flex w-100 justify-content-center">
                            <div className="spinner-border" role="status">
                                <span className="visually-hidden">{t('loading...')}</span>
                            </div>
                        </div>
                        
                    )}
                    {!loading && <>
                        <h5 className="text-center">{rightsId !== -1 ? rights.Name : t('new_permissions')}</h5>

                        <div className="d-flex flex-column overflow-auto pt-1 pe-1">
                            {!protectedRight && (
                                <div className="form-outline">
                                    <input type="text" name="name" id="name" className="form-control" value={name} onInput={ev => { setName(ev.currentTarget.value); setChanged(true); }}/>
                                    <label className="form-label" htmlFor="name">{t('name')}</label>
                                </div>
                            )}
                            

                            <div className="d-flex flex-row mt-2" style={{userSelect: 'none'}}>
                                <div className="d-flex flex-column col-6 ">
                                    <Category title={t('permissions_category_companies')} categoryName="Companies" key={"companies"+Date.now()}/>
                                    <div className="d-flex flex-column mb-2" style={{borderLeft: '2px solid #00000026', marginLeft: 8}}>
                                        <Item name="Companies.Own.Change" admin>{t('permissions_title.Companies.Own.Change')}</Item>
                                        <Item name="Companies.Sub.View" admin>{t('permissions_title.Companies.Sub.View')}</Item>
                                        <Item name="Companies.Sub.Add" admin>{t('permissions_title.Companies.Sub.Add')}</Item>
                                        <Item name="Companies.Sub.Modify" admin>{t('permissions_title.Companies.Sub.Modify')}</Item>
                                        <Item name="Companies.Sub.Delete" admin>{t('permissions_title.Companies.Sub.Delete')}</Item>
                                        <Item name="Companies.Sub.AddUser" admin>{t('permissions_title.Companies.Sub.AddUser')}</Item>
                                    </div>
                                    
                                    <Category title={t('permissions_category_users')} categoryName="Users" key={"users"+Date.now()}/>
                                    <div className="d-flex flex-column mb-2" style={{borderLeft: '2px solid #00000026', marginLeft: 8}}>
                                        <Item name="Users.View" admin>{t('permissions_title.Users.View')}</Item>
                                        <Item name="Users.Add" admin>{t('permissions_title.Users.Add')}</Item>
                                        <Item name="Users.Change" admin>{t('permissions_title.Users.Change')}</Item>
                                        <Item name="Users.Delete" admin>{t('permissions_title.Users.Delete')}</Item>
                                        <Item name="Users.ManageRights" admin>{t('permissions_title.Users.ManageRights')}</Item>
                                        <Item name="Users.ManageGroups" admin>{t('permissions_title.Users.ManageGroups')}</Item>
                                    </div>
                                    
                                    <Category title={t('permissions_category_groups')} categoryName="Groups" key={"groups"+Date.now()}/>
                                    <div className="d-flex flex-column mb-2" style={{borderLeft: '2px solid #00000026', marginLeft: 8}}>
                                        <Item name="Groups.View" admin operator>{t('permissions_title.Groups.View')}</Item>
                                        <Item name="Groups.ViewValues" operator>{t('permissions_title.Groups.ViewValues')}</Item>
                                        <Item name="Groups.Add" admin>{t('permissions_title.Groups.Add')}</Item>
                                        <Item name="Groups.Change" admin>{t('permissions_title.Groups.Change')}</Item>
                                        <Item name="Groups.Delete" admin>{t('permissions_title.Groups.Delete')}</Item>
                                        <Item name="Groups.Share" admin>{t('permissions_title.Groups.Share')}</Item>
                                        <Item name="Groups.AssignLocations" operator>{t('permissions_title.Groups.AssignLocations')}</Item>
                                        <Item name="Groups.UserAssociation" operator>{t('permissions_title.Groups.UserAssociation')}</Item>
                                    </div>
                                    
                                </div>
                                <div className="d-flex flex-column col-6">
                                    <Category title={t('permissions_category_locations')} categoryName="Locations" key={"locations"+Date.now()}/>
                                    <div className="d-flex flex-column mb-2" style={{borderLeft: '2px solid #00000026', marginLeft: 8}}>
                                        <Item name="Locations.View" admin operator>{t('permissions_title.Locations.View')}</Item>
                                        <Item name="Locations.ViewValues" operator>{t('permissions_title.Locations.ViewValues')}</Item>
                                        <Item name="Locations.Add" admin>{t('permissions_title.Locations.Add')}</Item>
                                        <Item name="Locations.Change" admin>{t('permissions_title.Locations.Change')}</Item>
                                        <Item name="Locations.Delete" admin>{t('permissions_title.Locations.Delete')}</Item>
                                        <Item name="Locations.ViewMachines" operator>{t('permissions_title.Locations.ViewMachines')}</Item>
                                        <Item name="Locations.AssignMachines" admin>{t('permissions_title.Locations.AssignMachines')}</Item>
                                    </div>
                                    
                                    <Category title={t('permissions_category_products')} categoryName="Products" key={"products"+Date.now()}/>
                                    <div className="d-flex flex-column mb-2" style={{borderLeft: '2px solid #00000026', marginLeft: 8}}>
                                        <Item name="Products.View" admin>{t('permissions_title.Products.View')}</Item>
                                        <Item name="Products.Add" admin>{t('permissions_title.Products.Add')}</Item>
                                        <Item name="Products.Change" admin>{t('permissions_title.Products.Change')}</Item>
                                        <Item name="Products.Delete" admin>{t('permissions_title.Products.Delete')}</Item>
                                        <Item name="Products.Share" admin>{t('permissions_title.Products.Share')}</Item>
                                        <Item name="Products.DeleteShared" admin>{t('permissions_title.Products.DeleteShared')}</Item>
                                    </div>

                                    <Category title={t('permissions_category_events')} categoryName="Events" key={"events"+Date.now()}/>
                                    <div className="d-flex flex-column mb-2" style={{borderLeft: '2px solid #00000026', marginLeft: 8}}>
                                        <Item name="Events.SalesEvents" operator>{t('permissions_title.Events.SalesEvents')}</Item>
                                        <Item name="Events.RuntimeEvents" operator>{t('permissions_title.Events.RuntimeEvents')}</Item>
                                        <Item name="Events.ErrorEvents" operator>{t('permissions_title.Events.ErrorEvents')}</Item>
                                        <Item name="Events.Management" operator>{t('permissions_title.Events.Management')}</Item>
                                    </div>
                                    

                                    
                                </div>
                            </div>
                            
                            <Category title={t('permissions_category_machines')} categoryName="Machines" key={"machines"+Date.now()}/>
                            <div className="d-flex flex-row mb-2" style={{borderLeft: '2px solid #00000026', marginLeft: 8}}>
                                <div className="d-flex flex-column col-6">
                                    <Item name="Machines.ViewUnassigned" admin>{t('permissions_title.Machines.ViewUnassigned')}</Item>
                                    <Item name="Machines.Transfer" admin>{t('permissions_title.Machines.Transfer')}</Item>
                                    <Item name="Machines.Credit.View" operator>{t('permissions_title.Machines.Credit.View')}</Item>
                                    <Item name="Machines.Credit.Change" operator>{t('permissions_title.Machines.Credit.Change')}</Item>
                                    <Item name="Machines.Price.View" operator>{t('permissions_title.Machines.Price.View')}</Item>
                                    <Item name="Machines.Price.Change" operator>{t('permissions_title.Machines.Price.Change')}</Item>
                                    <Item name="Machines.Type.View" operator>{t('permissions_title.Machines.Type.View')}</Item>
                                    <Item name="Machines.Type.Change" operator>{t('permissions_title.Machines.Type.Change')}</Item>

                                    <Item name="Machines.QNTY.View" operator>{t('permissions_title.Machines.QNTY.View')}</Item>
                                    <Item name="Machines.QNTY.ViewClue" operator>{t('permissions_title.Machines.QNTY.ViewClue')}</Item>
                                    <Item name="Machines.QNTY.Change" operator>{t('permissions_title.Machines.QNTY.Change')}</Item>
                                    <Item name="Machines.MoneyBox.View" operator>{t('permissions_title.Machines.MoneyBox.View')}</Item>
                                    <Item name="Machines.MoneyBox.Change" operator>{t('permissions_title.Machines.MoneyBox.Change')}</Item>
                                </div>

                                <div className="d-flex flex-column col-6">
                                    
                                    <Item name="Machines.Actions.DeliveryProducts" operator>{t('permissions_title.Machines.Actions.DeliveryProducts')}</Item>
                                    <Item name="Machines.Actions.ClearMoneyBox" operator>{t('permissions_title.Machines.Actions.ClearMoneyBox')}</Item>
                                    <Item name="Machines.Actions.OpenDoor" operator>{t('permissions_title.Machines.Actions.OpenDoor')}</Item>

                                    <Item name="Machines.Actions.ChangeDateTime" operator>{t('permissions_title.Machines.Actions.ChangeDateTime')}</Item>
                                    <Item name="Machines.Actions.ChangeName" operator admin>{t('permissions_title.Machines.Actions.ChangeName')}</Item>
                                    <Item name="Machines.Actions.ChangeCounters" operator>{t('permissions_title.Machines.Actions.ChangeCounters')}</Item>
                                    <Item name="Machines.Actions.ChangeSchedules" operator>{t('permissions_title.Machines.Actions.ChangeSchedules')}</Item>
                                    <Item name="Machines.Actions.ReadParams" operator>{t('permissions_title.Machines.Actions.ReadParams')}</Item>
                                    <Item name="Machines.Actions.ChangeParams" operator>{t('permissions_title.Machines.Actions.ChangeParams')}</Item>
                                    <Item name="Machines.Actions.Power" operator>{t('permissions_title.Machines.Actions.Power')}</Item>
                                    <Item name="Machines.AdvancedSettings" admin>{t('permissions_title.Machines.AdvancedSettings')}</Item>
                                </div>
                            </div>

                            { error && <div className="alert alert-danger alert-dismissible fade show mt-2" role="alert">
                                { error }
                            </div> }

                            <div className="d-flex flex-column">
                                {rightsId !== -1 && <>
                                    { !protectedRight && <button className="btn btn-primary mb-2" disabled={!changed || loading} onClick={() => setSavePopup(true)}>{t('save')}</button> }
                                    { !protectedRight && <button className="btn btn-danger" disabled={loading} onClick={() => setDeleteId(rightsId)}>{t('delete')}</button> }
                                </>}
                                {rightsId === -1 && <>
                                    { !protectedRight && <button className="btn btn-primary mt-2" disabled={!changed || loading} onClick={() => insert()}>{t('add')} <i className="far fa-square-plus fa-lg"/></button> }
                                </>}
                            </div>
                        </div>
                    </>}
                </div>

            </div>
        </div>
    )
}


