import TimeAgo from "javascript-time-ago";
import { useEffect, useState } from "react";
import API from "../../service/api";
import WS from "../../service/ws";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Lang from "../../service/lang";


const TYPE_ICON = {
    'error': {className: 'fa-exclamation-triangle text-white bg-danger'},
    'runtime': {className: 'fa-hard-drive text-primary', bg: '#cdcfd1'},
    'management': {className: 'fa-screwdriver-wrench text-white bg-primary'},
    'sales': {className: 'fa-coins text-white bg-success'},
    'silent': {className: 'fa-hard-drive text-primary', bg: '#cdcfd1'},

    // subtypes
    'cash': {className: 'fa-coins text-white bg-success'},
    'card': {className: 'fa-credit-card text-white bg-success'},
    'delivery': {className: 'fa-share-square text-white bg-success'},
}

export const LogViewer = ({ groupId, locationId, machineId }) => {
    const [logs, setLogs] = useState([]);
    const [loading, setLoading] = useState(false);
    const [lang, setLang] = useState('en');
    const [reachedBottom, setReachedBottom] = useState(false);
    const [reachedEndOfLogs, setReachedEndOfLogs] = useState(false);
    const [initialData, setInitialData] = useState(false);
    const [connected, setConnected] = useState(true);
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {

        const newLogCallback = log => { setLogs(prev => ([log, ...prev])) };
        const disconnectCallback = () => setConnected(false);
        const connectCallback = () => setConnected(true);
        const kickedCallback = () => { setLogs([]); };

        setLang(Lang.instance.getCurrent());
        const langSetter = l => setLang(l);
        Lang.instance.addListener(langSetter);

        WS.listen('newLogs', newLogCallback);
        WS.listen('disconnect', disconnectCallback);
        WS.listen('connect', connectCallback);
        WS.listen('logsKicked', kickedCallback);

        return () => {
            WS.unlisten('newLogs', newLogCallback);
            WS.unlisten('disconnect', disconnectCallback);
            WS.unlisten('connect', connectCallback);
            WS.unlisten('logsKicked', kickedCallback);
            Lang.instance.removeListener(langSetter);
        }
    }, []);

    useEffect(() => {
        if (connected) {
            setLoading(true);
            setInitialData(false);
            setLogs([]);
            API.loadLogs(groupId, locationId, machineId, null, lang).then(l => {
                setLogs(l || []);
                setInitialData(true);
                setLoading(false);
            });
        }
    }, [connected, lang]);

    const onLogsScroll = (dom) => {
        const { scrollTop, clientHeight, scrollHeight } = dom;
        if (scrollTop + clientHeight > scrollHeight - 5) {
            setReachedBottom(true);
        }
    }

    const debounce = (func, wait) => {
        let timeout;
        return function (...args) {
            const context = this;
            clearTimeout(timeout);
            timeout = setTimeout(() => {
                timeout = null;
                func.apply(context, args);
            }, wait);
        };
    }

    const debouncedHandleScroll = (dom) => { return debounce(() => onLogsScroll(dom), 100); }

    useEffect(() => {
        async function fetchNewLogs() {
            //const lastId = logs.length > 0 ? logs.reduce((prev, crt) => prev.id < crt.id ? prev : crt).id : null;
            //if (lastId == null) return;
            const lastId = logs.length > 0 ? logs[logs.length-1].id : null;
            if (lastId == null) return;
            setLoading(true);
            try {
                const newLogs = await API.loadLogs(groupId, locationId, machineId, lastId, lang);
                // let x = 0;
                // while(x < newLogs.length && new Date(newLogs[x].ts).getTime() == lastTs) x++;
                setLogs(prev => prev.concat(newLogs));
                if (newLogs.length == 0) setReachedEndOfLogs(true);
                setLoading(false);
                setReachedBottom(false);
            }catch(err){}
        }

        if (reachedBottom && !loading && !reachedEndOfLogs) fetchNewLogs();
    }, [loading, reachedBottom, lang]);

    const dayDiffer = (prev, crt) => {
        const date1 = new Date(prev.ts),
            date2 = new Date(crt.ts);
        return (
            date1.getFullYear() !== date2.getFullYear() ||
            date1.getMonth() !== date2.getMonth() ||
            date1.getDate() !== date2.getDate()
            );
    }

    const dateText = log => {
        const date = new Date(log.ts);
        const today = new Date();
        const yesterday = new Date();
        yesterday.setDate(today.getDate()-1);

        if (
            date.getFullYear() === today.getFullYear() &&
            date.getMonth() === today.getMonth() &&
            date.getDate() === today.getDate()
        ) return t('today');

        if (
            date.getFullYear() === yesterday.getFullYear() &&
            date.getMonth() === yesterday.getMonth() &&
            date.getDate() === yesterday.getDate()
        ) return t('yesterday');

        return date.toLocaleDateString(undefined, {day: '2-digit', month: '2-digit', year: 'numeric'});
    }

    return (
        <div className="d-flex flex-column overflow-hidden flex-fill h-100 px-2">
            {!connected &&
                <div className="alert alert-danger p-2" role="alert">
                    <i className="fas fa-exclamation-triangle me-1" />
                    {t('connection_lost')}
                </div>
            }

            <div className="d-flex flex-column flex-fill overflow-auto ps-2 pe-1" onScroll={(e) => onLogsScroll(e.currentTarget)} onTouchMove={(e) => debouncedHandleScroll(e.currentTarget)}>

                {(initialData && logs) && logs.map((log, index) => (
                    <div key={log.id} className="d-flex flex-column">
                        {(index == 0 || dayDiffer(logs[index-1], log)) && (
                            <div className="d-flex flex-row w-100 align-items-start" style={{ paddingLeft: 8 }}>
                                {index > 0 && <div className={"d-flex h-100 align-items-start"}>
                                    <span className="" style={{ background: 'rgba(0,0,0,0.15)', height: '100%', width: 2, zIndex: 1, position: 'relative' }}>&nbsp;</span>

                                </div>}
                                <span className="flex-fill text-center text-muted " style={{marginLeft: -10}}>{dateText(log)}</span>
                            </div>
                            
                        )}
                        <div className="d-flex flex-row w-100 align-items-start " style={{ paddingLeft: 8 }}>
                            <div className={((index == logs.length-1) ? 'd-none' : 'd-flex') + " h-100 " + (index === 0 ? 'align-items-end' : (index === logs.length - 1 ? 'align-items-start' : ''))}>
                                <span className="" style={{ background: 'rgba(0,0,0,0.15)', height: '100%', width: 2, zIndex: 1, position: 'relative' }}>&nbsp;</span>

                            </div>
                            <i className={"fas p-2 fa-xs " + (TYPE_ICON[log.subType || log.type]?.className ||'')} style={{width:12, height:12, zIndex: 200, marginLeft: -15, borderRadius: '16px', background: TYPE_ICON[log.subType || log.type]?.bg||'' }} />


                            <div className="d-flex flex-column ms-2 mb-3" style={{ fontSize: '0.75rem', textAlign: 'start' }}>
                                <div className="d-flex flex-row">
                                    {!locationId && <span role={log.missingLocation ? 'none' : 'button'}
                                        style={log.missingLocation ? {lineHeight: 1, fontWeight: 'normal', fontStyle: 'italic'} : {lineHeight: 1, fontWeight: 'bold'}}
                                        onClick={() => navigate(`/g/${groupId}/locations/${log.locationId}`)}><i className="fas fa-map-marker-alt fa-fw text-primary"/>
                                            {log.locationName}
                                            {log.missingLocation && <span>?</span>}
                                            /
                                    </span>}
                                    <span role={log.missingMachine ? 'none' : 'button'}
                                        style={log.missingMachine ? {lineHeight: 1, fontWeight: 'normal', fontStyle: 'italic'} : {lineHeight: 1, fontWeight: 'bold'}} 
                                        onClick={log.missingMachine ? undefined : () => navigate(`/g/${groupId}/locations/${log.locationId}/machines/${log.machineId}`)}>
                                            {!locationId && <span><i className="fas fa-server fa-fw text-primary me-1"/></span> }
                                            {log.machineName}
                                    </span>
                                </div>
                                <div className="d-flex flex-row">
                                    <span className="" dangerouslySetInnerHTML={{__html: `<span title="${new Date(log.ts).toLocaleString(Lang.instance.getCurrentLocale().code)}">${new Date(log.ts).toLocaleTimeString(Lang.instance.getCurrentLocale().code)}&nbsp;</span><span class="">${log.text}</span>`}}></span>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                ))}
                {initialData && logs.length == 0 &&
                    <span className="text-muted fst-italic w-100 text-center">{t('no_logs')}</span>
                }
                {reachedEndOfLogs && <span className="text-muted" style={{textAlign: "center"}}>{t('you_reached_end')}</span>}
                <div className="d-flex flex-row w-100 justify-content-center align-items-center" style={{ opacity: loading ? 1 : 0 }}>
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">{t('loading...')}</span>
                    </div>
                </div>
            </div>

        </div>


    )
}