import { NavLink } from "react-router-dom"
import API from "../../../service/api";
import { useTranslation } from "react-i18next";

const SideNavItem = ({name, icon, location, minimized, horizontal}) => (
    <NavLink end to={location} className={({isActive}) => "list-group-item " + (horizontal ? "py-2 px-3" : "py-2") + (isActive ? ' active':'')}
        >
        <i className={icon+" fa-fw"}/>
        {!minimized && <span className="ms-2">{name}</span>}
    </NavLink>
)



export const ProfileBar = ({userData, rights, minimized, horizontal}) => {
    const { t } = useTranslation();
    return (
        <section className={"d-flex flex-column " + (horizontal ? "w-100" : "h-100")} style={{width: horizontal ? "" : (minimized ? 80 : 300)}}>

            {!minimized && <div className="card p-3 mb-2 mt-3">
                <div className="d-flex flex-row align-items-start">
                    <i className="fas fa-user-circle fa-3x me-1 ms-2"/>
                    <div className="d-flex flex-column justify-content-center ms-1">
                        <span className="text-muted">{t('hello')},</span>
                        <h5 className="m-0">{userData.name}</h5>
                        <span className="d-flex flex-row align-items-center">
                            
                            <span className="ms-0 text-muted">@{userData.company?.name || '?'}</span>
                        </span>
                    </div>
                </div>
            </div>}
            <nav id="sidebarMenu" className="d-lg-block sidebar bg-white flex-fill">
                <div className={horizontal ? "w-100" : "h-100"}>
                    <div className={"list-group list-group-flush " + (horizontal ? "w-100 d-flex flex-row p-0 overflow-auto hide-scrollbar" : "h-100 p-2")}>
                        <SideNavItem minimized={minimized} horizontal={horizontal} name={t('profile')} icon="fas fa-user-alt" location="" />
                        { rights?.Companies?.Sub?.View && <SideNavItem minimized={minimized} horizontal={horizontal} name={t('companies')} icon="fas fa-building" location="companies" /> }
                        { rights?.Users?.View && <SideNavItem minimized={minimized} horizontal={horizontal} name={t('users')} icon="fas fa-users" location="users" /> }
                        { rights?.Users?.ManageRights && <SideNavItem minimized={minimized} horizontal={horizontal} name={t('permissions')} icon="fas fa-briefcase" location="permissions" /> }
                        { rights?.Groups?.View && <SideNavItem minimized={minimized} horizontal={horizontal} name={t('groups')} icon="fas fa-layer-group" location="groups" /> }
                        { rights?.Locations?.View && <SideNavItem minimized={minimized} horizontal={horizontal} name={t('locations')} icon="fas fa-map-marker-alt" location="locations" /> }
                        { rights?.Products?.View && <SideNavItem minimized={minimized} horizontal={horizontal} name={t('products')} icon="fas fa-shopping-cart" location="products" /> }
                        { rights?.Locations?.ViewMachines && <SideNavItem minimized={minimized} horizontal={horizontal} name={t('machines')} icon="fas fa-server" location="machines" /> }

                        <span className="flex-fill list-group-item p-0"/>
                        { userData.company.id == 1 && <SideNavItem minimized={minimized} horizontal={horizontal} name={t('developer_options')} icon="fas fa-code" location="dev" /> }
                        <SideNavItem minimized={minimized} horizontal={horizontal} name={t('logout')} icon=" fas fa-sign-out-alt" location="logout"/>
                        {horizontal && <span></span>}
                    </div>
                </div>
                
            </nav>
                
            
        </section>
    )
}