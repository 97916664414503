import { useEffect, useRef, useState } from "react";
import * as mdb from 'mdb-ui-kit'; // lib
import { useTranslation } from "react-i18next";


const CustomSelectItem = (props) => (
    <div className="custom-select-item" title={props.item.title} onClick={(() => {props.onSelect(props.item.value);})} style={{display: props.hidden ? 'none' : ''}}>
        {props.item.icon &&
            <i className={props.item.icon+' me-1'} style={{color: props.item.iconColor||''}}/>
        }
        {props.item.iconSrc &&
            <img src={props.item.iconSrc} className="me-1" alt=""/>
        }
        <span style={{overflow: 'hidden', textOverflow: 'ellipsis'}}>{props.item.title}</span>
    </div>
);

const CustomSelectSelectedItem = ({item, disabled, defaultTitle, title}) => {
    const [itemState, setItemState] = useState(item);
    const iref = useRef();
    const { t } = useTranslation();

    useEffect(() => {
        setItemState(item);
    }, [item]);

    useEffect(() => {
        if (iref && iref.current){
            setTimeout(() => {
                if (iref && iref.current) iref.current.dispatchEvent(new Event('input'));
            }, 100);
            
        }
    }, [iref]);

    return (
        <div className="w-100 h-100" style={{backgroundColor: disabled ? '#e9ecef' : ''}}>
            {itemState && itemState.icon &&
                <span style={{position: 'absolute', zIndex: 10001, left: 5, top: 9}}>
                    <i className={itemState.icon+' me-1 fa-fw'} style={{color: itemState.iconColor||''}}/>
                </span>
            }
            <div className="form-outline pe-3 d-flex flex-row"  style={{whiteSpace: 'nowrap'}}>
                
                <input ref={iref} type="text" className={"form-control " + (disabled ? '' : 'bg-white') + (itemState ? '' : ' fst-italic')} readOnly id="userName" disabled={disabled} value={itemState ? itemState.title : (defaultTitle||t('choose_from_list'))}
                    style={{overflow: 'hidden', textOverflow: 'ellipsis', userSelect: 'none', cursor: disabled ? '' : 'pointer', paddingLeft: itemState && itemState.icon ? 28 : ''}}></input>
                {title && <label className="form-label" htmlFor="userName">{title}</label> }
            </div>
        </div>
    )
};


export const CustomSelect = ({width, selected, items, onSelect, defaultTitle, expandWidth, expandUp, title, w100, disabled, search, slim}) => {
    const [selectedItem, setSelectedItem] = useState(selected);
    const [selItem, setSelItem] = useState(null);
    const [itemsState, setItemsState] = useState([]);
    const [opened, setOpened] = useState(false);
    const [searchText, setSearchText] = useState('');
    const input = useRef();

    useEffect(() => {
        setTimeout(() => {
            document.querySelectorAll(".form-outline").forEach((formOutline) => {
                new mdb.Input(formOutline).init();
            });
        }, 25);
        
    }, [selectedItem]);

    useEffect(() => {
        setSelectedItem(selected);
    }, [selected]);

    useEffect(() => {
        setSearchText('');
    }, [opened]);

    useEffect(() => {
        setItemsState(items);
    }, [items]);

    useEffect(() => {
        setSelItem(items.filter(it => it.val == selected)[0]||null);
    }, [items, selected]);

    return (
        <div className={w100 ? "w-100" : ""}>
            <div ref={input} className={`position-relative d-flex flex-row pt-1 pb-0`}
                style={{alignItems: 'center', width: width || ''}} onClick={() => setOpened(!disabled && !opened)}>
                
                <CustomSelectSelectedItem title={title} defaultTitle={defaultTitle} item={selItem} disabled={disabled}/>
                {!disabled && <>
                    <span style={{display: !opened ? 'block' : 'none', position: 'absolute', right: 5}}><i className="fas trailing fa-angle-down fa-fw" ></i></span>
                    <span style={{display: opened ? 'block' : 'none', position: 'absolute', right: 5}}><i className="fas trailing fa-angle-up fa-fw"></i></span>
                </>}
            </div>

            <div style={{position: 'fixed', height: '100vh', left: 0, top: 0, width: '100vw', zIndex: 99994, display: opened ? 'block' : 'none'}}
                    onClick={() => setOpened(false)}/>
            {opened &&
                <div className="card custom-select-box" style={{position: 'fixed', zIndex: 99995, display: opened ? 'block' : 'none',
                    top: input.current ? (input.current.getBoundingClientRect().y + input.current.getBoundingClientRect().height) : 0, 
                    left: input.current ? (input.current.getBoundingClientRect().x) : 0, 
                    maxHeight: (input.current && input.current.getBoundingClientRect().bottom) > window.innerHeight ? 
                    (input.current.getBoundingClientRect().height - (input.current.getBoundingClientRect().bottom - window.innerHeight)) : '',
                    width: (expandWidth || width ||  (input.current ? input.current.getBoundingClientRect().width : 0))}}>
                    {search && <div>
                        <div className="input-group rounded p-1">
                            <input type="search" className="form-control rounded" placeholder="Search" aria-label="Search" aria-describedby="search-addon" value={searchText} onChange={ev => setSearchText(ev.currentTarget.value)}/>
                            <span className="input-group-text border-0" id="search-addon">
                                <i className="fas fa-search"></i>
                            </span>
                        </div>
                    </div>}
                    <div className="custom-select-body" style={{maxHeight: search ? 200 : 250}}>
                        {
                            itemsState.map((item) => (
                                <CustomSelectItem key={item.val} item={item} hidden={item.title.toLocaleUpperCase().indexOf(searchText.toLocaleUpperCase()) < 0} onSelect={() => {setSelectedItem(item.val); setOpened(false); if (onSelect) onSelect(item.val)}}/>
                            ))
                        }
                    </div>
                </div>
            
            }
            
        </div>
        
    )
}