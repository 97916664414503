
import * as mdb from 'mdb-ui-kit'; // lib
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";
import API from '../../service/api';
import { Trans, useTranslation } from 'react-i18next';
// http://localhost:3000/join?invitationId=18d0a54a-fff8-44cd-bad5-a1e93204d9f5

export const JoinPage = ({onLogin}) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [invitation, setInvitation] = useState(null);
    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        const invitationId = searchParams.get('invitationId');

        API.joinGetInvitation(invitationId)
            .then(inv => {
                setInvitation(inv);
            })
            .catch(err => {
                navigate('/');
            })

    }, [searchParams, navigate]);

    useEffect(() => {
        document.querySelectorAll(".form-outline").forEach((formOutline) => {
			new mdb.Input(formOutline).init();
		});
    }, [invitation]);

    if (!invitation) return null;

    return (
        <section className="h-100" style={{background: '#eee'}}>
            <div className="container-fluid h-100 ">
                <div className="d-flex justify-content-center align-items-center h-100">
                    <div className='card d-flex position-relative'>
                        <span className='position-absolute d-flex w-100 justify-content-center' style={{top: '-50px'}}>
                            <span className='rounded-circle p-2 text-primary shadow-3' style={{background: '#ddd4d4'}}><i className="fas fa-door-open fa-3x p-2"/></span>
                        </span>
                        
                        <div className='card-body d-flex flex-column justify-content-center align-items-center' style={{marginTop: 15}}>
                            {invitation.name && <h5 className='mb-0'>{invitation.name}</h5> }
                            {invitation.by && <span className='text-muted'><Trans i18nKey="invited_by" values={{name: invitation.by}}/></span>}
                            {invitation.email && (
                                <div className="form-outline mt-3">
                                    <input type="text" name="email" id="email" className="form-control" value={invitation.email} disabled/>
                                    <label className="form-label" htmlFor="email">{t('email')}</label>
                                </div>
                            )}
                            {invitation.status === 'createUser' && (<>
                                <div className="form-outline mt-2">
                                    <input type="text" name="name" id="name" className="form-control" value={name} onChange={ev => setName(ev.currentTarget.value)}/>
                                    <label className="form-label" htmlFor="name">{t('fullname')}</label>
                                </div>
                                <div className="form-outline mt-2">
                                    <input type="text" name="phone" id="phone" className="form-control" value={phone} onChange={ev => setPhone(ev.currentTarget.value)}/>
                                    <label className="form-label" htmlFor="phone">{t('phone')}</label>
                                </div>
                                <div className="form-outline mt-2">
                                    <input type="password" name="password" id="password" className="form-control" value={password} onChange={ev => setPassword(ev.currentTarget.value)}/>
                                    <label className="form-label" htmlFor="password">{t('setpassword')}</label>
                                </div>
                                <div className="form-outline mt-2">
                                    <input type="password" name="confirmPassword" id="confirmPassword" className="form-control" value={confirmPassword} onChange={ev => setConfirmPassword(ev.currentTarget.value)}/>
                                    <label className="form-label" htmlFor="confirmPassword">{t('confirmpassword')}</label>
                                </div>
                                {passwordError && <div className="alert alert-danger alert-dismissible fade show mt-2 mb-1 p-3 w-100" role="alert">
                                    { passwordError }
                                </div>}
                                <div className='d-flex flex-row mt-2 w-100'>
                                    <button className='btn btn-danger w-50 me-1' disabled={loading} onClick={() => {
                                        setLoading(true);
                                        API.joinCancel(searchParams.get('invitationId'))
                                            .finally(() => {
                                                navigate('/');
                                            });
                                    }}>{t('cancel')}</button>
                                    <button className='btn btn-success w-50 ms-1' disabled={loading} onClick={() => {
                                        if (password !== confirmPassword) return setPasswordError(t('password_nomatch_error'));
                                        setLoading(true);
                                        API.joinConfirm(searchParams.get('invitationId'), name, phone, password)
                                            .then(() => {
                                                API.login(invitation.email, password)
                                                    .then(data => { onLogin(data); localStorage.setItem('newUser', '1'); navigate('/'); })
                                                    .catch(err => navigate('/'))
                                            })
                                            .catch(err => {
                                                setPasswordError(err.message);
                                            })
                                            .finally(() => {
                                                setLoading(false);
                                            })
                                    }}>{t('join')}</button>
                                </div>
                                
                            </>)}

                            {invitation.status === 'validatePassword' && (<>
                                <div className="form-outline mt-2">
                                    <input type="password" name="password" id="password" className="form-control" value={password} onChange={ev => setPassword(ev.currentTarget.value)}/>
                                    <label className="form-label" htmlFor="password">{t('password')}</label>
                                </div>
                                {passwordError && <div className="alert alert-danger alert-dismissible fade show mt-2 mb-1 p-3 w-100" role="alert">
                                    { passwordError }
                                </div>}
                                <div className='d-flex flex-row mt-2 w-100'>
                                    <button className='btn btn-danger w-50 me-1' disabled={loading} onClick={() => {
                                        setLoading(true);
                                        API.joinCancel(searchParams.get('invitationId'))
                                            .finally(() => {
                                                navigate('/');
                                            });
                                    }}>{t('cancel')}</button>
                                    <button className='btn btn-success w-50 ms-1' disabled={loading} onClick={() => {
                                        setLoading(true);
                                        API.joinConfirm(searchParams.get('invitationId'), null, null, password)
                                            .then(() => {
                                                API.login(invitation.email, password)
                                                    .then(data => { onLogin(data); navigate('/'); })
                                                    .catch(err => setPasswordError(err.message))
                                            })
                                            .catch(err => {
                                                setPasswordError(err.message);
                                            })
                                            .finally(() => {
                                                setLoading(false);
                                            })
                                    }}>{t('join')}</button>
                                </div>
                            </>)}

                            {invitation.status === 'confirm' && (<>
                                <button className='btn btn-success w-100 mt-2 ms-1' disabled={loading} onClick={() => {
                                        setLoading(true);
                                        API.joinConfirm(searchParams.get('invitationId'), null, null, null)
                                            .then(() => {
                                                navigate('/');
                                            })
                                            .catch(err => {
                                                setPasswordError(err.message);
                                            })
                                            .finally(() => {
                                                setLoading(false);
                                            })
                                    }}>{t('join')}</button>
                            </>)}

                            {invitation.error && (
                            <div>
                                <div className="alert alert-danger alert-dismissible fade show mt-2" role="alert">
                                    { invitation.error }
                                </div>
                                <button className='btn btn-primary w-100' onClick={() => navigate('/')}>{t('cancel')}</button>
                            </div>)}
                        </div>
                    </div>
                </div>
            </div>
            
            
        </section>
    )
}