
import { useEffect, useState } from 'react';
import { CustomModal } from '../../other/CustomModal';
import * as mdb from 'mdb-ui-kit'; // lib
import API from '../../../service/api';
import ImageFallback from 'react-image-fallback';
import { Trans, useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';


const ChangeNameModal = ({groupID, locationID, machine, onDone, onClose}) => {
    const [name, setName] = useState('');
    const [changed, setChanged] = useState(false);
    const [error, setError] = useState('');
    const { t } = useTranslation();

    useEffect(() => {
        document.querySelectorAll(".form-outline").forEach((formOutline) => {
			new mdb.Input(formOutline).init();
		});
    }, [name]);

    useEffect(() => {
        setError('');
        setName(machine?.name || '');
        setChanged(false);
    }, [machine]);

    const changeName = () => {
        setChanged(false);

        API.updateMachineName(groupID, locationID, machine.id, name)
            .then(() => { onDone(); onClose(); })
            .catch(err => setError(err.message));
    }

    return (
        <CustomModal open={machine} onClose={onClose}>
            <div className="card mx-3" style={{width: 300}}>
                <div className="card-header">
                    <h5>{t('change_name')}</h5>
                </div>
                <div className="card-body">
                    <div className="form-outline">
                        <input type="text" name="name" id="name" className="form-control" value={name} onInput={ev => { setName(ev.currentTarget.value); setChanged(true); }}/>
                        <label className="form-label" htmlFor="name">{t('name')}</label>
                    </div>
                    { error && <div className="alert alert-danger alert-dismissible fade show mt-2" role="alert">
                        { error }
                    </div> }
                </div>
                <div className="card-footer">
                    <button type="button" className="btn btn-primary" disabled={!changed} onClick={changeName}>{t('change')}</button>
                </div>
            </div>
        </CustomModal>
        
    )
}

const ClearMoneyboxModal = ({groupID, locationID, machine, onDone, onClose}) => {
    const [error, setError] = useState('');
    const { t } = useTranslation();

    useEffect(() => {
        setError('');
    }, [machine]);

    const clearMoneybox = () => {

        API.machineActionClearMoneybox(groupID, locationID, machine.id)
            .then(() => { onDone(); onClose(); })
            .catch(err => setError(err.message));
    }

    return (
        <CustomModal open={machine} onClose={onClose}>
            <div className="card mx-3" style={{width: 300}}>
                <div className="card-header">
                    <h5>{t('clear_cashbox')}</h5>
                </div>
                <div className="card-body">
                    {t('clear_cashbox_prompt')}
                    { error && <div className="alert alert-danger alert-dismissible fade show mt-2" role="alert">
                        { error }
                    </div> }
                </div>
                <div className="card-footer">
                    <button type="button" className="btn btn-primary" onClick={clearMoneybox}>{t('clear')}</button>
                </div>
            </div>
        </CustomModal>
        
    )
}

const ClearStatus = ({groupID, locationID, machine, status, statusTitle, onDone, onClose}) => {
    const [error, setError] = useState('');
    const { t } = useTranslation();

    useEffect(() => {
        setError('');
    }, [machine]);

    const clearStatus = () => {

        API.clearStatus(groupID, locationID, machine.id, status)
            .then(() => { onDone(); onClose(); })
            .catch(err => setError(err.message));
    }

    return (
        <CustomModal open={machine} onClose={onClose}>
            <div className="card mx-3" style={{width: 300}}>
                <div className="card-header">
                    <h5>{t('clear_status')}</h5>
                </div>
                <div className="card-body">
                    <Trans i18nKey={'clear_status_prompt'} values={{statusTitle}}/>
                    { error && <div className="alert alert-danger alert-dismissible fade show mt-2" role="alert">
                        { error }
                    </div> }
                </div>
                <div className="card-footer">
                    <button type="button" className="btn btn-primary" onClick={clearStatus}>{t('clear')}</button>
                </div>
            </div>
        </CustomModal>
        
    )
}







const EditCashboxNr = ({groupID, locationID, machine, onClose, onEdit}) => {
    const [coinboxNr, setCoinboxNr] = useState(0);
    const [coinboxNrChanged, setCoinboxNrChanged] = useState(false);
    const [error, setError] = useState('');
    const { t } = useTranslation();

    useEffect(() => {
        setError('');
        if (machine){
            setCoinboxNr(machine.coins);
            setCoinboxNrChanged(false); 
        }
        document.querySelectorAll(".form-outline").forEach((formOutline) => {
            new mdb.Input(formOutline).init();
        });
    }, [machine]);

    const update = () => {
        setCoinboxNrChanged(false);
        API.updateMachine(groupID, locationID, machine.id, undefined, Number(coinboxNr), undefined)
            .then(() => { onEdit(); onClose(); })
            .catch(err => setError(err.message));
    }


    return (
        <CustomModal open={machine} onClose={onClose}>
            <div className="card mx-3" style={{width: '280px'}}>
                <div className="card-body">
                    <h5 className="card-title">{t('edit_coins')}</h5>
                    
                    <div className={"form-outline mb-2"}>
                        <input
                            type="number"
                            id="editcoinboxnr"
                            className="form-control"
                            value={coinboxNr}
                            onChange={(ev) => { setCoinboxNr(ev.currentTarget.value); setCoinboxNrChanged(true);}}
                        />
                        <label className="form-label" htmlFor="editcoinboxnr">{t('cashbox_coins')}</label>
                    </div>
                    

                    {error && (
                        <div className="alert alert-danger alert-dismissible fade show mt-2" role="alert">
                            { error }
                        </div>
                    )}
                    
                    <button className="btn btn-primary w-100" disabled={!coinboxNrChanged} onClick={() => { update(); }}>{t('update')}</button>
                </div>
            </div>
        </CustomModal>
    )
}


const EditCashboxVal = ({groupID, locationID, machine, onClose, onEdit}) => {
    const [coinboxVal, setCoinboxVal] = useState(0);
    const [coinboxValChanged, setCoinboxValChanged] = useState(false);
    const [error, setError] = useState('');
    const { t } = useTranslation();

    useEffect(() => {
        setError('');
        if (machine){
            setCoinboxVal(machine.value);
            setCoinboxValChanged(false); 
        }
        document.querySelectorAll(".form-outline").forEach((formOutline) => {
            new mdb.Input(formOutline).init();
        });
    }, [machine]);

    const scaleVal = () => {
        const nr = coinboxVal / machine.cUnit;
		const nrInt = Math.round(nr);
		if (nr === nrInt) return coinboxVal;
		const newVal = nrInt * machine.cUnit;
		setCoinboxVal(newVal);
		return newVal;
    }

    const update = () => {
        setCoinboxValChanged(false);
        const val = scaleVal();
        API.updateMachine(groupID, locationID, machine.id, undefined, undefined, Number(val))
            .then(() => { onEdit(); onClose(); })
            .catch(err => setError(err.message));
    }


    return (
        <CustomModal open={machine} onClose={onClose}>
            <div className="card mx-3" style={{width: '280px'}}>
                <div className="card-body">
                    <h5 className="card-title">{t('edit_value')}</h5>
                    <div className={"form-outline mb-2"}>
                        <input
                            type="number"
                            id="editcoinboxval"
                            className="form-control"
                            value={coinboxVal}
                            onChange={(ev) => { setCoinboxVal(ev.currentTarget.value); setCoinboxValChanged(true);}}
                            onBlur={() => scaleVal()}
                        />
                        <label className="form-label" htmlFor="editcoinboxval">{t('value')}</label>
                    </div>
                    

                    {error && (
                        <div className="alert alert-danger alert-dismissible fade show mt-2" role="alert">
                            { error }
                        </div>
                    )}
                    
                    <button className="btn btn-primary w-100" disabled={!coinboxValChanged} onClick={() => { update(); }}>{t('update')}</button>
                </div>
            </div>
        </CustomModal>
    )
}


export const MachineDetails = ({groupID, locationID, machine, onEdit, rights}) => {
    const [aditional, setAditional] = useState(null);
    const [canEditName, setCanEditName] = useState(false);
    const [canEditCoinbox, setCanEditCoinbox] = useState(false);
    const [canClearMoneybox, setCanClearMoneybox] = useState(false);

    const [editName, setEditName] = useState(null);
    const [editCashboxCoins, setEditCashboxCoins] = useState(null);
    const [editValue, setEditValue] = useState(null);
    const [clearMoneybox, setClearMoneybox] = useState(null);
    const [clearStatus, setClearStatus] = useState(null);
    const [clearStatusMachine, setClearStatusMachine] = useState(null);
    const { t } = useTranslation();

    useEffect(() => {
        setCanEditCoinbox(rights?.Machines?.MoneyBox?.View && rights?.Machines?.MoneyBox?.Change);
        setCanEditName(rights?.Machines?.Actions?.ChangeName);
        setCanClearMoneybox(rights?.Machines?.Actions?.ClearMoneyBox);
    }, [rights]);

    useEffect(() => {
        try {
            setAditional(machine.aditional);
        }catch(err){};
    }, [machine]);

    if (!machine) return <div>...</div>;

    return (
        <section className="d-flex flex-column w-100 flex-fill justify-content-start mt-2 px-2 py-2">
            <EditCashboxNr groupID={groupID} locationID={locationID} machine={editCashboxCoins} onEdit={() => { setEditCashboxCoins(null); onEdit(); }} onClose={() => setEditCashboxCoins(null)}/>
            <EditCashboxVal groupID={groupID} locationID={locationID} machine={editValue} onEdit={() => { setEditValue(null); onEdit(); }} onClose={() => setEditValue(null)}/>
            <ChangeNameModal groupID={groupID} locationID={locationID} machine={editName} onDone={() => { setEditName(null); onEdit(); }} onClose={() => setEditName(null)}/>
            <ClearMoneyboxModal groupID={groupID} locationID={locationID} machine={clearMoneybox} onDone={() => { setClearMoneybox(null); onEdit(); }} onClose={() => setClearMoneybox(null)}/>
            <ClearStatus key={clearStatus} groupID={groupID} locationID={locationID} machine={clearStatusMachine} status={clearStatus?.val} statusTitle={clearStatus?.title} onDone={() => { setClearStatusMachine(null); onEdit(); }} onClose={() => setClearStatusMachine(null)}/>
            
            <div className='d-flex flex-row ms-2 mb-2 align-items-center'>
                <h5 className="mb-0">{t('general')}</h5>
            </div>

            <div className="card p-3">
                <ul className="list-group list-group-light p-0">
                    <li className="list-group-item p-1 d-flex flex-row align-items-start">
                        <span className="fw-bold me-1">{t('name')}:</span>
                        <span className='d-flex flex-fill overflow-hidden'>{machine.name}</span>
                        { canEditName && <span className='flex-fill d-flex justify-content-end ms-2'><span role="button" onClick={() => setEditName(machine)}><i className='fas fa-pen-to-square text-primary'/></span></span> }
                    </li>
                    <li className="list-group-item p-1"><span className="fw-bold me-1">{t('type')}:<ImageFallback src={`/mtypes/mt${machine.type.id}.png`} width={22} height={22} className='ms-1' fallbackImage={<span></span>}/></span>{machine.type?.name}</li>
                    <li className="list-group-item p-1">
                        <span className="fw-bold me-1" data-tooltip-id="expireDateTime" data-tooltip-content={machine.expireAt ? `${t('expire_at')} ${new Date(machine.expireAt).toLocaleString()}` : ''}>{t('status_title')}:</span>
                        <Tooltip id="expireDateTime"  />
                        { (machine.status?.CommLost) && <span role="button" className="badge badge-danger text-danger ms-1" onClick={() => { setClearStatus({val: 'CommLost', title: t('status.communication_lost')}); setClearStatusMachine(machine) }}><i className='fas fa-signal me-1'/>{t('status.communication_lost')}</span> }
                        { (machine.status?.CashlessLost) && <span role="button" className="badge badge-danger text-danger ms-1" onClick={() => { setClearStatus({val: 'CashlessLost', title: t('status.cashless')}); setClearStatusMachine(machine) }}><i className='fas fa-credit-card me-1'/>{t('status.cashless')}</span> }
                        { (machine.status?.CashboxFull) && <span role="button" className="badge badge-danger text-danger ms-1" onClick={() => { setClearStatus({val: 'CashboxFull', title: t('status.cashboxfull')}); setClearStatusMachine(machine) }}><i className='fas fa-cart-flatbed me-1'/>{t('status.cashboxfull')}</span> }
                        
                        { (machine.status?.CashboxAlmostFull) && <span role="button" className="badge badge-warning ms-1" onClick={() => { setClearStatus({val: 'CashboxAlmostFull', title: t('status.cashbox_almost_full')}); setClearStatusMachine(machine) }}><i className='fas fa-cart-flatbed me-1'/>{t('status.cashbox_almost_full')}</span> }
                        { (machine.status?.LowGSMSignal) && <span role="button" className="badge badge-warning ms-1" onClick={() => { setClearStatus({val: 'LowGSMSignal', title: t('status.low_signal')}); setClearStatusMachine(machine) }}><i className='fas fa-signal me-1'/>{t('status.low_signal')}</span> }
                        { (machine.status?.ScheduleSuspended) && <span role="button" className="badge badge-warning ms-1" onClick={() => { setClearStatus({val: 'ScheduleSuspended', title: t('status.schedule_suspended')}); setClearStatusMachine(machine) }}><i className='fas fa-calendar-xmark me-1'/>{t('status.schedule_suspended')}</span> }

                        {!(machine.status?.CashlessLost || machine.status?.CashboxFull || machine.status?.CommLost || machine.status?.CashboxAlmostFull
                            || machine.status?.LowGSMSignal || machine.status?.ScheduleSuspended) &&
                            <span className="badge badge-primary ms-1">{t('ok')}</span>
                        }
                    </li>
                    { ( (machine.expireAt && new Date(machine.expireAt).getTime() < Date.now()) || machine.expireAt == null) &&
                    <li className="list-group-item p-1">
                        <span className="badge badge-danger ms-1"><i className='fas fa-calendar-xmark me-1'/>{t('machine_expired')}</span>
                    </li> }
                    { (machine.expireAt && new Date(machine.expireAt).getTime() > Date.now() && (new Date(machine.expireAt).getTime() - Date.now() <= 30 * 24 * 60 * 60 * 1000)) &&
                    <li className="list-group-item p-1">
                        <span className="badge badge-warning ms-1"><i className='fas fa-hourglass me-1'/>{t('machine_expire_1month')}</span>
                    </li> }
                </ul>
            </div>
            {(machine.info && machine.info.length > 0) && <>
                <h5 className="ms-2 mt-2">{t('description')}</h5>
                <div className="card p-3">
                    <span>{machine.info}</span>
                </div>
            </>}
            

            {(machine.aditional && Object.keys(machine.aditional).length > 0) && <>
                <h5 className="ms-2 mt-4">{t('aditional')}</h5>
                <div className="card p-1">
                        <li className="list-group-item p-1">
                            {Object.keys(machine.aditional).map(key => (
                                <span key={key} className="badge badge-primary text-dark ms-1">
                                    {key}: <b className='text-primary'>{machine.aditional[key]}</b>
                                </span>
                            ))}
                        </li>
                    {/* {!aditional && <span>{machine.aditional}</span>} */}
                </div>
            </>}

            <h5 className="ms-2 mt-4">{t('identification')}</h5>
            <div className="card p-2">
                <ul className="list-group list-group-light p-0">
                    <li className="list-group-item p-1"><i className="fas fa-sim-card fa-fw text-primary me-1"/><span className="fw-bold me-1">{t('sn')}:</span>{machine.sn}</li>
                    <li className="list-group-item p-1"><i className="fas fa-memory fa-fw text-primary me-1"/><span className="fw-bold me-1">{t('imei')}:</span>{machine.IMEI}</li>
                    <li className="list-group-item p-1"><i className="fas fa-satellite fa-fw text-primary me-1"/><span className="fw-bold me-1">{t('iccid')}:</span>{machine.ICCID}</li>
                </ul>
            </div>

            <div className='d-flex flex-row ms-2 mt-4 mb-2 align-items-center'>
                <h5 className="mb-0">{t('balance')}</h5>
                { (canClearMoneybox && machine.status.Connected) && <span className='d-flex flex-fill justify-content-end'>
                    <button type="button" className="btn btn-link text-danger p-1" data-mdb-ripple-color="dark" onClick={() => setClearMoneybox(machine)}>{t('clear')}</button>
                </span> }
            </div>
            
            <div className="card p-2">
                <ul className="list-group list-group-light p-0">
                    { machine.coins !== undefined && <li className="list-group-item p-1 d-flex flex-row align-items-center">
                        { machine.coins !== undefined && <span className="d-inline-block me-3"><i className="fas fa-coins fa-fw text-primary me-1"/><span className="fw-bold me-1">{t('cashbox_coins')}:</span>{machine.coins}</span> }
                        { (canEditCoinbox && machine.status.Connected) && <span className='flex-fill d-flex justify-content-end ms-2'><span role="button" onClick={() => setEditCashboxCoins(machine)}><i className='fas fa-pen-to-square text-primary'/></span></span> }
                    </li> }
                    { (machine.topCnt !== undefined || machine.botCnt !== undefined) && <li className="list-group-item p-1">
                        <i className="fas fa-tachograph-digital fa-fw text-primary me-1"/><span className="fw-bold me-1">{t('counters')}:</span>
                        <i className="fas fa-caret-up text-secondary"/>{machine.topCnt}/<i className="fas fa-caret-down text-secondary"/>{machine.botCnt}
                    </li> }
                    { (machine.value !== undefined) && <li className="list-group-item d-flex flex-row align-items-center p-1 list-group-item-success">
                        <i className="fas fa-hand-holding-dollar fa-fw text-primary me-1"/><span className="fw-bold me-1">{t('value')}:</span>{machine.value}
                        { (canEditCoinbox && machine.status.Connected) && <span className='flex-fill d-flex justify-content-end ms-2'><span role="button" onClick={() => setEditValue(machine)}><i className='fas fa-pen-to-square text-primary'/></span></span> }
                    </li>}
                    
                </ul>
            </div>
        </section>
    );

}