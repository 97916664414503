import { Html5Qrcode } from "html5-qrcode";
import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next";
import API from "../../service/api";
import { useNavigate } from "react-router";
import { CustomSelect } from "../../utils/CustomSelect";


export const QRScanner = ({currentGroupId, onClose}) => {
    const [scanner, setScanner] = useState(null);
    const [status, setStatus] = useState(0); //0 - nothing, 1 - no data, 2 - data but not valid SN, 3 - valid SN
    const [cameraList, setCameraList] = useState(null);
    const [selectedCameraId, setSelectedCameraId] = useState(null);
    const [sn, setSN] = useState('');
    const [error, setError] = useState(null);
    const qrarea = useRef();
    const { t } = useTranslation();

    const close = () => {
        if (scanner && [2,3].includes(scanner.getState())) scanner.stop(); 
        onClose();
    }

    useEffect(() => {
        const handler = () => close();
        window.addEventListener('popstate', handler);
        return () => {
            window.removeEventListener('popstate', handler);
        }
    }, [scanner]);

    const selectCamera = (id) => {
        setSelectedCameraId(id);
        localStorage.setItem('qrcamera', id);
    }
    
    useEffect(() => {
        if (!scanner) {
            let html5Qrcode = new Html5Qrcode(
                "reader",
            );
            setScanner(html5Qrcode);

            Html5Qrcode.getCameras()
                .then(c => console.log(c))
                .catch(err => console.log(err));

            if (window.navigator){
                navigator.mediaDevices.getUserMedia({ video: true })
                    .then(() => {
                        navigator.mediaDevices.enumerateDevices()
                            .then(devices => {
                                const cameras = devices.filter(d => d.kind == 'videoinput').map(d => {
                                    const caps = d.getCapabilities ? d.getCapabilities() : null;
                                    let sz = 0;
                                    if (caps) sz = (caps.width||0) * (caps.height||0);
                                    return {deviceId: d.deviceId, label: d.label, caps: caps, sz};
                                }).filter(d => !!d);
                                cameras.sort((a,b) => {
                                    return b.sz - a.sz;
                                });
                                setCameraList(cameras);
                                const localCamera = localStorage.getItem('qrcamera');
                                if (localCamera && cameras.find(c => c.deviceId == localCamera)) selectCamera(localCamera);
                                else if (cameras.length > 0) selectCamera(cameras[0].deviceId);
                            })
                    })
                    .catch(err => setError(err.message));
            }
        }
    }, []);

    useEffect(() => {
        const start = (scanner) => {
            scanner.start(
                selectedCameraId,
                { fps: 20, qrbox: 250 },
                    (decodedText, res) => {
                    onDecodedText(decodedText, scanner);
                },
                err => {
                    setStatus(1);
                }
            )
            .catch(err => {
                console.log(err);
                setError(err);
            });
        }
        if (selectedCameraId){
            if ([2,3].includes(scanner.getState())){
                scanner.stop()
                    .then(() => {
                        start(scanner);
                    })
            }else start(scanner);
        }
    }, [selectedCameraId, scanner]);


    const onDecodedText = (text, scanner) => {
        try {
            if (textIsSN(text)){
                scanner.pause(true);
                try {
                    navigator.vibrate(200);
                }catch(err){};
                setSN(text);
                setStatus(3);
            }else{
                setStatus(2);
            }
        }catch(err){ setError(err.message); }
        
    }

    const textIsSN = text => {
        return /^[0-9a-fA-F]+$/.test(text) && text.length >= 8;
    }

    return (
        <div className="d-flex flex-column position-fixed w-100 h-100 bg-dark align-items-center overflow-hidden" style={{zIndex: 1001}}>
            <nav className="navbar navbar-expand-lg navbar-light bg-light p-0 w-100" style={{zIndex: 1003}}>
                <div className="container-fluid justify-content-between px-2 py-1">
                    <span
                        className="nav-link d-flex align-items-center user-select-none cursor-pointer position-absolute ps-2"
                        onClick={() => { close() }}
                        style={{ cursor: "pointer"}}
                    >
                        <div className="d-flex align-items-center pe-1">
                            <i
                                className="fas fa-arrow-left nav-link"
                                style={{fontSize: "1.8rem" }}
                            />
                        </div>
                    </span>

                    <span className="d-flex flex-fill justify-content-end align-items-center me-1">
                        {/* <i className="fas fa-camera fa-lg me-1"/>
                        <h5 style={{lineHeight: 1, padding: 0, margin: 0, fontWeight: ''}}>{t('qr_scan')}</h5> */}
                        <CustomSelect width={250}
                            items={(cameraList||[]).map(c => ({
                                val: c.deviceId, title: c.label, icon: 'fas fa-camera'
                            }))}
                            selected={selectedCameraId}
                            onSelect={id => selectCamera(id)}
                        />

                    </span>
                </div>
            </nav>

            
            {!error && <>
                <div id="reader" ref={qrarea} className="bg-light d-flex flex-fill h-100 w-100" style={{zIndex: 1002}}></div>
                {cameraList==null && 
                    <div className="d-flex flex-column w-100 h-100 justify-content-center align-items-center position-absolute" style={{zIndex: 1003, marginTop: 80, marginBottom: 80}}>
                        <div className="spinner-border mt-2 position-absolute" role="status" style={{width: '5rem', height: '5rem'}}>
                            <span className="visually-hidden">{t('loading...')}</span>
                        </div>
                    </div>
                }
                {status === 1 &&
                    <span className="badge badge-primary position-fixed" style={{bottom: 5, zIndex: 1003}}>Scan the QR code of a machine!</span>
                }
                {status === 2 &&
                    <span className="badge badge-danger position-fixed" style={{bottom: 5, zIndex: 1003}}>Invalid QR code!</span>
                }
            </>}
            {error && <>
                <div className="bg-dark w-100 d-flex flex-column flex-fill align-items-center justify-content-start mt-5">
                    <i className="far fa-circle-xmark fa-8x text-danger"/>
                    <h1 className="text-danger mt-1">{t('issue_occured')}</h1>
                    <h6 className="text-center mx-2 text-muted">{error}</h6>

                </div>
            </>}

            <SNAnalyzer currentGroupId={currentGroupId} sn={sn} onClose={() => close()} onCancel={() => { setSN(''); setStatus(1); scanner.resume();  }}/>
        </div>
    )
}

const PoweredOnIcon = <i className="fas p-2 fa-xs fa-power-off" style={{width:12, height:12, zIndex: 200, borderRadius: '16px', background: '#1aa51a9c'}} />;
const PoweredOffIcon = <i className="fas p-2 fa-xs fa-power-off" style={{width:12, height:12, zIndex: 200, borderRadius: '16px', background: '#cdcfd1'}} />;
const PoweredUnknownIcon = <i className="fas p-2 fa-xs fa-power-off" style={{width:12, height:12, zIndex: 200, borderRadius: '16px', background: '#efbac3'}} />;

const SNAnalyzer = ({sn, currentGroupId, onCancel, onClose}) => {
    const [loading, setLoading] = useState(false);
    const [stateSN, setStateSN] = useState(null);
    const [error, setError] = useState(null);
    const [commandError, setCommandError] = useState(null);
    const [result, setResult] = useState(null);
    const { t } = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        setStateSN(sn);
        setError(null);
        setCommandError(null);
        setResult(null);
        setLoading(true);
        if (sn){
            API.searchSN(sn, currentGroupId)
                .then(r => {
                    console.log(r);
                    setResult(r);
                })
                .catch(err => {
                    setError(err.message.indexOf('Not Found') !== -1 ? 'Machine not found!' : 'Server error');
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [sn, currentGroupId]);

    const openDoor = () => {
        setCommandError(null);

        API.machineActionOpenDoor(result.openDoor.groupId, result.openDoor.locationId, result.machine.id)
            .then(() => {
            })
            .catch(err => {
                setCommandError(err.message);
            });
    }

    return (
        <div className="d-flex flex-column align-items-center pt-3 w-100 h-75 bg-light position-fixed" style={{maxWidth: 500, bottom: 0, zIndex: 1004,
            transform: `` + (!stateSN ? 'translateY(150px)' :''), opacity: !stateSN ? 0 : 1, transition: 'transform .4s ease-out, opacity .2s ease-out',
            borderTopLeftRadius: '0.5rem', borderTopRightRadius: '0.5rem'}}>

            <h5 className="text-muted">{stateSN}</h5>
            {loading &&
                <div className="spinner-border mt-2" role="status" style={{width: '5rem', height: '5rem'}}>
                    <span className="visually-hidden">{t('loading...')}</span>
                </div>
            }
            {error && <>
                <div className="d-flex flex-column align-items-center">
                    <i className="far fa-circle-xmark fa-8x text-danger"/>
                    <h1 className="text-danger mt-1">{error}</h1>

                </div>
            </>}
            {result && <>
                <div className="d-flex flex-row">
                    <h4 className="mb-1 text-primary">{result.machine.name}</h4>
                    <div className="d-flex flex-row position-absolute justify-content-center align-items-center" style={{right: 10}}>
                        {(result.machine.status?.Connected && !result.machine.status?.LowGSMSignal) && <>
                            <span className="me-1"><i className='fas fa-signal fa-lg me-1 text-success'/></span>
                        </>}
                        {(result.machine.status?.Connected && result.machine.status?.LowGSMSignal) && <>
                            <span className="me-1"><i className='fas fa-signal fa-lg me-1 text-warning'/></span>
                        </>}
                        {(!result.machine.status?.Connected) && <>
                            <span className="me-1"><i className='fas fa-signal fa-lg me-1 text-danger'/></span>
                        </>}
                        { result.machine.status?.PowerOn && <span className="" style={{lineHeight: 1}}>{PoweredOnIcon}</span> }
                        { result.machine.status?.PowerOff && <span className="" style={{lineHeight: 1}}>{PoweredOffIcon}</span> }
                        { (!result.machine.status?.PowerOff && !result.machine.status?.PowerOn) && <span className="m-0" style={{lineHeight: 1}}>{PoweredUnknownIcon}</span> }
                    </div>
                </div>
                
                <span><i className="fas fa-map-marker-alt me-1"/>{result.machine.location?.name}</span>
                { result.operator &&
                    <button className="btn btn-primary mt-3 w-75"
                        onClick={() => {onClose(); navigate(`/g/${result.operator.groupId}/locations/${result.operator.locationId}/machines/${result.machine.id}`, {relative: false})}}><i className="fas fa-desktop me-1"/>Operator</button>
                }
                { result.admin &&
                    <button className="btn btn-primary mt-2 w-75"
                        onClick={() => {onClose(); navigate(`/profile/machines?mid=${result.machine.id}`, {relative: false})}}><i className="fas fa-user-gear me-1"/>Administrator</button>
                }
                { result.openDoor &&
                    <button className="btn btn-primary mt-2 w-75"
                        onClick={() => openDoor()}><i className="fas fa-door-open me-1"/>Open door</button>
                }

                { commandError && <div className="alert alert-danger alert-dismissible fade show mt-2" role="alert">
                    { commandError }
                </div> }
            </>}

            <div className="d-flex flex-fill"></div>

            <button className="btn btn-danger w-75 mb-2" onClick={() => onCancel()}>{t('cancel')}</button>

        </div>
        
    )
}