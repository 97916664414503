import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Input } from 'mdb-ui-kit';
import { NavLink } from "react-router-dom";
import API from "../../service/api";
import { useSearchParams, useNavigate } from "react-router-dom";

export const ResetPassword = ({}) => {
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [emailConfirm, setEmailConfirm] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const r = searchParams.get('r');

    useEffect(()=>{
        if (r && r.length > 0){
            setLoading(true);
            API.resetPasswordGetRequestEmail(r)
                .then(data => setEmailConfirm(data.email))
                .catch(err => { navigate('..'); })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, []);

    useEffect(() => {
        document.querySelectorAll('.form-outline').forEach((formOutline) => {
            new Input(formOutline).init();
        });
    }, [r, emailConfirm, loading]);

    function reset(){
        API.resetPasswordRequest(email)
            .then(() => {
                setMessage(t('reset_request_success'));
            })
            .catch(err => setError(err.message));
    }

    function confirmChangePassword(){
        API.resetPasswordChangePassword(r, password)
            .then(() => navigate('../auth?success=1'))
            .catch(err => setError(err.message));
        
    }

    return (
        <section className="h-100">
            <div className="container-fluid h-100 ">
                <div className="row d-flex justify-content-center align-items-center h-100">
                    <div className="h-100 w-100 col-sm-6 text-black d-flex flex-column justify-content-center align-items-center">
                        <div className="d-flex flex-column flex-fill align-items-center h-custom-2 justify-content-center mb-5 ">

                            <div className="d-flex flex-column align-items-center pt-5 mb-5">
                                <i className="fas fa-cubes fa-3x" style={{color: "#fc8c03"}}/>
                                <span className="h2 fw-bold mb-0">Smart Capsule Machine</span>
                            </div>

                            <form style={{width: '80vw', maxWidth: '25rem'}}>
                                <h3 className="fw-normal mb-3 pb-3" style={{letterSpacing: "1px"}}>{t('reset_password')}</h3>
                                {loading &&
                                    <div className="d-flex justify-content-center pt-2 w-100">
                                        <div className="spinner-border" role="status">
                                            <span className="visually-hidden">{t('loading...')}</span>
                                        </div>
                                    </div>
                                }

                                {!loading && <>
                                    {emailConfirm && <>
                                        <div className="form-outline mb-4">
                                            <input type="email" id="form2Example18" className="form-control form-control-lg" value={emailConfirm} disabled/>
                                            <label className="form-label" htmlFor="form2Example18">{t('email_address')}</label>
                                        </div>
                                        <div className="form-outline mb-4">
                                            <input type="password" id="pass" className="form-control form-control-lg" value={password} onChange={ev => setPassword(ev.target.value)}/>
                                            <label className="form-label" htmlFor="pass">{t('password')}</label>
                                        </div>
                                        <div className="form-outline mb-4">
                                            <input type="password" id="pass" className="form-control form-control-lg" value={confirmPassword} onChange={ev => setConfirmPassword(ev.target.value)}/>
                                            <label className="form-label" htmlFor="pass">{t('confirmpassword')}</label>
                                        </div>
                                        {error && <div className="alert alert-danger alert-dismissible fade show mt-2" role="alert">
                                            { error }
                                        </div> }
                                        <div className="pt-1 mb-2">
                                            <button className="btn btn-info btn-lg btn-block" type="button" disabled={password !== confirmPassword || password.length < 4} onClick={() => { confirmChangePassword(); }}>{t('confirm')}</button>
                                        </div>
                                    </>}

                                    {!emailConfirm && <>
                                        {!message && <>
                                            <div className="form-outline mb-4">
                                                <input type="email" id="form2Example18" className="form-control form-control-lg" onChange={ev => setEmail(ev.target.value)} />
                                                <label className="form-label" htmlFor="form2Example18">{t('email_address')}</label>
                                            </div>
                                            {error && <div className="alert alert-danger alert-dismissible fade show mt-2" role="alert">
                                                { error }
                                            </div> }
                                            <div className="pt-1 mb-2">
                                                <button className="btn btn-info btn-lg btn-block" type="button" onClick={() => { reset(); }}>{t('reset')}</button>
                                            </div>
                                        </>}
                                        {message && <div className="alert alert-success alert-dismissible fade show mt-2" role="alert">
                                            { message }
                                        </div> }
                                    </>}
                                    
                                   
                                </>}
                                <div className='d-flex flex-column justify-content-center align-items-center mt-5'>
                                    <span role="button" className="text-muted">{t('do_you_have_an_account')}</span>
                                    <NavLink to="/auth"><span className="btn btn-link btn-lg btn-block" type="button">{t('login')}</span></NavLink>
                                </div>
                            </form>

                            

                        </div>

                    </div>
                </div>
            </div>
        </section>
    )
}