import { useEffect, useRef, useState } from "react"
import { Portal } from 'react-portal';

export const CustomModal = ({children, open, onClose, lazyRecalculate}) => {
    const [show, isShow] = useState(false);
    const [left, setLeft] = useState('50%');
    const [top, setTop] = useState('50%');
    const [recalculate, setRecalculate] = useState(false);
    const modalBody = useRef();

    useEffect(() => {
        if (lazyRecalculate) {
            setTimeout(() => {
                setRecalculate(true);
            }, lazyRecalculate || 150);
        }
    }, [lazyRecalculate]);

    useEffect(() => {
        if (open) isShow(true);
        else isShow(false);
    }, [open]);

    useEffect(() => {
        if (recalculate) setRecalculate(false);
        if (modalBody.current) {
            setLeft(window.innerWidth/2 - (modalBody.current.getBoundingClientRect().width/2));
            setTop(window.innerHeight/2 - (modalBody.current.getBoundingClientRect().height/2));
        }
    }, [modalBody, children, show, recalculate])
    return (
        <>
            {open &&<div ref={modalBody} className={"position-fixed " + (show ? 'show' : '')} style={{left: left, transform: `` + (!show ? 'translateY(-50px)' :''), top: top, zIndex: 99992, opacity: show ? 1 : 0, transition: 'transform .3s ease-out, opacity .2s ease-out'}}>
                { children }
            </div>}
            {open && <Portal><div className="position-fixed w-100 h-100" style={{left: 0, top: 0, background: '#000', opacity: show ? 0.5 : 0, zIndex: 99991, transition: 'opacity .3s ease-out'}}
                onClick={() => {
                    if (!onClose) return;
                    isShow(false);
                    setTimeout(() => { onClose() }, 300);
                }}>
            </div></Portal> }

        </>
    )
}