import { useTranslation } from "react-i18next"
import API from "../../../service/api"

export const Logout = () => {
    const { t } = useTranslation();

    return (
        <div className="d-flex flex-column align-items-center flex-fill mt-3">
            <div className="card bg-warning mb-3 text-light" style={{maxWidth: '18rem'}}>
                <div className="card-body">
                    <h5 className="card-title fw-bold">{t('logout')}</h5>
                    <p className="card-text">{t('logout_prompt')}</p>
                </div>
                <div className="card-footer">
                    <button type="button" className="btn btn-danger" onClick={async () => await API.logout()}><i className="fas fa-sign-out-alt me-1"/>{t('confirm')}</button>
                </div>
            </div>
        </div>
    )
}