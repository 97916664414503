import { useTranslation } from "react-i18next";
import Popup from "reactjs-popup";
import * as fns from "date-fns";
import { useCallback, useEffect, useState } from "react";
import API from "../../service/api";
import { CustomModal } from "../other/CustomModal";
import { DateRangePicker } from "react-date-range";
import WS from "../../service/ws";
import Lang from "../../service/lang";
import { IconRadioSelector } from "../other/IconRadioSelector";


export const ReportsSelectorPopup = ({disabled, mobile, trigger, gid, lid, mid}) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState('');
    const [rtype, setRType] = useState('sales');
    const [customDate, setCustomDate] = useState(false);
    const [customDateRanges, setCustomDateRanges] = useState({startDate: new Date(), endDate: new Date(), key: 'selection'});
    const [productsSelectorWindow, setProductsSelectorWindow] = useState(null);
    const [productsSelectorType, setProductsSelectorType] = useState("0");
    const [productsSelected, setProductsSelected] = useState([]);
    const [openProductsSelector, setOpenProductsSelector] = useState(false);
    const [products, setProducts] = useState(null);

    const periods = [
        {
            label: t('today'),
            range: () => ({
                startDate: fns.startOfDay(new Date()),
                endDate: fns.endOfDay(new Date())
            })
        },
        {
            label: t('yesterday'),
            range: () => ({
                startDate: fns.addDays(fns.startOfDay(new Date()), -1),
                endDate: fns.endOfDay(fns.addDays(fns.startOfDay(new Date()), -1))
            })
        },
        {
            label: t('this_week'),
            range: () => ({
                startDate: fns.startOfWeek(new Date(), {weekStartsOn: 1}),
                endDate: fns.endOfDay(new Date())
            })
        },
        {
            label: t('this_month'),
            range: () => ({
                startDate: fns.startOfMonth(new Date()),
                endDate: fns.endOfDay(new Date())
            })
        },
        {
            label: t('last_7_days'),
            range: () => ({
                startDate: fns.addDays(fns.startOfDay(new Date()), -6),
                endDate: fns.endOfDay(new Date())
            })
        },
        {
            label: t('last_31_days'),
            range: () => ({
                startDate: fns.addDays(fns.startOfDay(new Date()), -30),
                endDate: fns.endOfDay(new Date())
            })
        },
        {
            label: t('last_12_months'),
            range: () => ({
                startDate: fns.addMonths(fns.startOfDay(new Date()), -12),
                endDate: fns.endOfDay(new Date())
            })
        }
    ];

    const reset = () => {
        setCustomDate(false);
        setLoading(false);
        setError('');
        setSuccess(false);
        setCustomDate(false);
        setCustomDateRanges({startDate: new Date(), endDate: new Date(), key: 'selection'});
        setProductsSelectorWindow(null);
        setProductsSelectorType("0");
        setProductsSelected([]);
        setOpenProductsSelector(false);
        setRType('sales');
    }

    const create = (startDate, endDate) => {
        setLoading(true);
        if (endDate.getTime() > Date.now()) endDate = new Date();
        const prodList = rtype == 'products' ? {
            "0": "all_client",
            "1": "all_source",
            "2": productsSelected
        }[productsSelectorType] : undefined;

        API.reportsRequest(startDate, endDate, (new Date()).getTimezoneOffset(), gid, lid, mid, prodList)
            .then(({id}) => {
                ReportsSelectorPopup.RequestedReports.push(id);
                setSuccess(true);
                
            })
            .catch(err => {
                setError(err.message);
            })
            .finally(() => setLoading(false));
    }

    useEffect(() => {
        if (!openProductsSelector) setProducts(null);
        else {
            API.getProducts()
                .then(p => setProducts(p))
                .catch(err => {
                    console.error(err);
                });
        }

    }, [openProductsSelector]);

    if (disabled || window.DISABLE_REPORTS) return trigger;
    return (
        <>
            {customDate &&
                <CustomModal open={true} onClose={() => setCustomDate(false)}>
                    <div className="card p-1">
                        <div className="card-header">
                            <h5>{t('select_custom_dates')}</h5>
                        </div>
                        <div className="card-body p-0 d-flex flex-column">
                            <DateRangePicker className={'rdr-no-inputs ' + (mobile ? 'rdr-mobile' : '')}
                                ranges={[customDateRanges]}
                                onChange={range => setCustomDateRanges(range['selection'])}
                                maxDate={new Date()}
                                minDate={new Date(new Date().getFullYear()-1,0,1)}
                                staticRanges={[]}
                                inputRanges={[]}
                                locale={Lang.instance.getCurrentLocale()}
                            />

                            <button className="btn btn-success m-2" onClick={() => {
                                setCustomDate(false);
                                const {startDate, endDate} = customDateRanges;
                                if (rtype == 'products') setProductsSelectorWindow({startDate, endDate});
                                else create(startDate, endDate);
                                //create(customDateRanges.startDate, customDateRanges.endDate);
                            }}>{t('confirm')}</button>
                        </div>
                        
                    </div>

                </CustomModal>
            }
            {openProductsSelector &&
                <CustomModal open={true} onClose={() => setOpenProductsSelector(false)}>
                    <div className="card p-1">
                        <div className="card-header">
                            <h5>{t('select_products')}</h5>
                        </div>
                        <div className="card-body p-2 d-flex flex-column justify-content-center">
                            {products == null &&
                                <div className="d-flex flex-row w-100 justify-content-center">
                                    <div className="spinner-border" role="status">
                                        <span className="visually-hidden">{t('loading...')}</span>
                                    </div>
                                </div>
                            }
                            {products !== null &&
                                <div className="d-flex flex-column w-100 overflow-auto" style={{maxHeight: 400}}>
                                    {products.map(p => (
                                        <div key={p.id} className="form-check align-items-center d-flex">
                                            <input className="form-check-input" type="checkbox" checked={productsSelected.includes(p.id)} id={'ps'+p.id}
                                                onChange={
                                                    ev => { if (ev.currentTarget.checked) setProductsSelected(pr => pr.concat([p.id])); else setProductsSelected(pr => pr.filter(pid => pid !== p.id)); }
                                                } />
                                            <label className="form-check-label" htmlFor={'ps'+p.id}>{p.name}</label>
                                        </div>
                                    ))}
                                </div>
                            }
                        </div>
                    </div>
                </CustomModal>
            }
            <Popup position={"bottom right"} overlayStyle={{ zIndex: 1}} closeOnDocumentClick={false} trigger={<div role="button">{trigger}</div>} onOpen={() => {reset();}} >
                {close => 
                    <>
                        <div className="position-fixed w-100 h-100" style={{left: 0, top: 0, zIndex: 500}}
                            onClick={() => {
                                close()
                            }}>
                        </div>
                        <div className="d-flex flex-column align-items-center position-relative" style={{zIndex: 501}}>
                            <span className="w-100 text-center bg-warning text-white mb-1">{t('reports')}</span>
                            {loading && 
                                <div className="spinner-border mt-1" role="status">
                                    <span className="visually-hidden">{t('loading...')}</span>
                                </div>
                            }
                            {success && close()
                                // <span className="text-success mt-1"><i className="fas fa-circle-check fa-xl"/></span>
                            }
                            {error &&
                                <span className="text-danger mt-1">{error}</span>
                            }

                            {(!loading && !success && !error) &&
                            <>
                                {productsSelectorWindow && <>
                                    <div className="d-flex flex-column">
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="prodSource" value="0" id="prod0" checked={productsSelectorType == 0} onChange={ev => setProductsSelectorType(ev.target.value)} />
                                            <label className="form-check-label" htmlFor="prod0">{t('products_source_all_client')}</label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="prodSource" value="1" id="prod1" checked={productsSelectorType == 1} onChange={ev => setProductsSelectorType(ev.target.value)} />
                                            <label className="form-check-label" htmlFor="prod1">{t('products_source_all_source')}</label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="prodSource" value="2" id="prod2" checked={productsSelectorType == 2} onChange={ev => setProductsSelectorType(ev.target.value)} />
                                            <label className="form-check-label" htmlFor="prod2">{t('products_source_custom')}</label>
                                        </div>
                                        {productsSelectorType == 2 && 
                                            <div className="d-flex flex-row align-items-center">
                                                <span>{t('selected')}: {productsSelected.length}</span>
                                                <button className="btn btn-link text-primary p-1 ms-2" onClick={() => setOpenProductsSelector(true)}>{t('select')}</button>
                                            </div>
                                        }
                                        <span className="d-flex flex-row w-100 px-2 my-1"><button className="btn btn-primary w-100 p-2" onClick={() => {
                                            const {startDate, endDate} = productsSelectorWindow;
                                            create(startDate, endDate);
                                        }}>{t('create')}</button></span>
                                    </div>
                                </>}

                                {!productsSelectorWindow && <>
                                    <IconRadioSelector
                                        items={[
                                            {id: 'sales', name: 'Sales', icon: 'fas fa-chart-line'},
                                            {id: 'products', name: 'Products', icon: 'fas fa-shopping-cart'}
                                        ]}
                                        selected={rtype} onSelect={t => setRType(t)} small={true}
                                    />
                                    {periods.map((p,idx) => (
                                        <div key={idx} className="custom-select-item" onClick={() => {
                                                const { startDate, endDate } = periods[idx].range();
                                                if (rtype == 'products') setProductsSelectorWindow({startDate, endDate});
                                                else create(startDate, endDate);
                                            }}>
                                            <i className="fas fa-calendar-days text-primary me-1 fa-fw"/>
                                            <span style={{overflow: 'hidden', textOverflow: 'ellipsis'}}>{p.label}</span>
                                        </div>
                                    ))}
                                    <div className="custom-select-item" style={{borderTop: '2px solid rgba(0,0,0,0.2)'}} onClick={() => { setCustomDate(true); }}>
                                        <i className="fas fa-pencil me-1 fa-fw text-primary"/>
                                        <span style={{overflow: 'hidden', textOverflow: 'ellipsis'}}>{t('custom')}</span>
                                    </div>
                                </>}
                                
                            </>  
                            }
                            
                        </div>
                    </>
                }
                
            </Popup>
        </>
    )
}

ReportsSelectorPopup.RequestedReports = [];