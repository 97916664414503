import CustomGrid from "../../../utils/CustomGrid";
import { Tooltip } from 'react-tooltip';
import ImageFallback from 'react-image-fallback';
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { StatusIcon } from "../../other/StatusIcon";

const PoweredOnIcon = <i className="fas p-2 fa-xs fa-power-off" style={{width:12, height:12, zIndex: 200, borderRadius: '16px', background: '#1aa51a9c'}} />;
const PoweredOffIcon = <i className="fas p-2 fa-xs fa-power-off" style={{width:12, height:12, zIndex: 200, borderRadius: '16px', background: '#cdcfd1'}} />;
const PoweredUnknownIcon = <i className="fas p-2 fa-xs fa-power-off" style={{width:12, height:12, zIndex: 200, borderRadius: '16px', background: '#efbac3'}} />;



const MachineItem = ({machine, onMachineSelect, elSize}) => {
    const { t } = useTranslation();
    //const [generalStatus, setGeneralStatus] = useState(null);
    const [generalColor, setGeneralColor] = useState('');
    const [statusIcons, setStatusIcons] = useState([]);
    

    useEffect(() => {
        const MachineStatusDictionary = {
            'CashlessLost': { type: 'single', priority: 'danger', title: t('status.cashless'), icon: 'fas fa-credit-card' },
            'CashboxFull': { type: 'single', priority: 'danger', title: t('status.cashboxfull'), icon: 'fas fa-cart-flatbed' },
            'Offline': { type: 'multiple', priority: 'danger', title: t('status.box_offline'), icon: 'fas fa-power-off' },
            'SensorTrouble': { type: 'multiple', priority: 'danger', title: t('status.box_sensor_issue'), icon: 'fas fa-rss' },
            'Empty': { type: 'multiple', priority: 'danger', title: t('status.box_empty'), icon: 'fas fa-store' },
            'LowQuantity': { type: 'multiple', priority: 'warning', title: t('status.low_quantity'), icon: 'fas fa-store' },
            'CashboxAlmostFull': { type: 'multiple', priority: 'warning', title: t('status.cashbox_almost_full'), icon: 'fas fa-cart-flatbed' },
            'ScheduleSuspended': { type: 'multiple', priority: 'warning', title: t('status.schedule_suspended'), icon: 'fas fa-calendar-xmark'}
        };

        const ss = Object.keys(machine.status).filter(key => MachineStatusDictionary[key] && machine.status[key])
            .map(key => ({
                key: key,
                ...MachineStatusDictionary[key],
                value: (MachineStatusDictionary[key].type == 'multiple' ? machine.status[key] : 1 ) * (MachineStatusDictionary[key].priority == 'danger' ? 100 : 1)
            }))
            .sort((a,b) => {
                return b.value - a.value;
            })
        setStatusIcons(ss);
        // if (ss.some(stat => stat.priority === 'danger') ||
        //     !machine.status.Connected) setGeneralStatus('danger');
        // else if (ss.some(stat => stat.priority === 'warning')) setGeneralStatus('warning');
        // else setGeneralStatus(null);
        if (machine.statusColor) setGeneralColor(machine.statusColor);



    }, [machine]);
    
    
    return (
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 pb-1 pt-1 d-inline-block">
            <div className={"card card-hover2 shadow-2-strong p-1 "} style={{width: '100%', userSelect: 'none', cursor: 'pointer', background: generalColor || ''}}>
                <div className="card-body p-1 w-100 h-100">
                    <div className="d-flex w-100 h-100 overflow-hidden">
                        {/* { this.icon } */}
                        <div className="d-flex flex-column flex-fill overflow-hidden ps-1">
                            <div className="d-flex flex-column" style={{alignItems: 'baseline', textOverflow: 'ellipsis'}}>
                                <div className="d-flex flex-row align-items-center w-100" onClick={() => onMachineSelect(machine.id)}>
                                    <div className="d-flex flex-row">
                                        <span className="me-1 d-flex align-items-center" data-tooltip-id="mtype-tooltip" title={machine.type?.name||'?'} onClick={ev => {ev.preventDefault(); ev.stopPropagation(); return false;}}>
                                            <ImageFallback src={`/mtypes/mt${machine.type.id}.png`} width={22} height={22} fallbackImage={<span></span>}/>
                                        </span>
                                        {/* <Tooltip id="mtype-tooltip" /> */}
                                        <span style={{lineHeight: '1.1em', maxHeight: '2.2em', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'normal',
                                                    display: '-webkit-box', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical', fontWeight: 'bold'}}>{ machine.name }</span>
                                    </div>
                                    <span className="flex-fill"/>
                                    { (machine.status?.PowerOn && machine.status?.Connected) && <span className="" style={{lineHeight: 1}}>{PoweredOnIcon}</span> }
                                    { (machine.status?.PowerOff && machine.status?.Connected) && <span className="" style={{lineHeight: 1}}>{PoweredOffIcon}</span> }
                                    { (!machine.status?.Connected || (!machine.status?.PowerOff && !machine.status?.PowerOn)) && <span className="m-0" style={{lineHeight: 1}}>{PoweredUnknownIcon}</span> }
                                </div>

                                <span style={{lineHeight: '1.5em', maxHeight: '3em', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'normal',
                                                display: '-webkit-box', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical'}} onClick={() => onMachineSelect(machine.id)}>{machine.info}</span>
                                
                                <div className="d-flex flex-row align-items-center flex-fill w-100" style={{paddingBottom: 3}}>

                                    {(machine.status?.Connected && !machine.status?.LowGSMSignal) &&
                                        <StatusIcon count={1} statusId="online" title={t('status.online')} icon="fas fa-signal" priority="success"/>
                                    }
                                    {(machine.status?.Connected && machine.status?.LowGSMSignal) &&
                                        <StatusIcon count={1} statusId="signal" title={t('status.low_signal')} icon="fas fa-signal" priority="warning"/>
                                    }
                                    {!machine.status?.Connected &&
                                        <StatusIcon count={1} statusId="offline" title={t('status.offline')} icon="fas fa-signal" priority="danger"/>
                                    }
                                    {statusIcons.map(el => (
                                        <StatusIcon count={typeof machine.status[el.key] == 'number' ? machine.status[el.key] : 1}
                                                statusId={el.key} {...el}
                                            />
                                    ))}

                                </div>

                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
} 


export const MachinesList = ({machines, onMachineSelect}) => {
    const { t } = useTranslation();

    if (!machines || machines.length === 0) return <span className="text-muted fst-italic w-100 text-center">{t('no_machines')}</span>;
    return (
        <div className="d-flex flex-column w-100 flex-fill overflow-hidden">
            <div className="d-block flex-fill overflow-auto row ms-1 me-1">
                { machines.map(d => <MachineItem key={d.id} machine={d} onMachineSelect={onMachineSelect}/>)}
                
                
            </div>
        </div>
        
    )

}