import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as mdb from 'mdb-ui-kit'; // lib
import 'mdb-ui-kit/css/mdb.min.css';
import 'flag-icon-css/css/flag-icons.min.css';
import '@fortawesome/fontawesome-free/js/all.js';
import 'reactjs-popup/dist/index.css';
import 'react-tooltip/dist/react-tooltip.css';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import 'react-day-picker/dist/style.css';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import './index.css';

import ro from 'javascript-time-ago/locale/ro';
import en from 'javascript-time-ago/locale/en';
import TimeAgo from 'javascript-time-ago';
import Lang from './service/lang';

TimeAgo.addDefaultLocale(en);


const rootDOM = document.getElementById('root')

// rootDOM.addEventListener('load', () => {
//   const innerHeightPer = window.innerHeight * 0.01;
//   document.documentElement.style.setProperty('--vh',`${innerHeightPer}px`);

//   rootDOM.addEventListener('resize', () => {
//     const innerHeightPer = window.innerHeight * 0.01;
//     document.documentElement.style.setProperty('--vh',`${innerHeightPer}px`);
//   })
// });

const lang = new Lang();
const root = ReactDOM.createRoot(rootDOM);

root.render(
  <App/>
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
