import { CustomModal } from "../../../other/CustomModal"
import { useTranslation } from "react-i18next";
import * as mdb from 'mdb-ui-kit'; // lib
import { useEffect, useState } from "react"
import { CustomSelect } from "../../../../utils/CustomSelect";
import API from "../../../../service/api";

export const UserInviteModal = ({companyId, open, onClose, onInviteDone}) => {
    const { t } = useTranslation();
    const [inviteEmail, setInviteEmail] = useState('');
    const [rights, setRights] = useState([]);
    const [inviteError, setInviteError] = useState('');
    const [loading, setLoading] = useState(false);  
    const [invitationURL, setInvitationURL] = useState('');
    const [selectedRightsId, setSelectedRightsId] = useState(null);

    useEffect(() => {
        document.querySelectorAll(".form-outline").forEach((formOutline) => {
			new mdb.Input(formOutline).init();
		});
    }, []);

    useEffect(() => {
        API.getAllRights()
            .then(res => {
                const rs = res.rights;
                setRights(rs.filter(r => r.private));
                const idx = rs.findIndex(v => v.Name == 'Admin');
                if (idx > 0) setSelectedRightsId(rs[idx].id);
                else if (rs.length > 0) setSelectedRightsId(rs[0].id);
            })
            .catch(err => setInviteError(err.message));
    }, [companyId]);

    const invite = () => {
        setLoading(true);
        setInviteError('');
        setInvitationURL('');
        API.inviteUser(inviteEmail, companyId, selectedRightsId)
            .then(({invitationId}) => {
                if (invitationId){
                    setInviteEmail('');
                    setInvitationURL(`${window.location.origin}/join?invitationId=${encodeURIComponent(invitationId)}`);
                    if (onInviteDone) onInviteDone();
                }
            })
            .catch(err => setInviteError(err.message));
    }


    return (
        <CustomModal open={open} onClose={() => onClose()}>
            <div className="px-2 overflow-auto" style={{minWidth: 250, maxWidth: 400, width: '100vw'}}>
                <div className="card">
                    <div className="card-body overflow-auto" style={{maxHeight: 500}}>
                        <div className="d-flex flex-column w-100 justify-content-center text-center">
                            <i className="fas fa-circle-user fa-4x mb-2"/>
                            <h5>{t('invite_user')}</h5>
                        </div>

                        <div className="form-outline mb-2">
                            <input type="text" name="invite_email" id="invite_email" className="form-control" value={inviteEmail} onChange={(ev) => setInviteEmail(ev.currentTarget.value)} />
                            <label className="form-label" htmlFor="invite_email">{t('email')}</label>
                        </div>
                        <CustomSelect title={t('permissions')}
                            items={rights.map(r => ({val: r.id, title: r.Name, icon: 'fas fa-lock'}))}
                            selected={selectedRightsId}
                            onSelect={id => setSelectedRightsId(id)}
                            w100
                        />

                        <button className="btn btn-primary mt-2 w-100" style={{minWidth: 0}} disabled={inviteEmail.length == 0} onClick={invite}>{t('invite')} <i className="fas fa-envelope fa-lg"/></button>
                        { inviteError && <div className="alert alert-danger alert-dismissible fade show mt-2" role="alert">
                            { inviteError }
                        </div> }
                        { invitationURL && <div className="alert alert-success alert-dismissible fade show mt-2" role="alert">
                            {t('invite_success_link')} <a href={invitationURL} className="text-primary">{invitationURL}</a>
                        </div> }
                    </div>
                </div>
            </div>
            
        </CustomModal>
    )
}