import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router"
import API from "../../service/api";
import { AgGridReact } from 'ag-grid-react';
import { Trans, useTranslation } from "react-i18next";
import * as fns from "date-fns"; 
import Lang from "../../service/lang";
import styles from './reports.css';
import Popup from "reactjs-popup";
import WS from "../../service/ws";
import { NavLink } from "react-router-dom";
import { utils, writeFile } from 'xlsx';
import { SalesReportViewer } from "./SalesReportViewer";
import { ProductsReportViewer } from "./ProductsReportViewer";




function getWindowSize() {
    const {innerWidth, innerHeight} = window;
    return {innerWidth, innerHeight}; 
}

export const ReportViewer = ({}) => {
    const { id } = useParams();
    const [report, setReport] = useState(null);
    const [source, setSource] = useState('');
    const [newReports, setNewReports] = useState([]);
    const [requestedReports, setRequestedReports] = useState([]);
    const [mobile, setMobile] = useState(getWindowSize().innerWidth < 800);
    const [timeUntilExpire, setTimeUntilExpire] = useState();
    const { t } = useTranslation();

    useEffect(() => {
        function handleWindowResize() {
            setMobile(getWindowSize().innerWidth < 1000);
        }
        window.addEventListener('resize', handleWindowResize);
        handleWindowResize();

        const callback = (r) => { setNewReports(prev => prev.concat(r)) }
        WS.listen('report', callback);

        const interval = setInterval(() => {
            setTimeUntilExpire(prev => prev-1);
        }, 1000);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
            WS.unlisten('report', callback);
            clearInterval(interval);
        };
    }, []);

    useEffect(() => {
        if (newReports.length > 0){
            newReports.forEach(r => {
                if (requestedReports.includes(r.id)) {
                    window.open(`/reports/${r.id}`, '_blank');
                    setRequestedReports(prev => prev.filter(id => id !== r.id));
                }
            });
            setNewReports([]);
        }
    }, [newReports, requestedReports]);

    useEffect(() => {
        API.reportsGetOne(id)
            .then(r => {
                console.log(r);
                setTimeUntilExpire(Math.floor((new Date(r.xat).getTime() - new Date().getTime())/1000));
                setSource(r.machine ? 'machine' : (r.location ? 'location' : 'group'));
                setReport(r);
                
                
            })
            .catch(err => {
                console.log(err);
                //navigate('../');
            });
    }, [id]);

    if (!report) return null;

    return (
        <div className="d-flex flex-column w-100 h-100 overflow-hidden" style={{background: '#eeeeee'}}>

            <nav className="navbar navbar-expand-lg navbar-light bg-light p-0 w-100"  style={{zIndex: 15}}>
                <div className="container-fluid justify-content-between">
                    <NavLink to={"/g/"+report.group.id} className="position-absolute nav-link d-flex align-items-center p-1 user-select-none cursor-pointer" style={{cursor: 'pointer'}}>
                        <div className="d-flex align-items-center pe-1">
                            <i className="fas fa-cubes" style={{color: "#fc8c03", fontSize: '1.8rem'}}/>
                            <span className="h5 fw-bold mb-0" style={{color: '#e89328', textShadow: '1px 1px 1px rgba(0,0,0,0.1)'}}>
                                {!mobile && 'Smart Capsule Machine'}
                            </span>
                        </div>
                    </NavLink>
                    <span className="d-flex flex-row align-items-center justify-content-center w-100"
                        style={{marginLeft: mobile ? 50 : 300, marginRight: mobile ? 50 : 300, height: 36,overflow: 'hidden' }}
                    >
                        <i className={"fas text-primary fa-lg me-1 " + {"group":"fa-layer-group", "location":"fa-location-dot", "machine": "fa-server"}[source]}/>
                        <span className="m-0" style={{whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', lineHeight: '1.3em', fontSize: mobile?'1.15rem':'1.2rem', fontWeight: 400}}>{{"group": report.group?.Name, "location": report.location?.Name, "machine": report.machine?.name}[source]}</span>
                    </span>

                    <div className="d-flex align-items-center position-absolute" style={{right: 10}}>
                        <i className={"fas text-secondary fa-lg " + (report.type == 'sales' ? 'fa-chart-line' : 'fa-shopping-cart')} style={{}}/>
                    </div>
                </div>
            </nav>

            <div className="d-flex flex-row w-100 justify-content-center">
                <nav className={"navbar navbar-expand-lg p-1 d-flex border-top justify-content-center align-items-center text-primary overflow-hidden" + (mobile ? ' w-100 px-1':' px-3')}
                    style={{background: '#fc8c0322', height: 28, fontSize: mobile ? '0.8rem':''}}>
                        <i className="fas fa-calendar-days me-1"/>
                        <span className="" style={{fontWeight: '0.55rem'}}>{new Date(report.startDate).toLocaleString(Lang.instance.getCurrentLocale().code)}</span>
                        <i className="fas fa-arrow-right-long mx-1"/>
                        <span className="">{new Date(report.endDate).toLocaleString(Lang.instance.getCurrentLocale().code)}</span>
                </nav>
            </div>

            {report.type == 'sales' && <SalesReportViewer report={report} mobile={mobile} onNewReportRequest={id => setRequestedReports(req => req.concat(id))}/>}
            {report.type == 'products' && <ProductsReportViewer report={report} mobile={mobile} onNewReportRequest={id => setRequestedReports(req => req.concat(id))}/>}

            <span className="d-flex flex-row w-100 align-items-center" style={{fontSize: '0.8rem'}}>
                <span><i className="fas fa-clock me-1"/></span>
                <span>{t('expire_in')}:&nbsp;</span>
                {timeUntilExpire > 0 && <span>{Math.floor(timeUntilExpire/60).toString().padStart(2, '0')}:{(timeUntilExpire%60).toString().padStart(2, '0')}</span>}
                {timeUntilExpire <= 0 && <span className="text-danger">{t('expired')}</span>}
                <span className="d-flex flex-row flex-fill justify-content-end">
                    <span className="fst-italic text-muted" style={{fontSize: '0.8rem'}}>{t('generated_in')}: {report.result.runtime}s</span>
                </span>
            </span>
        </div>
    )
}