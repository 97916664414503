import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import 'ol/ol.css';
import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import Text from 'ol/style/Text';
import Fill from 'ol/style/Fill';
import Style from 'ol/style/Style';
import Overlay from 'ol/Overlay';
import { toStringHDMS } from 'ol/coordinate';
import { Circle as StyleCircle, Icon, Stroke } from 'ol/style';
import { fromLonLat } from 'ol/proj';

export const LocationsGIS = ({ locations, onLocationClick, center, zoom, noPopups }) => {
    const mapRef = useRef(null);
    const popupRef = useRef(null);
    const [popup, setPopup] = useState(undefined);
    const [map, setMap] = useState(null);

    const stringToLocation = (str) => {
        if (!str) return null;
        
        const [lat, lon] = str.split(',').map(parseFloat);
        const coords = fromLonLat([lon, lat]);
        if (Number.isNaN(coords[0]) || Number.isNaN(coords[1])) return null;
        return coords;
    };

    useEffect(() => {
        if (!mapRef.current || !popupRef.current) return;
        document.getElementById('map').innerHTML = '';

        const vectorSource = new VectorSource({
            features: [],
        });

        const vectorLayer = new VectorLayer({
            source: vectorSource,
        });

        const mapp = new Map({
            target: mapRef.current,
            layers: [new TileLayer({ source: new OSM() }), vectorLayer],
            view: new View({
                center: center ? stringToLocation(center) : [0,0],
                zoom: zoom || 7,
            }),
        });

        vectorSource.on('addfeature', () => {
            mapp.getView().fit(vectorSource.getExtent(), {
                padding: [50,50,50,50]
            });
        });

        mapp.on('rendercomplete', () => {
            setMap(mapp);
        });

        // vectorLayer.on('postrender', () => {
        //     setMap(mapp);
        // });

        //setMap(map);

        const overlay = new Overlay({
            element: popupRef.current,
            autoPan: true,
            autoPanAnimation: {
                duration: 250,
            },
        });
        mapp.addOverlay(overlay);

        mapp.on('click', (event) => {
            if (noPopups) return;
            const feature = mapp.forEachFeatureAtPixel(event.pixel, (f) => f);
            if (feature) {
                const coordinate = feature.getGeometry().getCoordinates();
                setPopup(feature.getProperties()['location']);

                overlay.setPosition(coordinate);
            }else{
                overlay.setPosition(undefined);
            }
        });
        
    }, [mapRef, popupRef]);

    useEffect(() => {
        if (map) map.getView().setCenter(center ? stringToLocation(center) : [0,0]);
    }, [map, center]);

    useEffect(() => {
        if (map) {
            const features = locations.filter(location => stringToLocation(location.geo)).map((location) => {
                const point = new Point(stringToLocation(location.geo));
                const feature = new Feature({
                    geometry: point,
                    location: location
                });
                feature.setStyle(
                    new Style({
                        image: new Icon({
                            src: process.env.PUBLIC_URL + '/location_col.png',
                            anchor: [0.5, 1],
                            size: [64,64],
                            scale: 0.5
                        }),
                        // image: new StyleCircle({
                        //     radius: 10,
                        //     fill: new Fill({ color: 'rgba(255, 255, 255, 0.4)' }),
                        //     stroke: new Stroke({ color: 'rgba(0, 0, 0, 0.4)', width: 2 }),
                        //   }),
                        text: new Text({
                            text: location.Name,
                            font: '12px Calibri,sans-serif',
                            textAlign: 'center',
                            textBaseline: 'middle',
                            offsetY: 8,
                            fill: new Fill({ color: '#000' }),
                            stroke: new Stroke({ color: '#fff', width: 3 }),
                        }),
                        zIndex: 1,
                    })
                );
                return feature;
            });
            if (features.length > 0){
                map.getLayers().getArray()[1].getSource().clear();
                map.getLayers().getArray()[1].getSource().addFeatures(features);
            }else{
                map.getView().setCenter(stringToLocation('48.526, 13.255'));
                map.getView().setZoom(4);
            }
        }
    }, [map, locations]);

    return (
        <>
            <div ref={mapRef} id="map" style={{ height: '100%', width: '100%' }} />
            <div ref={popupRef} id="popup" className="ol-popup">
                <div id="popup-content">
                    { popup && <PopupContent location={popup} onClick={location => onLocationClick(location)}/> }
                </div>
            </div>
        </>
    );
};

const PopupContent = ({ location, onClick }) => {
    return (
        <div className="card card-hover2 shadow-2-strong" style={{height: '60px', userSelect: 'none', background: ''}} onClick={() => onClick(location)}>
            <div className="card-body p-1 w-100 h-100">
                <div className="d-flex align-items-center w-100 h-100 overflow-hidden">
                    {/* { this.icon } */}
                    <div className="d-flex flex-column flex-fill overflow-hidden ps-1 align-self-center">
                        <div className="d-flex flex-column" style={{alignItems: 'baseline', textOverflow: 'ellipsis'}}>
                            <span style={{overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>{ location.Name }</span>
                            <span className="d-flex flex-row align-items-center w-100 overflow-hidden">
                                <i className="fas fa-server me-1 text-primary"/>
                                <span style={{textAlign: 'left'}}>{location.nrOfMachines}</span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};