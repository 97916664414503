import { useEffect, useState } from "react";
import API from "../../../service/api";
import CustomTable from "../../other/CustomTable";
import { CustomModal } from "../../other/CustomModal";
import { DeleteConfirm } from "../../other/DeleteConfirm";
import * as mdb from 'mdb-ui-kit'; // lib
import { LocationsGIS } from "../group/LocationsGIS";
import { TabsViewer } from "../../../utils/TabsViewer";
import ReactImageFallback from "react-image-fallback";
import { CustomSelect } from "../../../utils/CustomSelect";
import ImageFallback from 'react-image-fallback';
import { useTranslation } from "react-i18next";


export const LocationsPage = ({userData, rights}) => {
    const [locations, setLocations] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
    const [deleteId, setDeleteId] = useState(null);
    const [canViewMachines, setCanViewMachines] = useState(false);
    const [canAddLocation, setCanAddLocation] = useState(false);
    const { t } = useTranslation();

    const fetch = () => {
        setLoading(true);

        API.getLocationsAdmin()
            .then(l => {
                setLocations(l); 
                setLoading(false);
        });
    }

    useEffect(() => {
        setCanViewMachines(rights?.Locations?.ViewMachines);
        setCanAddLocation(rights?.Locations?.Add);
    }, [rights]);

    useEffect(() => {
        fetch();
    }, []);

    const LocationsTable = (
        <div className="d-flex flex-fill flex-column overflow-hidden ms-3">
            <div className="d-flex flex-column overflow-hidden card">
                <div className="d-flex flex-column card-body overflow-hidden">
                    { canAddLocation && <span className="btn text-white btn-floating bg-primary position-absolute text-center" role="button" style={{top: 15, right: 15}} onClick={() => { setSelectedId(-1); }}>
                        <span className="d-flex justify-content-center align-items-center w-100 h-100"><i className="fas fa-plus fa-lg"/></span>
                    </span> }
                    <div className="d-flex flex-row ms-2 mb-2 align-items-center">
                        <h5 className="me-1 px-0 py-1 m-0 flex-fill">{t('locations')}</h5>
                    </div>
                    <CustomTable columns={selectedId !== null ? [t('name'), ...(canViewMachines ? [t('machines')]:[])] : [t('name'), t('address'), t('info'), ...(canViewMachines ? [t('machines')]:[])]}
                        columnWidths={selectedId !== null ? (canViewMachines ? [80,20] : [100]) : (canViewMachines ? [30, 20, 40, 10] : [30, 20, 50])}
                        columnRenderers={ selectedId !== null ? [
                            l => <span>{l.lType == 1 && <span><i className="fas fa-bookmark text-warning me-1"/></span>}{l.Name}</span>,
                            ...(canViewMachines?[l => <span>{l.nrOfMachines}</span>]:[])
                        ] : [
                            l => <span>{l.lType == 1 && <span><i className="fas fa-bookmark text-warning me-1"/></span>}{l.Name}</span>,
                            l => <span>{l.address}</span>,
                            l => <span style={{maxHeight: 75}}>{l.Info}</span>,
                            ...(canViewMachines?[l => <span>{l.nrOfMachines}</span>]:[])
                        ]}
                        search={(l, text) => (l.Name+l.address).toLocaleUpperCase().includes(text.toLocaleUpperCase())}
                        searchTitle={`${t('name')}, ${t('address')}`}//"Name, Address"
                        onClick={l => { setSelectedId(l.id); }}
                        activeRows={l => l.id === selectedId}
                        loading={loading}
                        rowClassName={l => l.lType == 1 ? 'list-group-item-warning fst-italic warning-row' : ''}
                        data={locations}/>
                </div>
            </div>
        </div>
    );

    return (
        <section className="d-flex flex-column flex-fill w-100 h-100" style={{minWidth: 800}}>
            <div className="d-flex flex-row h-100">
                {selectedId && <>
                    <div className="d-flex flex-column col-5">{LocationsTable}</div>
                    <div className="d-flex flex-column col-7 ms-3 pe-3">
                        <LocationEditor locationId={selectedId} defaultCountry={userData?.company?.country} rights={rights} onClose={() => setSelectedId(null)} onAdd={() => { setSelectedId(null); fetch(); }} onDelete={() => setDeleteId(selectedId)} onEdit={() => fetch()}/>
                    </div>
                </>}
                {!selectedId && <div className="d-flex flex-column w-100">{LocationsTable}</div>}

                <CustomModal open={deleteId !== null} onClose={() => setDeleteId(null)}>
                    <DeleteConfirm title={t('remove_location')} message={t('remove_location_prompt')} onConfirm={() => {
                        API.deleteLocation(deleteId)
                            .then(() => { setSelectedId(null); fetch(); })
                        setDeleteId(null);
                    }} onCancel={() => setDeleteId(null)}/>
                </CustomModal>
                
            </div>
        </section>
    )
}

const LocationEditor = ({locationId, rights, defaultCountry, onClose, onAdd, onEdit, onDelete}) => {
    const [location, setLocation] = useState(null);
    const [country, setCountry] = useState(null);
    const [tempGeo, setTempGeo] = useState(null);
    const [countries, setCountries] = useState([]);
    const [name, setName] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [canEditLocation, setCanEditLocation] = useState(false);
    const [canDeleteLocation, setCanDeleteLocation] = useState(false);
    const [canAssignMachines, setCanAssignMachines] = useState(false);
    const [changed, setChanged] = useState(false);
    const [deleteMachineId, setDeleteMachineId] = useState(null);
    const [addMachine, setAddMachine] = useState(false);
    const { t } = useTranslation();

    const fetch = () => {
        setError('');
        setChanged(false);

        API.configCountries()
            .then(c => setCountries(Array.isArray(c) ? c : []))
            .catch(err => setError(err.message))
            .finally(() => {
                if (locationId > 0) {
                    setLoading(true);
                    API.getLocationAdmin(locationId)
                        .then(l => {
                            setLocation(l);
                            setName(l.Name);
                            const cnty = countries.find(c => c.code === l.country);
                            if (cnty) setCountry(cnty);
                        })
                        .catch(err => setError(err.message))
                        .finally(() => setLoading(false));
                }else {
                    setLocation({
                        Name: '', address: '', Info: '', geo: '', machines: [], country: defaultCountry
                    });
                }
            });

        
    }

    useEffect(() => {
        setCanEditLocation(rights?.Locations?.Change);
        setCanDeleteLocation(rights?.Locations?.Delete && location?.lType !== 1);
        setCanAssignMachines(rights?.Locations?.AssignMachines && location?.lType !== 1);
    }, [rights, location]);

    const update = () => {
        setLoading(true);
        API.updateLocationAdmin(locationId, location.Name, location.address, location.Info, location.geo, location.country)
            .then(() => { fetch(); onEdit(); })
            .catch(err => setError(err.message))
            .finally(() => setLoading(false));
    }

    const insert = () => {
        setLoading(true);
        API.addLocationAdmin(location.Name, location.address, location.Info, location.geo, location.country)
            .then(() => onAdd())
            .catch(err => setError(err.message))
            .finally(() => setLoading(false));
    }


    useEffect(() => {
        fetch();
    }, [locationId]);

    useEffect(() => {
        document.querySelectorAll(".form-outline").forEach((formOutline) => {
			new mdb.Input(formOutline).init();
		});
    }, [locationId, location, loading]);

    if (!location) return null;
    return (
        <div className="d-flex flex-column overflow-hidden card">
            <button type="button" className="btn-close position-absolute" style={{right: 5, top: 5}} onClick={() => onClose ? onClose() : {}}></button>
            <CustomModal open={deleteMachineId !== null} onClose={() => setDeleteMachineId(null)}>
                <DeleteConfirm title={t('remove_machine')} message={t('remove_machine_prompt')} onConfirm={() => {
                    API.removeMachineFromLocation(locationId, deleteMachineId)
                        .then(() => { setDeleteMachineId(null); fetch(); onEdit(); })
                    setDeleteMachineId(null);
                }} onCancel={() => setDeleteMachineId(null)}/>
            </CustomModal>
            <AddMachineModal open={addMachine} onClose={() => setAddMachine(false)} locationId={locationId} onConfirm={mid => {
                setAddMachine(false);
                API.assignMachineToLocation(locationId, mid)
                    .then(() => { fetch(); onEdit(); });
            }}/>
            <div className="d-flex flex-column overflow-hidden card-body">
                <div className="d-flex flex-column w-100 justify-content-center overflow-hidden">
                    <i className="fas fa-map-marker-alt fa-4x mb-2"/>
                    
                    
                    {loading && (
                        <div className="d-flex w-100 justify-content-center">
                            <div className="spinner-border" role="status">
                                <span className="visually-hidden">{t('loading...')}</span>
                            </div>
                        </div>
                        
                    )}
                    {!loading && <>
                        <h5 className="text-center">{locationId == -1 ? t('new_location') : name}</h5>
                        { location?.lType == 1 && <div className="alert alert-warning alert-dismissible fade show mt-2" role="alert">
                            <i className="fas fa-bookmark text-warning me-1"/>{t('special_unassigned_location_prompt')}
                        </div> }
                        <div className="d-flex flex-column overflow-hidden pe-1 pb-2">
                            {(locationId !== -1 && (location.machines !== undefined || location.groups !== undefined)) && <>
                                <div className="d-flex flex-column overflow-hidden align-items-center">
                                    <TabsViewer
                                        id="locationdata" autoDefault fullSize
                                        tabs={[
                                            {
                                                name: 'info', title: t('location'), icon: 'fas fa-location-dot',
                                                component: (
                                                    <div className="d-flex flex-column w-100 overflow-auto px-1 pb-3 mb-2">
                                                       <h5 className="ms-2 mb-3">{t('information')}</h5>
                                                        { !canEditLocation && <>
                                                            <ul className="list-group list-group-light p-0">
                                                                <li className="list-group-item p-1"><i className="fas fa-map-location fa-fw text-primary me-1"/><span className="fw-bold me-1">{t('address')}:</span>{location.address}</li>
                                                                {location?.lType !== -1 && <li className="list-group-item p-1"><i className="fas fa-circle-info fa-fw text-primary me-1"/><span className="fw-bold me-1">{t('info')}:</span>{location.Info}</li>}
                                                                {location?.lType !== -1 && <li className="list-group-item p-1"><i className="fas fa-location-dot fa-fw text-primary me-1"/><span className="fw-bold me-1">{t('geo')}:</span>{location.geo}</li>}
                                                                {location?.lType !== -1 && <li className="list-group-item p-1"><i className="fas fa-location-dot fa-fw text-primary me-1"/><span className="fw-bold me-1">{t('country')}:</span>{country?.country || '?'}</li>}
                                                            </ul>
                                                        </> }

                                                        { canEditLocation && <>
                                                            <div className="d-flex mb-2 flex-row align-items-center">
                                                                <i className="fas fa-pen-to-square fa-fw text-primary"/>
                                                                <div className="col ms-2">
                                                                    <div className="form-outline">
                                                                        <input type="text" name="name" id="name" className="form-control" value={location.Name} onInput={ev => { setLocation(l => ({...l, Name: ev.target.value})); setChanged(true); }}/>
                                                                        <label className="form-label" htmlFor="name">{t('name')}</label>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            {location.lType !== 1 && <>
                                                                <div className="d-flex mb-2 flex-row align-items-center">
                                                                    <i className="fas fa-map-location fa-fw text-primary"/>
                                                                    <div className="col ms-2">
                                                                        <div className="form-outline">
                                                                            <input type="text" name="address" id="address" className="form-control" value={location.address} onInput={ev => { setLocation(l => ({...l, address: ev.target.value})); setChanged(true); }}/>
                                                                            <label className="form-label" htmlFor="address">{t('address')}</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex mb-2 flex-row align-items-center">
                                                                    <i className="fas fa-circle-info fa-fw text-primary"/>
                                                                    <div className="col ms-2">
                                                                        <div className="form-outline">
                                                                            <textarea rows={3} type="text" name="info" id="info" className="form-control" value={location.Info} onInput={ev => { setLocation(l => ({...l, Info: ev.target.value})); setChanged(true); }}/>
                                                                            <label className="form-label" htmlFor="info">{t('info')}</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex mb-2 flex-row align-items-center">
                                                                    <i className="fas fa-location-dot fa-fw text-primary"/>
                                                                    <div className="col ms-2">
                                                                        <div className="form-outline">
                                                                            <input type="text" name="geo" id="geo" className="form-control" value={location.geo} onInput={ev => { setLocation(l => ({...l, geo: ev.target.value})); setTempGeo(ev.target.value); setChanged(true); }}/>
                                                                            <label className="form-label" htmlFor="geo">{t('geo')}</label>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="d-flex mb-2 flex-row align-items-center w-100">
                                                                    <i className="fas fa-earth-europe fa-fw text-primary me-2"/>
                                                                    <CustomSelect w100 title={t('country')} search={true} items={countries.map(c => ({ val: c.code, title: `${c.country}`, icon: `flag-icon flag-icon-${c.code?.toLocaleLowerCase()}`}))} selected={location.country} onSelect={code => {setLocation(l => ({...l, country: code})); setChanged(true); }} />
                                                                </div>
                                                            </>}
                                                            
                                                            
                                                        </> }

                                                        {location?.lType !== 1 &&
                                                            <div className="mb-3" style={{minHeight: 250, height: 250}}>
                                                                <LocationsGIS locations={[{Name: name, geo: tempGeo}]} center={tempGeo} zoom={12} noPopups />
                                                            </div>
                                                        }

                                                        { error && <div className="alert alert-danger alert-dismissible fade show mt-2" role="alert">
                                                            { error }
                                                        </div> }

                                                        <div className="d-flex flex-column">
                                                            { canEditLocation && <button className="btn btn-primary mb-2" disabled={!changed || loading} onClick={() => update()}>{t('save')}</button> }
                                                            { canDeleteLocation && <button className="btn btn-danger" disabled={loading} onClick={() => onDelete()}>{t('delete')}</button> }
                                                        </div>         

                                                    </div>
                                                )
                                            },
                                            ...(location.machines ? [{
                                                name: 'machines', title: t('machines'), icon: 'fas fa-server',
                                                component: ( <div className="d-flex flex-column w-100 mt-1">
                                                    <CustomTable columns={[t('name'), t('boxes'), t('actions')]}
                                                        columnWidths={[60, 20, 20]}
                                                        columnRenderers={[
                                                            m => <div className="d-flex flex-row align-items-center">
                                                                <span><ImageFallback src={`/mtypes/mt${m.type?.id}.png`} width={22} height={22} className="me-1" title={m.type?.name}  fallbackImage={<span></span>}/></span>
                                                                <div className="d-flex flex-column" style={{lineHeight: '1.1rem'}}>
                                                                    <span>{m.name}</span>
                                                                    <span className="text-muted" style={{fontSize: '80%'}}>{m.sn}</span>
                                                                </div>
                                                            </div>,
                                                            m => <span>{m.nrOfBoxes}</span>,
                                                            m => <span>{canAssignMachines && <span role="button" onClick={() => setDeleteMachineId(m.id)}><i className="fas fa-trash-can text-danger"/></span>}</span>
                                                        ]}
                                                        data={location.machines.sort((a,b) => b.nrOfBoxes - a.nrOfBoxes)}
                                                        search={(m, text) => m.name.toLocaleUpperCase().includes(text.toLocaleUpperCase())}
                                                        searchTitle={t('name')}
                                                        header={() => (
                                                            <div className="d-flex flex-row me-2">
                                                                { canAssignMachines && <span role="button" onClick={() => setAddMachine(true)}><i className="fas fa-xl fa-square-plus text-primary"/></span> }
                                                            </div>
                                                    )}/>
                                                    </div>
                                                )
                                            }]:[]),
                                            ...(location.groups !== undefined ? [{
                                                name: 'groups', title: t('groups'), icon: 'fas fa-layer-group',
                                                component: ( <div className="d-flex flex-column w-100 mt-1">
                                                    <CustomTable columns={[t('name')]}
                                                        columnWidths={[100]}
                                                        columnRenderers={[
                                                            m => <span>{m.Name}</span>,
                                                        ]}
                                                        data={location.groups}
                                                        search={(m, text) => m.Name.toLocaleUpperCase().includes(text.toLocaleUpperCase())}
                                                        searchTitle={t('name')}
                                                    />
                                                    </div>
                                                )
                                            }]:[])
                                        ]}
                                    />
                                </div>
                                
                            </>}

                            {locationId === -1 && <div className="d-flex flex-column w-100 overflow-auto px-1 mt-3 pt-2">
                                { (locationId == -1) && <>
                                    <div className="d-flex mb-2 flex-row align-items-center">
                                        <i className="fas fa-pen-to-square fa-fw text-primary"/>
                                        <div className="col ms-2">
                                            <div className="form-outline">
                                                <input type="text" name="name" id="name" className="form-control" value={location.Name} onInput={ev => { setLocation(l => ({...l, Name: ev.target.value})); setChanged(true); }}/>
                                                <label className="form-label" htmlFor="name">{t('name')}</label>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="d-flex mb-2 flex-row align-items-center">
                                        <i className="fas fa-map-location fa-fw text-primary"/>
                                        <div className="col ms-2">
                                            <div className="form-outline">
                                                <input type="text" name="address" id="address" className="form-control" value={location.address} onInput={ev => { setLocation(l => ({...l, address: ev.target.value})); setChanged(true); }}/>
                                                <label className="form-label" htmlFor="address">{t('address')}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex mb-2 flex-row align-items-center">
                                        <i className="fas fa-circle-info fa-fw text-primary"/>
                                        <div className="col ms-2">
                                            <div className="form-outline">
                                                <textarea rows={3} type="text" name="info" id="info" className="form-control" value={location.Info} onInput={ev => { setLocation(l => ({...l, Info: ev.target.value})); setChanged(true); }}/>
                                                <label className="form-label" htmlFor="info">{t('info')}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex mb-2 flex-row align-items-center">
                                        <i className="fas fa-location-dot fa-fw text-primary"/>
                                        <div className="col ms-2">
                                            <div className="form-outline">
                                                <input type="text" name="geo" id="geo" className="form-control" value={location.geo} onInput={ev => { setLocation(l => ({...l, geo: ev.target.value})); setTempGeo(ev.target.value); setChanged(true); }}/>
                                                <label className="form-label" htmlFor="geo">{t('geo')}</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="d-flex mb-2 flex-row align-items-center w-100">
                                        <i className="fas fa-earth-europe fa-fw text-primary me-2"/>
                                        <CustomSelect w100 title={t('country')} search={true} items={countries.map(c => ({ val: c.code, title: `${c.country}`, icon: `flag-icon flag-icon-${c.code?.toLocaleLowerCase()}`}))} selected={location.country} onSelect={code => {setLocation(l => ({...l, country: code})); setChanged(true); }} />
                                    </div>

                                    <div className="mb-3" style={{minHeight: 250, height: 250}}>
                                        <LocationsGIS locations={[{Name: name, geo: tempGeo}]} center={tempGeo} zoom={12} noPopups />
                                    </div>

                                    { error && <div className="alert alert-danger alert-dismissible fade show mt-2" role="alert">
                                        { error }
                                    </div> }

                                    <div className="d-flex flex-column">
                                        <button className="btn btn-primary mt-2" disabled={!changed || loading} onClick={() => insert()}>{t('add')} <i className="far fa-square-plus fa-lg"/></button>
                                    </div>
                                </> }
                            </div>}
                        </div>
                    </>}
                </div>
            </div>
        </div>
    )
}

const AddMachineModal = ({locationId, open, onConfirm, onClose}) => {
    const [selectedId, setSelectedId] = useState(null);
    const [machines, setMachines] = useState(null);
    const [search, setSearch] = useState('');
    const [showAll, setShowAll] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        setSearch('');
        setSelectedId(null);
        setShowAll(false);
        setMachines(null);
        if (open){
            API.getMachinesNotPartOfLocation(locationId)
                .then(m => setMachines(m));
        }
    }, [open, locationId])

    useEffect(() => {
        document.querySelectorAll(".form-outline").forEach((formOutline) => {
			new mdb.Input(formOutline).init();
		});

        
    }, [machines]);

    return (
        <CustomModal open={open}>
            <div className="card">
                <div className="card-body">
                    <h5 className="card-title">{t('add_machine_to_location')}</h5>
                    <div className="d-flex flex-column justify-content-center align-items-center my-2">
                        {machines == null && (
                            <div className="spinner-border" role="status">
                                <span className="visually-hidden">{t('loading...')}</span>
                            </div>
                        )}
                        {machines !== null && (
                            <div className="d-flex flex-column">
                                <div className="d-flex form-outline px-1 mb-1 mt-1">
                                    <input type="text" name="search" id="search" className="form-control" value={search} onInput={ev => { setSearch(ev.currentTarget.value);}} autoComplete="off"/>
                                    <label className="form-label" htmlFor="search">{t('search')}</label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value={showAll} id="flexCheckDefault" onChange={ev => setShowAll(ev.currentTarget.checked)} />
                                    <label className="form-check-label" htmlFor="flexCheckDefault">{t('show_all')}</label>
                                </div>
                                <div className="d-flex flex-column overflow-auto px-1 pt-2 pb-2" style={{maxHeight: 500, minWidth: 300, userSelect: 'none'}}>
                                    {machines.map(m => (
                                        <div key={m.id} className={"flex-row align-items-center hover-shadow p-1" + ((!showAll && m.location !== null) ? ' d-none' : '')} style={{cursor: 'pointer', background: m.id == selectedId ? '#0000001a' :'', display: m.name.toLocaleUpperCase().indexOf(search.toLocaleUpperCase()) == '-1' ? 'none': 'flex'}} onClick={() => setSelectedId(m.id)}>
                                            <i className="fas fa-server text-primary"/>
                                            <span className="ms-1">{m.name}</span>
                                            { m.location && <span>&nbsp;(<i className="fas fa-map-marker-alt text-secondary fa-xs"/>{m.location.Name})</span> }
                                        </div>
                                    ))}
                                </div>
                            </div>
                            
                        )}
                    </div>
                    
                    <button className="btn btn-danger me-1" disabled={!selectedId} onClick={() => onConfirm(selectedId)}>{t('confirm')}</button>
                    <button className="btn btn-secondary" onClick={onClose}>{t('cancel')}</button>
                </div>
            </div>
        </CustomModal>
    )
}