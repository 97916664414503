import axios from 'axios';
import WS from './ws';

export default class API {
    static token = '';
    static uri = !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? 'http://localhost:81' : '';
    static onLogout = () => {};
    static ws = new WS();
    static rights = {};

    static TransformRights = (rights, standard=false) => {
        return {
            Companies: {
                Own: {
                    Change: rights?.Companies?.Own?.Change || false
                },
                Sub: {
                    View: rights?.Companies?.Sub?.View || false,
                    Add: rights?.Companies?.Sub?.Add || false,
                    Modify: rights?.Companies?.Sub?.Modify || false,
                    Delete: rights?.Companies?.Sub?.Delete || false,
                    AddUser: rights?.Companies?.Sub?.AddUser || false,
                }
            },
            Users: {
                View: rights?.Users?.View || false,
                Add: rights?.Users?.Add || false,
                Change: rights?.Users?.Change || false,
                Delete: rights?.Users?.Delete || false,
                ManageRights: rights?.Users?.ManageRights || false,
                ManageGroups: rights?.Users?.ManageGroups || false,
            },
            Groups: {
                View: rights?.Groups?.View || false,
                ViewValues: rights?.Groups?.ViewValues || false,
                Add: rights?.Groups?.Add || false,
                Change: rights?.Groups?.Change || false,
                Delete: rights?.Groups?.Delete || false,
                Share: rights?.Groups?.Share || false,
                AssignLocations: rights?.Groups?.AssignLocations || false,
                UserAssociation: rights?.Groups?.UserAssociation || false,
            },
            Locations: {
                View: rights?.Locations?.View || false,
                ViewValues: rights?.Locations?.ViewValues || false,
                Add: rights?.Locations?.Add || false,
                Change: rights?.Locations?.Change || false,
                Delete: rights?.Locations?.Delete || false,
                ViewMachines: rights?.Locations?.ViewMachines || false,
                AssignMachines: rights?.Locations?.AssignMachines || false,
            },
            Products: {
                View: rights?.Products?.View || false,
                Add: rights?.Products?.Add || false,
                Change: rights?.Products?.Change || false,
                Delete: rights?.Products?.Delete || false,
                DeleteShared: rights?.Products?.DeleteShared || false,
                Share: rights?.Products?.Share || false,
            },
            Machines: {
                ViewUnassigned: rights?.Machines?.ViewUnassigned || false,
                Transfer: rights?.Machines?.Transfer || false,
                Credit: {
                    View: rights?.Machines?.Credit?.View || false,
                    Change: rights?.Machines?.Credit?.Change || false
                },
                Price: {
                    View: rights?.Machines?.Price?.View || false,
                    Change: rights?.Machines?.Price?.Change || false
                },
                Type: {
                    View: rights?.Machines?.Type?.View || false,
                    Change: rights?.Machines?.Type?.Change || false
                },
                QNTY: {
                    View: rights?.Machines?.QNTY?.View || false,
                    ViewClue:  rights?.Machines?.QNTY?.ViewClue || false,
                    Change: rights?.Machines?.QNTY?.Change || false
                },
                MoneyBox: {
                    View: rights?.Machines?.MoneyBox?.View || false,
                    Change: rights?.Machines?.MoneyBox?.Change || false
                },
                Actions: {
                    DeliveryProducts: rights?.Machines?.Actions?.DeliveryProducts || false,
                    ClearMoneyBox: rights?.Machines?.Actions?.ClearMoneyBox || false,
                    OpenDoor: rights?.Machines?.Actions?.OpenDoor || false,
                    ChangeDateTime: rights?.Machines?.Actions?.ChangeDateTime || false,
                    ChangeName: rights?.Machines?.Actions?.ChangeName || false,
                    ChangeCounters: rights?.Machines?.Actions?.ChangeCounters || false,
                    ChangeSchedules: rights?.Machines?.Actions?.ChangeSchedules || false,
                    ReadParams: rights?.Machines?.Actions?.ReadParams || false,
                    ChangeParams: rights?.Machines?.Actions?.ChangeParams || false,
                    Power: rights?.Machines?.Actions?.Power || false,
                }
            },
            Events: {
                SalesEvents: rights?.Events?.SalesEvents || false,
                RuntimeEvents: rights?.Events?.RuntimeEvents || false,
                ErrorEvents: rights?.Events?.ErrorEvents || false,
                Management: rights?.Events?.Management || false,
            }
        }
    }

    static async request(axiosFunction) {
        try {
            const res = await axiosFunction();
            return res;
        }catch(err){
            console.log(err);
            const errmsg = err.response?.data?.error || (err.message + (err.response?.statusText ? `: ${err.response.statusText}` : ''));
            if (errmsg === 'Invalid session!') return API.logout();
            throw new Error(errmsg);
        }
    }

    static async connectWS() {
        if (!WS.connected()) return await API.ws.connect();
    }

    static async login(u, p) {
        console.log(`login`);

        const res = await API.request(async () => await axios.post(API.uri+'/r/login', {u,p,c: Number(localStorage.getItem('lastopcomp'))}));
        API.token = res.data.token;
        localStorage.setItem('lastopcomp', res.data.company);
        localStorage.setItem('token', API.token);
        //this.ws.connect();
        return res.data.user;
    }

    static async logout() {
        await axios.post(API.uri+'/api/logout', {}, {headers: {Authorization: API.token}})
            .finally(() => {
                API.token = '';
                localStorage.removeItem('token');
                API.onLogout();
            });
        
    }

    static async getUserGroups() {
        const res = await API.request(async () => await axios.get(API.uri+'/api/groups', {headers: {Authorization: API.token}}));
        return res.data;
    }

    static async getLocationsByGroupID(groupID) {
        const res = await API.request(async () => await axios.get(API.uri+'/api/groups/'+groupID, {headers: {Authorization: API.token}}));
        return res.data;
    }

    static async getMachinesByLocationID(groupID, locID) {
        return (await API.request(async () => await axios.get(API.uri+`/api/groups/${groupID}/${locID}`, {headers: {Authorization: API.token}}))).data;
    }

    static async getBoxesByMachineID(groupID, locID, mid) {
        return (await API.request(async () => await axios.get(API.uri+`/api/groups/${groupID}/${locID}/${mid}`, {headers: {Authorization: API.token}}))).data;
    }

    static async getProducts() {
        return (await API.request(async () => await axios.get(API.uri+`/api/products?includeGeneric=true`, {headers: {Authorization: API.token}}))).data;
    }

    static async updateMachine(groupID, locID, mid, crtCredit, coinBoxNr, coinBoxVal) {
        return (await API.request(async () => await axios.patch(API.uri+`/api/groups/${groupID}/${locID}/${mid}`, {crtCredit, coinBoxNr, coinBoxVal}, {headers: {Authorization: API.token}}))).data;
    }

    static async updateMachineName(groupID, locID, mid, name) {
        return (await API.request(async () => await axios.patch(API.uri+`/api/groups/${groupID}/${locID}/${mid}`, {name}, {headers: {Authorization: API.token}}))).data;
    }

    static async machineActionClearMoneybox(groupID, locID, mid) {
        return (await API.request(async () => await axios.post(API.uri+`/api/groups/${groupID}/${locID}/${mid}/clearmoneybox`, {}, {headers: {Authorization: API.token}}))).data;
    }

    static async machineActionOpenDoor(groupID, locID, mid) {
        return (await API.request(async () => await axios.post(API.uri+`/api/groups/${groupID}/${locID}/${mid}/opendoor`, {}, {headers: {Authorization: API.token}}))).data;
    }

    static async machineActionReset(groupID, locID, mid) {
        return (await API.request(async () => await axios.post(API.uri+`/api/groups/${groupID}/${locID}/${mid}/reset`, {}, {headers: {Authorization: API.token}}))).data;
    }

    static async machineActionGetRTC(groupID, locID, mid) {
        return (await API.request(async () => await axios.post(API.uri+`/api/groups/${groupID}/${locID}/${mid}/getrtc`, {}, {headers: {Authorization: API.token}}))).data;
    }

    static async machineActionSetRTC(groupID, locID, mid) {
        return (await API.request(async () => await axios.post(API.uri+`/api/groups/${groupID}/${locID}/${mid}/setrtc`, {}, {headers: {Authorization: API.token}}))).data;
    }

    static async machineActionPower(groupID, locID, mid, power) {
        return (await API.request(async () => await axios.post(API.uri+`/api/groups/${groupID}/${locID}/${mid}/power/${power}`, {}, {headers: {Authorization: API.token}}))).data;
    }

    static async clearStatus(groupID, locID, mid, status) {
        return (await API.request(async () => await axios.post(API.uri+`/api/groups/${groupID}/${locID}/${mid}/statusclear`, {status}, {headers: {Authorization: API.token}}))).data;
    }

    static async updateBox(groupID, locID, mid, boxNr, pid, price, qnty) {
        return (await API.request(async () => await axios.patch(API.uri+`/api/groups/${groupID}/${locID}/${mid}/${boxNr}`, {pid, price, qnty}, {headers: {Authorization: API.token}}))).data;
    }

    static async deliverProduct(groupID, locID, mid, boxNr, wCash) {
        return (await API.request(async () => await axios.post(API.uri+`/api/groups/${groupID}/${locID}/${mid}/${boxNr}/deliver`, {wCash}, {headers: {Authorization: API.token}}))).data;
    }

    static async getMachineAdvancedParamsOperator(groupID, locID, mid) {
        return (await API.request(async () => await axios.get(API.uri + `/api/groups/${groupID}/${locID}/${mid}/advanced`, {headers: {Authorization: API.token}}))).data;
    }

    static async openProductDoor(groupID,locID,mid,boxNr){
        return(await API.request(async() => await axios.get(API.uri + `/api/groups/${groupID}/${locID}/${mid}/${boxNr}/open`,{headers:{Authorization: API.token}}))).data;
    }

    static async readFRAMOperator(groupID, locID, mid, addr){
        return (await API.request(async () => await axios.get(API.uri + `/api/groups/${groupID}/${locID}/${mid}/fram/${addr}`, {headers: {Authorization: API.token}}))).data;
    }

    static async writeFRAMOperator(groupID, locID, mid, addr, val){
        return (await API.request(async () => await axios.post(API.uri + `/api/groups/${groupID}/${locID}/${mid}/fram/${addr}`, {val}, {headers: {Authorization: API.token}}))).data; 
    }


    static async loadLogs(groupID, locationID, machineID, lastId, language) {
        let uri = '/api/logs';
        if (groupID) uri += `/${groupID}`;
        if (locationID) uri += `/${locationID}`;
        if (machineID) uri += `/${machineID}`;

        return (await API.request(async () => await axios.get(API.uri + uri + `?lang=${language}` + (typeof lastId == 'number' ? `&lastId=${lastId}`:''), {headers: {Authorization: API.token}}))).data;
    }

    static async search(text){
        return (await API.request(async () => await axios.post(API.uri+'/api/search', {text}, {headers: {Authorization: API.token}}))).data;
    }

    static async searchSN(sn, currentGroup) {
        return (await API.request(async () => await axios.post(API.uri+'/api/search/qr', {sn, currentGroup}, {headers: {Authorization: API.token}}))).data;
    }

    static async unregisterLogs() {
        return (await API.request(async () => await axios.post(API.uri + '/api/logs/stop', {}, {headers: {Authorization: API.token}}))).data;
    }

    static async updateUser(name, phone, language, company, password) {
        return (await API.request(async () => await axios.patch(API.uri + '/api/users', {name, phone, company, language, password}, {headers: {Authorization: API.token}}))).data;
    }

    static async updateOtherUser(id, name, phone, rightsId) {
        return (await API.request(async () => await axios.patch(API.uri + '/api/users/u/'+id, {name, phone, rightsId}, {headers: {Authorization: API.token}}))).data;
    }

    static async removeUserFromCompany(id) {
        return (await API.request(async () => await axios.delete(API.uri + '/api/users/u/'+id, {headers: {Authorization: API.token}}))).data;
    }

    static async subcompanies() {
        return (await API.request(async () => await axios.get(API.uri + '/api/companies/subcompanies', {headers: {Authorization: API.token}}))).data;
    }

    static async subcompany(id) {
        return (await API.request(async () => await axios.get(API.uri + '/api/companies/subcompanies/' + id, {headers: {Authorization: API.token}}))).data;
    }

    static async updateSubcompanyUserInheritRights(subcompanyId, userId, inheritRights){
        return (await API.request(async () => await axios.patch(API.uri + `/api/companies/subcompanies/${subcompanyId}/u/${userId}`, {inheritRights}, {headers: {Authorization: API.token}}))).data;
    }
    
    static async companyUsers() {
        return (await API.request(async () => await axios.get(API.uri + '/api/users/u', {headers: {Authorization: API.token}}))).data;
    }

    static async getCompanyUser(id) {
        return (await API.request(async () => await axios.get(API.uri + '/api/users/u/'+id, {headers: {Authorization: API.token}}))).data;

    }

    static async getMyCompany() {
        return (await API.request(async () => await axios.get(API.uri + '/api/companies', {headers: {Authorization: API.token}}))).data;
    }

    static async updateMyCompany(name, CIF, country){
        return (await API.request(async () => await axios.patch(API.uri + '/api/companies', {name, CIF, country}, {headers: {Authorization: API.token}}))).data;
    }

    static async addSubcompany(name, CIF, maxRightsId, country) {
        return (await API.request(async () => await axios.post(API.uri + '/api/companies/subcompanies', {name, CIF, maxRightsId, country}, {headers: {Authorization: API.token}}))).data;
    }

    static async deleteCompany(id) {
        return (await API.request(async () => await axios.delete(API.uri + '/api/companies/subcompanies/'+id, {headers: {Authorization: API.token}}))).data;
    }

    static async updateSubcompany(id, name, CIF, maxRightsId, country) {
        return (await API.request(async () => await axios.patch(API.uri + '/api/companies/subcompanies/'+id, {name, CIF, maxRightsId, country}, {headers: {Authorization: API.token}}))).data;
    }

    static async me(){
        return (await API.request(async () => await axios.get(API.uri + '/api/users', {headers: {Authorization: API.token}}))).data;
    }

    static async inviteUser(email, companyId, rightsId){
        return (await API.request(async () => await axios.post(API.uri + '/api/invite', {email, companyId, rightsId}, {headers: {Authorization: API.token}}))).data;
    }

    static async joinGetInvitation(id) {
        return (await API.request(async () => await axios.get(API.uri + '/r/join/'+id, {headers: {Authorization: API.token}}))).data;
    }

    static async joinConfirm(id, Name, phone, password) {
        return (await API.request(async () => await axios.post(API.uri + '/r/join/confirm/'+id, {Name, phone, password}, {headers: {Authorization: API.token}}))).data;
    }

    static async joinCancel(id) {
        return (await API.request(async () => await axios.post(API.uri + '/r/join/cancel/'+id, {}, {headers: {Authorization: API.token}}))).data;
    }

    static async resetPasswordRequest(email) {
        return (await API.request(async () => await axios.post(API.uri + '/r/reset', {email}, {headers: {Authorization: API.token}}))).data;
    }

    static async resetPasswordGetRequestEmail(id) {
        return (await API.request(async () => await axios.post(API.uri + '/r/reset/get', {id}, {headers: {Authorization: API.token}}))).data;
    }

    static async resetPasswordChangePassword(id, password) {
        return (await API.request(async () => await axios.post(API.uri + '/r/reset/confirm', {id, password}, {headers: {Authorization: API.token}}))).data;
    }

    /* ADM RIGHTS */

    static async getAllRights(){
        return (await API.request(async () => await axios.get(API.uri + '/api/rights/', {headers: {Authorization: API.token}}))).data;
    }

    static async getRight(id){
        return (await API.request(async () => await axios.get(API.uri + '/api/rights/'+id, {headers: {Authorization: API.token}}))).data;
    }

    static async addRight(Name, def){
        return (await API.request(async () => await axios.post(API.uri + '/api/rights/', {Name, def}, {headers: {Authorization: API.token}}))).data;
    }

    static async updateRight(id, Name, def){
        return (await API.request(async () => await axios.patch(API.uri + '/api/rights/'+id, {Name, def}, {headers: {Authorization: API.token}}))).data;
    }

    static async deleteRight(id){
        return (await API.request(async () => await axios.delete(API.uri + '/api/rights/'+id, {headers: {Authorization: API.token}}))).data;
    }

    /* ADM GROUPS  */

    static async getGroupsAdmin(){
        return (await API.request(async () => await axios.get(API.uri + '/api/admgroups/', {headers: {Authorization: API.token}}))).data;
    }

    static async getGroupAdmin(id){
        return (await API.request(async () => await axios.get(API.uri + '/api/admgroups/'+id, {headers: {Authorization: API.token}}))).data;
    }

    static async updateGroupAdmin(id, Name) {
        return (await API.request(async () => await axios.patch(API.uri + '/api/admgroups/'+id, {Name}, {headers: {Authorization: API.token}}))).data;
    }

    // static async getLocationOfGroupAdmin(groupId, id) {
    //     return (await API.request(async () => await axios.get(API.uri + '/api/admgroups/'+groupId+'/l/'+id, {headers: {Authorization: API.token}}))).data;
    // }

    static async getLocationsNotPartOfGroupAdm(groupId) {
        return (await API.request(async () => await axios.get(API.uri + '/api/admgroups/'+groupId+'/notl/', {headers: {Authorization: API.token}}))).data;
    }

    static async removeLocationFromGroupAdmin(groupId, id) {
        return (await API.request(async () => await axios.delete(API.uri + '/api/admgroups/'+groupId+'/l/'+id, {headers: {Authorization: API.token}}))).data;
    }

    static async addLocationToGroupAdmin(groupId, id) {
        return (await API.request(async () => await axios.post(API.uri + '/api/admgroups/'+groupId+'/l/'+id, {}, {headers: {Authorization: API.token}}))).data;
    }

    static async getUserOfGroupAdmin(groupId, id) {
        return (await API.request(async () => await axios.get(API.uri + '/api/admgroups/'+groupId+'/u/'+id, {headers: {Authorization: API.token}}))).data;
    }

    static async getUsersNotPartOfGroupAdm(groupId) {
        return (await API.request(async () => await axios.get(API.uri + '/api/admgroups/'+groupId+'/notu/', {headers: {Authorization: API.token}}))).data;
    }

    static async removeUserFromGroupAdmin(groupId, id) {
        return (await API.request(async () => await axios.delete(API.uri + '/api/admgroups/'+groupId+'/u/'+id, {headers: {Authorization: API.token}}))).data;
    }

    static async updateUserGroupRights(groupId, userId, rightsId) {
        return (await API.request(async () => await axios.patch(API.uri + '/api/admgroups/'+groupId+'/u/'+userId, {rightsId}, {headers: {Authorization: API.token}}))).data;
    }

    static async addUserToGroupAdmin(groupId, userId, rightsId) {
        return (await API.request(async () => await axios.post(API.uri + '/api/admgroups/'+groupId+'/u/'+userId, {rightsId}, {headers: {Authorization: API.token}}))).data;
    }

    static async deleteGroup(groupId) {
        return (await API.request(async () => await axios.delete(API.uri + '/api/admgroups/'+groupId, {headers: {Authorization: API.token}}))).data;
    }

    static async addGroup(Name){
        return (await API.request(async () => await axios.post(API.uri + '/api/admgroups/', {Name}, {headers: {Authorization: API.token}}))).data;
    }

    static async getShareVariants(groupId){
        return (await API.request(async () => await axios.get(API.uri + '/api/admgroups/'+groupId+'/share/', {headers: {Authorization: API.token}}))).data;
    }

    static async getSharedCompanies(groupId){
        return (await API.request(async () => await axios.get(API.uri + '/api/admgroups/'+groupId+'/shared/', {headers: {Authorization: API.token}}))).data;
    }

    static async shareGroup(groupId, compId, rightsId){
        return (await API.request(async () => await axios.post(API.uri + '/api/admgroups/'+groupId+'/share/'+compId, {rightsId}, {headers: {Authorization: API.token}}))).data;
    }

    static async shareWithLink(groupId, rightsId){
        return (await API.request(async () => await axios.post(API.uri + '/api/admgroups/'+groupId+'/sharelink/', {rightsId}, {headers: {Authorization: API.token}}))).data;
    }

    static async unshareGroup(groupId, compId) {
        return (await API.request(async () => await axios.delete(API.uri + '/api/admgroups/'+groupId+'/share/'+compId, {headers: {Authorization: API.token}}))).data;
    }

    static async getSharedGroup(invitationId) {
        return (await API.request(async () => await axios.post(API.uri + '/api/admgroups/share/', {invitationId}, {headers: {Authorization: API.token}}))).data;
    }


    /* ADM LOCATIONS  */

    static async getLocationsAdmin(){
        return (await API.request(async () => await axios.get(API.uri + '/api/admlocations/', {headers: {Authorization: API.token}}))).data;
    }

    static async getLocationAdmin(id){
        return (await API.request(async () => await axios.get(API.uri + '/api/admlocations/'+id, {headers: {Authorization: API.token}}))).data;
    }

    static async addLocationAdmin(Name, address, Info, geo, country) {
        return (await API.request(async () => await axios.post(API.uri + '/api/admlocations/', {Name, address, Info, geo, country}, {headers: {Authorization: API.token}}))).data;
    }

    static async updateLocationAdmin(id, Name, address, Info, geo, country) {
        return (await API.request(async () => await axios.patch(API.uri + '/api/admlocations/'+id, {Name, address, Info, geo, country}, {headers: {Authorization: API.token}}))).data;
    }

    static async deleteLocation(id) {
        return (await API.request(async () => await axios.delete(API.uri + '/api/admlocations/'+id, {headers: {Authorization: API.token}}))).data;
    }

    static async getMachinesNotPartOfLocation(id) {
        return (await API.request(async () => await axios.get(API.uri + '/api/admlocations/'+id+'/notm', {headers: {Authorization: API.token}}))).data;
    }

    static async assignMachineToLocation(locationId, machineId) {
        return (await API.request(async () => await axios.post(API.uri + '/api/admlocations/'+locationId+'/m/'+machineId, {}, {headers: {Authorization: API.token}}))).data;
    }

    static async removeMachineFromLocation(locationId, machineId) {
        return (await API.request(async () => await axios.delete(API.uri + '/api/admlocations/'+locationId+'/m/'+machineId, {headers: {Authorization: API.token}}))).data;
    }
    
    /* ADM PRODUCTS */

    static async getProductsAdm(){
        return (await API.request(async () => await axios.get(API.uri + '/api/products/adm', {headers: {Authorization: API.token}}))).data;
    }

    static async getProductAdm(id){
        return (await API.request(async () => await axios.get(API.uri + '/api/products/adm/'+id, {headers: {Authorization: API.token}}))).data;
    }

    static async addProductAdm(name, description, aqPrice){
        return (await API.request(async () => await axios.post(API.uri + '/api/products/adm', { name, description, aqPrice }, {headers: {Authorization: API.token}}))).data;
    }

    static async updateProductAdm(id, name, description, aqPrice){
        return (await API.request(async () => await axios.patch(API.uri + '/api/products/adm/'+id, { name, description, aqPrice }, {headers: {Authorization: API.token}}))).data;
    }

    static async deleteProduct(id) {
        return (await API.request(async () => await axios.delete(API.uri + '/api/products/adm/'+id, {headers: {Authorization: API.token}}))).data;
    }

    /* ADM MACHINES */

    static async getMachinesAdm(){
        return (await API.request(async () => await axios.get(API.uri + '/api/machines', {headers: {Authorization: API.token}}))).data;
    }

    static async getMachineAdm(id){
        return (await API.request(async () => await axios.get(API.uri + '/api/machines/'+id, {headers: {Authorization: API.token}}))).data;
    }

    static async updateMachineAdm(id, name, info, locationId) {
        return (await API.request(async () => await axios.patch(API.uri + '/api/machines/'+id, { name, info, locationId }, {headers: {Authorization: API.token}}))).data;
    }

    static async getMachineTransferVariants(id) {
        return (await API.request(async () => await axios.get(API.uri + '/api/machines/'+id+'/transfer', {headers: {Authorization: API.token}}))).data;
    }

    static async transferMachineToCompany(id, compId){
        return (await API.request(async () => await axios.post(API.uri + '/api/machines/'+id+'/transfer/c/'+compId, {}, {headers: {Authorization: API.token}}))).data;
    }

    static async transferMachineLink(id){
        return (await API.request(async () => await axios.post(API.uri + '/api/machines/'+id+'/transfer/link', {}, {headers: {Authorization: API.token}}))).data;
    }

    static async transferMachineAccept(invitationId){
        return (await API.request(async () => await axios.post(API.uri + '/api/machines/', { invitationId }, {headers: {Authorization: API.token}}))).data;
    }

    static async getMachineAdvancedParams(id) {
        return (await API.request(async () => await axios.get(API.uri + '/api/machines/'+id+'/advanced', {headers: {Authorization: API.token}}))).data;
    }

    static async readFRAM(id, addr){
        return (await API.request(async () => await axios.get(API.uri + '/api/machines/'+id+'/fram/'+addr, {headers: {Authorization: API.token}}))).data;
    }

    static async writeFRAM(id, addr, val){
        return (await API.request(async () => await axios.post(API.uri + '/api/machines/'+id+'/fram/'+addr, {val}, {headers: {Authorization: API.token}}))).data; 
    }

    /* CONFIG */

    static async configCountries(){
        return (await API.request(async () => await axios.get(API.uri + '/api/config/countries', {headers: {Authorization: API.token}}))).data;
    }

    /* REPORTS */

    static async reportsRequest(startDate, endDate, timezoneOffset, gid, lid, mid, prodList){
        return (await API.request(async () => await axios.post(API.uri + '/api/reports/'+gid, {startDate, endDate, timezoneOffset, lid, mid, prodList}, {headers: {Authorization: API.token}}))).data;
    }

    static async reportsGetOne(id){
        return (await API.request(async () => await axios.get(API.uri + '/api/reports/'+id, {headers: {Authorization: API.token}}))).data;
    }

    /* DEV */

    static async devSessions(){
        return (await API.request(async () => await axios.get(API.uri + '/api/dev/sessions', {headers: {Authorization: API.token}}))).data;
    }

    static async devConnections(){
        return (await API.request(async () => await axios.get(API.uri + '/api/dev/connections', {headers: {Authorization: API.token}}))).data;
    }

    static async devResetCache(){
        return (await API.request(async () => await axios.post(API.uri + '/api/dev/cachereset', {}, {headers: {Authorization: API.token}}))).data;
    }

    static async devJoinLogs(){
        return (await API.request(async () => await axios.post(API.uri + '/api/dev/logs/join', {}, {headers: {Authorization: API.token}}))).data;
    }

    static async devLeaveLogs(){
        return (await API.request(async () => await axios.post(API.uri + '/api/dev/logs/leave', {}, {headers: {Authorization: API.token}}))).data;
    }

    static async devGetLogs(limit){
        return (await API.request(async () => await axios.get(API.uri + '/api/dev/logs' + (typeof limit == 'number' ? `?limit=${limit}` : ''), {headers: {Authorization: API.token}}))).data;
    }

    static async devCommand(cmd){
        return (await API.request(async () => await axios.post(API.uri + '/api/dev/cmd', {cmd}, {headers: {Authorization: API.token}}))).data;

    }
}
