import { Input } from 'mdb-ui-kit';
import { useEffect, useState } from 'react';
import API from '../../service/api';
import { useTranslation } from 'react-i18next';
import { NavLink, useSearchParams } from 'react-router-dom';



export const LoginPage = ({onLogin, initialError}) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isWaiting, setIsWaiting] = useState(false);
    const [message, setMessage] = useState('');
    const [error, setError] = useState(initialError||'');
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        document.querySelectorAll('.form-outline').forEach((formOutline) => {
            new Input(formOutline).init();
        });
        if (searchParams.get('success')) setMessage(t('password_changed_success'));
    }, []);

    async function doLogin() {
        setIsWaiting(true);
        try {
            const data = await API.login(email, password);
            onLogin(data);
        }catch(err){
            setError(`Authentication failed: ${err.message}`);
        }finally{
            setIsWaiting(false);
        }
    }

    return (
        <section className="h-100">
            <div className="container-fluid h-100 ">
                <div className="row d-flex justify-content-center align-items-center h-100">
                    <div className="h-100 w-100 col-sm-6 text-black d-flex flex-column justify-content-center align-items-center">

                        

                        <div className="d-flex flex-column flex-fill align-items-center h-custom-2 justify-content-center mb-5 ">

                            <div className="d-flex flex-column align-items-center pt-5 mb-5">
                                <i className="fas fa-cubes fa-3x" style={{color: "#fc8c03"}}/>
                                <span className="h2 fw-bold mb-0">Smart Capsule Machine</span>
                            </div>

                            <form style={{width: '80vw', maxWidth: '25rem'}}>

                                <h3 className="fw-normal mb-3 pb-3" style={{letterSpacing: "1px"}}>{t('login')}</h3>

                                <div className="form-outline mb-4">
                                <input type="email" id="form2Example18" className="form-control form-control-lg" onChange={ev => setEmail(ev.target.value)} />
                                <label className="form-label" htmlFor="form2Example18">{t('email_address')}</label>
                                </div>

                                <div className="form-outline mb-4">
                                <input type="password" id="form2Example28" className="form-control form-control-lg" onChange={ev => setPassword(ev.target.value)}  />
                                <label className="form-label" htmlFor="form2Example28">{t('password')}</label>
                                </div>

                                {error && <div className="alert alert-danger alert-dismissible fade show mt-2" role="alert">
                                    { error }
                                </div> }

                                {message && <div className="alert alert-success alert-dismissible fade show mt-2" role="alert">
                                    { message }
                                </div> }

                                <div className="pt-1 mb-2">
                                    <button className="btn btn-info btn-lg btn-block" type="button" disabled={isWaiting}
                                        onClick={() => {
                                            doLogin();
                                        }}>{t('login')}</button>
                                </div>
                                <div className='d-flex flex-row justify-content-end'>
                                    <NavLink to="/reset"><span role="button" className="text-primary">{t('forgot_password')}</span></NavLink>
                                </div>

                            </form>

                        </div>

                    </div>
                </div>
            </div>
        </section>
    );
}