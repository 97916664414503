import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from 'react-tooltip';
import { StatusIcon } from "../../other/StatusIcon";

function getWindowSize() {
    const {innerWidth, innerHeight} = window;
    return {innerWidth, innerHeight};
}



const LocationItem = ({location, onSelect}) => {
    const [stateIcons, setStateIcons] = useState([]);
    const [generalColor, setGeneralColor] = useState('');
    const { t } = useTranslation();

    useEffect(() => {
        const LocationStatusDictionary = {
            'CashlessLost': { type: 'multiple', priority: 'danger', title: t('status.cashless'), icon: 'fas fa-credit-card' },
            'CashboxFull': { type: 'multiple', priority: 'danger', title: t('status.cashboxfull'), icon: 'fas fa-cart-flatbed' },
            'Offline': { type: 'multiple', priority: 'danger', title: t('status.box_offline'), icon: 'fas fa-power-off' },
            'SensorTrouble': { type: 'multiple', priority: 'danger', title: t('status.box_sensor_issue'), icon: 'fas fa-rss' },
            'Empty': { type: 'multiple', priority: 'danger', title: t('status.box_empty'), icon: 'fas fa-store' },
            'LowQuantity': { type: 'multiple', priority: 'warning', title: t('status.low_quantity'), icon: 'fas fa-store' },
            'CashboxAlmostFull': { type: 'multiple', priority: 'warning', title: t('status.cashbox_almost_full'), icon: 'fas fa-cart-flatbed' },
            'ScheduleSuspended': { type: 'multiple', priority: 'warning', title: t('status.schedule_suspended'), icon: 'fas fa-calendar-xmark'}
        };

        const ss = Object.keys(location.state).filter(key => LocationStatusDictionary[key] && location.state[key])
            .map(key => ({key: key, ...LocationStatusDictionary[key], value: (location.state[key]||0) * (LocationStatusDictionary[key].priority == 'danger' ? 100 : 1)}))
            .sort((a,b) => {
                return b.value - a.value;
        });
        
        setStateIcons(ss);

        if (location.stateColor) setGeneralColor(location.stateColor);
    }, [location]);
    
    return (
        <div className="d-flex pb-1 pt-1 col-12 col-sm-6 col-md-6">
            <div className={"card card-hover2 shadow-2-strong"} style={{height: '60px', width: '100%', userSelect: 'none', cursor: 'pointer', background: generalColor || ''}}
                onClick={() => onSelect(location.id)}>
                <div className="card-body p-1 w-100 h-100">
                    <div className="d-flex align-items-center w-100 h-100 overflow-hidden">
                        <div className="d-flex flex-column flex-fill overflow-hidden ps-1 align-self-center">
                            <div className="d-flex flex-column" style={{alignItems: 'baseline', textOverflow: 'ellipsis'}}>
                                <div className="d-flex flex-row align-items-center w-100">
                                    <i className="fas fa-map-marker-alt fa-lg me-1 text-primary"/>
                                    <span style={{lineHeight: '1.4em', maxHeight: '2.8em', marginRight: '4px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap',
                                    display: '-webkit-box', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical', fontWeight: 'bold'}}>{location.lType == 1 && <span><i className="fas fa-bookmark text-warning me-1"/></span>}{ location.Name }</span>

                                    <div className="d-flex flex-row flex-fill justify-content-end align-items-center">
                                        <span data-tooltip-id="powercount-tooltip" data-tooltip-content="Powered on" onClick={ev => {ev.preventDefault(); ev.stopPropagation(); return false;}}><i className="fas fa-power-off me-1 text-success"/></span>
                                        {/* <Tooltip id="powercount-tooltip" /> */}
                                        <span style={{textAlign: 'left'}}>{location.state?.PowerOn}</span>
                                        <span data-tooltip-id="onlinecount-tooltip" data-tooltip-content="Online" onClick={ev => {ev.preventDefault(); ev.stopPropagation(); return false;}}><i className="fas fa-signal me-1 ms-1 text-success"/></span>
                                        {/* <Tooltip id="onlinecount-tooltip" /> */}
                                        <span style={{textAlign: 'left'}}>{location.state?.Connected}</span>
                                    </div>
                                </div>
                                
                                <div className="d-flex flex-row w-100 pb-1">
                                    {(location.state.Connected < location.state.PowerOn) &&
                                        <StatusIcon count={location.state.PowerOn - location.state.Connected} statusId="Offline" title={t('status.offline')} icon="fas fa-signal" priority="danger"/>
                                    }
                                    {stateIcons.map(s =>
                                        <StatusIcon statusId={s.key} count={typeof location.state[s.key] == 'number' ? location.state[s.key] : 1} {...s}/>
                                    )}

                                    <span className="d-flex flex-row align-items-center w-100 flex-fill justify-content-end">
                                        {typeof location.value == 'number' && <>
                                            <i className="fas fa-hand-holding-dollar ms-2 me-1 text-primary"/>
                                            <span className="me-1" style={{textAlign: 'left'}}>{location.value}</span>
                                        </>}
                                        <i className="fas fa-server me-1 text-primary"/>
                                        <span style={{textAlign: 'left'}}>{location.nrOfMachines}</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}


export const LocationsList = ({locations, onLocationSelect}) => {
    const { t } = useTranslation();


    if (!locations || locations.length === 0) return <span className="text-muted fst-italic w-100 text-center">{t('no_locations')}</span>;
    return (
        <div className="d-flex flex-column w-100 h-100">
            <div className="d-flex row overflow-auto w-100 ps-3">
                {locations.map(d => (
                    <LocationItem key={d.id} location={d} onSelect={onLocationSelect}/>
                ))}
            </div>
        </div>
        
    )

}