import { useTranslation } from "react-i18next"
import { NavLink } from "react-router-dom";


export const GroupSelector = ({groups}) => {
    const {t} = useTranslation();

    return (
        <div className="d-flex flex-column flex-fill align-items-center overflow-hidden">
            {groups.length > 0 && <>
                {t('select_group')}
                <div className="list-group list-group-light d-flex align-items-center my-3 overflow-auto"  style={{width: '75%', maxWidth: 300}}>
                    {groups.map(g => (
                        <NavLink key={g.id} end to={"/g/"+g.id} className="list-group-item list-group-item-action px-3">
                            <i className="fas fa-layer-group"/><span className="ms-1">{g.Name}</span>
                        </NavLink>
                    ))}
                </div> 
            </>}
            {groups.length == 0 &&
                <span>{t('no_groups')}</span>
            }
                   
        </div>
    )
}