import { Trans, useTranslation } from "react-i18next";
import { CustomModal } from "../other/CustomModal"
import { useState } from "react";

const Step1 = ({userData, next}) => {
    const { t } = useTranslation();
    return <>
        <span className="p-3 mb-3 d-flex justify-content-center align-items-center" style={{borderRadius: 160, background: '#6699ee12', height: 160, width: 160}}><i className="fas fa-cubes fa-6x" style={{color: '#fc8c03', paddingLeft: 22}}/></span>
        <h2 className="mb-2"><Trans i18nKey={"welcome_prompt_1"} values={{name: userData.name}}/></h2>
        <h5><Trans i18nKey={"welcome_prompt_2"} values={{company: userData.company.name}} components={{1: <span className="text-warning"/>, 2: <span className="text-primary"/>}}/></h5>
        <button className="btn btn-primary mt-3" onClick={() => next()}>{t('continue')}</button>
    </>
}

const Step2 = () => {
    const { t } = useTranslation();
    return <>
        <span className="shadow-2"><img src="/tutorial/t1.png" width="100%"/></span>

    </>
}

export const NewUserPopup = ({userData, mobile, withoutWelcome}) => {
    const [step, setStep] = useState(withoutWelcome ? 2 : 1);

    const { t } = useTranslation();

    return <>
        <CustomModal open={step == 1}>
            <div className="card py-3 text-center" style={{width: mobile ? 320 : 600}}>
                <div className="card-body d-flex flex-column justify-content-center align-items-center">
                    <span className="p-3 mb-3 d-flex justify-content-center align-items-center" style={{borderRadius: 160, background: '#6699ee12', height: 160, width: 160}}><i className="fas fa-cubes fa-6x" style={{color: '#fc8c03', paddingLeft: 22}}/></span>
                    <h2 className="mb-2"><Trans i18nKey={"welcome_prompt_1"} values={{name: userData.name}}/></h2>
                    <h5><Trans i18nKey={"welcome_prompt_2"} values={{company: userData.company.name}} components={{1: <span className="text-warning"/>, 2: <span className="text-primary"/>}}/></h5>
                    <button className="btn btn-primary mt-3" onClick={() => setStep(2)}>{t('continue')}</button>
                </div>
            </div>
        </CustomModal>
        <CustomModal open={step == 2}>
            <div className="card py-3 text-center overflow-auto" style={{width: mobile ? 320 : 600, height: 780, maxHeight: mobile ? 500 : 1200}}>
                <div className="card-body d-flex flex-column justify-content-center align-items-center">
                    <span className="shadow-2"><img src="/tutorial/t1.png" width="100%"/></span>
                    <div className="d-flex flex-column text-start justify-content-start w-100 mt-2">
                        <div className="d-flex flex-row align-items-center" style={{whiteSpace: 'normal'}}>
                            <span className="p-3 me-1 d-flex justify-content-center align-items-center" style={{borderRadius: 24, background: '#6699ee33', height: 24, width: 24}}><i className="fas fa-1" style={{color: '#1359ec'}}/></span>
                            <span className="d-inline"><Trans i18nKey={"welcome_prompt_part_1_1"} components={{1: <span className="text-primary"/>}}/></span>
                        </div>
                        <div className="d-flex flex-row align-items-center mt-1" style={{whiteSpace: 'normal'}}>
                            <span className="p-3 me-1 d-flex justify-content-center align-items-center" style={{borderRadius: 24, background: '#6699ee33', height: 24, width: 24}}><i className="fas fa-2" style={{color: '#1359ec'}}/></span>
                            <span className="d-inline"><Trans i18nKey={"welcome_prompt_part_1_2"} components={{1: <span className="text-primary"/>}}/></span>
                        </div>
                        <div className="d-flex flex-row align-items-center mt-1" style={{whiteSpace: 'normal'}}>
                            <span className="p-3 me-1 d-flex justify-content-center align-items-center" style={{borderRadius: 24, background: '#6699ee33', height: 24, width: 24}}><i className="fas fa-3" style={{color: '#1359ec'}}/></span>
                            <span className="d-inline"><Trans i18nKey={"welcome_prompt_part_1_3"} components={{1: <span className="text-primary"/>}}/></span>
                        </div>
                        <div className="d-flex flex-row align-items-center mt-1" style={{whiteSpace: 'normal'}}>
                            <span className="p-3 me-1 d-flex justify-content-center align-items-center" style={{borderRadius: 24, background: '#6699ee33', height: 24, width: 24}}><i className="fas fa-4" style={{color: '#1359ec'}}/></span>
                            <span className="d-inline"><Trans i18nKey={"welcome_prompt_part_1_4"} components={{1: <span className="text-primary"/>}}/></span>
                        </div>
                    </div>

                    <button className="btn btn-primary mt-3" onClick={() => setStep(3)}>{t('continue')}</button>
                    
                </div>
            </div>
        </CustomModal>
        <CustomModal open={step == 3}>
            <div className="card py-3 text-center overflow-auto" style={{width: mobile ? 320 : 600, height: 751, maxHeight: mobile ? 500 : 1200}}>
                <div className="card-body d-flex flex-column justify-content-center align-items-center">
                    <span className="shadow-2"><img src="/tutorial/t2.png" width="100%"/></span>
                    <div className="d-flex flex-column text-start justify-content-start w-100 mt-2">
                        <div className="d-flex flex-row align-items-center" style={{whiteSpace: 'normal'}}>
                            <span className="p-3 me-1 d-flex justify-content-center align-items-center" style={{borderRadius: 24, background: '#6699ee33', height: 24, width: 24}}><i className="fas fa-1" style={{color: '#1359ec'}}/></span>
                            <span className="d-inline"><Trans i18nKey={"welcome_prompt_part_2_1"} components={{1: <span className="text-primary"/>}}/></span>
                        </div>
                        <div className="d-flex flex-row align-items-center mt-1" style={{whiteSpace: 'normal'}}>
                            <span className="p-3 me-1 d-flex justify-content-center align-items-center" style={{borderRadius: 24, background: '#6699ee33', height: 24, width: 24}}><i className="fas fa-2" style={{color: '#1359ec'}}/></span>
                            <span className="d-inline"><Trans i18nKey={"welcome_prompt_part_2_2"} components={{1: <span className="text-primary"/>}}/></span>
                        </div>
                        <div className="d-flex flex-row align-items-center mt-1" style={{whiteSpace: 'normal'}}>
                            <span className="p-3 me-1 d-flex justify-content-center align-items-center" style={{borderRadius: 24, background: '#6699ee33', height: 24, width: 24}}><i className="fas fa-3" style={{color: '#1359ec'}}/></span>
                            <span className="d-inline"><Trans i18nKey={"welcome_prompt_part_2_3"} components={{1: <span className="text-primary"/>}}/></span>
                        </div>
                        <div className="d-flex flex-row align-items-center mt-1" style={{whiteSpace: 'normal'}}>
                            <span className="p-3 me-1 d-flex justify-content-center align-items-center" style={{borderRadius: 24, background: '#6699ee33', height: 24, width: 24}}><i className="fas fa-4" style={{color: '#1359ec'}}/></span>
                            <span className="d-inline"><Trans i18nKey={"welcome_prompt_part_2_4"} components={{1: <span className="text-primary"/>}}/></span>
                        </div>
                    </div>

                    <button className="btn btn-primary mt-3" onClick={() => setStep(4)}>{t('continue')}</button>
                    
                </div>
            </div>
        </CustomModal>

        <CustomModal open={step == 4}>
            <div className="card py-3 text-center overflow-auto" style={{width: mobile ? 320 : 600, height: 890, maxHeight: mobile ? 500 : 1200}}>
                <div className="card-body d-flex flex-column justify-content-center align-items-center">
                    <span className="shadow-2"><img src="/tutorial/t3.png" width="100%"/></span>
                    <div className="d-flex flex-column text-start justify-content-start w-100 mt-2">
                        <div className="d-flex flex-row align-items-center" style={{whiteSpace: 'normal'}}>
                            <span className="p-3 me-1 d-flex justify-content-center align-items-center" style={{borderRadius: 24, background: '#6699ee33', height: 24, width: 24}}><i className="fas fa-1" style={{color: '#1359ec'}}/></span>
                            <span className="d-inline"><Trans i18nKey={"welcome_prompt_part_3_1"} components={{1: <span className="text-primary"/>}}/></span>
                        </div>
                        <div className="d-flex flex-row align-items-center mt-1" style={{whiteSpace: 'normal'}}>
                            <span className="p-3 me-1 d-flex justify-content-center align-items-center" style={{borderRadius: 24, background: '#6699ee33', height: 24, width: 24}}><i className="fas fa-2" style={{color: '#1359ec'}}/></span>
                            <span className="d-inline"><Trans i18nKey={"welcome_prompt_part_3_2"} components={{1: <span className="text-primary"/>}}/></span>
                        </div>
                        <div className="d-flex flex-row align-items-center mt-1" style={{whiteSpace: 'normal'}}>
                            <span className="p-3 me-1 d-flex justify-content-center align-items-center" style={{borderRadius: 24, background: '#6699ee33', height: 24, width: 24}}><i className="fas fa-3" style={{color: '#1359ec'}}/></span>
                            <span className="d-inline"><Trans i18nKey={"welcome_prompt_part_3_3"} components={{1: <span className="text-primary"/>}}/></span>
                        </div>
                        <div className="d-flex flex-row align-items-center mt-1" style={{whiteSpace: 'normal'}}>
                            <span className="p-3 me-1 d-flex justify-content-center align-items-center" style={{borderRadius: 24, background: '#6699ee33', height: 24, width: 24}}><i className="fas fa-4" style={{color: '#1359ec'}}/></span>
                            <span className="d-inline"><Trans i18nKey={"welcome_prompt_part_3_4"} components={{1: <span className="text-primary"/>}}/></span>
                        </div>
                        <div className="d-flex flex-row align-items-center mt-1" style={{whiteSpace: 'normal'}}>
                            <span className="p-3 me-1 d-flex justify-content-center align-items-center" style={{borderRadius: 24, background: '#6699ee33', height: 24, width: 24}}><i className="fas fa-5" style={{color: '#1359ec'}}/></span>
                            <span className="d-inline"><Trans i18nKey={"welcome_prompt_part_3_5"} components={{1: <span className="text-primary"/>}}/></span>
                        </div>
                    </div>

                    <button className="btn btn-primary mt-3" onClick={() => setStep(5)}>{t('continue')}</button>
                    
                </div>
            </div>
        </CustomModal>

        <CustomModal open={step == 5}>
            <div className="card py-3 text-center" style={{width: mobile ? 320 : 600}}>
                <div className="card-body d-flex flex-column justify-content-center align-items-center">
                    <span className="p-3 mb-3 d-flex justify-content-center align-items-center" style={{borderRadius: 160, background: '#8fff0024', height: 160, width: 160}}><i className="fas fa-check text-success fa-6x" style={{}}/></span>
                    <h2 className="mb-2"><Trans i18nKey={"welcome_prompt_all_set_1"}/></h2>
                    <h5><Trans i18nKey={"welcome_prompt_all_set_2"} components={{1: <span className="text-primary"/>, 2: <span className="text-primary"/>}}/></h5>
                    <button className="btn btn-primary mt-3" onClick={() => { setStep(-1); localStorage.removeItem('newUser'); }}>{t('lets_start')}</button>
                </div>
            </div>
        </CustomModal>
    </>
        
}