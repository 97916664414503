import logo from './logo.svg';
import './App.css';
import React, { useCallback, useEffect, useState } from 'react';
import { BrowserRouter, createBrowserRouter, Navigate, Outlet, Route, RouterProvider, Routes, useNavigate } from 'react-router-dom';
import { MainPage } from './pages/main/MainPage';
import { LoginPage } from './pages/login/LoginPage';
import { Input } from 'mdb-ui-kit';
import { GroupSelect } from './pages/main/GroupSelect';
import API from './service/api';
import { GroupViewer } from './pages/main/group/GroupViewer';
import { Profile } from './pages/main/profile/Profile';
import { JoinPage } from './pages/join/JoinPage';
import WS from './service/ws';
import { I18nextProvider, Trans, useTranslation } from 'react-i18next';
import i18n from './i18n'; // Your i18n instance
import { ResetPassword } from './pages/login/ResetPassword';
import { MainReportPage } from './pages/reports_old/MainReportPage';
import { CustomModal } from './pages/other/CustomModal';
import { ReportViewer } from './pages/reports/ReportViewer';
import { ReportsSelectorPopup } from './pages/main/ReportsSelectorPopup';


const generateReportName = (r,t) => {
	const type = r.type == 'sales' ? 'Sales' : (r.type == 'products' ? 'Products' : '???');
	const source = r.mname ? t('machine') : (r.lname ? t('location') : t('group'));
	const name = r.mname || r.lname || r.gname;
	const st = new Date(r.startDate),
		et = new Date(r.endDate);

	return `${type}/${source}: ${name} [${st.toLocaleDateString()}-${et.toLocaleDateString()}]`;
}

function App() {
	const [loading, setLoading] = useState(true);
	const [userData, setUserData] = useState(null);
	const [rights, setRights] = useState(null);
	const [wsConnected, setWsConnected] = useState(WS.connected);
	const [rightsListener, setRightsListener] = useState(null);
	const [loginError, setLoginError] = useState('');
	const [reports, setReports] = useState([]);
	const [showRep, setShowRep] = useState(null);
	const { t } = useTranslation();

	const onReport = useCallback(r => {
		if (ReportsSelectorPopup.RequestedReports.includes(r.id)){
			ReportsSelectorPopup.RequestedReports = ReportsSelectorPopup.RequestedReports.filter(rid => rid !== r.id);
			window.open('/reports/'+r.id, '_blank');
		}
	}, []);

	useEffect(() => {
        document.querySelectorAll('.form-outline').forEach((formOutline) => {
            new Input(formOutline).init();
        });

		return () => {
			WS.unlisten('report', onReport);
		}
	}, []);

	
	
	const getMyData = () => {
		setLoading(true);
		API.me()
			.then(data => {
				API.connectWS().then(() => {
					if (!rightsListener) {
						WS.listen('newRights', r => { setRights(r)});
						setRightsListener(true);
					}
					setUserData(data);
					try {
						setRights(data.rights);
					}catch(err){
						console.error(err);
					}
					
					setWsConnected(WS.connected());
					WS.listen('report', onReport);
					setLoading(false);
				});
			})
			.catch(err => {
				setLoginError(t('token_expired'));
				localStorage.removeItem('token');
				setLoading(false);
			})
	}

	useEffect(() => {
		API.onLogout = () => {
			setUserData(null);
		}
		API.token = localStorage.getItem('token') || '';
		if (API.token) getMyData();
		else setLoading(false);
		
	}, []);
	
	if (loading && (!userData || !wsConnected)) return null;
	return (
		<I18nextProvider>
			{/* {showRep && 
				<CustomModal open={showRep}>
					<div className="card" style={{width: '100vw', maxWidth: 300}}>
						<div className="card-header">
							<h5>{t('report_result')}</h5>
						</div>
						<div className='card-body'>
							<Trans i18nKey="report_finished_prompt" values={{title: generateReportName(showRep, t)}}/>
							<a href={'/reports/'+showRep.id} target="_blank" className='btn btn-primary m-1 w-100' onClick={() => { setShowRep(null); setReports(prev => prev.slice(1)); }}>{t('view_report')}</a>
							<button className='btn btn-danger m-1 w-100' onClick={() => { setShowRep(null); setReports(prev => prev.slice(1)); }}>{t('close')}</button>
						</div>
					</div>
				</CustomModal>
			} */}
			<BrowserRouter>
				<Routes>
					{/* <Route path="" element={
						!userData ? <Navigate to="/auth"/> : <Navigate to="/g"/>
					}/> */}
					<Route path="*" element={userData ? <MainPage userData={userData}/> : <Navigate to="/auth"/>}/>
					<Route path="auth" element={userData ? <Navigate to="/"/> :<LoginPage onLogin={getMyData} initialError={loginError}/>}/>
					<Route path="reset" element={<ResetPassword/>}/>
					<Route path="profile/*" element={userData ? <Profile userData={userData} rights={rights} onUserUpdate={getMyData}/> : <Navigate to="/auth"/>}/>
					<Route path="join" element={<JoinPage onLogin={getMyData}/>} />
					{/* <Route path="reports/*" element={userData ? <MainReportPage userData={userData}/> : <Navigate to="/auth"/>}/> */}
					<Route path="reports/:id" element={<ReportViewer/>}/>
					<Route path="*" element={<Navigate to="/"/>}/>
				</Routes>
			</BrowserRouter>
		</I18nextProvider>
		
	);
}

export default App;
