import * as mdb from "mdb-ui-kit"; // lib
import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { NavLink } from "react-router-dom";
import API from "../../../service/api";
import { CustomSelect } from "../../../utils/CustomSelect";
import { PageNavigation } from "../../../utils/PageNavigation";
import { TabsViewer } from "../../../utils/TabsViewer";
import { LogViewer } from "../../other/LogViewer";
import { BoxesList } from "./BoxesList";
import { MachineDetails } from "./MachineDetails";
import { CustomModal } from "../../other/CustomModal";
import WS from "../../../service/ws";
import Popup from 'reactjs-popup';
import { Tooltip } from 'react-tooltip';
import { AdvancedSettingsModal } from "../profile/AdvancedSettingsModal";
import { Trans, useTranslation } from "react-i18next";
import { ReportsSelectorPopup } from "../ReportsSelectorPopup";

function getWindowSize() {
	const { innerWidth, innerHeight } = window;
	return { innerWidth, innerHeight };
}

const UpdateModal = ({ groupID, locationID, machineID, machine, boxProp, products, onClose, onUpdate, canChangeProduct, canChangePrice, canChangeQnty, canDeliverProduct }) => {
	const [box, setBox] = useState(null);
	const [productId, setProductId] = useState(-1);
	const [productChanged, setProductChanged] = useState(false);
	const [price, setPrice] = useState(null);
	const [priceChanged, setPriceChanged] = useState(false);
	const [QNTY, setQNTY] = useState(null);
	const [QNTYchanged, setQNTYChanged] = useState(false);
	const [wCash, setWCash] = useState(false);
	const [changed, setChanged] = useState(false);
	const [error, setError] = useState('');
	const { t } = useTranslation();

	useEffect(() => {
		document.querySelectorAll(".form-outline").forEach((formOutline) => {
			new mdb.Input(formOutline).init();
		});
	}, [box]);

	useEffect(() => {
		setBox(boxProp);
		setProductId(boxProp.prodId);
		setPrice(boxProp.price);
		setQNTY(boxProp.qnty);
		setWCash(false);

		setProductChanged(false);
		setPriceChanged(false);
		setQNTYChanged(false);

		setChanged(false);
		setError('');
	}, [boxProp]);


	const saveProduct = () => {
		setProductChanged(false);
		setError('');
		API.updateBox(groupID, locationID, machineID, box.nr, Number(productId), undefined, undefined)
			.then(() => onUpdate())
			.catch(err => setError(err.message));
	}

	const savePrice = () => {
		setPriceChanged(false);
		setError('');
		const p = scalePrice();
		API.updateBox(groupID, locationID, machineID, box.nr, undefined, Number(p), undefined)
			.then(() => onUpdate())
			.catch(err => setError(err.message));
	}

	const saveQNTY = () => {
		setQNTYChanged(false);
		setError('');
		API.updateBox(groupID, locationID, machineID, box.nr, undefined, undefined, Number(QNTY))
			.then(() => onUpdate())
			.catch(err => setError(err.message));
	}


	const deliverProduct = () => {
		setError('');
		API.deliverProduct(groupID, locationID, machineID, box.nr, wCash)
			.then(() => { onUpdate(); })
			.catch(err => setError(err.message));
	}

	const scalePrice = () => {
		const nr = (price||0) / machine.mUnit;
		const nrInt = Math.round(nr);
		if (nr === nrInt && typeof price == 'number') return price;
		const newPrice = nrInt * machine.mUnit;
		setPrice(newPrice);
		return newPrice;
	}

	return (
		<div className="card" style={{width: 300}}>
			{box && (
				<>
					<div
						className="d-flex position-absolute w-100 justify-content-center"
						style={{ marginTop: -10 }}
					>
						<span className="badge rounded-pill badge-primary">{box.nr+1}</span>
					</div>
					<div className="card-body">
						<div className="d-flex flex-column justify-content-center align-items-center">
							<TabsViewer id="boxupdater" fullSize autoDefault
								tabs={[
									...((canChangeProduct && typeof productId == 'number') ? [{
										name: 'product', title: '', icon: 'fas fa-shopping-basket',
										component: (
											<div className="d-flex flex-column justify-content-center align-items-center p-2 w-100">
												<CustomSelect title={t('product')} w100
													items={products.map((p) => ({
														val: p.id,
														title: p.name,
														icon: "fas fa-shopping-basket text-primary",
													}))}
													selected={productId}
													search={true}
													disabled={!machine.status.Connected}
													onSelect={(el) => { setProductId(el); setProductChanged(true); }}
												/>
												<button className="btn btn-primary w-100 mt-2" disabled={!productChanged || !machine.status.Connected} onClick={saveProduct}>{t('save')}</button>
											</div>
										)
									}]:[]),
									...((canChangePrice && price !== undefined) ? [{
										name: 'price', title: '', icon: 'fas fa-money-bill',
										component: (
											<div className="d-flex flex-column justify-content-center align-items-center p-2 w-100">
												<div className="form-outline mb-2">
													<input
														type="number"
														id="price"
														className="form-control"
														value={price}
														step={machine.mUnit}
														disabled={!machine.status.Connected}
														onChange={(ev) => { setPrice(ev.target.value); setPriceChanged(true); }}
														onBlur={() => scalePrice()}
													/>
													<label className="form-label" htmlFor="price">{t('price')}</label>
												</div>
												<button className="btn btn-primary w-100 mt-2" disabled={!priceChanged || !machine.status.Connected} onClick={savePrice}>{t('save')}</button>
											</div>
										)
									}]:[]),
									...((canChangeQnty && QNTY !== undefined) ? [{
										name: 'qnty', title: '', icon: 'fas fa-shopping-cart',
										component: (
											<div className="d-flex flex-column justify-content-center align-items-center p-2 w-100">
												<div className="form-outline mb-2">
													<input
														type="number"
														id="qnty"
														className="form-control"
														value={QNTY}
														disabled={!machine.status.Connected}
														onChange={(ev) => { setQNTY(ev.target.value); setQNTYChanged(true); }}
														
													/>
													<label className="form-label" htmlFor="qnty">{t('quantity')}</label>
												</div>
												<button className="btn btn-primary w-100 mt-2" disabled={!QNTYchanged || !machine.status.Connected} onClick={saveQNTY}>{t('save')}</button>
											</div>
										)
									}]:[]),
									...((canDeliverProduct) ? [{
										name: 'deliver', title: '', icon: 'fas fa-wrench',
										component: (
											<div className="d-flex flex-column justify-content-center align-items-center p-2 w-100">
												<div className="form-check align-items-center d-flex">
													<input className="form-check-input" type="checkbox" value={wCash} disabled={!machine.status.Connected} id="wcash" onChange={ev => setWCash(ev.currentTarget.checked)} />
													<label className="form-check-label" htmlFor="wcash">{t('with_cash')}</label>
												</div>
												<button className="btn btn-secondary w-100 mt-2" onClick={deliverProduct} disabled={!machine.status.Connected}>{t('deliver_product')}</button>
											</div>
										)
									}]:[]),

								]}
							/>
							{ error && <div className="alert alert-danger alert-dismissible fade show mt-2" role="alert">
								{ error }
							</div> }
							{!machine.status.Connected &&
								<span className="text-danger">
									<i className="fas fa-exclamation-triangle text-danger me-1"/>
									{t('status.communication_lost')}!
								</span>
							}
						</div>
							
					</div>
				</>
			)}
			{!box && <>...</>}
		</div>
	);
};

const EditCredit = ({groupID, locationID, machine, onClose, onEdit}) => {
    const [credit, setCredit] = useState(0);
    const [changed, setChanged] = useState(false);
    const [error, setError] = useState('');
	const { t } = useTranslation();

    useEffect(() => {
        setError('');
        if (machine){
            setCredit(machine.credit);
            setChanged(false); 
        }
        document.querySelectorAll(".form-outline").forEach((formOutline) => {
            new mdb.Input(formOutline).init();
        });
    }, [machine]);

    const update = () => {
        setChanged(false);
        const credit = scaleCredit();
        API.updateMachine(groupID, locationID, machine.id, Number(credit))
            .then(() => { onEdit(); onClose(); })
            .catch(err => setError(err.message));
    }

    const scaleCredit = () => {

        const nr = (credit || 0) / machine.mUnit;
		const nrInt = Math.round(nr);
		if (nr === nrInt && typeof credit == 'number') return credit;
		const newCredit = nrInt * machine.mUnit;
		setCredit(newCredit);
		return newCredit;
    }

    return (
        <CustomModal open={machine} onClose={onClose}>
            <div className="card mx-3" style={{width: '280px'}}>
                <div className="card-body">
                    <h5 className="card-title">{t('edit_credit')}</h5>
                        <div className="form-outline mb-2">
                            <input
                                type="number"
                                id="editcredit"
                                className="form-control"
                                value={credit}
                                step={machine?.cUnit}
                                onChange={(ev) => {
									setCredit(ev.currentTarget.value); setChanged(true);
									//setCredit(Number(ev.currentTarget.value)); 
								}}
                                onBlur={() => scaleCredit()}
                            />
                            <label className="form-label" htmlFor="editcredit">{t('credit')}</label>
                        </div>
                    
                    {error && (
                        <div className="alert alert-danger alert-dismissible fade show mt-2" role="alert">
                            { error }
                        </div>
                    )}
                    
                    <button className="btn btn-primary w-100" disabled={!changed} onClick={() => { update(); }}>{t('update')}</button>
                </div>
            </div>
        </CustomModal>
    )
}

const ResetModal = ({groupID, locationID, machine, onDone, onClose}) => {
    const [error, setError] = useState('');
	const { t } = useTranslation();

    useEffect(() => {
        setError('');
    }, [machine]);

    const resetMachine = () => {

        API.machineActionReset(groupID, locationID, machine.id)
            .then(() => { onDone(); onClose(); })
            .catch(err => setError(err.message));
    }

    return (
        <CustomModal open={machine} onClose={onClose}>
            <div className="card mx-3" style={{width: 300}}>
                <div className="card-header">
                    <h5>{t('reset_machine')}</h5>
                </div>
                <div className="card-body">
                    { t('reset_machine_prompt') }
                    { error && <div className="alert alert-danger alert-dismissible fade show mt-2" role="alert">
                        { error }
                    </div> }
                </div>
                <div className="card-footer">
                    <button type="button" className="btn btn-primary" onClick={resetMachine}>{t('reset')}</button>
                </div>
            </div>
        </CustomModal>
        
    )
}

const SetRTC = ({groupID, locationID, machine, onDone, onClose}) => {
    const [error, setError] = useState('');
	const { t } = useTranslation();

    useEffect(() => {
        setError('');
    }, [machine]);

    const setRTC = () => {

        API.machineActionSetRTC(groupID, locationID, machine.id)
            .then(() => { onDone(); onClose(); })
            .catch(err => setError(err.message));
    }

    return (
        <CustomModal open={machine} onClose={onClose}>
            <div className="card mx-3" style={{width: 300}}>
                <div className="card-header">
                    <h5>{t('set_rtc')}</h5>
                </div>
                <div className="card-body">
                    { t('set_rtc_prompt') }
                    { error && <div className="alert alert-danger alert-dismissible fade show mt-2" role="alert">
                        { error }
                    </div> }
                </div>
                <div className="card-footer">
                    <button type="button" className="btn btn-primary" onClick={setRTC}>{t('set')}</button>
                </div>
            </div>
        </CustomModal>
        
    )
}

const OpenDoorModal = ({machine, onDone, onClose}) => {
    const [error, setError] = useState('');
	const { t } = useTranslation();

	useEffect(() => {
        setError('');
    }, [machine]);

    const openDoor = () => {
        onDone();
    }

    return (
        <CustomModal open={machine} onClose={onClose}>
            <div className="card mx-3" style={{width: 300}}>
                <div className="card-header">
                    <h5>{t('open_door')}</h5>
                </div>
                <div className="card-body">
                    <Trans i18nKey="open_door_prompt"/>
                    { error && <div className="alert alert-danger alert-dismissible fade show mt-2" role="alert">
                        { error }
                    </div> }
                </div>
                <div className="card-footer">
                    <button type="button" className="btn btn-primary" onClick={openDoor}>{t('open')}</button>
                </div>
            </div>
        </CustomModal>
        
    )
}

const ConfirmOpenDoorModal = ({groupID, locationID, machine, onDone, onClose}) => {
    const [error, setError] = useState('');
    const [timeoutDone, setTimeoutDone] = useState(false);
	const { t } = useTranslation();

    useEffect(() => {
        setError('');
        setTimeoutDone(false);
        const t = setTimeout(() => {
            setTimeoutDone(true);
        }, 2000);

        return () => {
            clearTimeout(t);
        }
    }, [machine]);

    const openDoor = () => {

        API.machineActionOpenDoor(groupID, locationID, machine.id)
            .then(() => { onDone(); onClose(); })
            .catch(err => setError(err.message));
    }

    return (
        <CustomModal open={machine} onClose={onClose}>
            <div className="card mx-3" style={{width: 300}}>
                <div className="card-header bg-danger text-white fw-bold d-flex flex-row align-items-center" style={{borderColor: 'rgba(0,0,0,0.175)'}}>
                    <h5 className='fw-bold m-0 me-1'>{t('open_door')}</h5> <i className='fas fa-exclamation-triangle fa-lg text-warning'/>
                </div>
                <div className="card-body bg-danger text-white" style={{borderRadius: 0}}>
					<Trans i18nKey="confirm_open_door_prompt"/>
                    { error && <div className="alert alert-danger alert-dismissible fade show mt-2" role="alert">
                        { error }
                    </div> }
                </div>
                <div className="card-footer bg-danger " style={{borderColor: 'rgba(0,0,0,0.175)'}}>
                    <button type="button" className="btn btn-warning text-white" disabled={!timeoutDone} onClick={openDoor}>{timeoutDone ? t('confirm_and_execute') : t('please_wait')}</button>
                </div>
            </div>
        </CustomModal>
        
    )
}

const StatusBar = ({groupID, locationID, propMachine, rights}) => {
	const [machine, setMachine] = useState(propMachine);
    const [canEditCredit, setCanEditCredit] = useState(false);
	const [editCredit, setEditCredit] = useState(null);
	const [canPower, setCanPower] = useState(false);
	const [canOpenDoor, setCanOpenDoor] = useState(false);
	const [canAdvancedSettings, setCanAdvancedSettings] = useState(false);
    const [canSetRTC, setCanSetRTC] = useState(false);
	const [canGetRTC, setCanGetRTC] = useState(false);

	const [resetMachine, setResetMachine] = useState(false);
	const [RTCMachine, setRTCMachine] = useState(null);
	const [openDoor, setOpenDoor] = useState(null);
	const [confirmOpenDoor, setConfirmOpenDoor] = useState(null);
	const [advancedSettings, setAdvancedSettings] = useState(null);
	const { t } = useTranslation();

	useEffect(() => {
		setMachine(propMachine);
	}, [propMachine]);

	useEffect(() => {
		setCanEditCredit(rights?.Machines?.Credit?.View && rights?.Machines?.Credit?.Change);
		setCanPower(rights?.Machines?.Actions?.Power);
		setCanOpenDoor(rights?.Machines?.Actions?.OpenDoor);
		setCanSetRTC(rights?.Machines?.Actions?.ChangeDateTime);
		setCanGetRTC(rights?.Machines?.Actions?.ReadParams);
		setCanAdvancedSettings(machine && machine.advanced_params && machine.advanced_params.length > 0);
	}, [rights, machine]);

    const PowerIconColor = useMemo(() => {
        if (machine.status?.PowerOn && machine.status?.Connected) return '#1aa51a9c';
        if (machine.status?.PowerOff && machine.status?.Connected) return '#cdcfd1';
        if (!machine.status?.Connected || (!machine.status?.PowerOff && !machine.status?.PowerOn)) return '#efbac3';
    }, [machine.status]);

	if (!machine) return null;
	return (
		<nav
			className="navbar navbar-expand-lg navbar-light p-1 w-100 border-top"
			style={{ background: "#fc8c0342" }}
		>
			<EditCredit groupID={groupID} locationID={locationID} machine={editCredit} onEdit={() => { setEditCredit(null); }} onClose={() => setEditCredit(null)}/>
            <ResetModal groupID={groupID} locationID={locationID} machine={resetMachine} onDone={() => { setResetMachine(null); }} onClose={() => setResetMachine(null)}/>
			<SetRTC groupID={groupID} locationID={locationID} machine={RTCMachine} onDone={() => { setRTCMachine(null); }} onClose={() => setRTCMachine(null)}/>
			<OpenDoorModal groupID={groupID} locationID={locationID} machine={openDoor} onDone={() => { setOpenDoor(null); setConfirmOpenDoor(machine); }} onClose={() => setOpenDoor(null)}/>
            <ConfirmOpenDoorModal groupID={groupID} locationID={locationID} machine={confirmOpenDoor} onDone={() => { setConfirmOpenDoor(null); }} onClose={() => setConfirmOpenDoor(null)}/>
			{advancedSettings && <AdvancedSettingsModal groupID={groupID} locationID={locationID} machine={advancedSettings} onClose={() => setAdvancedSettings(null)} /> }


			<div className="d-flex flex-row flex-fill">
				{ machine.credit !== undefined && <li className="list-group-item p-1 d-flex flex-row align-items-center">
					<i className="fas fa-money-bill-alt fa-fw text-primary me-1"/><span className="fw-bold me-1">{t('credit')}:</span>{machine.credit}
					{ (canEditCredit && machine.status.Connected) && <span className='flex-fill d-flex justify-content-end ms-2'><span role="button" onClick={() => setEditCredit(machine)}><i className='fas fa-pen-to-square text-primary'/></span></span> }
				</li> }

				<div className="d-flex flex-row align-items-center ms-1">
					{ (machine.status?.CashlessLost) && <span><i className='fas fa-credit-card text-danger me-1'/></span> }
					{ (machine.status?.CashboxFull) && <span><i className='fas fa-cart-flatbed text-danger me-1'/></span> }
					
					{ (machine.status?.CashboxAlmostFull) && <span><i className='fas fa-cart-flatbed text-warning me-1'/></span> }
					{ (machine.status?.LowGSMSignal) && <span><i className='fas fa-signal text-warning me-1'/></span> }
					{ (machine.status?.ScheduleSuspended) && <span><i className='fas fa-calendar-xmark text-warning me-1'/></span> }
				</div>

				<div className="d-flex flex-fill"></div>
				<span className='d-flex flex-row justify-content-end position-relative align-items-center text-center' style={{lineHeight: 1}}>
					<span className={'me-1 '+ (machine.status?.Connected ? 'text-success' : 'text-danger')} 
						style={{}} data-tooltip-id="online-tooltip"
						data-tooltip-content={(machine.status?.Connected ? t('status.online') : t('status.offline'))}><i className='fas fa-signal fa-lg' /></span>
					<Tooltip id="online-tooltip" place="left" />
					<Popup
                        key={JSON.stringify(machine.status)}
                        trigger={
                            <span role="button">
                                <span><i className="fas p-2 fa-xs fa-power-off" style={{width:12, height:12, zIndex: 200, borderRadius: '16px', background: PowerIconColor}} /></span>
                            </span>
                        }
						position="top right" arrow={!canPower}
                    >
						{ close =>
							<div className="d-flex flex-column">
								{ (machine.status?.PowerOn && machine.status?.Connected) && <span className="w-100 text-center bg-success text-white">{t('powered_on')}</span> }
								{ (machine.status?.PowerOff && machine.status?.Connected) && <span className="w-100 text-center bg-danger text-white">{t('powered_off')}</span> }
								{ (!machine.status?.Connected || (!machine.status?.PowerOff && !machine.status?.PowerOn)) && <span className="w-100 text-center bg-danger text-white">{t('powered_unknown')}</span> }

								{machine.status.Connected && <>
									{ (!machine.status?.PowerOff && machine.status?.PowerOn && canPower) && 
										<div className="custom-select-item" disabled onClick={() => { close(); API.machineActionPower(groupID, locationID, machine.id, 0); }}>
											<i className="fas fa-power-off me-1"/>
											<span style={{overflow: 'hidden', textOverflow: 'ellipsis'}}>{t('power_off')}</span>
										</div>
									}
									{ (!machine.status?.PowerOff && machine.status?.PowerOn && canPower) && 
										<div className="custom-select-item" onClick={() => { close(); API.machineActionPower(groupID, locationID, machine.id, 2); }}>
											<i className="fas fa-power-off me-1"/>
											<i className="fas fa-calendar-xmark position-absolute bg-white text-warning" style={{width: 12, height: 12, marginLeft: 8, marginTop: 9}}/>
											<span style={{overflow: 'hidden', textOverflow: 'ellipsis'}}>{t('power_off')}</span>
										</div>
									}
									{ (!machine.status?.PowerOn && canPower) && 
										<div className="custom-select-item" onClick={() => { close(); API.machineActionPower(groupID, locationID, machine.id, 1); }}>
											<i className="fas fa-power-off me-1 text-success"/>
											<span style={{overflow: 'hidden', textOverflow: 'ellipsis'}}>{t('power_on')}</span>
										</div>
									}
									{ (!machine.status?.PowerOn && canPower) && 
										<div className="custom-select-item" onClick={() => { close(); API.machineActionPower(groupID, locationID, machine.id, 3); }}>
											<i className="fas fa-power-off me-1 text-success"/>
											<i className="fas fa-calendar-xmark position-absolute bg-white text-warning" style={{width: 12, height: 12, marginLeft: 8, marginTop: 9}}/>
											<span style={{overflow: 'hidden', textOverflow: 'ellipsis'}}>{t('power_on')}</span>
										</div>
									}
									{ (canPower) && 
										<div className="custom-select-item" style={{borderBottom: '2px solid rgba(0,0,0,0.2)'}} onClick={() => { close(); setResetMachine(machine); }}>
											<i className="fas fa-rotate-right me-1 text-primary"/>
											<span style={{overflow: 'hidden', textOverflow: 'ellipsis'}}>{t('reset')}</span>
										</div>
									}
									{ (canGetRTC) && 
										<div className="custom-select-item" onClick={() => { close(); API.machineActionGetRTC(groupID, locationID, machine.id) }}>
											<i className="fas fa-clock me-1 text-primary"/>
											<span style={{overflow: 'hidden', textOverflow: 'ellipsis'}}>{t('get_rtc')}</span>
										</div>
									}
									{ (canSetRTC) && 
										<div className="custom-select-item" onClick={() => { close(); setRTCMachine(machine); }}>
											<i className="fas fa-clock me-1 text-primary"/>
											<span style={{overflow: 'hidden', textOverflow: 'ellipsis'}}>{t('set_rtc')}</span>
										</div>
									}
									{ (canOpenDoor) && 
										<div className="custom-select-item" onClick={() => { close(); setOpenDoor(machine); }}>
											<i className="fas fa-door-open me-1 text-primary"/>
											<span style={{overflow: 'hidden', textOverflow: 'ellipsis'}}>{t('open_door')}</span>
										</div>
									}
									{ (canAdvancedSettings) && 
										<div className="custom-select-item" onClick={() => { close(); setAdvancedSettings(machine); }}>
											<i className="fas fa-server me-1 text-primary"/>
											<span style={{overflow: 'hidden', textOverflow: 'ellipsis'}}>{t('advanced_settings')}</span>
										</div>
									}
								</>}
								{!machine.status.Connected &&
									<span className="text-danger">
										<i className="fas fa-exclamation-triangle text-danger me-1"/>
										{t('status.communication_lost')}!
									</span>
								}
								
								
								
							</div>
						}
					</Popup>
					
				</span> 
			</div>

		</nav>
	)
}



export const MachineViewer = ({ groupID, locationID, groupName, rights, standalone }) => {
	const { id } = useParams();
	const [machine, setMachine] = useState(null);
	const [updateMachine, setUpdateMachine] = useState(null);
	const [boxes, setBoxes] = useState([]);
	const navigate = useNavigate();
	const [fetching, setFetching] = useState(false);
	const [needsToFetch, setNeedsToFetch] = useState(true);
	const [windowSize, setWindowSize] = useState(getWindowSize());
	const [products, setProducts] = useState([]);
	const [editingBox, setEditingBox] = useState(null);
	
	const [canEditProduct, setCanEditProduct] = useState(false);
	const [canEditPrice, setCanEditPrice] = useState(false);
	const [canEditQnty, setCanEditQnty] = useState(false);
	const [canDeliverProduct, setCanDeliverProduct] = useState(false);
	const [error, setError] = useState('');
	const { t } = useTranslation();
	
	useEffect(() => {
		function handleWindowResize() {
			setWindowSize(getWindowSize());
		}
		window.addEventListener("resize", handleWindowResize);
		handleWindowResize();

		API.getProducts().then((prods) => setProducts(prods));

		const machineUpdate = ev => {
			setUpdateMachine(ev[id]);
		}

		//WS.listen('machineUpdate', machineUpdateCallback);
		WS.listen('machine_update', machineUpdate);

		return () => {
			window.removeEventListener("resize", handleWindowResize);
			//WS.unlisten(machineUpdateCallback);
			WS.unlisten('machine_update', machineUpdate);
			WS.emit('machine_leave', {id});
		};
	}, [id]);

	useEffect(() => {
		if (machine && updateMachine) {
			if (updateMachine.boxes) setBoxes(updateMachine.boxes?.boxes || []);
			setUpdateMachine(null);
			setMachine({...machine, ...updateMachine});
			
		}
	}, [machine, updateMachine]);

	const fetch = async () => {
		setFetching(true);
		setNeedsToFetch(false);
		try {
			let data = await API.getBoxesByMachineID(groupID, locationID, id);
			setMachine(data);
			setBoxes(data.boxes?.boxes || []);
		}catch(err){
			setError(err.message);
		}
		finally {
			setFetching(false);
			setEditingBox(null);
		}
	}
	
	useEffect(() => {
		setCanEditProduct(rights?.Machines?.Type?.View && rights?.Machines?.Type?.Change);
		setCanEditPrice(rights?.Machines?.Price?.View && rights?.Machines?.Price?.Change);
		setCanEditQnty(rights?.Machines?.QNTY?.View && rights?.Machines?.QNTY?.Change);
		setCanDeliverProduct(rights?.Machines?.Actions?.DeliveryProducts);
	}, [rights]);

	useEffect(() => {
		if (needsToFetch && !fetching) fetch();
	}, [needsToFetch, fetching]);

	if (error) return (
        <div className="d-flex flex-row justify-content-center align-items-center w-100" style={{height: 'fit-content'}}>
            <div className="alert alert-danger alert-dismissible fade show m-3" role="alert">
                { error }
            </div>
        </div>
        
    );

	if (!machine) return (
        <div className="d-flex justify-content-center pt-2 w-100 h-100">
            <div className="spinner-border" role="status">
                <span className="visually-hidden">{t('loading...')}</span>
            </div>
        </div>
	);

	

	return (
		<div className="d-flex flex-fill flex-column w-100 p-0 justify-content-center align-items-center">
			<CustomModal open={!!editingBox} onClose={() => setEditingBox(null)}>
				<UpdateModal
					machine={machine}
					groupID={groupID} locationID={locationID} machineID={id}
					boxProp={editingBox}
					products={products}
					onClose={() => setEditingBox(null)}
					onUpdate={() => setEditingBox(null)}
					canChangePrice={canEditPrice} canChangeProduct={canEditProduct} canChangeQnty={canEditQnty}
					canDeliverProduct={canDeliverProduct}
				/>
			</CustomModal>

			<nav
				className="navbar navbar-expand-lg navbar-light p-1 w-100 border-top mb-1"
				style={{ background: "#fc8c0342" }}
			>
				<div className="justify-content-between d-flex flex-row w-100 overflow-hidden">
					<div style={{ position: "" }}>
						<NavLink
							to={standalone ? '../../' : `../locations/${locationID}`}
							className="nav-link d-flex align-items-center p-1 user-select-none cursor-pointer"
							style={{ cursor: "pointer" }}
						>
							<div className="d-flex align-items-center pe-1">
								<i
									className="fas fa-arrow-left nav-link"
									style={{ fontSize: "1.8rem" }}
								/>
							</div>
						</NavLink>
					</div>
					{windowSize.innerWidth >= 600 &&
						<div
							className="d-flex flex-row flex-fill align-items-center justify-content-center text-center"
							style={{ overflow: "hidden", marginLeft: -16 }}
						>
							{/* <div className="flex-fill"></div> */}
							<div
								className="d-flex flex-row align-items-center justify-content-end overflow-hidden"
								onClick={() => navigate(`../../g/${groupID}`)}
								style={{ whiteSpace: "nowrap" }}
							>
								<i className="fas fa-layer-group text-primary me-1 fa-sm" />
								<span
									style={{
										fontSize: "0.9rem",
										overflow: "hidden",
										textOverflow: "ellipsis",
										cursor: 'pointer',
									}}
								>
									{groupName}
								</span>
								<span
									style={{
										fontSize: "0.9rem",
									}}
								>
									&nbsp;/
								</span>
							</div>
							<div
								className="d-flex flex-row align-items-center justify-content-end overflow-hidden"
								onClick={() => navigate(`../locations/${locationID}`)}
								style={{ whiteSpace: "nowrap" }}
							>
								<i className="fas fa-map-marker-alt text-primary me-1 fa-sm" />
								<span
									style={{
										fontSize: "0.9rem",
										overflow: "hidden",
										textOverflow: "ellipsis",
										cursor: 'pointer',
									}}
								>
									{machine.location?.name}
								</span>
								<span
									style={{
										fontSize: "0.9rem",
									}}
								>
									&nbsp;/
								</span>
							</div>
							<div className="d-flex flex-row align-items-center">
								<i className="fas fa-broadcast-tower text-primary me-1 ms-1" />
								<span
									style={{
										fontSize: "1rem",
										fontWeight: 'bold',
										whiteSpace: "nowrap",
									}}
								>
									{machine.name}
								</span>
							</div>

							<div className="d-flex flex-row position-absolute align-items-center" style={{right: 5}}>
								{/* {rights?.Events?.SalesEvents && <span role="button" className="d-flex flex-row text-primary ms-2 me-1"
									onClick={() => window.open(`//${window.location.host}/reports?m=${id}`)}
								><i className="fas fa-chart-line"/></span>} */}
								{(rights?.Events?.SalesEvents && !window.DISABLE_REPORTS) &&
									<ReportsSelectorPopup gid={groupID} lid={locationID} mid={id} disabled={false} mobile={windowSize.innerWidth<600}
										trigger={<span role="button"><i className="fas fa-chart-line fa-lg fa-fw text-primary me-1"/></span>}/> 
								}
							</div>
						</div>
					}
					{windowSize.innerWidth < 600 &&
					<div
						className="d-flex flex-column flex-fill align-items-start text-center"
						style={{ overflow: "hidden" }}
					>
						{/* <div className="flex-fill"></div> */}
						<div className="d-flex flex-row flex-fill align-items-center" style={{textOverflow: 'ellipsis'}}>
							<i className="fas fa-broadcast-tower text-primary fa-fw me-1" />
							<span className="d-flex flex-fill"
								style={{
									fontSize: "1rem",
									fontWeight: 'bold',
									whiteSpace: "nowrap",
									lineHeight: '1em',
								}}
							>
								{machine.name}
							</span>
						</div>
						<div
							className="d-flex flex-row flex-fill align-items-center justify-content-end overflow-hidden"
							style={{ whiteSpace: "nowrap" }}
						>
							<div className="d-flex flex-row align-items-center" onClick={() => navigate(`../../g/${groupID}`)}>
								<i className="fas fa-layer-group text-primary fa-fw fa-sm" />
								<span
									style={{
										fontSize: "0.9rem",
										overflow: "hidden",
										textOverflow: "ellipsis",
									}}
								>
									{groupName}
								</span>
								/
							</div>
							<div className="d-flex flex-row align-items-center" onClick={() => navigate(`../locations/${locationID}`)}>
								<i className="fas fa-map-marker-alt text-primary fa-fw fa-sm" />
								<span
									style={{
										fontSize: "0.9rem",
										overflow: "hidden",
										textOverflow: "ellipsis",
									}}
								>
									{machine.location.name}
								</span>
							</div>

							<div className="d-flex flex-row position-absolute align-items-center h-100" style={{right: 5, top: 0}}>
								{(rights?.Events?.SalesEvents && !window.DISABLE_REPORTS) &&
									<ReportsSelectorPopup gid={groupID} lid={locationID} mid={id} disabled={false} mobile={windowSize.innerWidth<600}
										trigger={<span role="button"><i className="fas fa-chart-line fa-lg fa-fw text-primary me-1"/></span>}/> 
								}
							</div>
							
						</div>
						
					</div>
					}

					
					
				</div>
			</nav>

			{windowSize.innerWidth < 1000 && (
				<div className="d-flex flex-column h-100 w-100 flex-fill justify-content-center align-items-center overflow-hidden">
					<TabsViewer
						id="machine"
						tabs={[
							{ name: "machineinfo", title: t('machine'), component: <MachineDetails groupID={groupID} locationID={locationID} machine={machine} onEdit={() => {}} rights={rights}/> },
							{ default: true, name: "boxes", title: t('boxes'), component: (
								<div className="d-flex flex-column flex-fill">
									<BoxesList boxes={boxes} onBoxSelect={box => {
										setEditingBox(box);
									}} />
									<div className="d-flex flex-fill"></div>
								</div>
								
							)},
							{ name: "logs", title: t('logs'), component: <LogViewer groupId={groupID} locationId={locationID} machineId={id} />}
						]}
					/>
					<StatusBar groupID={groupID} locationID={locationID} propMachine={machine} rights={rights}/>

				</div>
				
			)}
			{(windowSize.innerWidth >= 1000 && windowSize.innerWidth < 1200) && (
				<div className="d-flex flex-row w-100 flex-fill overflow-auto">
					<div className="d-flex flex-column align-items-center col-8">
						<TabsViewer
							id="machine"
							tabs={[
								{ name: "machineinfo", title: t('machine'), component: <MachineDetails groupID={groupID} locationID={locationID} machine={machine} onEdit={() => {}} rights={rights}/> },
								{ name: "boxes", title: t('boxes'), default: true, component: (
									<div className="d-flex flex-column flex-fill">
										<BoxesList boxes={boxes} onBoxSelect={box => {
											setEditingBox(box);
										}} />
										<div className="d-flex flex-fill"></div>
										
									</div>
								)}
							]}
						/>
						<StatusBar groupID={groupID} locationID={locationID} propMachine={machine} rights={rights}/>
					</div>
					<div className="d-flex flex-column flex-fill col-4 ms-2">
						<h5 className="w-100" style={{textAlignLast: 'center'}}>{t('logs')}</h5>
						<LogViewer groupId={groupID} locationId={locationID} machineId={id} />
						
					</div>
				</div>
				
			)}

			{windowSize.innerWidth >= 1200 && (
				<div className="d-flex flex-row w-100 flex-fill overflow-hidden">
					<div className="d-flex flex-column align-items-center col-4">
						<h5 className="w-100" style={{textAlignLast: 'center'}}>{t('machine')}</h5>
						<div className="d-flex flex-column overflow-auto w-100">
							<MachineDetails groupID={groupID} locationID={locationID} machine={machine} onEdit={() => {}} rights={rights}/>
						</div>
						
					</div>
					<div className="d-flex flex-column align-items-center col-4">
						<h5 className="w-100" style={{textAlignLast: 'center'}}>{t('boxes')}</h5>
						<div className="d-flex flex-column flex-fill">
							<BoxesList boxes={boxes} onBoxSelect={box => {
								setEditingBox(box);
							}} />
							<div className="d-flex flex-fill"></div>
							<StatusBar groupID={groupID} locationID={locationID} propMachine={machine} rights={rights}/>
						</div>
							
						
					</div>
					<div className="d-flex flex-column flex-fill col-4 ms-2">
						<h5 className="w-100" style={{textAlignLast: 'center'}}>{t('logs')}</h5>
						<LogViewer groupId={groupID} locationId={locationID} machineId={id} />
						
					</div>
				</div>
			)}
			

		</div>
	);
};
